import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
import { PencilFill, Trash3Fill, FileText, ArrowRightCircleFill } from 'react-bootstrap-icons';
import useDeleteRequest from '../../hooks/useDeleteRequest';
import usePostRequest from '../../hooks/usePostRequest';
import getListOfCrops from '../../helpers/getListOfCrops';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import addLastYearDataToJson from '../../helpers/addLastYearDataToJson'
import removeLastYearPropertiesFromJson from '../../helpers/removeLastYearPropertiesFromJson'


const SurveyRow = ({ survey, removeSurvey, addSurvey, farm, lastYearSurvey }) => {
    const [data, setData] = useState({
        CopyFromYear: survey.year,
        CopyToYear: survey.year + 1
    });
    const { post } = usePostRequest(`/api/survey/copy/${survey.id}`, data);
    const { remove } = useDeleteRequest(`/api/survey/${survey.id}`);

    const manageLastYearDataToSchema = () => {
        if (lastYearSurvey === null) {
            removeLastYearPropertiesFromJson(survey);
            return;
        }
        addLastYearDataToJson(survey, lastYearSurvey);
    };

    useEffect(() => {
        manageLastYearDataToSchema();
    }, []);

    const handleRemoveSurvey = async (survey) => {
        var response = await remove(survey.id)
        if (response.isValid) removeSurvey(survey)
    };

    const handleCopySurvey = async () => {
        var response = await post(survey.id, data)
        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        toast.info("Copied", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
        addSurvey(response);
    };
    // ToDo Allow users to select year
    // const onCopyYearChange = event => {
    //     setData(prevData =>
    //     ({
    //         ...prevData,
    //         CopyToYear: event.target.value
    //     }));
    // };

    const onDeleteSubmitClick = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div >
                        <h1 className="text-uppercase">Confirm delete IPM Plan</h1>
                        <p>Are you sure want to delete IPM PLAN:
                            <span className="text-uppercase font-weight-bold"> {survey.year}</span>
                            ?
                        </p>
                        <button type="button" className="btn btn-danger m-2"
                            onClick={() => {
                                handleRemoveSurvey(survey)
                                onClose();
                            }}>
                            Yes, delete all the data...
                        </button>
                        <button type="button" className="btn btn-primary  m-2" onClick={onClose}>No</button>
                    </div>
                );
            }
        });
    }

    const onCopySubmitClick = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div >
                        <h1 className="text-uppercase">Copy IPM Plan</h1>
                        {/* ToDo Allow users to select year */}
                        {/* <div>
                            <Form.Label><h2 className='my-2'>Enter the new year to copy data</h2></Form.Label>
                            <Form.Control type="number"
                                step="1"
                                min={data.CopyToYear}
                                placeholder="YYYY"
                                value={copyToYear}
                                onChange={onCopyYearChange} />
                        </div> */}
                        <p>Are you sure want to copy IPM PLAN from
                            <span className="text-uppercase font-weight-bold"> {data.CopyFromYear} </span>
                            to
                            <span className="text-uppercase font-weight-bold"> {data.CopyToYear}</span>
                            ?
                        </p>
                        <button type="button" className="btn btn-success m-2"
                            onClick={() => {
                                handleCopySurvey()
                                onClose();
                            }}>
                            Yes, copy all the data.
                        </button>
                        <button type="button" className="btn btn-primary  m-2" onClick={onClose}>No</button>
                    </div>
                );
            }
        });
    }

    const listOfCrops = getListOfCrops(survey.landParcels);

    return (
        <tr>
            <td className="text-uppercase align-middle fs-4">{survey.year}</td>
            <td>
                <span>Edit</span>
                <Link to="/add-ipm-plan"
                    state={{
                        farmData: farm,
                        surveyData: survey,
                        isNewSurvey: false
                    }}
                    className="ms-2 me-5" >
                    <PencilFill color="royalblue" size={30} />
                </Link >
            </td>
            <td>
                <span>Delete</span>
                <Trash3Fill type="button" className="ms-2 me-5" color="red" size={30} onClick={() => onDeleteSubmitClick()} />
            </td>
            <td>
                <span>Summary</span>
                <Link to="/ipm-plan-summary"
                    state={{
                        farmData: farm,
                        surveyData: survey,
                        isNewSurvey: false,
                        crops: listOfCrops
                    }}
                    className="ms-2 me-5" >
                    <FileText type="button"
                        color="grey"
                        size={30}
                    />
                </Link>
            </td>
            <td>
                <span>Copy</span>
                <ArrowRightCircleFill type="button" className="ms-2 me-5" color="green" size={30} onClick={() => onCopySubmitClick()} />
            </td>
        </tr>
    );
};

export default SurveyRow;
