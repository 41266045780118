import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const peasPestSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const peaAphid = { name: 'peaAphid', display: "Pea Aphid" };
    const peaMoth = { name: 'peaMoth', display: "Pea Moth" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'bioprotectantsNaturalSubstances'.toLowerCase()) {
                    measureWithPests.measures = 'Bioprotectants natural substances'
                    const issuesInControl = [peaAphid];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'bioprotectantsSemiochemical'.toLowerCase()) {
                    measureWithPests.measures = 'Bioprotectants Semiochemical  '
                    const issuesInControl = [peaMoth];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support, incl. monitoring'
                    const issuesInControl = [peaAphid, peaMoth];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Select low-risk locations'
                    const issuesInControl = [peaAphid];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nonCroppedArea'.toLowerCase()) {
                    measureWithPests.measures = 'In field non-cropped areas'
                    const issuesInControl = [peaAphid, peaMoth];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [peaAphid, peaMoth]);
    return listOfMeasuresWithPests;
};

export default peasPestSummaryMeasures;