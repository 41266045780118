const BrassicasPestList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('aphids'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/biology-and-management-of-the-mealy-cabbage-aphid" target="_blank" rel="noreferrer">Aphids</a>
                    </li>
                    : <></>
                }
                {values.includes('cabbageRootFly'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/cabbage-root-fly-biology-and-management-in-brassica-crops" target="_blank" rel="noreferrer">Cabbage root fly</a>
                    </li>
                    : <></>
                }
                {values.includes('caterpillars'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://horticulture.ahdb.org.uk/knowledge-library/pest-insects-in-brassica-crops-caterpillars" target="_blank" rel="noreferrer">Caterpillars</a>
                    </li>
                    : <></>
                }
                {values.includes('whiteFly'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/pest-insects-in-brassica-crops-cabbage-whitefly" target="_blank" rel="noreferrer">White Fly</a>
                    </li>
                    : <></>
                }
                {values.includes('slugs'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/slugs" target="_blank" rel="noreferrer">Slugs</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default BrassicasPestList;