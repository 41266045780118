import { Link, useLocation, useNavigate } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { ArrowLeft, ArrowRight, QuestionCircle } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import usePutRequest from '../../../../hooks/usePutRequest';
import { useEffect, useState } from 'react';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import AppleWeedsList from './appleWeedsList';
import SurveyNavigation from '../../surveyNavigation';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';
import getListOfCrops from '../../../../helpers/getListOfCrops';

const AddWeedsAppleMeasures = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`660c0db0e1dde05975ebcbf5`);
    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && (location.state.surveyData.weeds !== null)
        && (location.state.surveyData.weeds !== undefined)
        && (location.state.surveyData.weeds.apple !== null)
        && (location.state.surveyData.weeds.apple !== undefined)
        && (location.state.surveyData.weeds.apple.controlMeasures !== null)
        && (location.state.surveyData.weeds.apple.controlMeasures !== undefined)) ?
        location.state.surveyData.weeds.apple.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const { put } = usePutRequest(`/api/survey/${location.state?.surveyData?.id}`, location.state?.surveyData);
    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const listOfCrops = getListOfCrops(location.state?.surveyData.landParcels);

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    let listOfIssues = getListOfIssues(location.state.surveyData.weeds.apple);
    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.weeds.apple.controlMeasures = data;
    };

    const onAddWeedsResistanceSubmitClick = async (goToNextPage) => {
        const response = await put();
        if (location.state.surveyData.weeds.apple !== undefined && location.state.surveyData.weeds.apple !== null)
            location.state.surveyData.weeds.apple.controlMeasures = data;
        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!goToNextPage) {
            toast.info("Saved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        let finalUrl;
        if (listOfCrops.includes("improvedgrassland")) {
            finalUrl = "/add-weeds-improvedgrassland";
        } else {
            finalUrl = "/add-pests";
        }
        navigate(finalUrl,
            {
                state: {
                    farmData: location.state.farmData,
                    surveyData: location.state.surveyData,
                    crops: location.state.crops
                }
            });
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>weeds apple control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            {listOfIssues.length === 0 ? (
                <>
                    <h3>No issues selected</h3>
                </>
            ) : (
                <>
                    {(
                        listOfIssues.includes('windBlownWeeds'.toLowerCase()) ||
                        listOfIssues.includes('tapRootBlwApple'.toLowerCase()) ||
                        listOfIssues.includes('fibrousRootBlwApple'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase())
                    ) && (
                            <>
                                <h2>Weed Controls for Apple crop</h2>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Mowing/topping/crimping/rolling</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#mowingDesc" aria-expanded="false" aria-controls="mowingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="mowingDesc">
                                    <p>Primary action of mowing and topping is to remove the growing point of weeds that are extending above the sward and to prevent flowering. Seed production is prevented and the seedbank is diminished. The timing of mowing and topping is crucial in some species, too early and the weed will branch and produce flowers on a shorter stem, too late and fertile  seeds will already have been set.</p>
                                    <p>Mowing can kill some annual weed species as they are overcome by a competitive grass sward.</p>
                                </div>
                                <AppleWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'windBlownWeeds'.toLowerCase(),
                                        'tapRootBlwApple'.toLowerCase(),
                                        'fibrousRootBlwApple'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.mowing}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('windBlownWeeds'.toLowerCase()) ||
                        listOfIssues.includes('creepingThistle'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Hygiene/prevention</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="hygieneDesc">
                                    <p>Prevent weed seeds being introduced into the crop. </p>
                                    <p>Remove weeds from wild areas of margins particularly those with seeds that are blown in the wind such as sowthistles, creeping and spear thistles.</p>
                                    <p>Keep uncropped areas tidy of possible, mow regularly to prevent weeds flowering and setting seed, or sow  competitive grasses.</p>
                                    <p>Be careful when mowing or using machinery, do not scalp soil and leave bare patches where weeds can establish.</p>
                                </div>
                                <AppleWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'windBlownWeeds'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                        'spearThistle'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.hygiene}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('windBlownWeeds'.toLowerCase()) ||
                        listOfIssues.includes('tapRootBlwApple'.toLowerCase()) ||
                        listOfIssues.includes('fibrousRootBlwApple'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Harrowing/mechanical weeding (seedling weeds)</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#harrowingDesc" aria-expanded="false" aria-controls="harrowingDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="harrowingDesc">
                                    <p>Harrows and tine weeders uproot seedling weeds and cover with a thin layer of soil. These work best where the soil surface has a medium to fine tilth and can be used in all soil types. They disturb the soil at a depth of 2-3 cm and are effective on weeds at the early growth stages (up to 2.5 cm in height).  Weeds are generally controlled by burial, but there is some uprooting where working depth and speed of travel are increased. These techniques work best in dry, sunny  conditions. This technique can be effective on weeds with shallow fibrous roots such as chickweed, mayweed and fat hen or small tap rooted weeds. This will need to be repeated if soil is to be kept bare.</p>
                                </div>
                                <AppleWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'windBlownWeeds'.toLowerCase(),
                                        'tapRootBlwApple'.toLowerCase(),
                                        'fibrousRootBlwApple'.toLowerCase(),
                                        'spearThistle'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.harrowing}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('windBlownWeeds'.toLowerCase()) ||
                        listOfIssues.includes('tapRootBlwApple'.toLowerCase()) ||
                        listOfIssues.includes('fibrousRootBlwApple'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Physical mulches</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#physicalMulchesDesc" aria-expanded="false" aria-controls="physicalMulchesDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="physicalMulchesDesc">
                                    <p>A thick layer of mulch will be effective in preventing weed emergence or smothering existing weeds. A good depth of mulch is needed 3 to 4 inches. Mulch needs to be monitored as weeds will start to root on the surface after time. Mulch will need to be replenished.</p>
                                    <p>Fabric mulches can be pegged down around trees but subsequent build up of organic matter will allow weeds to establish on top of them.</p>
                                </div>
                                <AppleWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'windBlownWeeds'.toLowerCase(),
                                        'tapRootBlwApple'.toLowerCase(),
                                        'fibrousRootBlwApple'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'spearThistle'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.physicalMulches}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('windBlownWeeds'.toLowerCase()) ||
                        listOfIssues.includes('tapRootBlwApple'.toLowerCase()) ||
                        listOfIssues.includes('fibrousRootBlwApple'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Thermal weed control</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#thermalWeedControlDesc" aria-expanded="false" aria-controls="thermalWeedControlDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="thermalWeedControlDesc">
                                    <p>Hand held machinery is currently available to apply hot foam direct to the soil, this kills surface vegetation but does not touch the roots. Effective on small annual weeds, both tap and fibrous rooted.</p>
                                    <p>An electric weeder as a handheld lance is available for spot treatment of individual weeds. Effecive on larger weeds both annual and perennial.</p>
                                </div>
                                <AppleWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'windBlownWeeds'.toLowerCase(),
                                        'tapRootBlwApple'.toLowerCase(),
                                        'fibrousRootBlwApple'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'spearThistle'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.thermalWeedControl}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Hand pulling/cutting/digging</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#handPullingDesc" aria-expanded="false" aria-controls="handPullingDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="handPullingDesc">
                                    <p>Perennial weeds can be directly dug or pulled. Specialised handheld tools have been developed to remove specific weeds e.g. prongs or forks to remove tap rooted weeds such as docks or ragwort. Easier when soil is moist.</p>
                                </div>
                                <AppleWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'docks'.toLowerCase(),
                                        'spearThistle'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.handPulling}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('couch'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('creepingThistle'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Spot treatment</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#spotTreatmentDesc" aria-expanded="false" aria-controls="spotTreatmentDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="spotTreatmentDesc">
                                    <p>Herbicide is sprayed through a knapsack or from a lance on a quadbike. Used to treat individual weeds such as docks and spear thistle. Herbicide usage is targeted and there is minimal damage to other species in the sward.</p>
                                    <p>Products such as pelargonic acid, maleic acid hydrazide can be used for spot treatment of weeds.</p>
                                    <p>An electric weeder as a hand held lance is available for spot treatment of individual weeds. Effective on larger weeds both annual and perennials.</p>
                                </div>
                                <AppleWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'couch'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                        'spearThistle'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.spotTreatment}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                </>
            )}
            <div className="row justify-content-between my-5">
                <div className="alert alert-info" role="alert">
                    To save your work, click <strong>Save</strong> before using the <strong>Go To</strong> or <strong>Go back</strong> button.
                </div>
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                <Link className="col-2 mx-3"
                    to={`/add-weeds-apple`}
                    state={{
                        farmData: location.state.farmData,
                        surveyData: location.state.surveyData,
                        crops: location.state.crops
                    }}>
                    <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                </Link>
                <SurveyNavigation farmData={location.state.farmData} surveyData={location.state.surveyData} crops={location.state.crops} />
                <button type="button" className="col-2 mx-3 btn btn-primary" onClick={() => onAddWeedsResistanceSubmitClick(false)} disabled={status === 'completed'} >Save</button>
                {status === 'completed' ? (
                    <>
                        <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddWeedsResistanceSubmitClick(true)}>Continue</button>
                    </>
                ) : (
                    <>
                        <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddWeedsResistanceSubmitClick(true)}>Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight></button>
                    </>
                )}
            </div>
        </>
    );
};

export default AddWeedsAppleMeasures;