import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const applePestSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const aphids = { name: 'aphids', display: "Aphids" };
    const fruitTreeSpiderMite = { name: 'fruitTreeSpiderMite', display: "Fruit tree spider mite" };
    const lepidopterousCaterpillars = { name: 'lepidopterousCaterpillars', display: "Lepidopterous caterpillars" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'BioprotectantsMacrobiological'.toLowerCase()) {
                    measureWithPests.measures = 'Bioprotectants Macrobiological'
                    const issuesInControl = [aphids, fruitTreeSpiderMite, lepidopterousCaterpillars];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'BioprotectantsMicrobial'.toLowerCase()) {
                    measureWithPests.measures = 'Bioprotectants Microbial'
                    const issuesInControl = [aphids, fruitTreeSpiderMite, lepidopterousCaterpillars];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'BioprotectantsSemiochemical'.toLowerCase()) {
                    measureWithPests.measures = 'Bioprotectants Semiochemical'
                    const issuesInControl = [lepidopterousCaterpillars];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'HygieneAndPrevention'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene And Prevention'
                    const issuesInControl = [lepidopterousCaterpillars];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'NutrientManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Bioprotectants Macrobiological / Natural enemies'
                    const issuesInControl = [aphids];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'PhysicalExclusion'.toLowerCase()) {
                    measureWithPests.measures = 'Physical Exclusion'
                    const issuesInControl = [aphids];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'CanopyManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Canopy Management'
                    const issuesInControl = [aphids, lepidopterousCaterpillars];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'CompanionCropping'.toLowerCase()) {
                    measureWithPests.measures = 'Companion Cropping'
                    const issuesInControl = [aphids, fruitTreeSpiderMite, lepidopterousCaterpillars];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'VarietyChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Variety Choice'
                    const issuesInControl = [aphids, lepidopterousCaterpillars];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'SpatialSeparation'.toLowerCase()) {
                    measureWithPests.measures = 'Spatial Separation'
                    const issuesInControl = [lepidopterousCaterpillars];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [aphids, fruitTreeSpiderMite, lepidopterousCaterpillars]);
    return listOfMeasuresWithPests;
};

export default applePestSummaryMeasures;