const getListOfNonOtherCrops = (landParcels) => {
    let listOfCrops = [];
    if (landParcels == null) return;
    landParcels.forEach(
        function callback(parcel) {
            let crops = parcel.crops.map(function (crop) {
                if (!crop.isOther) {
                    listOfCrops.push(crop.cropType)
                }
                
            });
        }
    );

    return listOfCrops;
}

export default getListOfNonOtherCrops;