import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const oatsPestSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const bydvVectors = { name: 'bydvVectors', display: "BYDV Vectors" };
    const fritFly = { name: 'fritFly', display: "Frit Fly" };
    const leatherjackets = { name: 'leatherjackets', display: "Leatherjackets" };
    const slugs = { name: 'slugs', display: "Slugs" };
    const wireworms = { name: 'wireworms', display: "Wireworms" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support (including thresholds)'
                    const issuesInControl = [
                        bydvVectors,
                        fritFly,
                        slugs
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, Rotation & break crops'
                    const issuesInControl = [
                        fritFly,
                        leatherjackets,
                        slugs,
                        wireworms
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'primaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Primary cultivations / Crop residue burial'
                    const issuesInControl = [
                        bydvVectors,
                        fritFly,
                        leatherjackets,
                        wireworms
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'rollingSoil'.toLowerCase()) {
                    measureWithPests.measures = 'Rolling soil post-planting'
                    const issuesInControl = [
                        slugs
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'secondaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Secondary cultivations / drilling method'
                    const issuesInControl = [
                        bydvVectors,
                        fritFly,
                        leatherjackets,
                        wireworms
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedbedQuality'.toLowerCase()) {
                    measureWithPests.measures = 'Seedbed quality'
                    const issuesInControl = [
                        slugs
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'sowingDate'.toLowerCase()) {
                    measureWithPests.measures = 'Sowing date'
                    const issuesInControl = [
                        bydvVectors,
                        fritFly
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nonCroppedAreas'.toLowerCase()) {
                    measureWithPests.measures = 'In field non-cropped areas'
                    const issuesInControl = [
                        bydvVectors
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietalChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Varietal Choice'
                    const issuesInControl = [
                        bydvVectors
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [bydvVectors, fritFly, leatherjackets, slugs, wireworms]);
    return listOfMeasuresWithPests;
};

export default oatsPestSummaryMeasures;