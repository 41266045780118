import React from 'react'
import { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
      isAdmin: false
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated, isAdmin } = this.state;
    var link = document.createElement("a");
    link.href = this.props.path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
      return <div></div>;
    } else {
      const { element, onlyAdmin } = this.props;
      if (authenticated && onlyAdmin && (onlyAdmin !== isAdmin)) {
        return <Navigate replace to="/" />;
      }
      return authenticated ? element : <Navigate replace to={redirectUrl} />;
    }
  }

  async populateAuthenticationState() {
    const [authenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    let isAdmin = false;
    if (user !== null) isAdmin = (user.role === "administrator");
    this.setState({ ready: true, authenticated, isAdmin });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
