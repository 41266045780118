import { useState } from "react";
import { ArrowLeftRight } from "react-bootstrap-icons";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import getListOfNonOtherCrops from "../../helpers/getListOfNonOtherCrops";

const SurveyNavigation = ({ farmData, surveyData }) => {
    const crops = getListOfNonOtherCrops(surveyData.landParcels)
    
    const state = {
        farmData: farmData,
        surveyData: surveyData,
        crops: crops,
        isNewSurvey: false
    }
    const [showSpecificSectionDropdown, setShowSpecificSectionDropdown] = useState(false);
    const [dropDownKey, setDropDownKey] = useState("");
    const [dropDownOptions, setDropDownOptions] = useState([]);

    const onMainDropDownSelected = (value) => {
        if (value === null) return;

        setShowSpecificSectionDropdown(true)
        setDropDownKey(value);
        if (value === "weeds") AddWeedsDropDownOptions();
        if (value === ("pests") || value === ("diseases")) AddPestDiseasesDropDownOptions(value);
    };

    function AddWeedsDropDownOptions() {
        let grassWeeds = [];
        if (crops !== undefined && crops.includes('apple')) {
            grassWeeds.push(
                {
                    'whereTo': "/add-weeds-apple",
                    'description': "Apple Selection"
                },
                {
                    'whereTo': "/add-weeds-apple-measures",
                    'description': "Apple Measures Selection"
                })
        };
        if (crops !== undefined && crops.includes('improvedgrassland')) {
            grassWeeds.push(
                {
                    'whereTo': "/add-weeds-improvedgrassland",
                    'description': "Improved Grassland Selection"
                },
                {
                    'whereTo': "/add-weeds-improvedgrassland-measures",
                    'description': "Improved Grassland Measures Selection"
                })
        };

        setDropDownOptions([{
            'whereTo': "/add-weeds",
            'description': "Issues Selection"
        }, {
            'whereTo': "/add-weeds-measures",
            'description': "Control Measures"
        }, {
            'whereTo': "/add-weeds-resistance",
            'description': "Resistance"
        },
        ...grassWeeds]);
    }

    function AddPestDiseasesDropDownOptions(value) {
        let cropMeasures = [];
        if (crops !== undefined) {
            for (var i = 0; i < crops.length; i++) {
                let crop = crops[i];
                let cropWithSpaces = crop;
                if (typeof crop === 'object') {
                    cropWithSpaces = crop.cropType;
                    crop = crop.cropType;
                }
                cropWithSpaces = cropWithSpaces.replace(/winter/g, 'winter ');
                cropWithSpaces = cropWithSpaces.replace(/spring/g, 'spring ');
                cropWithSpaces = cropWithSpaces.replace(/sugar/g, 'sugar ');
                cropWithSpaces = cropWithSpaces.replace(/improved/g, 'improved ');
                cropWithSpaces = cropWithSpaces.replace(/oilseed/g, 'oilseed ');
                cropMeasures.push({
                    'whereTo': `/add-${value}-measures/${crop}`,
                    'description': `${cropWithSpaces} control measures`
                })
            };
        }
        if (!surveyData.landParcels.map(parcel => parcel.crops).flat().map(crop => crop.isOther).every(isOther => !isOther)) {
            cropMeasures.push({
                'whereTo': `/add-${value}-other`,
                'description': 'Other crops control measures'
            })
        }
        setDropDownOptions([{
            'whereTo': `/add-${value}`,
            'description': "Issues Selection"
        },
        ...cropMeasures
            ,
        {
            'whereTo': `/add-${value}-resistance`,
            'description': "Resistance"
        }]);
    }

    return (
        <>
            <button type="button" className="col-2 mx-3 btn btn-secondary" data-bs-toggle="offcanvas" href="#offcanvasNavigation" aria-controls="offcanvasNavigation">
                <ArrowLeftRight size={20} className="mx-2" />
                Go to
            </button>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasNavigation" aria-labelledby="offcanvasNavigationLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasNavigationLabel">IPM Plan navigation</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        Select the page that you would like to go:
                    </div>
                    <div className="dropdown mt-3">
                        <Dropdown onSelect={onMainDropDownSelected}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Go to {dropDownKey}
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                <Dropdown.Item as={Link} to="/add-ipm-plan" state={state}>Start IPM Plan
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/add-general-practices" state={state}>General Practices
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="weeds">Weeds
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="pests">Pests
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="diseases">Diseases
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/ipm-plan-summary" state={state}>Summary
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    {showSpecificSectionDropdown ?
                        <>
                            <div className="mt-4">
                                Select the specific section for <strong>{dropDownKey}</strong> that you would like to go:
                            </div>
                            <div className="dropdown mt-3">
                                <Dropdown id="specific-section-dropdown">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <span className="text-capitalize">{dropDownKey}</span> section
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {dropDownOptions.map((option, index) => {
                                            return <Dropdown.Item as={Link} to={option.whereTo} state={state} key={index} className="text-capitalize">
                                                {option.description}
                                            </Dropdown.Item>;
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </>
                        : null
                    }
                </div>
            </div>
        </>
    );
};

export default SurveyNavigation;
