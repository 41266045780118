import { Control } from "@jsonforms/react";
import { getBothIssues } from "../../../../helpers/getBothIssues";
import SummaryIssuesParcels from "../summaryIssuesParcels";
import SummaryIssuesTables from "../summaryIssuesTables";
import SummaryOtherIssuesTables from "../summaryOtherIssuesTables";

const OtherCropSummary = (props) => {
    const surveyData = props.surveyData;
    const cropName = props.cropName;
    const currentCrop = surveyData.otherCrops.filter(dat => dat.cropName === cropName)[0];
    const listOfPests = currentCrop.pests;
    const listOfDiseases = currentCrop.diseases;

    let pestData = { notUse: [], inUse: [], futureUse: [] };
    let diseaseData = { notUse: [], inUse: [], futureUse: [] };

    let listOfPestsControls = surveyData.otherCrops.find(crop => crop.cropName === cropName).pests.map(pest => pest.controlMeasures).flat()
    let listOfDiseasesControls = surveyData.otherCrops.find(crop => crop.cropName === cropName).diseases.map(disease => disease.controlMeasures).flat();
    function otherCropPestSummaryMeasures(listOfPests, cropName) {
        let controlMeasures = [...new Set(listOfPests.filter(pest => pest.controlMeasures !== undefined).map(pest => pest.controlMeasures).flat().map(dat => dat.name))];
        let notUseControlMeasures = listOfPests.filter(pest => pest.controlMeasures !== undefined).map(pest => pest.controlMeasures).flat().filter(controlMeasure => controlMeasure.status === 'notSuitable' || controlMeasure.status === 'notUsed').map(dat => dat.name);
        let futureUseControlMeasures = listOfPests.filter(pest => pest.controlMeasures !== undefined).map(pest => pest.controlMeasures).flat().filter(controlMeasure => controlMeasure.status === 'futureUse').map(dat => dat.name);
        let inUseControlMeasures = listOfPests.filter(pest => pest.controlMeasures !== undefined).map(pest => pest.controlMeasures).flat().filter(controlMeasure => controlMeasure.status === 'inUse').map(dat => dat.name);

        //let notSelected = [{
        //    measures: "No IPM control measures implemented",
        //    significantRisk: listOfPests.filter(pest => pest.status === "significantRisk").map(pest => pest.name).join(', '),
        //    moderateRisk: listOfPests.filter(pest => pest.status === "moderateRisk").map(pest => pest.name).join(', '),
        //    slightRisk: listOfPests.filter(pest => pest.status === "slightRisk").map(pest => pest.name).join(', ')
        //}]

        let notUse = [];

        //if (listOfPests.some(pest => pest.controlMeasures === undefined)) {
        //    listOfPests = listOfPests.filter(pest => pest.controlMeasures === undefined).map(pest => { return { ...pest, controlMeasures:[]} })

        //}
        

        
        let listOfNotUsedPests = listOfPests.filter(pest => pest.controlMeasures !== undefined).filter(pest =>
            !pest.controlMeasures?.flat().map(dat => dat.status).includes("inUse") &&
            !pest.controlMeasures?.flat().map(dat => dat.status).includes("futureUse")
        )
        
        if (listOfNotUsedPests.length !== 0) {
            if (listOfPests.map(pest => pest.controlMeasures).flat().length === 0) {

                notUse = [{
                    measures: "No IPM control measures implemented",
                    significantRisk: listOfNotUsedPests.filter(pest => pest.status === "significantRisk").map(pest => pest.name).join(', '),
                    moderateRisk: listOfNotUsedPests.filter(pest => pest.status === "moderateRisk").map(pest => pest.name).join(', '),
                    slightRisk: listOfNotUsedPests.filter(pest => pest.status === "slightRisk").map(pest => pest.name).join(', '),
                    isWarning: true
                }]

            } else {


                notUse = [{
                    measures: "No IPM control measures implemented",
                    significantRisk: listOfNotUsedPests.filter(pest => pest.controlMeasures.flat().map(dat => dat.name)).filter(pest => pest.status === "significantRisk").map(pest => pest.name).join(', '),
                    moderateRisk: listOfNotUsedPests.filter(pest => pest.controlMeasures.flat().map(dat => dat.name)).filter(pest => pest.status === "moderateRisk").map(pest => pest.name).join(', '),
                    slightRisk: listOfNotUsedPests.filter(pest => pest.controlMeasures.flat().map(dat => dat.name)).filter(pest => pest.status === "slightRisk").map(pest => pest.name).join(', '),
                    isWarning: true
                }]
            }
        }
        
        

        //notUse = notUse.concat(notSelected);

        let inUse = inUseControlMeasures.map(measure => {
            return ({
                measures: measure,
                significantRisk: listOfPests.filter(pest => pest.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(pest => pest.status === "significantRisk").map(pest => pest.name).join(', '),
                moderateRisk: listOfPests.filter(pest => pest.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(pest => pest.status === "moderateRisk").map(pest => pest.name).join(', '),
                slightRisk: listOfPests.filter(pest => pest.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(pest => pest.status === "slightRisk").map(pest => pest.name).join(', ')
            })
        }
        )

        let futureUse = futureUseControlMeasures.map(measure => {
            return ({
                measures: measure,
                significantRisk: listOfPests.filter(pest => pest.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(pest => pest.status === "significantRisk").map(pest => pest.name).join(', '),
                moderateRisk: listOfPests.filter(pest => pest.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(pest => pest.status === "moderateRisk").map(pest => pest.name).join(', '),
                slightRisk: listOfPests.filter(pest => pest.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(pest => pest.status === "slightRisk").map(pest => pest.name).join(', ')
            })
        }
        )

        pestData.futureUse = futureUse;
        pestData.inUse = inUse;
        pestData.notUse = notUse;

        return pestData;
    }

    function otherCropDiseaseSummaryMeasures(listOfPests, cropName) {
        let controlMeasures = [...new Set(listOfDiseases.filter(disease => disease.controlMeasures !== undefined).map(disease => disease.controlMeasures).flat().map(dat => dat.name))];
        let notUseControlMeasures = listOfDiseases.filter(disease => disease.controlMeasures !== undefined).map(disease => disease.controlMeasures).flat().filter(controlMeasure => controlMeasure.status === 'notSuitable' || controlMeasure.status === 'notUsed').map(dat => dat.name);
        let futureUseControlMeasures = listOfDiseases.filter(disease => disease.controlMeasures !== undefined).map(disease => disease.controlMeasures).flat().filter(controlMeasure => controlMeasure.status === 'futureUse').map(dat => dat.name);
        let inUseControlMeasures = listOfDiseases.filter(disease => disease.controlMeasures !== undefined).map(disease => disease.controlMeasures).flat().filter(controlMeasure => controlMeasure.status === 'inUse').map(dat => dat.name);


        let notUse = [];

        let listOfNotUsedDiseases = listOfPests.filter(disease => disease.controlMeasures !== undefined).filter(disease =>
            !disease.controlMeasures?.flat().map(dat => dat.status).includes("inUse") &&
            !disease.controlMeasures?.flat().map(dat => dat.status).includes("futureUse")
        )

        if (listOfNotUsedDiseases.length !== 0) {
            if (listOfDiseases.map(disease => disease.controlMeasures).flat().length === 0) {

                notUse = [{
                    measures: "No IPM control measures implemented",
                    significantRisk: listOfNotUsedDiseases.filter(disease => disease.status === "significantRisk").map(disease => disease.name).join(', '),
                    moderateRisk: listOfNotUsedDiseases.filter(disease => disease.status === "moderateRisk").map(disease => disease.name).join(', '),
                    slightRisk: listOfNotUsedDiseases.filter(disease => disease.status === "slightRisk").map(disease => disease.name).join(', '),
                    isWarning: true
                }]

            } else {


                notUse = [{
                    measures: "No IPM control measures implemented",
                    significantRisk: listOfNotUsedDiseases.filter(pest => pest.controlMeasures.flat().map(dat => dat.name)).filter(pest => pest.status === "significantRisk").map(pest => pest.name).join(', '),
                    moderateRisk: listOfNotUsedDiseases.filter(pest => pest.controlMeasures.flat().map(dat => dat.name)).filter(pest => pest.status === "moderateRisk").map(pest => pest.name).join(', '),
                    slightRisk: listOfNotUsedDiseases.filter(pest => pest.controlMeasures.flat().map(dat => dat.name)).filter(pest => pest.status === "slightRisk").map(pest => pest.name).join(', '),
                    isWarning: true
                }]
            }
        }

        let inUse = inUseControlMeasures.map(measure => {
            return ({
                measures: measure,
                significantRisk: listOfDiseases.filter(disease => disease.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(disease => disease.status === "significantRisk").map(disease => disease.name).join(', '),
                moderateRisk: listOfDiseases.filter(disease => disease.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(disease => disease.status === "moderateRisk").map(disease => disease.name).join(', '),
                slightRisk: listOfDiseases.filter(disease => disease.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(disease => disease.status === "slightRisk").map(disease => disease.name).join(', ')
            })
        }
        )

        let futureUse = futureUseControlMeasures.map(measure => {
            return ({
                measures: measure,
                significantRisk: listOfDiseases.filter(disease => disease.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(disease => disease.status === "significantRisk").map(disease => disease.name).join(', '),
                moderateRisk: listOfDiseases.filter(disease => disease.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(disease => disease.status === "moderateRisk").map(disease => disease.name).join(', '),
                slightRisk: listOfDiseases.filter(disease => disease.controlMeasures.flat().map(dat => dat.name).includes(measure)).filter(disease => disease.status === "slightRisk").map(disease => disease.name).join(', ')
            })
        }
        )

        diseaseData.futureUse = futureUse;
        diseaseData.inUse = inUse;
        diseaseData.notUse = notUse;

        return diseaseData;
    }


    if (listOfPests.length !== 0) {
        pestData = otherCropPestSummaryMeasures(listOfPests, cropName);
    }
    if (listOfDiseases.length !== 0) {
        diseaseData = otherCropDiseaseSummaryMeasures(listOfDiseases, cropName);
    }
    return (
        <>
            <SummaryIssuesParcels landParcels={props.surveyData.landParcels} cropName={cropName}></SummaryIssuesParcels>
            
            <SummaryIssuesTables
                listOfPestsControls={listOfPestsControls}
                listOfDiseasesControls={listOfDiseasesControls}
                listOfPests={listOfPests}
                listOfDiseases={listOfDiseases}
                //listOfWeeds={listOfWeeds}
                pestData={pestData}
                diseaseData={diseaseData}
                //weedsData={weedsData}
                crop={cropName}
                isOther={true}>
            </SummaryIssuesTables>
        </>
    );
};

export default OtherCropSummary;