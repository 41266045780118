const OilseedsPestList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('brassicaPodMidge'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/damage-caused-by-the-brassica-pod-midge-on-oilseed-rape" target="_blank" rel="noreferrer">Brassica Pod Midge</a>
                    </li>
                    : <></>
                }
                {values.includes('cabbageRootFly'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/cabbage-root-fly-biology-and-management-in-brassica-crops" target="_blank" rel="noreferrer">Cabbage Root Fly</a>
                    </li>
                    : <></>
                }
                {values.includes('cabbageSeedWeevil'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/control-of-cabbage-seed-weevil-on-oilseed-rape" target="_blank" rel="noreferrer">Cabbage Seed Weevil</a>
                    </li>
                    : <></>
                }
                {values.includes('cabbageStemFleaBeetle'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/cabbage-stem-flea-beetle-csfb-and-its-management-in-oilseed-rape" target="_blank" rel="noreferrer">Cabbage Stem Flea Beetle</a>
                    </li>
                    : <></>
                }
                {values.includes('mealyCabbageAphid'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/biology-and-management-of-the-mealy-cabbage-aphid" target="_blank" rel="noreferrer">Mealy Cabbage Aphid</a>
                    </li>
                    : <></>
                }
                {values.includes('pollenBeetle'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/pollenbeetle" target="_blank" rel="noreferrer">Pollen Beetle</a>
                    </li>
                    : <></>
                }
                {values.includes('slugs'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/slugs" target="_blank" rel="noreferrer">Slugs</a>
                    </li>
                    : <></>
                }
                {values.includes('turnipSawfly'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/turnip-sawfly-biology-and-impact-on-vegetable-brassicas-and-oilseed-rape" target="_blank" rel="noreferrer">Turnip Sawfly</a>
                    </li>
                    : <></>
                }
                {values.includes('tuyvVectors'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/turnip-yellow-virus-tuyv-management-in-oilseed-rape" target="_blank" rel="noreferrer">TuYV Vectors</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default OilseedsPestList;