import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const sugarBeetDiseaseSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const cercospera = { name: 'cercospera', display: "Cercospera" };
    const mildew = { name: 'mildew', display: "Mildew" };
    const rhizoctonia = { name: 'rhizoctonia', display: "Rhizoctonia" };
    const rhizomania = { name: 'rhizomania', display: "Rhizomania" };
    const rust = { name: 'rust', display: "Rust" };
    const violetRootRot = { name: 'violetRootRot', display: "Violet Root Rot" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support, incl. monitoring'
                    const issuesInControl = [
                        cercospera,
                        rhizoctonia,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, rotation & break crops'
                    const issuesInControl = [
                        cercospera,
                        rhizoctonia,
                        violetRootRot
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygiene'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene and prevention'
                    const issuesInControl = [
                        cercospera,
                        rhizoctonia,
                        rhizomania,
                        violetRootRot
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Select low-risk locations'
                    const issuesInControl = [
                        rhizoctonia,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietyChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Variety Choice'
                    const issuesInControl = [
                        cercospera,
                        mildew,
                        rust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [cercospera, mildew, rhizoctonia, rhizomania, rust, violetRootRot]);
    return listOfMeasuresWithPests;
};

export default sugarBeetDiseaseSummaryMeasures;