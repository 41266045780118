import { useTable } from 'react-table'
import BTable from 'react-bootstrap/Table';
import { ExclamationTriangle } from 'react-bootstrap-icons';

function SummaryNotesTables({ columns, data, tableId }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    const rowProps = (row) => {
        if (row.original.isWarning) {
            return {
                style: {
                    background: 'rgb(100%, 88%, 88%)',
                },
            };
        }
        return {};
    };


    return (
        <BTable striped bordered size="sm" {...getTableProps()} id={tableId}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                style={{ width: column.width, backgroundColor: column.backgroundColor, borderRight: column.borderRight }}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, rowIndex) => {
                    prepareRow(row);
                    const isWarning = row.original.isWarning || false;
                    return (
                        <tr {...row.getRowProps(rowProps(row))}>
                            {row.cells.map((cell, celIndex) => {
                                if (celIndex === 0) {
                                    return <td {...cell.getCellProps()} style={{
                                        borderRight: '3px solid black',
                                        fontWeight: 'bolder',
                                    }}>
                                        {isWarning &&
                                            <ExclamationTriangle color="red" size={25} className='me-2' />
                                        }
                                        {cell.render('Cell')}
                                    </td>
                                }
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </BTable>
    );
}

export default SummaryNotesTables;