import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import usePostRequest from '../../hooks/usePostRequest';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import usePutRequest from '../../hooks/usePutRequest';
import './surveys.css'
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import SurveyNavigation from './surveyNavigation';
import getListOfCrops from '../../helpers/getListOfCrops';
import Multiselect from 'multiselect-react-dropdown';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CropOptions from '../../helpers/cropOptions';
import { Button } from 'react-bootstrap';
import autogenerateFieldWithCrops from '../../helpers/autogenerateFieldWithCrops';
import useGetRequest from '../../hooks/useGetRequest';
import addLastYearDataToJson from '../../helpers/addLastYearDataToJson'
import removeOldCrops from '../../helpers/removeOldCrops';

const AddSurvey = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [continueToNextPage, setContinueToNextPage] = useState(false);
    const [crops, setCrops] = useState([]);
    const [listOfSelectedCrops, setListOfSelectedCrops] = useState([]);
    const [displayOtherCrop, setDisplayOtherCrop] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Please select one crop before continuing');
    const [mapCropsToRpa, setMapCropsToRpa] = useState(false);
    const [listOfParcelsAndCrops, setListOfParcelsAndCrops] = useState([]);
    const [otherCropValue, setOtherCropValue] = useState('');
    const [displayMapAsDefault, setDisplayMapAsDefault] = useState(false);
    const listOfCropOptions = CropOptions;

    useEffect(() => {
        if (location.state === null || location.state.farmData === null) {
            navigate("/add-farm");
        };

        function defaultUserCrops() {
            let cropsOnDb = [];
            if (!location.state.isNewSurvey && location.state.surveyData.landParcels !== undefined) {
                cropsOnDb = getListOfCrops(location.state.surveyData.landParcels, false);
            }
            let cropsOnDbAsMultiselection = [];
            cropsOnDb.forEach(function (crop) {
                let selectedCrop;
                if (typeof crop === 'object' && !Array.isArray(crop)) {
                    selectedCrop = listOfCropOptions.find((option) => option.cropType.toLowerCase() === crop.cropType.toLowerCase())
                }
                else {
                    selectedCrop = listOfCropOptions.find((option) => option.cropType.toLowerCase() === crop.toLowerCase())
                }
                if (selectedCrop === undefined) {
                    let cropObject = {
                        cropType: crop,
                        title: crop,
                        isOther: true
                    };
                    selectedCrop = cropObject;
                }
                cropsOnDbAsMultiselection.push(selectedCrop);
            });
            setListOfSelectedCrops(cropsOnDbAsMultiselection);
        };

        function defaultUserParcels() {
            let parcelsOnDb = [];
            if (!location.state.isNewSurvey && location.state.surveyData.landParcels !== undefined) {
                parcelsOnDb = location.state.surveyData.landParcels;
                if (parcelsOnDb.some(p => p.hasOwnProperty('geometry') && p.geometry !== null && typeof p.geometry !== 'undefined')) {
                    setListOfParcelsAndCrops(parcelsOnDb);
                    setMapCropsToRpa(true);
                    setDisplayMapAsDefault(true);
                };
            }
        };

        defaultUserCrops();
        defaultUserParcels();
    }, [listOfCropOptions, location, navigate]);

    useEffect(() => {
        if (!location.state.isNewSurvey && location.state.surveyData.landParcels !== undefined) {
            setCrops(getListOfCrops(location.state.surveyData.landParcels, false));
        };
        function checkStateOfForm() {
            setErrorMessage('')
            if (listOfSelectedCrops.length === 0) {
                setContinueToNextPage(false);
                setErrorMessage('Please select one crop before continuing');
                return;
            };
            if (displayOtherCrop && !otherCropValue) {
                setContinueToNextPage(false);
                setErrorMessage('Please add a `other crop` value before continuing');
                return;
            };
            if (mapCropsToRpa && listOfParcelsAndCrops.length === 0) {
                setContinueToNextPage(false);
                setErrorMessage("Please add at least one parcel from the RPA data. If you don't want to map your crops, please remove this option");
                return;
            }
            setContinueToNextPage(true);
        };

        function checkCropAndParcels() {
            let listOfCrops = [];
            listOfSelectedCrops.forEach(function (crop) {
                let newCrop = {
                    area: 0,
                    cropType: crop.cropType,
                    isOther: false
                };
                if (!listOfCropOptions.includes(crop)) {
                    newCrop.isOther = true;
                };
                listOfCrops.push(newCrop)
            });
            setCrops(listOfCrops);
            let listOfOtherCrops = listOfCrops.filter(crop => crop.isOther);
            var autoGeneratedLandParcel = autogenerateFieldWithCrops(listOfCrops);
            let otherCropsData;
            if (location.state.surveyData.otherCrops === undefined) {
                otherCropsData = listOfOtherCrops.map(crop => {
                    return {
                        CropName: crop.cropType,
                        Pests: [],
                        Diseases: []
                    }
                })
            }
            else {
                otherCropsData = location.state.surveyData.otherCrops
            }
            setData(prevData =>
            ({
                ...prevData,
                otherCrops: otherCropsData,
                landParcels: [autoGeneratedLandParcel]
            }));
            return;
        };

        checkStateOfForm();
        checkCropAndParcels();
    }, [listOfSelectedCrops, location, mapCropsToRpa, otherCropValue, listOfParcelsAndCrops, displayOtherCrop]);

    const initialData = (location.state.isNewSurvey) ?
        {
            farmId: `${location.state.farmData.id}`,
            year: new Date().getFullYear(),
            landParcels: []
        }
        : location.state.surveyData;
    const [data, setData] = useState(initialData);
    const { post } = usePostRequest("/api/survey", data);
    const { put } = usePutRequest(`/api/survey/${location.state.surveyData.id}`, data);
    const { get } = useGetRequest(`/api/survey?farmId=${data.farmId}&surveyYear=${data.year - 1}`);

    const onAddSurveySubmitClick = async () => {
        let response = await post();
        if (!response.isValid) {
            displayToastError(response.message);
            return;
        }
        // get last year survey and merge
        let lastYearSurveyResponse = await get();
        await addLastYearDataToJson(response, lastYearSurveyResponse, location.state.isNewSurvey);

        navigateToGeneralPractices(navigate, location.state.farmData, response);
    };

    const onUpdateSurveySubmitClick = async () => {
        removeOldCropsInformation();
        let response = await put();
        if (!response.isValid) {
            displayToastError(response);
            return;
        }
        navigateToGeneralPractices(navigate, location.state.farmData, data);
    };

    const removeOldCropsInformation = () => {
        let cropsSelected = getListOfCrops(location.state.surveyData.landParcels, false);
        removeOldCrops(location.state.surveyData.pests, cropsSelected);
        removeOldCrops(location.state.surveyData.disease, cropsSelected);
    }

    const onYearChange = event => {
        setData(prevData => ({
            ...prevData,
            year: event.target.value
        }));
    };

    const onCropsSelect = (selectedList, selectedItem) => {
        if (selectedItem.cropType === "other") setDisplayOtherCrop(true);
        listOfSelectedCrops.push(selectedItem);
        var orderedListOfSelectedCrops = listOfSelectedCrops.sort((a, b) => (a.cropType > b.cropType) ? 1 : ((b.cropType > a.cropType) ? -1 : 0));
        setListOfSelectedCrops([...orderedListOfSelectedCrops]);
    };

    const onCropsRemove = (selectedList, removedItem) => {
        if (removedItem.cropType === "other") setDisplayOtherCrop(false);
        location.state.surveyData.otherCrops = location.state.surveyData?.otherCrops?.filter(crop => crop.cropName !== removedItem.cropType);
        setOtherCropValue('');
        setListOfSelectedCrops([...listOfSelectedCrops.filter(f => f.cropType !== removedItem.cropType)]);
    }

    const onOtherCropValueChange = event => {
        setOtherCropValue(event.target.value);
    };

    const onAddOtherCrop = () => {
        if (listOfSelectedCrops.map(crop => crop.cropType.toLowerCase()).includes(otherCropValue.toLowerCase())) {
            let message = otherCropValue + " already addded into the IPM Plan."
            displayToastError(message)
            setOtherCropValue("");
        } else {
            setListOfSelectedCrops([...listOfSelectedCrops, { cropType: otherCropValue, title: otherCropValue, otherCrop: true }])
            let message = otherCropValue + " has been added to the IPM Plan."
            diplayToastOtherCropAdded(message)
            if (location.state.surveyData.otherCrops !== undefined)
                location.state.surveyData.otherCrops.push({ cropName: otherCropValue, pests: [], diseases: [] })
            setOtherCropValue("");
        }
    }

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} -
                    {location.state.isNewSurvey ? " Start" : ""} IPM Plan - <span>{data.year}</span>
                </h1>
            </div>
            <div className="row my-3">
                <Form.Label><h2 className='my-2'>1. Select the harvest year of the IPM Plan</h2></Form.Label>
                <Form.Control type="number" step="1" min={new Date().getFullYear()} placeholder="Enter the year of the IPM Plan" value={data.year} onChange={onYearChange} />
                <h2 className='my-2'>2. Select your crops for the IPM Plan</h2>
                <Multiselect
                    options={listOfCropOptions}
                    selectedValues={listOfSelectedCrops}
                    onRemove={onCropsRemove}
                    onSelect={onCropsSelect}
                    displayValue="title"
                    className='my-2'
                    placeholder="Select crop(s)"
                    hidePlaceholder="true"
                />
                <div className="row my-3">
                    <div className="col-9 mx-1" >
                        <FloatingLabel controlId="floatingInput" label="Enter 'other' crop">
                            <Form.Control maxLength="50" type="text" placeholder="Enter 'other' crop" value={otherCropValue} onChange={onOtherCropValueChange} />
                        </FloatingLabel>
                    </div>
                    <button type="button" className="col-2 mx-1 btn btn-success" onClick={onAddOtherCrop} disabled={otherCropValue.length === 0}>Add</button>
                </div>
            </div>
            <div className="row justify-content-between my-5">
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                <Link className="col-2 mx-3" to={{ pathname: `/edit-farm/${data.farmId}` }}>
                    <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                </Link>
                {location.state.isNewSurvey ?
                    <>
                        <span title={!continueToNextPage ? errorMessage : null} className="col-2 mx-3">
                            <Button
                                variant="success"
                                disabled={!continueToNextPage}
                                onClick={() => onAddSurveySubmitClick()}>
                                Save and continue <ArrowRight size={20} className="mx-2" />
                            </Button>
                        </span>
                    </> :
                    <>
                        <SurveyNavigation farmData={location.state.farmData} surveyData={location.state.surveyData} crops={crops} />
                        <button type="button" className="col-2 mx-3 btn btn-success" disabled={!continueToNextPage} onClick={() => onUpdateSurveySubmitClick()}>Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight></button>
                    </>
                }
            </div>
        </>
    );
};

export default AddSurvey;

function navigateToGeneralPractices(navigate, farmData, data) {
    navigate("/add-general-practices",
        {
            state: {
                farmData: farmData,
                surveyData: data
            }
        });
}

function displayToastError(message) {
    toast.error(message, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
    });
}

function diplayToastOtherCropAdded(message) {
    toast.info(message, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        autoClose: 2000,
    })
}
