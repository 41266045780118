const removeOldIssues = (issues, listOfCrops) => {
    if (issues === null || listOfCrops === null) return;
    for (var crop in issues) {
        var cropProperty = issues[crop];
        if (cropProperty !== null
            && cropProperty !== undefined) {
            if (!listOfCrops.includes(crop.toLowerCase())) {
                delete issues[crop];
            }
        };
    };
    return null;
}
export default removeOldIssues;