import SummaryIssuesParcels from "../summaryIssuesParcels";
import SummaryIssuesTables from "../summaryIssuesTables";
import { getBothIssues } from "../../../../helpers/getBothIssues";
import getListOfIssues from "../../../../helpers/getListOfIssues";
import improvedGrasslandDiseaseSummaryMeasures from "./improvedGrasslandDiseaseSummaryMeasures";
import improvedGrasslandPestSummaryMeasures from "./improvedGrasslandPestSummaryMeasures";
import improvedGrasslandWeedsSummaryMeasures from "./improvedGrasslandWeedsSummaryMeasures";

const ImprovedGrasslandSummary = (props) => {
    const surveyData = props.surveyData;
    const cropName = props.cropName;
    const { listOfPests, listOfDiseases } = getBothIssues(surveyData, cropName);
    const listOfWeeds = (surveyData.weeds !== null && surveyData.weeds[cropName] !== null) ? getListOfIssues(surveyData.weeds[cropName], true) : [];
    let pestData = { notUse: [], inUse: [], futureUse: [] };
    let diseaseData = { notUse: [], inUse: [], futureUse: [] };
    let weedsData = { notUse: [], inUse: [], futureUse: [] };
    if (listOfPests.length !== 0) {
        pestData = improvedGrasslandPestSummaryMeasures(surveyData.pests[cropName].controlMeasures, listOfPests);
    }
    if (listOfDiseases.length !== 0) {
        diseaseData = improvedGrasslandDiseaseSummaryMeasures(surveyData.diseases[cropName].controlMeasures, listOfDiseases);
    }
    if (listOfWeeds.length !== 0) {
        weedsData = improvedGrasslandWeedsSummaryMeasures(surveyData.weeds[cropName].controlMeasures, listOfWeeds);
    }
    return (
        <>
            <SummaryIssuesParcels landParcels={props.surveyData.landParcels} cropName={cropName}></SummaryIssuesParcels>
            <SummaryIssuesTables
                listOfPestsControls={surveyData?.pests ? surveyData?.pests[cropName]?.controlMeasures : null}
                listOfDiseasesControls={surveyData?.diseases ? surveyData?.diseases[cropName]?.controlMeasures : null}
                listOfWeedsControls={surveyData?.weeds ? surveyData?.weeds[cropName]?.controlMeasures : null}
                listOfPests={listOfPests}
                listOfDiseases={listOfDiseases}
                listOfWeeds={listOfWeeds}
                pestData={pestData}
                diseaseData={diseaseData}
                weedsData={weedsData}
                crop={cropName}>
            </SummaryIssuesTables>
        </>
    );
};

export default ImprovedGrasslandSummary;