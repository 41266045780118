import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const maizeDiseaseSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const eyespot = { name: 'eyespot', display: "Eyespot" };
    const fusarium = { name: 'fusarium', display: "Fusarium" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'fieldHistoryRotation'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, rotation & break crops'
                    const issuesInControl = [eyespot, fusarium];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'primaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Primary cultivations (crop residue burial)'
                    const issuesInControl = [eyespot, fusarium];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietyChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Variety choice'
                    const issuesInControl = [eyespot];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [eyespot, fusarium]);
    return listOfMeasuresWithPests;
};

export default maizeDiseaseSummaryMeasures;