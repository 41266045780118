import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const improvedGrasslandWeedsSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const ragwort = { name: 'ragwort', display: "Ragwort" };
    const creepingButtercup = { name: 'creepingButtercup', display: "Creeping Buttercup" };
    const commonNettle = { name: 'commonNettle', display: "Common Nettle" };
    const docks = { name: 'docks', display: "Docks" };
    const creepingThistle = { name: 'creepingThistle', display: "Creeping Thistle" };
    const rushes = { name: 'rushes', display: "Rushes" };
    const bracken = { name: 'bracken', display: "Bracken" };
    const gorse = { name: 'gorse', display: "Gorse" };
    const broadleaved = { name: 'broadleaved', display: "Annual broadleaved weeds" };
    const meadow = { name: 'meadow', display: "Annual meadow grass" };
    const spearThistle = { name: 'spearThistle', display: "Spear Thistle" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'rotation'.toLowerCase()) {
                    measureWithPests.measures = 'Rotation'
                    const issuesInControl = [
                        ragwort,
                        creepingButtercup,
                        commonNettle,
                        docks,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'improveSoilCondition'.toLowerCase()) {
                    measureWithPests.measures = 'Improving Soil Condition'
                    const issuesInControl = [
                        creepingButtercup,
                        commonNettle,
                        rushes,
                        bracken,
                        gorse
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'staleSeedbed'.toLowerCase()) {
                    measureWithPests.measures = 'Stale Seedbed'
                    const issuesInControl = [
                        ragwort,
                        creepingButtercup,
                        commonNettle,
                        docks,
                        rushes,
                        bracken,
                        gorse,
                        broadleaved,
                        meadow,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygiene'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene'
                    const issuesInControl = [
                        ragwort,
                        creepingButtercup,
                        commonNettle,
                        docks,
                        creepingThistle,
                        rushes,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'mowing'.toLowerCase()) {
                    measureWithPests.measures = 'Mowing/topping/crimping/rolling'
                    const issuesInControl = [
                        docks,
                        rushes,
                        bracken,
                        gorse
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'handPulling'.toLowerCase()) {
                    measureWithPests.measures = 'Hand pulling/cutting/digging'
                    const issuesInControl = [
                        ragwort,
                        docks,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'harrowing'.toLowerCase()) {
                    measureWithPests.measures = 'Harrowing/mechanical weeding'
                    const issuesInControl = [
                        creepingButtercup,
                        docks,
                        broadleaved,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'ploughing'.toLowerCase()) {
                    measureWithPests.measures = 'Ploughing'
                    const issuesInControl = [
                        ragwort,
                        creepingButtercup,
                        commonNettle,
                        docks,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedRate'.toLowerCase()) {
                    measureWithPests.measures = 'Increased seed rate'
                    const issuesInControl = [
                        ragwort,
                        creepingButtercup,
                        docks,
                        broadleaved,
                        meadow,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'reSeeding'.toLowerCase()) {
                    measureWithPests.measures = 'Re-seeding'
                    const issuesInControl = [
                        ragwort,
                        creepingButtercup,
                        commonNettle,
                        docks,
                        rushes,
                        bracken,
                        gorse,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'grazing'.toLowerCase()) {
                    measureWithPests.measures = 'Grazing'
                    const issuesInControl = [
                        ragwort,
                        creepingButtercup,
                        docks,
                        broadleaved,
                        meadow,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'bioprotectans'.toLowerCase()) {
                    measureWithPests.measures = 'Botanical bioprotectants'
                    const issuesInControl = [
                        ragwort
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'spotTreatment'.toLowerCase()) {
                    measureWithPests.measures = 'Spot Treatment'
                    const issuesInControl = [
                        ragwort,
                        creepingButtercup,
                        commonNettle,
                        docks,
                        creepingThistle,
                        spearThistle,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'weedWiper'.toLowerCase()) {
                    measureWithPests.measures = 'Weed Wiper'
                    const issuesInControl = [
                        commonNettle,
                        creepingThistle
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [
        ragwort,
        creepingButtercup,
        commonNettle,
        docks,
        creepingThistle,
        rushes,
        bracken,
        gorse,
        broadleaved,
        meadow,
        spearThistle]);
    return listOfMeasuresWithPests;
};

export default improvedGrasslandWeedsSummaryMeasures;