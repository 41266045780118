const ApplePestList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('aphids'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://applegrowing.niab.com/rosy-apple-aphid/" target="_blank" rel="noreferrer">Aphids</a>
                    </li>
                    : <></>
                }
                {values.includes('fruittreespidermite'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://applegrowing.niab.com/fruit-tree-red-spider-mite/" target="_blank" rel="noreferrer">Fruit tree spider mite</a>
                    </li>
                    : <></>
                }
                {values.includes('lepidopterouscaterpillars'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://applegrowing.niab.com/fruit-tree-tortrix-moth/" target="_blank" rel="noreferrer">Lepidopterous caterpillars</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default ApplePestList;