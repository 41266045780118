const WheatDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value))
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('brownRust'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/brownrust" target="_blank" rel="noreferrer">Brown Rust</a>
                    </li>
                    : <></>
                }
                {values.includes('earBlight'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/fusarium-and-microdochium-in-cereals" target="_blank" rel="noreferrer">Ear blight</a>
                    </li>
                    : <></>
                }
                {values.includes('eyespot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/eyespot-in-cereals-and-risk-assessment-in-wheat" target="_blank" rel="noreferrer">Eyespot</a>
                    </li>
                    : <></>
                }{values.includes('leafAndGlumeBlotch'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/septoria-nodorum-disease-symptoms-in-cereals" target="_blank" rel="noreferrer">Leaf and Glume Blotch</a>
                    </li>
                    : <></>
                }{values.includes('mildew'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/management-of-powdery-mildew-in-cereals" target="_blank" rel="noreferrer">Mildew</a>
                    </li>
                    : <></>
                }{values.includes('seedBorneDiseases'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/seedborne-diseases-of-cereals-tests-and-thresholds" target="_blank" rel="noreferrer">Seed Borne Diseases</a>
                    </li>
                    : <></>
                }{values.includes('septoria'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/septoria-tritici-in-winter-wheat" target="_blank" rel="noreferrer">Septoria</a>
                    </li>
                    : <></>
                }{values.includes('takeAll'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/take-all-disease-in-cereals-prevent-detect-control" target="_blank" rel="noreferrer">Take-All</a>
                    </li>
                    : <></>
                }{values.includes('yellowRust'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/yellowrust" target="_blank" rel="noreferrer">Yellow Rust</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default WheatDiseaseList;