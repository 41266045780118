import React from 'react';
import useFarms from '../../hooks/useFarms';
import LoadingIndicator from "../common/loadingIndicator";
import loadingStatus from "../../helpers/loadingStatus";
import { Link } from 'react-router-dom';
import FarmRow from './farmRow';


const FarmList = () => {
    const { farms, loadingState, removeFarm } = useFarms([]);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    return (
        <>
            {farms.length === 0 ? (
                <div className="row">
                    <h1>You don't have any farms yet. Please add one before continuing.</h1>
                </div>
            ) : (
                <>
                    <h1>Your farms</h1>
                    <div className="row">
                        <table className='table table-striped' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {farms.map(farm =>
                                    <FarmRow key={farm.id} farm={farm} removeFarm={removeFarm} />
                                )}
                            </tbody>
                        </table>
                    </div>
                </>)
            }
            <div className="row">
                <div className="col sm-3">
                    <Link to="/add-farm">
                        <button type="button" className="mt-3 btn btn-primary btn-lg float-left">Add a new farm</button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default FarmList;
