import { useEffect, useState } from 'react';
import useGetRequest from '../../hooks/useGetRequest';
import LoadingIndicator from "../common/loadingIndicator";
import loadingStatus from "../../helpers/loadingStatus";
import BenchMarkingSection from './BenchMarkingSection';

const BenchMarking = (props) => {

    const [benchmarkingUrl, setBenchmarkingUrl] = useState(props.benchmarkingUrl);
    const { get } = useGetRequest(`/api/benchmarking/${benchmarkingUrl}?currentYear=${props.currentYear}`);
    const [loading, setLoading] = useState(false);
    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [benchmarkingData, setBenchmarkingData] = useState([]);
    const [dataIsDisplayed, setDataIsDisplayed] = useState(false);

    useEffect(() => {
        const season = props.isSpring ? 'spring' : 'winter';
        setBenchmarkingUrl(benchmarkingUrl.replace('{season}', season));
    }, [benchmarkingUrl, props.isSpring]);

    const handleClick = async () => {
        setLoading(true);
        const data = await get();
        if (data !== undefined) {
            setBenchmarkingData(data);
            setDataRetrieved(true);
            setLoading(false);
            setDataIsDisplayed(!dataIsDisplayed);
        }
    }

    return (
        <>
            {!dataRetrieved &&
                <>
                <div>{props.sectionString}</div>
                <button type="button" onClick={() => handleClick()} className='btn btn-primary'>
                    Get benchmarking
                </button >
                </>
            }
            {loading &&
                <div className='mt-3'>
                    <LoadingIndicator loadingState={loadingStatus.isLoading} />
                </div>
            }
            {dataRetrieved &&
                <>
                <div>{props.sectionString}</div>
                <div style={{ display: 'flex' }}>
                        <button type="button" onClick={() => setDataIsDisplayed(!dataIsDisplayed)} data-bs-toggle="collapse" data-bs-target={`#${benchmarkingUrl.replace(/\//g, "_")}`} aria-expanded="true" aria-controls="displayBenchmarkingData" className='btn btn-primary'>
                            {
                                dataIsDisplayed ? "Hide benchmarking" : "Display benchmarking"
                            }
                    </button >
                    </div>

                    <BenchMarkingSection dynamicId={benchmarkingUrl.replace(/\//g, "_")} currentYearData={benchmarkingData.currentYearData} previousYearData={benchmarkingData.previousYearData} />
                </>
            }
            <hr></hr>
        </>
    );
}

export default BenchMarking;