import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import loadingStatus from '../../helpers/loadingStatus';
import useGetRequest from '../../hooks/useGetRequest';
import LoadingIndicator from '../common/loadingIndicator';
import SurveyList from '../surveyList/surveyList';
import FarmInformation from './farmInformation';

const Farm = () => {

    let { id } = useParams();
    const [farm, setFarm] = useState([]); 
    const [surveys, setSurveys] = useState([]); 
    const { get, loadingState } = useGetRequest(`/api/farm/${id}?includesurveys=true`);

    useEffect(() => {
        const fetchFarm = async () => {
            const data = await get();
            setFarm(data.farm);
            setSurveys(data.surveys.sort((a, b) => a.year - b.year));
        };
        fetchFarm();
    }, [get]);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    return (
        <>
            <h1 className="text-uppercase fw-bold">{farm.name}</h1>
            <div className="row">
                <div className="col-8">
                    <SurveyList surveyList={surveys} farm={farm}></SurveyList>
                    <div className="row justify-content-between my-5">
                        <Link className="col-2 mx-3" to="/farm-list">
                            <button type="button" className="btn btn-danger">Go back to your farms</button>
                        </Link>
                        <Link className="col-2 mx-3"
                            to="/add-ipm-plan"
                            state={{
                            farmData: farm,
                            surveyData: {},
                            isNewSurvey: true }}>
                            <button type="button" className="btn btn-success">Start a new IPM Plan</button>
                        </Link>
                    </div>
                </div>
                <div className="col-4">
                    <FarmInformation farm={farm}></FarmInformation>
                </div>
            </div>
        </>
    );
};

export default Farm;
