const PotatoesPestList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('cutworms'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/damage-caused-by-cutworms-noctuid-moths-on-field-crops" target="_blank" rel="noreferrer">Cutworms</a>
                    </li>
                    : <></>
                }
                {values.includes('flnAndSpraing'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/free-living-nematodes-and-their-impact-on-the-yield-and-quality-of-field-crops" target="_blank" rel="noreferrer">FLN and Spraing</a>
                    </li>
                    : <></>
                }
                {values.includes('potatoCystNematode'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/potato-cyst-nematodes-pcn-and-their-management-in-potatoes" target="_blank" rel="noreferrer">Potato Cyst Nematode</a>
                    </li>
                    : <></>
                }
                {values.includes('slugs'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/slugs" target="_blank" rel="noreferrer">Slugs</a>
                    </li>
                    : <></>
                }
                {values.includes('aphidBorne'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/aphid-and-virus-potatoes" target="_blank" rel="noreferrer">Viruses (Aphid Borne)</a>
                    </li>
                    : <></>
                }
                {values.includes('wireworm'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/identification-and-management-of-wireworms-in-field-crops" target="_blank" rel="noreferrer">Wireworm</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default PotatoesPestList;