import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import { QuestionCircle } from 'react-bootstrap-icons';
import PotatoesPestList from './potatoesPestList';
import MeasuresButtons from '../measuresButtons';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddPotatoesPestMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`6380ae971ae8c36a287b41de`);


    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.potatoes !== null)
        && (location.state.surveyData.pests.potatoes !== undefined)
        && (location.state.surveyData.pests.potatoes.controlMeasures !== null)
        && (location.state.surveyData.pests.potatoes.controlMeasures !== undefined)) ?
        location.state.surveyData.pests.potatoes.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.potatoes);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.potatoes.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>potatoes pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>

            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('potatoCystNematode'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Biofumigation</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#biofumigationDesc" aria-expanded="false" aria-controls="biofumigationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="biofumigationDesc">
                                <p>
                                    Biofumigation is the suppression of soilborne pests by toxic gases emitted from organic material. It has been used for PCN control although results are varied. This involves growing brassica green manure crops which are then macerated and incorporated as they reach early to mid-flowering. Most common species planted are Indian mustard (Brassica juncea), rocket (Eruca sativa) and oil radish (Raphanus sativus). They have a growing period of 8 – 14 weeks within a mid-July to early November window.
                                </p>
                            </div>

                            <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                'potatoCystNematode'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.biofumigation}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('cutworms'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Controlled Irrigation</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#controlledIrrigationDesc" aria-expanded="false" aria-controls="controlledIrrigationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="controlledIrrigationDesc">
                                <p>
                                    Irrigation can be used as a substitute for insecticides to wash cutworms from the foliage after which they cannot re-climb the plant.  Cutworms must be controlled before they leave the foliage and burrow below ground.
                                </p>
                            </div>
                            <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                'cutworms'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.controlledIrrigation}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('cutworms'.toLowerCase()) ||
                        listOfIssues.includes('potatoCystNematode'.toLowerCase()) ||
                        listOfIssues.includes('aphidBorne'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Control Volunteers & Weeds</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#controlVolunteersAndWeedsDesc" aria-expanded="false" aria-controls="controlVolunteersAndWeedsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="controlVolunteersAndWeedsDesc">
                                    <p>
                                        Volunteer potatoes can act as hosts or reservoirs for PCN, cutworms and aphids/aphid-borne viruses. Control of volunteer potatoes are unlikely to have a significant effect where PCN infestations are high but may help to maintain infestations at a low level from one crop to the next.
                                    </p>
                                </div>
                                <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                    'cutworms'.toLowerCase(),
                                    'potatoCystNematode'.toLowerCase(),
                                    'aphidBorne'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.controlVolunteersAndWeeds}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('cutworms'.toLowerCase()) ||
                        listOfIssues.includes('flnAndSpraing'.toLowerCase()) ||
                        listOfIssues.includes('potatoCystNematode'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase()) ||
                        listOfIssues.includes('wireworm'.toLowerCase()) ||
                        listOfIssues.includes('aphidBorne'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Decision Support(Including Thresholds)</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="decisionSupportDesc">
                                    <p>
                                        Monitoring is an essential component of pest management.  This can involve visual inspection of the crop or a trap-based system (eg water traps, sticky traps or pheromone traps).  Pest numbers are related to thresholds and a treatment decision is made using this information.  To monitor for cutworm risk, pheromone traps are available for the turnip moth. Use of traps can help to determine if irrigation/insecticides are justified as control measures.
                                    </p>
                                    <p>Pest forecasts are available from the <a href="https://platform.ipmdecisions.net/" target="_blank" rel="noreferrer">IPM Decisions platform</a>. Forecasts are only active during the growing season.</p>
                                </div>

                                <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                    'cutworms'.toLowerCase(),
                                    'flnAndSpraing'.toLowerCase(),
                                    'potatoCystNematode'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                    'wireworm'.toLowerCase(),
                                    'aphidBorne'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.decisionSupport}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('cutworms'.toLowerCase()) ||
                        listOfIssues.includes('potatoCystNematode'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase()) ||
                        listOfIssues.includes('wireworm'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Early harvest (after skin set) </h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#earlyHarvestDesc" aria-expanded="false" aria-controls="earlyHarvestDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="earlyHarvestDesc">
                                    <p>
                                        This may be useful in the presence of wireworm or PCN infestation. Early harvest will limit further damage and also reduce the potential for PCN reproduction. Potato crops must be harvested after skin set.                                     </p>
                                </div>

                                <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                    'cutworms'.toLowerCase(),
                                    'potatoCystNematode'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                    'wireworm'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.earlyHarvest}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('flnAndSpraing'.toLowerCase()) ||
                        listOfIssues.includes('potatoCystNematode'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase()) ||
                        listOfIssues.includes('wireworm'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Field History, Rotations & Break Crops</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="fieldHistoryDesc">
                                    <p>
                                        Potatoes ideally should not be grown following long term grass leys to minimise the risk of wireworm infestation. Rotation is vital to limit the reproduction of PCN.  Where the pest is present, potatoes should not be grown more than one year in eight.  The growing of seed potatoes is only permitted on land that is free from PCN.
                                    </p>
                                </div>

                                <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                    'flnAndSpraing'.toLowerCase(),
                                    'potatoCystNematode'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                    'wireworm'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.fieldHistory}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('potatoCystNematode'.toLowerCase()) ||
                        listOfIssues.includes('aphidBorne'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Hygiene</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="hygieneDesc">
                                    <p>
                                        Outgrade piles are an important source of aphid-borne viruses in potato. Controlling haulm growth on dumps or covering the dumps is an essential part of the national guidance for virus management. Small quantities of tubers discarded on fields can have the same impact, particularly when in close proximity to the current crop. Early sources of virus should be removed before they can contribute to spread. PCN can infect clean land via infected soil, so machinery used on infested fields should be power-washed before use in uninfected fields. Clean fields should be visited first in the sequence of crops so that cleaning down equipment can be done at the end of the day.
                                    </p>
                                </div>

                                <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                    'potatoCystNematode'.toLowerCase(),
                                    'aphidBorne'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.hygiene}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('slugs'.toLowerCase())
                        || listOfIssues.includes('wireworm'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Primary Cultivations / Crop Residue Burial</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#primaryCultivationsDesc" aria-expanded="false" aria-controls="primaryCultivationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="primaryCultivationsDesc">
                                    <p>
                                        The mechanical action of cultivations can reduce soil populations of wireworms.  The pests can be killed or brought to the surface where they are eaten by birds or die due to dehydration.  Undisturbed trash or crop residue can provide shelter and food for pests such as slugs, and should be buried below the soil surface through cultivations. On the negative side additional cultivations increase your carbon footprint and can reduce soil biodiversity.
                                    </p>
                                </div>

                                <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                    'slugs'.toLowerCase(),
                                    'wireworm'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.primaryCultivations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('slugs'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Secondary Cultivations / Seedbed quality </h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#secondaryCultivationsDesc" aria-expanded="false" aria-controls="secondaryCultivationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="secondaryCultivationsDesc">
                                <p>
                                    Cloddy seed beds can allow slugs access to developing potato tubers. The mechanical action of cultivations can produce finer seed beds and reduce slug risk.
                                </p>
                            </div>

                            <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                'slugs'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.secondaryCultivations}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('potatoCystNematode'.toLowerCase()) ||
                        listOfIssues.includes('aphidBorne'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Seed Testing</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedTestingDesc" aria-expanded="false" aria-controls="seedTestingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="seedTestingDesc">
                                    <p>
                                        Potato seed production in the UK is undertaken under the strict regulation of the Seed Potato Classification Scheme, which ensures that seed potatoes are guaranteed to be free from a range of pests and diseases, including potato cyst nematode. All potatoes grown for seed production must be certified before marketing. Only certified potato seed can be used for the planting of new crops.
                                    </p>
                                </div>

                                <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                    'potatoCystNematode'.toLowerCase(),
                                    'aphidBorne'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.seedTesting}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('flnAndSpraing'.toLowerCase()) ||
                        listOfIssues.includes('potatoCystNematode'.toLowerCase()) ||
                        listOfIssues.includes('wireworm'.toLowerCase()) ||
                        listOfIssues.includes('aphidBorne'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Select Low-Risk Locations</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="lowRiskLocationsDesc">
                                    <p>
                                        This is essential for planning control of free living nematodes (FLN), potato cyst nematodes (PCN) and wireworms.  Soil analysis will detect stubby root nematodes which are the vectors of tobacco rattle virus (TRV) that causes spraing.  It will also establish the level of PCN infestation and the species present.  In most cases this is white PCN (Globodera pallida) rather than yellow PCN (G. rostochiensis). If land is intended for seed potato production, it is mandatory to have the field sampled and tested for PCN. Soil analysis will only detect wireworm numbers down to 60,000/ha but crop damage is still possible at levels below this. Land with wireworms and high numbers of FLN and PCN is best avoided for potatoes. The level of aphid infestation is related to the altitude at which the crop is grown. The higher the altitude the lower the risk of aphid infestation.
                                    </p>
                                </div>
                                <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                    'flnAndSpraing'.toLowerCase(),
                                    'potatoCystNematode'.toLowerCase(),
                                    'wireworm'.toLowerCase(),
                                    'aphidBorne'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.lowRiskLocations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('aphidBorne'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Spatial Separation</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#spatialSeparationDesc" aria-expanded="false" aria-controls="spatialSeparationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="spatialSeparationDesc">
                                <p>
                                    The main virus of concern for seed potato growers and industry is Potato Virus Y (PVY), which is transmitted by aphids, principally Myzus persicae, during July and August. As aphids are not thought to carry the virus over long distances, current guidance for PVY management recommends high grade seed crops to be isolated and away from fields with volunteers and to plant away from fields where volunteers have not been controlled.
                                </p>
                            </div>

                            <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                'aphidBorne'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.spatialSeparation}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('potatoCystNematode'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Trap Crops</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#trapCropsDesc" aria-expanded="false" aria-controls="trapCropsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="trapCropsDesc">
                                <p>
                                    Involves growing Solanum species eg Solanum sisymbrifolium which stimulate PCN hatch but limit PCN reproduction.  Problems can exist with establishing trap crops and fitting in with potato rotations.
                                </p>
                            </div>

                            <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                'potatoCystNematode'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.trapCrops}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('flnAndSpraing'.toLowerCase()) ||
                        listOfIssues.includes('potatoCystNematode'.toLowerCase()) ||
                        listOfIssues.includes('aphidBorne'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Varietal Choice</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#varietalChoiceDesc" aria-expanded="false" aria-controls="varietalChoiceDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="varietalChoiceDesc">
                                    <p>
                                        Potato varieties are available with resistance to the yellow PCN (Globodera rostochiensis) although this is the least common PCN species of PCN in the UK.  A smaller range of varieties are resistant to the more common white PCN (G. pallida).  Some varieties also show tolerance to PCN which means they yield well in the presence of PCN but allow PCN reproduction.
                                    </p>
                                </div>
                                <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                    'flnAndSpraing'.toLowerCase(),
                                    'potatoCystNematode'.toLowerCase(),
                                    'aphidBorne'.toLowerCase()
                                ]} />                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.varietalChoice}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('aphidBorne'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>In Field Non-Cropped Area</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#nonCroppedAreaDesc" aria-expanded="false" aria-controls="nonCroppedAreaDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="nonCroppedAreaDesc">
                                <p>
                                    Beetle banks consist of stands of wildflowers and grasses and are designed to act as reservoirs of beneficial insects such as ground beetles and parasitoids, which help to provide natural biological control of insect pests. Diverse crop margins and strips act in a similar way to beetle banks to increase natural enemies. More diverse strips should harbour greater biodiversity and greater numbers of beneficials. Ladybirds, hoverflies and lacewings are natural enemies of aphid in potato.
                                </p>
                            </div>

                            <PotatoesPestList listOfIssues={listOfIssues} toDisplay={[
                                'aphidBorne'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.nonCroppedArea}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                </>
            )}


            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddPotatoesPestMeasures;