import { withJsonFormsControlProps } from '@jsonforms/react';
import BenchMarking from './BenchMarking';
import { ControlProps, JsonSchema, RankedTester, and, rankWith, schemaMatches } from '@jsonforms/core';

type JsonSchemaWithUrl = JsonSchema & { benchmarkingUrl: string };

interface Renderer {
    tester: any;
    renderer: any;
    year: number;
    isSpring: boolean;
    sectionString: any;
}

const BenchMarkingControl = (props: ControlProps) => {
    const schema = props.schema as JsonSchemaWithUrl;
    let yearValue = null;
    let isSpring = false;
    let sectionString = "To see how many other users use this intervention: ";
    const renderers = props.renderers as Array<Renderer>;

    for (const renderer of renderers) {
        if (renderer.sectionString !== undefined) {
            sectionString = renderer.sectionString;
            break;
        }
    }

    for (const renderer of renderers) {
        if (renderer.year !== undefined) {
            yearValue = renderer.year;
            break;
        }
    }
    for (const renderer of renderers) {
        if (renderer.isSpring !== undefined) {
            isSpring = renderer.isSpring;
            break;
        }
    }
    return (
        <BenchMarking sectionString={sectionString} benchmarkingUrl={schema.benchmarkingUrl} currentYear={yearValue} isSpring={isSpring} />
    )
};

export const benchMarkingControlTester: RankedTester = rankWith(
    3,
    and(
        schemaMatches((schema) => schema.hasOwnProperty('benchmarkingUrl'))
    )
);

export default withJsonFormsControlProps(BenchMarkingControl);