import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import ImprovedGrasslandDiseaseList from './improvedGrasslandDiseaseList';
import { QuestionCircle } from 'react-bootstrap-icons';
import MeasuresButtons from '../measuresButtons';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddImprovedGrasslandDiseaseMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`6380b5dae250d623d32622c3`);


    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.diseases !== null
        && (location.state.surveyData.diseases !== undefined)
        && (location.state.surveyData.diseases.improvedGrassland !== null)
        && (location.state.surveyData.diseases.improvedGrassland !== undefined)) ?
        location.state.surveyData.diseases.improvedGrassland.controlMeasures : {};

    const [data, setData] = useState(initialData);
    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.diseases?.improvedGrassland);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.diseases.improvedGrassland.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>Improved Grassland disease control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement </p>
            </div>

            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('leafBlotch'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('crownRust'.toLowerCase()) ||
                        listOfIssues.includes('drechsleraLeafDisease'.toLowerCase()) ||
                        listOfIssues.includes('cloverRot'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Ploughing in Crop Residue</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#ploughingInCropResidueDesc" aria-expanded="false" aria-controls="ploughingInCropResidueDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="ploughingInCropResidueDesc">
                                    <p>
                                        Burial of crop debris by ploughing can reduce inoculum for some pathogens which produce inoculum on plant debris. Many pathogens survive over the winter and produce air-borne spores that are dispersed to new crops. However, if the preceding crop is a non-host, tillage may increase disease by bringing older infected residue back to the surface. Also on the negative side the use of ploughing can reduce soil biodiversity.
                                    </p>
                                </div>


                                <ImprovedGrasslandDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'leafBlotch'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'crownRust'.toLowerCase(),
                                    'drechsleraLeafDisease'.toLowerCase(),
                                    'cloverRot'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.ploughingInCropResidue}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('leafBlotch'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('crownRust'.toLowerCase()) ||
                        listOfIssues.includes('drechsleraLeafDisease'.toLowerCase()) ||
                        listOfIssues.includes('virusDiseases'.toLowerCase()) ||
                        listOfIssues.includes('verticilliumWilt'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Resistant Varieties </h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#resistanceVariablesDesc" aria-expanded="false" aria-controls="resistanceVariablesDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="resistanceVariablesDesc">
                                    <p>
                                        Resistant varieties are an important part of non-chemical disease control. There are good sources of information on disease resistance to many of the major pathogens in the AHDB England and Wales Recommended Grass and Clover Lists (RGCL).
                                    </p>
                                    <p>For specific guidance click <a href="https://ahdb.org.uk/knowledge-library/recommended-grass-and-clover-lists-rgcl" target="_blank" rel="noreferrer">here</a> </p>
                                </div>


                                <ImprovedGrasslandDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'leafBlotch'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'crownRust'.toLowerCase(),
                                    'drechsleraLeafDisease'.toLowerCase(),
                                    'virusDiseases'.toLowerCase(),
                                    'verticilliumWilt'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.resistanceVariables}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('leafBlotch'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('crownRust'.toLowerCase()) ||
                        listOfIssues.includes('drechsleraLeafDisease'.toLowerCase()) ||
                        listOfIssues.includes('virusDiseases'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Control Volunteers & Weeds</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#controlVolunteersAndWeedsDesc" aria-expanded="false" aria-controls="controlVolunteersAndWeedsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="controlVolunteersAndWeedsDesc">
                                    <p>
                                        Volunteers often carry a range of diseases.  Ideally the volunteers should be destroyed prior to the emergence of new crops.
                                    </p>
                                </div>

                                <ImprovedGrasslandDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'leafBlotch'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'crownRust'.toLowerCase(),
                                    'drechsleraLeafDisease'.toLowerCase(),
                                    'virusDiseases'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.controlVolunteersAndWeeds}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('leafBlotch'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('crownRust'.toLowerCase()) ||
                        listOfIssues.includes('drechsleraLeafDisease'.toLowerCase()) ||
                        listOfIssues.includes('virusDiseases'.toLowerCase()) ||
                        listOfIssues.includes('cloverRot'.toLowerCase()) ||
                        listOfIssues.includes('leafDiseases'.toLowerCase()) ||
                        listOfIssues.includes('verticilliumWilt'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Nutrient Management</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#nutrientManagementDesc" aria-expanded="false" aria-controls="nutrientManagementDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="nutrientManagementDesc">
                                    <p>
                                        Excessive nitrogen will exacerbate diseases which thrive in a dense lush canopy ie crown rust and mildew. Avoid excessive nitrogen application whilst ensuring the correct phosphorous, potash and pH status to stimulate vigorous growth.
                                    </p>
                                </div>

                                <ImprovedGrasslandDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'leafBlotch'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'crownRust'.toLowerCase(),
                                    'drechsleraLeafDisease'.toLowerCase(),
                                    'virusDiseases'.toLowerCase(),
                                    'cloverRot'.toLowerCase(),
                                    'leafDiseases'.toLowerCase(),
                                    'verticilliumWilt'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.nutrientManagement}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('leafBlotch'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('crownRust'.toLowerCase()) ||
                        listOfIssues.includes('drechsleraLeafDisease'.toLowerCase()) ||
                        listOfIssues.includes('leafDiseases'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Regular Grazing of Young Material</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#regularGrazingDesc" aria-expanded="false" aria-controls="regularGrazingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="regularGrazingDesc">
                                    <p>
                                        Regular grazing keeps grass swards open and avoids dense lush swards which encourage disease.
                                    </p>
                                </div>

                                <ImprovedGrasslandDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'leafBlotch'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'crownRust'.toLowerCase(),
                                    'drechsleraLeafDisease'.toLowerCase(),
                                    'leafDiseases'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.regularGrazing}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('leafBlotch'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('crownRust'.toLowerCase()) ||
                        listOfIssues.includes('drechsleraLeafDisease'.toLowerCase()) ||
                        listOfIssues.includes('virusDiseases'.toLowerCase()) ||
                        listOfIssues.includes('leafDiseases'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Harvest at First Sign / Early to Reduce Loss / Spread </h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#earlyHarvestDesc" aria-expanded="false" aria-controls="earlyHarvestDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="earlyHarvestDesc">
                                    <p>
                                        A crown rust attack in the autumn can be dealt with by immediate grazing or cutting to remove the leaf food supply for the fungus. For grassland intended for silage or hay production, cutting and baling early at the first signs of a disease outbreak will help to prevent losses and disease spread.
                                    </p>
                                </div>

                                <ImprovedGrasslandDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'leafBlotch'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'crownRust'.toLowerCase(),
                                    'drechsleraLeafDisease'.toLowerCase(),
                                    'virusDiseases'.toLowerCase(),
                                    'leafDiseases'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.earlyHarvest}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('cloverRot'.toLowerCase()) ||
                        listOfIssues.includes('verticilliumWilt'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Hygiene And Prevention</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#hygieneAndPreventionDesc" aria-expanded="false" aria-controls="hygieneAndPreventionDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="hygieneAndPreventionDesc">
                                    <p>
                                        This is the first defence against the introduction of soil-borne diseases into clean land. Machinery used in infested fields should be power-washed before use in uninfected fields, and soil should at least be knocked off from boots and tools. Clean fields should be visited first in the sequence of crops so that cleaning down equipment can be done at the end of the day.
                                    </p>
                                </div>

                                <ImprovedGrasslandDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'cloverRot'.toLowerCase(),
                                    'verticilliumWilt'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.hygieneAndPrevention}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                </>
            )}

            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"diseases"}
                    finalUrl={"/add-diseases-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddImprovedGrasslandDiseaseMeasures;