function SummaryIssuesParcels(props) {
    const nameOfParcelsInCrop = props.landParcels.filter(parcel =>
        parcel.hasOwnProperty('geometry') && parcel.geometry !== null && typeof parcel.geometry !== 'undefined' &&
        parcel.crops.some(crop => crop.cropType.toLowerCase() === props.cropName.toLowerCase())
    );
    return (
        <>
            {nameOfParcelsInCrop.length > 0 &&
                <>
                    <h4>List of parcels that have this crop:</h4>
                    <ul class="list-group list-group-horizontal mb-4">
                        {nameOfParcelsInCrop.map(parcel => { return <li key={parcel.rpaId} class="list-group-item">{parcel.name}</li> })}
                    </ul>
                </>
            }
        </>

    )
}
export default SummaryIssuesParcels;