import { Component } from 'react';

export class TermsAndConditions extends Component {
    static displayName = TermsAndConditions.name;

    render() {
        return (
            <>
                <section className="py-2 border-bottom">
                    <div className="container px-5 my-5">
                        <div className="row gx-5">
                            <h1><strong>IPM Tool User Terms and Conditions</strong></h1>
                            <p>Last updated [August 2023]</p>
                            <h2>1. Agreement to terms</h2>
                            <p>The IPM Planning Tool (IPM Tool) provides online guidance on IPM methods of controlling pests, weeds and diseases of major crops. Users can select control methods that are appropriate for their farming system and record them as an IPM plan. These terms and conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (&ldquo;you&rdquo;) and IPM Tool Host (&ldquo;we,&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;), concerning your access to and use of the IPM Tool as well as any other media form or media channel related, linked, or otherwise connected thereto.</p>
                            <p>You agree that by accessing the IPM Tool, you have read, understood, and agree to be bound by these terms and conditions. If you do not agree with all of these terms and conditions, then you are expressly prohibited from using the IPM Tool and you must discontinue using it immediately.</p>
                            <p>Supplemental terms and conditions or documents that may be posted on the IPM Tool from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these terms and conditions at any time and for any reason.</p>
                            <p>We will alert you about any changes by updating the &ldquo;Last updated&rdquo; date of these terms and conditions, and you waive any right to receive specific notice of each such change.</p>
                            <p>It is your responsibility to periodically review these terms and conditions to stay informed of updates. You will be subject to and will be deemed to have been made aware of and to have accepted, the changes in any revised terms and conditions by your continued use of the IPM Tool after the date such revised terms and conditions are posted.</p>
                            <p>The information provided on the IPM Tool is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.</p>
                            <p>Accordingly, those persons who choose to access the IPM Tool from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
                            <h2>2. User representations</h2>
                            <p>By using the IPM Tool, you represent and warrant that:</p>
                            <ol>
                                <li>all registration information you submit will be true, accurate, current, and complete;</li>
                                <li>you will maintain the accuracy of such information and promptly update such registration information as necessary;</li>
                                <li>you have the legal capacity and you agree to comply with these terms and conditions;</li>
                                <li>you are not under the age of 18;</li>
                                <li>you will not access the IPM Tool through automated or non-human means, whether through a bot, script, or otherwise;</li>
                                <li>you will not use the IPM Tool for any illegal or unauthorized purpose;</li>
                                <li>your use of the IPM Tool will not violate any applicable law or regulation.</li>
                            </ol>
                            <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the IPM Tool (or any portion thereof).</p>
                            <h2>3. User registration</h2>
                            <p>In order to use the IPM Tool, you are required to register an account. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>
                            <p>Accounts will be deemed inactive where farm data has not been updated for a period of at least 36 months. Users will be notified when an account is made inactive. If inactive accounts are not subsequently re-activated by the user within 3 months of notification, the account will be permanently deleted.</p>
                            <h2>4. Prohibited activities</h2>
                            <p>As a user of the IPM Tool, you agree not to:</p>
                            <ol>
                                <li>make any unauthorized use of the IPM Tool, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretences;</li>
                                <li>circumvent, disable, or otherwise interfere with security-related features of the IPM Tool, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the IPM Tool and/or the Content contained therein;</li>
                                <li>trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
                                <li>make improper use of our support services or submit false reports of abuse or misconduct;</li>
                                <li>engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools;</li>
                                <li>interfere with, disrupt, or create an undue burden on the IPM Tool or the networks or services connected to the IPM Tool;</li>
                                <li>use any information obtained from the IPM Tool in order to harass, abuse, or harm another person;</li>
                                <li>attempt to bypass any measures of the IPM Tool designed to prevent or restrict access to the IPM Tool, or any portion of the IPM Tool;</li>
                                <li>harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the IPM Tool to you;</li>
                                <li>delete the copyright or other proprietary rights notice from any Content;</li>
                                <li>upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party&rsquo;s uninterrupted use and enjoyment of the IPM Tool or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the IPM Tool;</li>
                                <li>upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (&ldquo;gifs&rdquo;), 1&times;1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo; or &ldquo;pcms&rdquo;);</li>
                                <li>except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the IPM Tool, or using or launching any unauthorized script or other software;</li>
                                <li>disparage, tarnish, or otherwise harm, in our opinion, us and/or the IPM Tool;</li>
                                <li>use the IPM Tool in a manner inconsistent with any applicable laws or regulations.</li>
                            </ol>
                            <h2>5. Submissions</h2>
                            <p>You acknowledge and agree that any data, regarding the IPM Tool ("Submissions") provided by you to us are non-confidential and shall become jointly-owned. As such, we have joint rights, including all intellectual property rights, and shall be entitled without your consent to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>
                            <p>You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.</p>
                            <h2>6. Third-party websites and content</h2>
                            <p>The IPM Tool contains links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content").</p>
                            <p>Third-Party Websites and Third-Party Content accessed via a direct link on the IPM Tool, are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the IPM Tool or any Third-Party Content posted on, available through, or installed from the IPM Tool, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.</p>
                            <p>Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the IPM Tool and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk. You should be aware these terms and conditions are longer apply once you leave the IPM Tool and you are bound by the terms of the Third Party.</p>
                            <p>You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the IPM Tool or relating to any applications you use or install from the IPM Tool. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party.</p>
                            <p>You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your purchase of such products or services. Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.</p>
                            <h2>7. IPM Tool management</h2>
                            <p>We reserve the right, but not the obligation, to:</p>
                            <ol>
                                <li>monitor the IPM Tool for violations of these terms and conditions;</li>
                                <li>take appropriate legal action against anyone who, in our sole discretion, violates the law or these terms and conditions, including without limitation, reporting such user to law enforcement authorities;</li>
                                <li>in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;</li>
                                <li>in our sole discretion and without limitation, notice, or liability, to remove from the IPM Tool or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;</li>
                                <li>otherwise manage the IPM Tool in a manner designed to protect our rights and property and to facilitate the proper functioning of the IPM Tool.</li>
                            </ol>
                            <h2>8. Privacy policy</h2>
                            <p>We care about data privacy and security. Please review our Privacy Policy [Appendix 1]. By using the IPM Tool, you agree to be bound by our Privacy Policy, and to monitor this policy for updates.</p>
                            <p>If you access the IPM Tool from the United States, Asia, or any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United Kingdom, then through your continued use of the IPM Tool, you are transferring your data to the United Kingdom, and you expressly consent to have your data transferred to and processed in the United Kingdom. Further, if you access your personal data outside of the United Kingdom, we do not assume any liability for that transfer being compliant with the laws and regulation within the United Kingdom, or the laws of the country of the territory where the access takes place.</p>
                            <h2>9. Intellectual Property Rights &ndash; Notice and Policy</h2>
                            <p>We respect the intellectual property rights of others. If you believe that any material available on or through the IPM Tool infringes upon any copyright you own or control, please immediately alert us via the &lsquo;Contact Us&rsquo; email address, summarising the details of the material in question. Following this, you will be asked to provide formal notification, providing the following information:</p>
                            <ol>
                                <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
                                <li>identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on the IPM Tool are covered by the Notification, a representative list of such works on the IPM Tool;</li>
                                <li>identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;</li>
                                <li>information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an email address at which the complaining party may be contacted;</li>
                                <li>a statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;</li>
                                <li>a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed upon.</li>
                            </ol>
                            <h2>10. Terms and termination</h2>
                            <p>These terms and conditions shall remain in full force and effect while you use the IPM Tool. Without limiting any other provision of these terms and conditions, we reserve the right to, in our sole discretion and without notice or liability, deny access to and use of the IPM Tool (including blocking certain IP addresses), to any person for any reason or for no reason, including without limitation for breach of any representation, warranty, or covenant contained in these terms and conditions or of any applicable law or regulation. We may terminate your use or participation in the IPM Tool or delete [your account and] any content or information that you posted at any time, without warning, in our sole discretion.</p>
                            <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.</p>
                            <p>In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
                            <h2>11. Modifications and interruptions</h2>
                            <p>We reserve the right to change, modify, or remove the contents of the IPM Tool at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our IPM Tool. Users will be notified of pre-scheduled IPM Tool maintenance as appropriate.</p>
                            <p>We will not be liable to you or any third party for any modification, suspension, or discontinuance of the IPM Tool.</p>
                            <p>We cannot guarantee the IPM Tool will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the IPM Tool, resulting in interruptions, delays, or errors.</p>
                            <p>We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the IPM Tool at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the IPM Tool during any downtime or discontinuance of the IPM Tool.</p>
                            <p>Nothing in these terms and conditions will be construed to obligate us to maintain and support the IPM Tool or to supply any corrections, updates, or releases in connection therewith.</p>
                            <h2>12. Governing law</h2>
                            <p>These terms and conditions and your use of the IPM Tool are governed by and construed in accordance with United Kingdom law applicable to agreements made and to be entirely performed within the United Kingdom, without regard to its conflict of law principles.</p>
                            <p>In no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the site, even if we have been advised of the possibility of such damages.</p>
                            <h3>12.1. Dispute resolution</h3>
                            <p>Any legal action of whatever nature brought by either you or us (collectively, the &ldquo;Parties&rdquo; and individually, a &ldquo;Party&rdquo;) shall be commenced or prosecuted under United Kingdom law. Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from these terms and conditions. In no event shall any claim, action, or proceeding brought by either Party related in any way to the IPM Tool be commenced more than one year after the cause of action arose.</p>
                            <h3>12.2. Liability</h3>
                            <p>The IPM Tool Host is not liable for damages associated with Third-Party Websites linked to the IPM Tool (Clause 6) nor for any cost incurred by users associated with use of the IPM Tool (Clause 14).</p>
                            <h2>13. Corrections</h2>
                            <p>There may be information on the IPM Tool that contains typographical errors, inaccuracies, or omissions that may relate to the IPM Tool, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the IPM Tool at any time, without prior notice.</p>
                            <h2>14. Disclaimer</h2>
                            <p><em>The IPM Tool provides access to guidance on integrated pest management (IPM) in outdoor crops and enables users to record current, and planned, IPM actions. The IPM Tool Host has no economic responsibility whatsoever for losses,</em> damages or <em>inconveniences arising out of the use of or inability to use this service.</em></p>
                            <p>The IPM Tool is provided on an as-is and as-available basis. You agree that your use of the IPM Tool and associated services will be at your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or implied, in connection with the IPM Tool and your use thereof, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                            <p>We make no warranties or representations about the accuracy or completeness of the IPM Tool&lsquo;s content or the content of any websites linked to the IPM Tool and we will assume no liability or responsibility for any (1) errors, mistakes, or inaccuracies of content and materials, (2) property damage, of any nature whatsoever, resulting from your access to and use of the IPM Tool, (3) any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein, (4) any interruption or cessation of transmission to or from the IPM Tool, (5) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the IPM Tool by any third party, and/or (6) any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via the IPM Tool.</p>
                            <p>We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the IPM Tool, any hyperlinked website, or any website or mobile application featured in any banner or other advertising, and we will not be a party to or in any way be responsible for monitoring any transaction between you and any third-party providers of products or services.</p>
                            <p>As with the purchase of a product or service through any medium or in any environment, you should use your best judgment and exercise caution where appropriate.</p>
                            <h2>15. Indemnification</h2>
                            <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys&rsquo; fees and expenses, made by any third party due to or arising out of: (1) use of the IPM Tool; (2) breach of these terms and conditions; (3) any breach of your representations and warranties set forth in these terms and conditions; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the IPM Tool with whom you connected via the IPM Tool.</p>
                            <p>Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
                            <h2>16. Electronic communications, transactions and signatures</h2>
                            <p>Visiting the IPM Tool, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the IPM Tool, satisfy any legal requirement that such communication be in writing.</p>
                            <h2>17. Miscellaneous</h2>
                            <p>These terms and conditions and any policies or operating rules posted by us on the IPM Tool constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these terms and conditions shall not operate as a waiver of such right or provision.</p>
                            <p>These terms and conditions operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.</p>
                            <p>If any provision or part of a provision of these terms and conditions is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these terms and conditions and does not affect the validity and enforceability of any remaining provisions.</p>
                            <p>There is no joint venture, partnership, employment, or agency relationship created between you and us as a result of these terms and conditions or use of the IPM Tool. You agree that these terms and conditions will not be construed against us by virtue of having drafted them.</p>
                            <p>You hereby waive any and all defences you may have based on the electronic form of these terms and conditions and the lack of signing by the parties hereto to execute these terms and conditions.<strong>;</strong></p>
                            <h2>18. Contact us</h2>
                            <p>To receive further information regarding the use of the IPM Tool, or in order to resolve a complaint regarding the IPM Tool, please contact us at:</p>
                            <p><strong>IPM@adas.co.uk</strong></p>
                            <h1><strong>Appendix 1</strong></h1>
                            <h1><strong>IPM Tool</strong> <strong>Data Privacy Policy</strong></h1>
                            <p>Last updated [August 2023]</p>
                            <h2>1. Scope</h2>
                            <p>This privacy policy only applies to the website for the IPM Tool. Where links are provided from our websites to third party websites, the IPM Tool Host is not responsible for those websites and a link to them does not imply endorsement. Data provided by you as part of IPM Tool may include some personal data. Your personal data will never be shared outside by the IPM Tool Host and will only be used as part of the website for the IPM Tool. All data is stored digitally behind a secure network, and only accessed by staff participating with the website for the IPM Tool. No personal data is shared outside of the website for the IPM Tool.</p>
                            <h2>2. Our core beliefs regarding user privacy and data protection</h2>
                            <ul>
                                <li>User privacy and data protection are core principles.</li>
                                <li>We have a duty of care to the people whose personal data we hold.</li>
                                <li>We will never sell, rent, or otherwise distribute or make public your personal information.</li>
                            </ul>
                            <h2>3. Relevant legislation</h2>
                            <p>Along with internal computer systems, the IPM Tool Host will comply with the following international legislation with regards to data protection and user privacy:</p>
                            <ul>
                                <li>EU General Data Protection Regulation 679/2016 (GDPR)</li>
                            </ul>
                            <h2>4. Lawful basis</h2>
                            <p>The lawful basis for data collection in the IPM Tool is as per Clause 6.1 (a) and (b) of the GDPR.</p>
                            <p>&lsquo;Legitimate Interest&rsquo; &ndash; this means that personal information will be used to enable us to keep stakeholders informed in ways they would reasonably expect to run the website and associated IPM Tool.</p>
                            <p>&lsquo;Consent&rsquo; &ndash; this means that the provision of information to consumers, which may include direct marketing, will be done when the data subject has consented to the processing of his or her personal data for one or more specific purposes.</p>
                            <p>The information provided on the IPM Tool is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the IPM Tool from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
                            <h2>5. How we will use your data</h2>
                            <p>The IPM Tool collects your personal data so that we can:</p>
                            <ul>
                                <li>Contact you with your permission with information relating to IPM Tool.</li>
                                <li>Identify usage trends on our websites and social media channels in order to enhance, improve or modify our websites and to facilitate your participation in interactive features you may choose to use on our websites and social media, also to personalise your user experience by presenting content tailored to you.</li>
                            </ul>
                            <h2>6. Personal information collected by the IPM Tool and why we collect it</h2>
                            <p>You may give us your personal information via &lsquo;contact us&rsquo;, when you are involved in testing or providing feedback on the IPM Tool, or when you sign up to or update account details in the IPM Tool. We will not receive your information through third parties unless you have given written permission for the third party to pass on your details. We may supplement the information you provide with data from the public domain, such as Companies House and other public databases. As part of IPM Tool, we collect and use personal information for the following reasons:</p>
                            <ul>
                                <li>Contact details are provided by website users from the IPM Tool &lsquo;contact us&rsquo;. All personal data are restricted to the contact us page.</li>
                                <li>Contact details are used to send users supporting information and resources.</li>
                                <li>Website users who have entered their details into the contact us form may also be contacted with information about IPM-related workshops, events, updates, and invitations to join similar projects.</li>
                                <li>IPM Tool users who have updated their account.</li>
                            </ul>
                            <h3>6.1. What details we collect</h3>
                            <p>"Personal Information" is information that identifies you as an individual or relates to an identifiable individual. Personal Information we may collect includes:</p>
                            <ul>
                                <li>Your title, first name, surname, business name, postal address and postcode, email address, telephone, and mobile numbers.</li>
                                <li>Your social media handles.</li>
                                <li>Field locations.</li>
                                <li>Information regarding your dealings with us including details of your attendance at events and engagement with our programmes.</li>
                                <li>Any preference you have in relation to our publications and services.</li>
                                <li>Any information you may voluntarily submit to us by completing any paper or web-based form or job application.</li>
                                <li>Details of your visits to and usage of our websites and IPM Tool.</li>
                                <li>Details of whether you have opened our emails, clicked on a link or watched our videos.</li>
                            </ul>
                            <h3>6.2. Cookies</h3>
                            <p>Like most websites, ours use &lsquo;cookies&rsquo; to help us make our website and IPM Tool, and your user experience, better. Cookies mean that a website and IPM Tool will remember you. They are small text files that sites transfer to your computer/tablet/smartphone and make interacting with a website faster and easier.</p>
                            <p>We will also track the Internet Protocol (IP) address used to connect your computer to the Internet; computer connection information such as browser type and version; and time zone setting, browser plug-in types and versions; operating system; download history, which we sometimes aggregate with similar information from other contacts to improve the navigation and services we provide. During some visits we may use software tools to measure and collect session information, including page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page.</p>
                            <h2>7. Your Rights</h2>
                            <p>Under the GDPR, from the 25<sup>th of</sup> May 2018, you have several rights:</p>
                            <ul>
                                <li>Right of access &ndash; you have the right to request a copy of the information that we hold about you.</li>
                                <li>Right of rectification &ndash; you have a right to correct data that we hold about you that is inaccurate or incomplete.</li>
                                <li>Right to be forgotten &ndash; in certain circumstances you can ask for the data we hold about you to be erased from our records.</li>
                                <li>Right to restriction of processing &ndash; where certain conditions apply to have a right to restrict the processing.</li>
                                <li>Right of portability &ndash; you have the right to have the data we hold about you transferred to another organisation.</li>
                                <li>Right to object &ndash; you have the right to object to certain types of processing such as direct marketing.</li>
                                <li>Right to object to automated processing, including profiling &ndash; you also have the right not to be subject to the legal effects of automated processing or profiling.</li>
                                <li>Right to judicial review: in the event that IPM Tool refuses your request under rights of access, we will provide you with a reason as to why and you have the right to complain.</li>
                            </ul>
                            <p>If you wish to exercise any of these rights, please contact us on:</p>
                            <p>ipm@adas.co.uk</p>
                            <h2>8. Data storage</h2>
                            <p>We ensure that there are appropriate and proportionate controls in place to protect your personal information.</p>
                            <ul>
                                <li>Your data will be stored on a secure servers based in the United Kingdom and will be password protected and encrypted.</li>
                                <li>Access to IPM Tool data is restricted to named staff in the IPM Tool Host.&nbsp;</li>
                                <li>IPM Tool data may be shared with third parties, however where IPM Tool data are shared all personal data will be removed prior to transfer.&nbsp;</li>
                            </ul>
                            <p>We&nbsp;will never sell your personal information to third parties and we will not release your personal information to any company outside of the IPM Tool Host for their mailing or marketing purposes. We will retain your personal information for no longer than necessary for the purpose it was obtained unless a longer retention period is required or permitted by law.</p>
                            <h2>9. Data breaches</h2>
                            <p>We will report any unlawful data breach of the IPM Tool database or the database(s) of any of our third party data processors to any and all relevant persons and authorities within 72 hours of the breach if it is apparent that personal data stored in an identifiable manner has been stolen, and there is a high likelihood or severity of the resulting risk to people&rsquo;s rights and freedoms.</p>
                            <h2>10. Data controller</h2>
                            <p>The data controller of this website is:</p>
                            <p>RSK ADAS Limited, a UK private limited company with company number 10486936.</p>
                            <p>Whose registered office is:</p>
                            <p>Spring Lodge, 172 Chester Road, Helsby, Cheshire UK WA6 0AR.</p>
                            <h2>11. Changes to our Privacy Policy</h2>
                            <p>This privacy policy may change from time to time in line with legislation or industry developments. We will not explicitly inform our clients or website users of these changes. Instead, we recommend that you check the <a href="https://iptool.net/terms-and-conditions">IPM Tool Privacy Policy web page</a> occasionally for any changes. Specific policy changes and updates are mentioned in the change log below.</p>
                            <h2>12. Change log</h2>
                            <p>This privacy policy was last updated August 2023.</p>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}