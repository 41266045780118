const BeansPestList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('beanWeevil'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/management-of-pea-and-bean-weevils-in-peas-and-field-beans" target="_blank" rel="noreferrer">Bean Weevil</a>
                    </li>
                    : <></>
                }
                {values.includes('blackbeanAphid'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/management-of-the-black-bean-aphid-in-field-beans-and-sugar-beet" target="_blank" rel="noreferrer">Black Bean Aphid</a>
                    </li>
                    : <></>
                }
                {values.includes('bruchidBeetle'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/management-of-bruchid-beetle-in-field-and-broad-beans" target="_blank" rel="noreferrer">Bruchid Beetle</a>
                    </li>
                    : <></>
                }
            </ul>
        </>
    );
};

export default BeansPestList;