import {
    JsonSchema,
    ControlProps,
    RankedTester,
    rankWith,
    schemaMatches,
    and,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';

type JsonSchemaWithUrl = JsonSchema & { url: string };

export const urlControl = (props: ControlProps) => {
    const schema = props.schema as JsonSchemaWithUrl;
    const value = schema.url;
    return (
        <div id='#/properties/url' className='url'>
            <span>For guidance on identification and further information click  </span><a id='#/properties/url' href={value} target="_blank" rel="noreferrer">here</a>
        </div>
    );
};

export const urlControlTester: RankedTester = rankWith(
    3,
    and(
        schemaMatches((schema) => schema.hasOwnProperty('url'))
    )
);
export default withJsonFormsControlProps(urlControl);