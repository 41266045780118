import { useState } from 'react';
import usePostRequest from '../../hooks/usePostRequest';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';

const ResetUserPassword = () => {

    const [data, setData] = useState({
        email: ""
    });
    const { post } = usePostRequest("/api/admin/resetuserpassword", data);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let response = await post();
        if (!response.isValid) {
            toast.error("Error", {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        toast.info("Saved", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    }

    const handleChange = (event) => {
        setData({ ...data, email: event.target.value });
    }

    return (
        <>
            <h3 className='mt-4'>Reset user's password to default</h3>
            <Form inline="true" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="userEmailTextInput">User Email</Form.Label>
                    <Form.Control id="userEmailTextInput" placeholder="Enter user's email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required onChange={handleChange} />
                </Form.Group>
                <Button type="submit">Reset Password</Button>
            </Form>
        </>
    );
};

export default ResetUserPassword;