const controlNamesDictionary = {
    "lowRiskLocations": "Low Risk Locations",
    "controlVolunteers": "Control Volunteers",
    "seedbedManagement": "Seedbed Management",
    "nonCroppedAreas": "Noncropped Areas",
    "bioprotectantsMicrobial": "Bioprotectants Microbial",
    "bioprotectantsNaturalSubstances": "Bioprotectants Natural Substances",
    "decisionSupport": "Decision Support",
    "precisionIrrigation": "Precision Irrigation",
    "physicalExclusion": "Physical Exclusion",
    "earlyProductionUnderCropCovers": "Early Production Under Crop Covers",
    "soilManagement": "Soil Management",
    "fieldHistory": "Field History",
    "hygiene": "Hygiene",
    "seedTesting": "Seed Testing",
    "varietyChoice": "Variety Choice",
    "VarietyChoice": "Variety Choice",
    "primaryCultivations": "Primary Cultivations",
    "bioprotectantsMacrobiological": "Bioprotectants Macrobiological",
    "bioprotectantsSemiochemical": "Bioprotectants Semiochemical",
    "hygieneAndPrevention": "Hygiene And Prevention",
    "nutrientManagement": "Nutrient Management",
    "canopyManagement": "Canopy Management",
    "companionCropping": "Companion Cropping",
    "spatialSeparation": "Spatial Separation",
    "biostimulants": "Biostimulants",
    "organicAmendments": "Organic Amendments",
    "rollingSoil": "Rolling Soil",
    "secondaryCultivations": "Secondary Cultivations",
    "seedRate": "Seed Rate",
    "seedbedQuality": "Seedbed Quality",
    "sowingDate": "Sowing Date",
    "varietalChoice": "Varietal Choice",
    "VarietalChoice": "Varietal Choice",
    "earlyHarvest": "Early Harvest",
    "controlVolunteersAndWeeds": "Control Volunteers And Weeds",
    "microbialBioprotectants": "Microbial Bioprotectants",
    "longTermLeys": "Long Term Leys",
    "extraCultivations": "Extra Cultivations",
    "larvaeCountsInSoil": "Larvae Counts In Soil",
    "pestMonitoring": "Pest Monitoring",
    "rollingSoilPostPlanting": "Rolling Soil Post Planting",
    "narrowStemmedGrasses": "Narrow Stemmed Grasses",
    "ploughingInCropResidue": "Ploughing In Crop Residue",
    "resistanceVariables": "Resistance Variables",
    "regularGrazing": "Regular Grazing",
    "fieldHistoryRotation": "Field History Rotation",
    "seedbed": "Seedbed",
    "defoliation": "Defoliation",
    "trapCrops": "Trap Crops",
    "goodDrainage": "Good Drainage",
    "lime": "Lime",
    "nonCroppedArea": "Non Cropped Area",
    "biofumigation": "Biofumigation",
    "controlledIrrigation": "Controlled Irrigation",
    "appropriateHarvest": "Appropriate Harvest",
    "soilAnalysis": "Soil Analysis",
    "mowing": "Mowing",
    "harrowing": "Harrowing",
    "physicalMulches": "Physical Mulches",
    "thermalWeedControl": "Thermal Weed Control",
    "handPulling": "Hand Pulling",
    "spotTreatment": "Spot Treatment",
    "rotation": "Rotation",
    "improveSoilCondition": "Improve Soil Condition",
    "staleSeedbed": "Stale Seedbed",
    "reSeeding": "Reseeding",
    "grazing": "Grazing",
    "bioprotectans": "Bioprotectans",
    "weedWiper": "Weed Wiper",
    "ploughing": "Ploughing",
    "fallow": "Fallow",
    "stubbleManagement": "Stubble Management",
    "coverCrops": "Cover Crops",
    "precisionApplication": "Precision Application",
    "nonInversionTillage": "Non Inversion Tillage",
    "directDrilling": "Direct Drilling"
}


export default controlNamesDictionary;