import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const beansDiseaseSummaryMeasures = (controlMeasures, listOfIssues, isWinter) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const botrytis = { name: 'botrytis', display: "Botrytis" };
    const rust = { name: 'rust', display: "Rust" };
    const sclerotinia = { name: 'sclerotinia', display: "Sclerotinia" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, rotation & break crops'
                    const issuesInControl = [
                        sclerotinia
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nutrientManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Nutrient management'
                    const issuesInControl = [
                        botrytis,
                        rust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'primaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Primary cultivations (crop residue burial)'
                    const issuesInControl = [
                        botrytis,
                        rust,
                        sclerotinia
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'controlVolunteersAndWeeds'.toLowerCase()) {
                    measureWithPests.measures = 'Control volunteers & weeds'
                    const issuesInControl = [
                        rust,
                        sclerotinia
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'microbialBioprotectants'.toLowerCase()) {
                    measureWithPests.measures = 'Microbial bioprotectants'
                    const issuesInControl = [
                        sclerotinia
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support, incl. monitoring'
                    const issuesInControl = [
                        sclerotinia
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietalChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Variety choice'
                    const issuesInControl = [
                        botrytis,
                        rust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [botrytis, rust, sclerotinia]);
    return listOfMeasuresWithPests;
};

export default beansDiseaseSummaryMeasures;