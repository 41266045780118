import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const brassicasDiseaseSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const darkLeafSpot = { name: 'darkLeafSpot', display: "Dark leaf spot - Alternaria" };
    const downyMildew = { name: 'downyMildew', display: "Downy Mildew" };
    const lightLeafSpot = { name: 'lightLeafSpot', display: "Light leaf spot" };
    const ringspot = { name: 'ringspot', display: "Ringspot" };
    const xanthomonas = { name: 'xanthomonas', display: "Xanthomonas" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support, incl. monitoring'
                    const issuesInControl = [
                        darkLeafSpot,
                        downyMildew,
                        lightLeafSpot,
                        ringspot,
                        xanthomonas
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'earlyProductionUnderCropCovers'.toLowerCase()) {
                    measureWithPests.measures = 'Early production under crop covers'
                    const issuesInControl = [
                        downyMildew
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'soilManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Soil management and good drainage'
                    const issuesInControl = [
                        darkLeafSpot,
                        downyMildew,
                        lightLeafSpot,
                        ringspot,
                        xanthomonas
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, rotation & break crops'
                    const issuesInControl = [
                        darkLeafSpot,
                        lightLeafSpot,
                        ringspot,
                        xanthomonas
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygiene'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene (particularly in propagation)'
                    const issuesInControl = [
                        downyMildew,
                        lightLeafSpot,
                        ringspot,
                        xanthomonas
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'precisionIrrigation'.toLowerCase()) {
                    measureWithPests.measures = 'Precision Irrigation'
                    const issuesInControl = [
                        darkLeafSpot,
                        xanthomonas
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedTesting'.toLowerCase()) {
                    measureWithPests.measures = 'Seed testing'
                    const issuesInControl = [
                        downyMildew,
                        ringspot,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Select low-risk locations'
                    const issuesInControl = [
                        darkLeafSpot,
                        downyMildew,
                        lightLeafSpot,
                        ringspot,
                        xanthomonas
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietyChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Variety choice'
                    const issuesInControl = [
                        downyMildew,
                        ringspot,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'controlVolunteers'.toLowerCase()) {
                    measureWithPests.measures = 'Control volunteers and weeds'
                    const issuesInControl = [
                        darkLeafSpot,
                        downyMildew,
                        lightLeafSpot,
                        ringspot,
                        xanthomonas
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'primaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Primary cultivations (crop residue burial)'
                    const issuesInControl = [
                        darkLeafSpot,
                        downyMildew,
                        lightLeafSpot,
                        ringspot,
                        xanthomonas
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [darkLeafSpot, downyMildew, lightLeafSpot, ringspot, xanthomonas]);
    return listOfMeasuresWithPests;
};

export default brassicasDiseaseSummaryMeasures;