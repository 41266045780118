import { Link, useLocation, useNavigate } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { ArrowLeft, ArrowRight, QuestionCircle } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import usePutRequest from '../../../../hooks/usePutRequest';
import { useEffect, useState } from 'react';
import SurveyNavigation from '../../surveyNavigation';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';
import getListOfNonOtherCrops from '../../../../helpers/getListOfNonOtherCrops';

const AddDiseasesResistance = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });
    const { jsonForm, loadingState } = useJsonForm(`638f294215da63b36e8038c9`);
    if (location.state.surveyData.resistanceManagement === null) location.state.surveyData.resistanceManagement = {};
    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.resistanceManagement !== null
        && (location.state.surveyData.resistanceManagement !== undefined)
        && (location.state.surveyData.resistanceManagement.diseases !== null)
        && (location.state.surveyData.resistanceManagement.diseases !== undefined)) ?
        location.state.surveyData.resistanceManagement.diseases : {};
    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const { put } = usePutRequest(`/api/survey/${location.state?.surveyData?.id}`, location.state?.surveyData);

    let crops = location.state.crops;
    const [data, setData] = useState(initialData);
    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    if (initialData === null) initialData.resistanceManagement = {};
    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    let nonOtherCrops = getListOfNonOtherCrops(location.state.surveyData.landParcels);
    let prevUrl;
    if (nonOtherCrops.length === 0) {
        prevUrl = '/add-diseases-other';
    } else {
        prevUrl = `/add-diseases-measures/${nonOtherCrops[nonOtherCrops.length - 1]}`;
    }

    const onAddResistanceSubmitClick = async (goToNextPage) => {
        const response = await put();
        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!goToNextPage) {
            toast.info("Saved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        let finalUrl = "/ipm-plan-summary";


        navigate(finalUrl,
            {
                state: {
                    farmData: location.state.farmData,
                    surveyData: location.state.surveyData,
                    crops: crops
                }
            });
    };

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.resistanceManagement.diseases = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>diseases resistance management</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}><h2>Managing fungicide resistance</h2></div>
                <div data-bs-toggle="collapse" data-bs-target="#manageDesc" aria-expanded="false" aria-controls="manageDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
            </div>
            <div className="collapse" id="manageDesc">
                <p>Reducing reliance on intensive fungicide programmes by implementing IPM, will help slow development of fungicide resistance.</p>
                <p>Following resistance management guidance from the UK Fungicide Resistance Action Group <a href="https://ahdb.org.uk/knowledge-library/the-fungicide-resistance-action-group-frag-uk" target="_blank" rel="noreferrer">here</a> will further reduce the risk of resistance.
                </p>
            </div>
            <JsonForms validationMode={'NoValidation'}
                schema={schema.properties.diseases}
                data={data}
                uischema={uiSchema.uiSchemas.diseases.fungicideResistance}
                renderers={renderers}
                cells={materialCells}
                onChange={({ data, errors }) => formOnChange({ data, errors })}
                readonly={status === 'completed'}
            />
            <hr />
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}><h2>Check if fungicides are affected by resistance</h2></div>
                <div data-bs-toggle="collapse" data-bs-target="#affectedDesc" aria-expanded="false" aria-controls="affectedDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
            </div>
            <div className="collapse" id="affectedDesc">
                <p>Do you suspect that resistance is affecting control from particular fungicides against certain diseases? </p>
                <p>Check if resistance has been reported in the UK <a href="https://ahdb.org.uk/knowledge-library/the-fungicide-resistance-action-group-frag-uk" target="_blank" rel="noreferrer">here</a></p>
            </div>
            <JsonForms validationMode={'NoValidation'}
                schema={schema.properties.diseases}
                data={data}
                uischema={uiSchema.uiSchemas.diseases.fungicideAffected}
                renderers={renderers}
                cells={materialCells}
                onChange={({ data, errors }) => formOnChange({ data, errors })}
                readonly={status === 'completed'}
            />
            <hr />
            <div className="row justify-content-between my-5">
                <div className="alert alert-info" role="alert">
                    To save your work, click <strong>Save</strong> before using the <strong>Go To</strong> or <strong>Go back</strong> button.
                </div>
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                {crops.length === 0 ? (
                    <Link className="col-2 mx-3"
                        to={`/add-diseases`}
                        state={{
                            farmData: location.state.farmData,
                            surveyData: location.state.surveyData,
                            crops: crops
                        }}>
                        <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                    </Link>)
                    : (<Link className="col-2 mx-3"
                        to={prevUrl}
                        state={{
                            farmData: location.state.farmData,
                            surveyData: location.state.surveyData,
                            index: crops.length - 1,
                            crops: crops
                        }}>
                        <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                    </Link>
                    )}
                <SurveyNavigation farmData={location.state.farmData} surveyData={location.state.surveyData} crops={location.state.crops} />
                <button type="button" className="col-2 mx-3 btn btn-primary" onClick={() => onAddResistanceSubmitClick(false)} disabled={status === 'completed'} >Save</button>
                {status === 'completed' ? (
                    <>
                        <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddResistanceSubmitClick(true)}>Continue</button>
                    </>
                ) : (
                    <>
                        <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddResistanceSubmitClick(true)}>Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight></button>
                    </>
                )}
            </div>
        </>
    );
};

export default AddDiseasesResistance;