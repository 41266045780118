import AddBarleyDiseaseMeasures from './components/survey/measures/barley/addBarleyDiseaseMeasures';
import AddBarleyPestMeasures from './components/survey/measures/barley/addBarleyPestMeasures';
import AddBeansDiseaseMeasures from './components/survey/measures/beans/addBeansDiseaseMeasures';
import AddBeansPestMeasures from './components/survey/measures/beans/addBeansPestMeasures';
import AddBrassicasDiseaseMeasures from './components/survey/measures/brassicas/addBrassicasDiseaseMeasures';
import AddBrassicasPestMeasures from './components/survey/measures/brassicas/addBrassicasPestMeasures';
import AddAppleDiseaseMeasures from './components/survey/measures/apple/addAppleDiseaseMeasures';
import AddApplePestMeasures from './components/survey/measures/apple/addApplePestMeasures';
import AddAppleWeeds from './components/survey/weeds/addAppleWeeds';
import AddWeedsAppleMeasures from './components/survey/measures/weeds/addWeedsAppleMeasures';
import AddGeneralPractices from './components/survey/generalPractices/addGeneralPractices';
import AddImprovedGrasslandDiseaseMeasures from './components/survey/measures/improvedGrassland/addImprovedGrasslandDiseaseMeasures';
import AddImprovedGrasslandPestMeasures from './components/survey/measures/improvedGrassland/addImprovedGrasslandPestMeasures';
import AddWeedsImprovedGrassland from './components/survey/weeds/addImprovedGrasslandWeeds';
import AddWeedsImprovedGrasslandMeasures from './components/survey/measures/weeds/addWeedsImprovedGrasslandMeasures';
import AddMaizeDiseaseMeasures from './components/survey/measures/maize/addMaizeDiseaseMeasures';
import AddMaizePestMeasures from './components/survey/measures/maize/addMaizePestMeasures';
import AddOatsDiseaseMeasures from './components/survey/measures/oats/addOatsDiseaseMeasures';
import AddOatsPestMeasures from './components/survey/measures/oats/addOatsPestMeasures';
import AddOilseedsDiseaseMeasures from './components/survey/measures/oilseeds/addOilseedsDiseaseMeasures';
import AddOilseedsPestMeasures from './components/survey/measures/oilseeds/addOilseedsPestMeasures';
import AddPeasDiseaseMeasures from './components/survey/measures/peas/addPeasDiseaseMeasures';
import AddPeasPestMeasures from './components/survey/measures/peas/addPeasPestMeasures';
import AddPotatoesDiseaseMeasures from './components/survey/measures/potatoes/addPotatoesDiseaseMeasures';
import AddPotatoesPestMeasures from './components/survey/measures/potatoes/addPotatoesPestMeasures';
import AddSpringBarleyDiseaseMeasures from './components/survey/measures/barley/addSpringBarleyDiseaseMeasures';
import AddSpringBarleyPestMeasures from './components/survey/measures/barley/addSpringBarleyPestMeasures';
import AddSpringBeansDiseaseMeasures from './components/survey/measures/beans/addSpringBeansDiseaseMeasures';
import AddSpringBeansPestMeasures from './components/survey/measures/beans/addSpringBeansPestMeasures';
import AddSpringOatsDiseaseMeasures from './components/survey/measures/oats/addSpringOatsDiseaseMeasures';
import AddSpringOatsPestMeasures from './components/survey/measures/oats/addSpringOatsPestMeasures';
import AddSpringWheatDiseaseMeasures from './components/survey/measures/wheat/addSpringWheatDiseaseMeasures';
import AddSpringWheatPestMeasures from './components/survey/measures/wheat/addSpringWheatPestMeasures';
import AddSugarBeetDiseaseMeasures from './components/survey/measures/sugarBeet/addSugarBeetDiseaseMeasures';
import AddSugarBeetPestMeasures from './components/survey/measures/sugarBeet/addSugarBeetPestMeasures';
import AddWheatDiseaseMeasures from './components/survey/measures/wheat/addWheatDiseaseMeasures';
import AddWheatPestMeasures from './components/survey/measures/wheat/addWheatPestMeasures';


import AddDisease from './components/survey/disease/addDisease';
import AddDiseasesResistance from './components/survey/measures/resistanceManagement/addDiseasesResistance';
import AddPest from './components/survey/pest/addPest';
import AddPestOther from './components/survey/pest/addPestOther';
import AddDiseaseOther from './components/survey/disease/addDiseaseOther';
import AddPestsResistance from './components/survey/measures/resistanceManagement/addPestsResistance';
import AddWeeds from './components/survey/weeds/addWeeds';
import AddWeedsMeasures from './components/survey/measures/weeds/addWeedsMeasures';
import AddWeedsResistance from './components/survey/measures/resistanceManagement/addWeedsResistance';

import AddSurvey from './components/survey/addSurvey';
import Admin from './components/admin/admin';
import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AddFarm from './components/farm/addFarm';
import Farm from './components/farm/farm';
import FarmList from './components/farmList/farmList';
import SummaryPage from './components/survey/summary/summaryPage';
import Survey from './components/survey/survey';
import EditFarm from './components/farm/editFarm';
import { TermsAndConditions } from './components/TermsAndConditions';
import { Home } from './components/Home';


const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />
    },
    {
        path: '/farm-list',
        requireAuth: true,
        element: <FarmList />
    },
    {
        path: '/add-farm',
        requireAuth: true,
        element: <AddFarm />
    },
    {
        path: '/add-ipm-plan',
        requireAuth: true,
        element: <AddSurvey />
    },
    {
        path: '/add-general-practices',
        requireAuth: true,
        element: <AddGeneralPractices />
    },
    {
        path: '/add-pests',
        requireAuth: true,
        element: <AddPest />
    },
    {
        path: '/add-pests-other',
        requireAuth: true,
        element: <AddPestOther />
    },
    {
        path: '/add-diseases',
        requireAuth: true,
        element: <AddDisease />
    },
    {
        path: '/add-diseases-other',
        requireAuth: true,
        element: <AddDiseaseOther />
    },
    {
        path: '/add-weeds',
        requireAuth: true,
        element: <AddWeeds />
    },
    {
        path: '/add-weeds-apple',
        requireAuth: true,
        element: <AddAppleWeeds />
    },
    {
        path: '/add-weeds-improvedgrassland',
        requireAuth: true,
        element: <AddWeedsImprovedGrassland />
    },
    {
        path: '/view-farm/:id',
        requireAuth: true,
        element: <Farm />
    },
    {
        path: '/edit-farm/:id',
        requireAuth: true,
        element: <EditFarm />
    },
    {
        path: '/view-ipm-plan',
        requireAuth: true,
        element: <Survey />
    },
    {
        path: '/add-weeds-measures',
        requireAuth: true,
        element: <AddWeedsMeasures />
    },
    {
        path: '/add-weeds-apple-measures',
        requireAuth: true,
        element: <AddWeedsAppleMeasures />
    },
    {
        path: '/add-weeds-improvedgrassland-measures',
        requireAuth: true,
        element: <AddWeedsImprovedGrasslandMeasures />
    },
    {
        path: '/add-diseases-measures/apple',
        requireAuth: true,
        element: <AddAppleDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/springbarley',
        requireAuth: true,
        element: <AddSpringBarleyDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/springbeans',
        requireAuth: true,
        element: <AddSpringBeansDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/springoats',
        requireAuth: true,
        element: <AddSpringOatsDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/springwheat',
        requireAuth: true,
        element: <AddSpringWheatDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/winterbarley',
        requireAuth: true,
        element: <AddBarleyDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/winterbeans',
        requireAuth: true,
        element: <AddBeansDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/winteroats',
        requireAuth: true,
        element: <AddOatsDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/winterwheat',
        requireAuth: true,
        element: <AddWheatDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/improvedGrassland',
        requireAuth: true,
        element: <AddImprovedGrasslandDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/brassicas',
        requireAuth: true,
        element: <AddBrassicasDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/maize',
        requireAuth: true,
        element: <AddMaizeDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/oilseedrape',
        requireAuth: true,
        element: <AddOilseedsDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/peas',
        requireAuth: true,
        element: <AddPeasDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/potatoes',
        requireAuth: true,
        element: <AddPotatoesDiseaseMeasures />
    },
    {
        path: '/add-diseases-measures/sugarBeet',
        requireAuth: true,
        element: <AddSugarBeetDiseaseMeasures />
    },
    {
        path: '/add-pests-measures/apple',
        requireAuth: true,
        element: <AddApplePestMeasures />
    },
    {
        path: '/add-pests-measures/springbarley',
        requireAuth: true,
        element: <AddSpringBarleyPestMeasures />
    },
    {
        path: '/add-pests-measures/springbeans',
        requireAuth: true,
        element: <AddSpringBeansPestMeasures />
    },
    {
        path: '/add-pests-measures/springoats',
        requireAuth: true,
        element: <AddSpringOatsPestMeasures />
    },
    {
        path: '/add-pests-measures/springwheat',
        requireAuth: true,
        element: <AddSpringWheatPestMeasures />
    },
    {
        path: '/add-pests-measures/winterbarley',
        requireAuth: true,
        element: <AddBarleyPestMeasures />
    },
    {
        path: '/add-pests-measures/winterbeans',
        requireAuth: true,
        element: <AddBeansPestMeasures />
    },
    {
        path: '/add-pests-measures/winteroats',
        requireAuth: true,
        element: <AddOatsPestMeasures />
    },
    {
        path: '/add-pests-measures/winterwheat',
        requireAuth: true,
        element: <AddWheatPestMeasures />
    },
    {
        path: '/add-pests-measures/improvedGrassland',
        requireAuth: true,
        element: <AddImprovedGrasslandPestMeasures />
    },
    {
        path: '/add-pests-measures/brassicas',
        requireAuth: true,
        element: <AddBrassicasPestMeasures />
    },
    {
        path: '/add-pests-measures/maize',
        requireAuth: true,
        element: <AddMaizePestMeasures />
    },
    {
        path: '/add-pests-measures/oilseedrape',
        requireAuth: true,
        element: <AddOilseedsPestMeasures />
    },
    {
        path: '/add-pests-measures/peas',
        requireAuth: true,
        element: <AddPeasPestMeasures />
    },
    {
        path: '/add-pests-measures/potatoes',
        requireAuth: true,
        element: <AddPotatoesPestMeasures />
    },
    {
        path: '/add-pests-measures/sugarBeet',
        requireAuth: true,
        element: <AddSugarBeetPestMeasures />
    },
    {
        path: '/add-pests-measures/winterwheat',
        requireAuth: true,
        element: <AddWheatPestMeasures />
    },
    {
        path: '/add-weeds-resistance',
        requireAuth: true,
        element: <AddWeedsResistance />
    },
    {
        path: '/add-pests-resistance',
        requireAuth: true,
        element: <AddPestsResistance />
    },
    {
        path: '/add-diseases-resistance',
        requireAuth: true,
        element: <AddDiseasesResistance />
    },
    {
        path: '/ipm-plan-summary',
        requireAuth: true,
        element: <SummaryPage />
    },
    {
        path: '/admin-area',
        requireAuth: true,
        onlyAdmin: true,
        element: <Admin />
    },
    ...ApiAuthorzationRoutes,
    {
        path: '*',
        element: <Home />
    },
];

export default AppRoutes;
