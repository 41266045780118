import { useCallback } from "react";
import authService from '../components/api-authorization/AuthorizeService'

const useDeleteRequest = (url) => {

    const remove = useCallback(async () => {
        try {
            const token = await authService.getAccessToken();
            const requestOptions = {
                method: 'DELETE',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            };
            const response = await fetch(url, requestOptions);
            let result = {};
            result.isValid = true;
            if (!response.ok) {
                result = await response.json();
                result.isValid = false;
            };
            return result;
        }
        catch (e) {
            console.error(e);
        }
    }, [url]);

    return { remove };
};

export default useDeleteRequest;