import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ArrowLeft } from 'react-bootstrap-icons';
import SummarySurvey from './summarySurvey';
import Accordion from 'react-bootstrap/Accordion';
import AppleSummary from './apple/appleSummary';
import BrassicasSummary from './brassicas/brassicasSummary';
import ImprovedGrasslandSummary from './improvedGrassland/improvedGrasslandSummary';
import MaizeSummary from './maize/maizeSummary';
import OilseedRapeSummary from './oilseedRape/oilseedRapeSummary';
import PeasSummary from './peas/peasSummary';
import PotatoesSummary from './potatoes/potatoesSummary';
import SugarBeetSummary from './sugarBeet/sugarBeetSummary';
import BarleySummary from './barley/barleySummary';
import BeansSummary from './beans/beansSummary';
import OatsSummary from './oats/oatsSummary';
import WheatSummary from './wheat/wheatSummary';
import WeedsSummary from './weeds/weedsSummary';
import SurveyNavigation from '../surveyNavigation';
import useGetRequest from '../../../hooks/useGetRequest';
import './summaryPage.css'
import SummaryReport from './report/summaryReport';
import getListOfCrops from '../../../helpers/getListOfCrops';
import getListOfOtherCrops from '../../../helpers/getListOfOtherCrops';
import OtherCropSummary from './otherCrop/otherCropSummary';

const SummaryPage = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.farmData === null) {
            navigate("/farm-list");
        };
        handleDataFetch();
    }, []);
    const { get } = useGetRequest(`/api/user`);
    const [userData, setUserData] = useState([]);

    const handleDataFetch = async () => {
        const data = await get();
        if (data !== undefined) {
            setUserData(data);
        }
    }
    let crops = location.state.crops;
    const listOfCrops = getListOfCrops(location.state?.surveyData.landParcels);
    const listOfOtherCrops = getListOfOtherCrops(location.state?.surveyData.landParcels);
    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>summary page</u> - {location.state.surveyData.year}
                </h1>
                <h2>You have finished your IPM Plan</h2>
                <p className='mt-2'>
                    To claim BASIS points please send an email to <a href="mailto:cpd@basis-reg.co.uk">cpd@basis-reg.co.uk</a> quoting the following references as appropriate:
                    <ul>
                        <li>BASIS <strong>Professional</strong> Register - <strong>IPM/131385/2324/k</strong></li>
                        <li>BASIS <strong>Environmental Advisers</strong> Register - <strong>IPM/131386/2324/I</strong></li>
                    </ul>
                </p >
                {userData.basisNumber ?
                    <p className='mt-2 alert alert-info' role="alert">
                        This plan was completed by: <strong>{userData.email}</strong> BASIS registration number: <strong>{userData.basisNumber}</strong>
                    </p>
                    :
                    <p className='mt-2 alert alert-warning' role="alert">
                        This plan was completed by: <strong>{userData.email}</strong> BASIS registration number: <strong>______________</strong>
                    </p>
                }
                <p>Please click on each crop to get a summary table with your issues and control measures selected.</p>
                {
                    listOfCrops.length === 0 &&
                    <h3>No crops selected</h3>
                }
                <Accordion alwaysOpen flush>
                    {listOfCrops.includes('apple'.toLowerCase()) &&
                        <>
                            <Accordion.Item key="0" eventKey="0">
                                <Accordion.Header><h3>Apple</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <AppleSummary surveyData={location.state.surveyData} cropName={'apple'} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </>
                    }
                    {listOfCrops.includes('brassicas'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><h3>Brassicas</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <BrassicasSummary surveyData={location.state.surveyData} cropName={'brassicas'} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('improvedgrassland'.toLowerCase()) &&
                        <><Accordion.Item eventKey="2">
                            <Accordion.Header><h3>Improved Grassland</h3>
                                <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                            <Accordion.Body>
                                <ImprovedGrasslandSummary surveyData={location.state.surveyData} cropName={'improvedGrassland'} />
                            </Accordion.Body>
                        </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('maize'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><h3>Maize</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <MaizeSummary surveyData={location.state.surveyData} cropName={'maize'}/>
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('oilseedRape'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header><h3>Oilseed Rape</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <OilseedRapeSummary surveyData={location.state.surveyData} cropName={'oilseedRape'}/>
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('peas'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header><h3>Peas</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <PeasSummary surveyData={location.state.surveyData} cropName={'peas'}/>
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('potatoes'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header><h3>Potatoes</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <PotatoesSummary surveyData={location.state.surveyData} cropName={'potatoes'}/>
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('sugarbeet'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header><h3>Sugar Beet</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <SugarBeetSummary surveyData={location.state.surveyData} cropName={'sugarbeet'}/>
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }

                    {listOfCrops.includes('springbarley'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header><h3>Spring Barley</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <BarleySummary surveyData={location.state.surveyData} cropName={'springBarley'} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('springbeans'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header><h3>Spring Beans</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <BeansSummary surveyData={location.state.surveyData} cropName={'springBeans'} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('springoats'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header><h3>Spring Oats</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <OatsSummary surveyData={location.state.surveyData} cropName={'springOats'} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('springwheat'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header><h3>Spring Wheat</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <WheatSummary surveyData={location.state.surveyData} cropName={'springWheat'} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('winterbarley'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="12">
                                <Accordion.Header><h3>Winter Barley</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <BarleySummary surveyData={location.state.surveyData} cropName={'winterBarley'} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('winterbeans'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="13">
                                <Accordion.Header><h3>Winter Beans</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <BeansSummary surveyData={location.state.surveyData} cropName={'winterBeans'} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('winteroats'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="14">
                                <Accordion.Header><h3>Winter Oats</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <OatsSummary surveyData={location.state.surveyData} cropName={'winterOats'} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {listOfCrops.includes('winterwheat'.toLowerCase()) &&
                        <>
                            <Accordion.Item eventKey="15">
                                <Accordion.Header><h3>Winter Wheat</h3>
                                    <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                <Accordion.Body>
                                    <WheatSummary surveyData={location.state.surveyData} cropName={'winterWheat'} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </>
                    }
                    {
                        listOfOtherCrops.map((otherCrop, index) => {
                            return (
                                <>
                                    <Accordion.Item eventKey={17+index} >
                                        <Accordion.Header><h3>{otherCrop}</h3>
                                            <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                                        <Accordion.Body>
                                            <OtherCropSummary surveyData={location.state.surveyData} cropName={otherCrop} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                            )
                        }
                        )
                    }
                    <Accordion.Item eventKey="16">
                        <Accordion.Header><h3>Weeds</h3>
                            <h6 className='accordionHeader'>Click here to open/close</h6></Accordion.Header>
                        <Accordion.Body>
                            <WeedsSummary surveyData={location.state.surveyData} cropName={'winterWheat'} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <SummaryReport listOfCrops={listOfCrops}
                    userEmail={userData.email}
                    basisNumber={userData.basisNumber}
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData} />
                <SummarySurvey />
            </div >
            <div className="row justify-content-between my-5">
                <Link className="col-2 mx-3"
                    to={`/add-diseases-resistance`}
                    state={{
                        farmData: location.state.farmData,
                        surveyData: location.state.surveyData,
                        crops: crops
                    }}>
                    <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                </Link>
                <SurveyNavigation farmData={location.state.farmData} surveyData={location.state.surveyData} crops={location.state.crops} />
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-success">Go back to your farms</button>
                </Link>
            </div>
        </>
    );
};

export default SummaryPage;