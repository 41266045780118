import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const brassicasPestSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const aphids = { name: 'aphids', display: "Aphids" };
    const cabbageRootFly = { name: 'cabbageRootFly', display: "Cabbage root fly" };
    const caterpillars = { name: 'caterpillars', display: "Caterpillars" };
    const whiteFly = { name: 'whiteFly', display: "White Fly" };
    const slugs = { name: 'slugs', display: "Slugs" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Selecting low-risk locations'
                    const issuesInControl = [whiteFly];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'controlVolunteers'.toLowerCase()) {
                    measureWithPests.measures = 'Control volunteers and weeds'
                    const issuesInControl = [aphids];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedbedManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Seed bed management'
                    const issuesInControl = [
                        aphids,
                        slugs];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nonCroppedAreas'.toLowerCase()) {
                    measureWithPests.measures = 'In field non-cropped areas'
                    const issuesInControl = [
                        aphids,
                        cabbageRootFly,
                        caterpillars,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'bioprotectantsMicrobial'.toLowerCase()) {
                    measureWithPests.measures = 'Bioprotectants Microbial'
                    const issuesInControl = [
                        caterpillars,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'bioprotectantsNaturalSubstances'.toLowerCase()) {
                    measureWithPests.measures = 'Bioprotectants natural substances'
                    const issuesInControl = [
                        aphids,
                        whiteFly,
                        slugs
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support, incl. monitoring'
                    const issuesInControl = [
                        aphids,
                        cabbageRootFly,
                        caterpillars,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'precisionIrrigation'.toLowerCase()) {
                    measureWithPests.measures = 'Precision irrigation'
                    const issuesInControl = [
                        cabbageRootFly,
                        caterpillars,
                        whiteFly,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'physicalExclusion'.toLowerCase()) {
                    measureWithPests.measures = 'Physical exclusion of pests'
                    const issuesInControl = [
                        aphids,
                        cabbageRootFly,
                        caterpillars,
                        whiteFly,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [aphids, cabbageRootFly, caterpillars, whiteFly, slugs]);
    return listOfMeasuresWithPests;
};

export default brassicasPestSummaryMeasures;