import React from 'react';
import { Link } from 'react-router-dom';

const FarmInformation = ({ farm }) => {
    return (
        <>
            <div className="card">
                <h5 className="card-header">Farm information</h5>
                <div className="card-body">
                    <h5 className="card-title">Farm address information</h5>
                    {farm.address !== null ?
                        (
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Address Line 1</div>
                                        {farm.address.addressLine1}
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Address Line 2</div>
                                        {farm.address.addressLine2}
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">County</div>
                                        {farm.address.county}
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Postcode</div>
                                        {farm.address.postcode}
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Town</div>
                                        {farm.address.town}
                                    </div>
                                </li>
                            </ul>
                        ) : (
                            <p className="card-text">No address information provided</p>
                        )}
                </div>
                <div className="card-body">
                    <h5 className="card-title">Farm contact information</h5>
                    {farm.contactInformation !== null ?
                        (
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Name</div>
                                        {farm.contactInformation.name}
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Email</div>
                                        {farm.contactInformation.email}
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Mobile</div>
                                        {farm.contactInformation.mobile}
                                    </div>
                                </li>
                            </ul>
                        ) : (
                            <p className="card-text">No contact information provided</p>
                        )}
                </div>
                <div className="card-body text-center">
                    <Link to={{ pathname: `/edit-farm/${farm.id}` }} className="mx-5">
                        <button type="button" className="btn btn-outline-success">Edit Farm</button>
                    </Link >
                </div>
            </div>
        </>
    );
};

export default FarmInformation;
