const SugarBeetDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value))
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('cercospera'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://bbro.co.uk/on-farm/diseases/" target="_blank" rel="noreferrer">Cercospera</a>
                    </li>
                    : <></>
                }
                {values.includes('mildew'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://bbro.co.uk/on-farm/diseases/" target="_blank" rel="noreferrer">Mildew</a>
                    </li>
                    : <></>
                }
                {values.includes('rhizoctonia'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Rhizoctonia
                    </li>
                    : <></>
                }
                {values.includes('rhizomania'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Rhizomania
                    </li>
                    : <></>
                }
                {values.includes('rust'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://bbro.co.uk/on-farm/diseases/" target="_blank" rel="noreferrer">Rust</a>
                    </li>
                    : <></>
                }
                {values.includes('violetRootRot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://horticulture.ahdb.org.uk/knowledge-library/violet-root-rot#:~:text=Violet%20Root%20Rot%20(Helicobasidium%20purpureum,some%20vegetable%20crops%20including%20potatoes.&text=Violet%20root%20rot%20is%20most,although%20roots%20can%20be%20affected" target="_blank" rel="noreferrer">Violet Root Rot</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default SugarBeetDiseaseList;