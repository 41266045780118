const UserDataHeaders = [
    // User
    { label: "User_Email", key: "otherFields.user.Email" },
    { label: "User_BasisNumber", key: "otherFields.user.BasisNumber" },
    { label: "User_NRoSONumber", key: "otherFields.user.NRoSONumber" },
    { label: "User_RegistrationDate", key: "otherFields.user.RegistrationDate" },
    { label: "User_LastValidAccess", key: "otherFields.user.LastValidAccess" },
    // Farm
    { label: "Farm_Name", key: "otherFields.farm_info.Name" },
    { label: "Farm_SBINumber", key: "otherFields.farm_info.SBINumber" },
    { label: "Farm_Address_AddressLine1", key: "otherFields.farm_info.Address.AddressLine1" },
    { label: "Farm_Address_AddressLine2", key: "otherFields.farm_info.Address.AddressLine2" },
    { label: "Farm_Address_Town", key: "otherFields.farm_info.Address.Town" },
    { label: "Farm_Address_County", key: "otherFields.farm_info.Address.County" },
    { label: "Farm_Address_Postcode", key: "otherFields.farm_info.Address.Postcode" },
    { label: "Farm_ContactInformation_Name", key: "otherFields.farm_info.ContactInformation.Name" },
    { label: "Farm_ContactInformation_Email", key: "otherFields.farm_info.ContactInformation.Email" },
    { label: "Farm_ContactInformation_Mobile", key: "otherFields.farm_info.ContactInformation.Mobile" },
    // Survey General
    { label: "Year", key: "otherFields.Year" },
    { label: "Crops", key: "Crops" },
    // Survey crops
    // General Practices
    { label: "GP_CropWalking_Status", key: "otherFields.GeneralPractices.CropWalking.Status" },
    { label: "GP_CropWalking_Notes", key: "otherFields.GeneralPractices.CropWalking.Notes" },
    { label: "GP_CropWalkingPerson_Status", key: "otherFields.GeneralPractices.CropWalkingPerson.Status" },
    { label: "GP_CropWalkingPerson_Notes", key: "otherFields.GeneralPractices.CropWalkingPerson.Notes" },
    { label: "GP_CropWalkingPerson_IsBasis", key: "otherFields.GeneralPractices.CropWalkingPerson.IsBasis" },
    { label: "GP_ReducePesticideDosage_Status", key: "otherFields.GeneralPractices.ReducePesticideDosage.Status" },
    { label: "GP_ReducePesticideDosage_Notes", key: "otherFields.GeneralPractices.ReducePesticideDosage.Notes" },
    { label: "GP_SpecificWeeds_Status", key: "otherFields.GeneralPractices.SpecificWeeds.Status" },
    { label: "GP_GP_SpecificWeeds_Status_Notes", key: "otherFields.GeneralPractices.GP_SpecificWeeds_Status.Notes" },
    { label: "GP_Biopesticides_Biocontrol_Status", key: "otherFields.GeneralPractices.Biopesticides.Biocontrol.Status" },
    { label: "GP_Biopesticides_Biocontrol_Notes", key: "otherFields.GeneralPractices.Biopesticides.Biocontrol.Notes" },
    { label: "GP_Biopesticides_Biofungicides_Status", key: "otherFields.GeneralPractices.Biopesticides.Biofungicides.Status" },
    { label: "GP_Biopesticides_Biofungicides_Notes", key: "otherFields.GeneralPractices.Biopesticides.Biofungicides.Notes" },
    { label: "GP_Biopesticides_Biostimulants_Status", key: "otherFields.GeneralPractices.Biopesticides.Biostimulants.Status" },
    { label: "GP_Biopesticides_Biostimulants_Notes", key: "otherFields.GeneralPractices.Biopesticides.Biostimulants.Notes" },
    { label: "GP_Biopesticides_Elicitors_Status", key: "otherFields.GeneralPractices.Biopesticides.Elicitors.Status" },
    { label: "GP_Biopesticides_Elicitors_Notes", key: "otherFields.GeneralPractices.Biopesticides.Elicitors.Notes" },
    { label: "GP_Biopesticides_Endophytes_Status", key: "otherFields.GeneralPractices.Biopesticides.Endophytes.Status" },
    { label: "GP_Biopesticides_Endophytes_Notes", key: "otherFields.GeneralPractices.Biopesticides.Endophytes.Notes" },
    { label: "GP_Biopesticides_Other_Status", key: "otherFields.GeneralPractices.Biopesticides.Other.Status" },
    { label: "GP_Biopesticides_Other_Notes", key: "otherFields.GeneralPractices.Biopesticides.Other.Notes" },
    { label: "GP_ControlStrategiesSuccessReview_Status", key: "otherFields.GeneralPractices.ControlStrategiesSuccessReview.Status" },
    { label: "GP_ControlStrategiesSuccessReview_Notes", key: "otherFields.GeneralPractices.ControlStrategiesSuccessReview.Notes" },
    { label: "GP_ControlStrategiesRecordOutcome_Status", key: "otherFields.GeneralPractices.ControlStrategiesRecordOutcome.Status" },
    { label: "GP_ControlStrategiesRecordOutcome_Notes", key: "otherFields.GeneralPractices.ControlStrategiesRecordOutcome.Notes" },
    { label: "GP_DiverseRotation_Status", key: "otherFields.GeneralPractices.DiverseRotation.Status" },
    { label: "GP_DiverseRotation_Notes", key: "otherFields.GeneralPractices.DiverseRotation.Notes" },
    { label: "GP_DiverseRotation_Status", key: "otherFields.GeneralPractices.DiverseRotation.Status" },
    { label: "GP_DiverseRotation_Notes", key: "otherFields.GeneralPractices.DiverseRotation.Notes" },
    { label: "GP_NaturalPredators_Status", key: "otherFields.GeneralPractices.NaturalPredators.Status" },
    { label: "GP_NaturalPredators_Notes", key: "otherFields.GeneralPractices.NaturalPredators.Notes" },
    { label: "GP_PrecisionTechniques_GpsSectionControl_Status", key: "otherFields.GeneralPractices.PrecisionTechniques.GpsSectionControl.Status" },
    { label: "GP_PrecisionTechniques_GpsSectionControl_Notes", key: "otherFields.GeneralPractices.PrecisionTechniques.GpsSectionControl.Notes" },
    { label: "GP_PrecisionTechniques_GpsSteering_Status", key: "otherFields.GeneralPractices.PrecisionTechniques.GpsSteering.Status" },
    { label: "GP_PrecisionTechniques_GpsSteering_Notes", key: "otherFields.GeneralPractices.PrecisionTechniques.GpsSteering.Notes" },
    { label: "GP_PrecisionTechniques_NozzleSelection_Status", key: "otherFields.GeneralPractices.PrecisionTechniques.NozzleSelection.Status" },
    { label: "GP_PrecisionTechniques_NozzleSelection_Notes", key: "otherFields.GeneralPractices.PrecisionTechniques.NozzleSelection.Notes" },
    { label: "GP_PrecisionTechniques_VariableRate_Status", key: "otherFields.GeneralPractices.PrecisionTechniques.VariableRate.Status" },
    { label: "GP_PrecisionTechniques_VariableRate_Notes", key: "otherFields.GeneralPractices.PrecisionTechniques.VariableRate.Notes" },
    { label: "GP_PrecisionTechniques_Other_Status", key: "otherFields.GeneralPractices.PrecisionTechniques.Other.Status" },
    { label: "GP_PrecisionTechniques_Other_Notes", key: "otherFields.GeneralPractices.PrecisionTechniques.Other.Notes" },
    //
    // Pests
    //
    // Apple
    { label: "Pests_Apple_Aphids", key: "otherFields.Pests.Apple.Aphids" },
    { label: "Pests_Apple_FruitTreeSpiderMite", key: "otherFields.Pests.Apple.FruitTreeSpiderMite" },
    { label: "Pests_Apple_LepidopterousCaterpillars", key: "otherFields.Pests.Apple.LepidopterousCaterpillars" },
    { label: "Pests_Apple_Measures_BioprotectantsMacrobiological_Status", key: "otherFields.Pests.Apple.ControlMeasures.BioprotectantsMacrobiological.Status" },
    { label: "Pests_Apple_Measures_BioprotectantsMacrobiological_Notes", key: "otherFields.Pests.Apple.ControlMeasures.BioprotectantsMacrobiological.Notes" },
    { label: "Pests_Apple_Measures_BioprotectantsMicrobial_Status", key: "otherFields.Pests.Apple.ControlMeasures.BioprotectantsMicrobial.Status" },
    { label: "Pests_Apple_Measures_BioprotectantsMicrobial_Notes", key: "otherFields.Pests.Apple.ControlMeasures.BioprotectantsMicrobial.Notes" },
    { label: "Pests_Apple_Measures_BioprotectantsSemiochemical_Status", key: "otherFields.Pests.Apple.ControlMeasures.BioprotectantsSemiochemical.Status" },
    { label: "Pests_Apple_Measures_BioprotectantsSemiochemical_Notes", key: "otherFields.Pests.Apple.ControlMeasures.BioprotectantsSemiochemical.Notes" },
    { label: "Pests_Apple_Measures_HygieneAndPrevention_Status", key: "otherFields.Pests.Apple.ControlMeasures.HygieneAndPrevention.Status" },
    { label: "Pests_Apple_Measures_HygieneAndPrevention_Notes", key: "otherFields.Pests.Apple.ControlMeasures.HygieneAndPrevention.Notes" },
    { label: "Pests_Apple_Measures_NutrientManagement_Status", key: "otherFields.Pests.Apple.ControlMeasures.NutrientManagement.Status" },
    { label: "Pests_Apple_Measures_NutrientManagement_Notes", key: "otherFields.Pests.Apple.ControlMeasures.NutrientManagement.Notes" },
    { label: "Pests_Apple_Measures_PhysicalExclusion_Status", key: "otherFields.Pests.Apple.ControlMeasures.PhysicalExclusion.Status" },
    { label: "Pests_Apple_Measures_PhysicalExclusion_Notes", key: "otherFields.Pests.Apple.ControlMeasures.PhysicalExclusion.Notes" },
    { label: "Pests_Apple_Measures_CanopyManagement_Status", key: "otherFields.Pests.Apple.ControlMeasures.CanopyManagement.Status" },
    { label: "Pests_Apple_Measures_CanopyManagement_Notes", key: "otherFields.Pests.Apple.ControlMeasures.CanopyManagement.Notes" },
    { label: "Pests_Apple_Measures_CompanionCropping_Status", key: "otherFields.Pests.Apple.ControlMeasures.CompanionCropping.Status" },
    { label: "Pests_Apple_Measures_CompanionCropping_Notes", key: "otherFields.Pests.Apple.ControlMeasures.CompanionCropping.Notes" },
    { label: "Pests_Apple_Measures_VarietyChoice_Status", key: "otherFields.Pests.Apple.ControlMeasures.VarietyChoice.Status" },
    { label: "Pests_Apple_Measures_VarietyChoice_Notes", key: "otherFields.Pests.Apple.ControlMeasures.VarietyChoice.Notes" },
    { label: "Pests_Apple_Measures_SpatialSeparation_Status", key: "otherFields.Pests.Apple.ControlMeasures.SpatialSeparation.Status" },
    { label: "Pests_Apple_Measures_SpatialSeparation_Notes", key: "otherFields.Pests.Apple.ControlMeasures.SpatialSeparation.Notes" },
    // Brassicas
    { label: "Pests_Brassicas_DarkLeafSpot", key: "otherFields.Pests.Brassicas.DarkLeafSpot" },
    { label: "Pests_Brassicas_DownyMildew", key: "otherFields.Pests.Brassicas.DownyMildew" },
    { label: "Pests_Brassicas_LightLeafSpot", key: "otherFields.Pests.Brassicas.LightLeafSpot" },
    { label: "Pests_Brassicas_Ringspot", key: "otherFields.Pests.Brassicas.Ringspot" },
    { label: "Pests_Brassicas_Xanthomonas", key: "otherFields.Pests.Brassicas.Xanthomonas" },
    { label: "Pests_Brassicas_Measures_DecisionSupport_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.DecisionSupport.Status" },
    { label: "Pests_Brassicas_Measures_DecisionSupport_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.DecisionSupport.Notes" },
    { label: "Pests_Brassicas_Measures_EarlyProductionUnderCropCovers_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.EarlyProductionUnderCropCovers.Status" },
    { label: "Pests_Brassicas_Measures_EarlyProductionUnderCropCovers_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.EarlyProductionUnderCropCovers.Notes" },
    { label: "Pests_Brassicas_Measures_SoilManagement_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.SoilManagement.Status" },
    { label: "Pests_Brassicas_Measures_SoilManagement_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.SoilManagement.Notes" },
    { label: "Pests_Brassicas_Measures_FieldHistory_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.FieldHistory.Status" },
    { label: "Pests_Brassicas_Measures_FieldHistory_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.FieldHistory.Notes" },
    { label: "Pests_Brassicas_Measures_Hygiene_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.Hygiene.Status" },
    { label: "Pests_Brassicas_Measures_Hygiene_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.Hygiene.Notes" },
    { label: "Pests_Brassicas_Measures_PrecisionIrrigation_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.PrecisionIrrigation.Status" },
    { label: "Pests_Brassicas_Measures_PrecisionIrrigation_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.PrecisionIrrigation.Notes" },
    { label: "Pests_Brassicas_Measures_SeedTesting_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.SeedTesting.Status" },
    { label: "Pests_Brassicas_Measures_SeedTesting_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.SeedTesting.Notes" },
    { label: "Pests_Brassicas_Measures_LowRiskLocations_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.LowRiskLocations.Status" },
    { label: "Pests_Brassicas_Measures_LowRiskLocations_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Pests_Brassicas_Measures_VarietyChoice_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.VarietyChoice.Status" },
    { label: "Pests_Brassicas_Measures_VarietyChoice_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.VarietyChoice.Notes" },
    { label: "Pests_Brassicas_Measures_ControlVolunteers_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.ControlVolunteers.Status" },
    { label: "Pests_Brassicas_Measures_ControlVolunteers_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.ControlVolunteers.Notes" },
    { label: "Pests_Brassicas_Measures_PrimaryCultivations_Status", key: "otherFields.Pests.Brassicas.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Pests_Brassicas_Measures_PrimaryCultivations_Notes", key: "otherFields.Pests.Brassicas.ControlMeasures.PrimaryCultivations.Notes" },
    // ImprovedGrassland
    { label: "Pests_Grass_FritFly", key: "otherFields.Pests.ImprovedGrassland.FritFly" },
    { label: "Pests_Grass_Leatherjackets", key: "otherFields.Pests.ImprovedGrassland.Leatherjackets" },
    { label: "Pests_Grass_Wireworms", key: "otherFields.Pests.ImprovedGrassland.Wireworms" },
    { label: "Pests_Grass_Slugs", key: "otherFields.Pests.ImprovedGrassland.Slugs" },
    { label: "Pests_Grass_CloverWeevil", key: "otherFields.Pests.ImprovedGrassland.CloverWeevil" },
    { label: "Pests_Grass_CloverStemNematode", key: "otherFields.Pests.ImprovedGrassland.CloverStemNematode" },
    { label: "Pests_Grass_Measures_LongTermLeys_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.LongTermLeys.Status" },
    { label: "Pests_Grass_Measures_LongTermLeys_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.LongTermLeys.Notes" },
    { label: "Pests_Grass_Measures_SowingDate_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.SowingDate.Status" },
    { label: "Pests_Grass_Measures_SowingDate_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.SowingDate.Notes" },
    { label: "Pests_Grass_Measures_ExtraCultivations_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.ExtraCultivations.Status" },
    { label: "Pests_Grass_Measures_ExtraCultivations_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.ExtraCultivations.Notes" },
    { label: "Pests_Grass_Measures_LarvaeCountsInSoil_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.LarvaeCountsInSoil.Status" },
    { label: "Pests_Grass_Measures_LarvaeCountsInSoil_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.LarvaeCountsInSoil.Notes" },
    { label: "Pests_Grass_Measures_PestMonitoring_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.PestMonitoring.Status" },
    { label: "Pests_Grass_Measures_PestMonitoring_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.PestMonitoring.Notes" },
    { label: "Pests_Grass_Measures_RollingSoilPostPlanting_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.RollingSoilPostPlanting.Status" },
    { label: "Pests_Grass_Measures_RollingSoilPostPlanting_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.RollingSoilPostPlanting.Notes" },
    { label: "Pests_Grass_Measures_NarrowStemmedGrasses_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.NarrowStemmedGrasses.Status" },
    { label: "Pests_Grass_Measures_NarrowStemmedGrasses_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.NarrowStemmedGrasses.Notes" },
    { label: "Pests_Grass_Measures_FieldHistory_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.FieldHistory.Status" },
    { label: "Pests_Grass_Measures_FieldHistory_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.FieldHistory.Notes" },
    { label: "Pests_Grass_Measures_HygieneAndPrevention_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.HygieneAndPrevention.Status" },
    { label: "Pests_Grass_Measures_HygieneAndPrevention_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.HygieneAndPrevention.Notes" },
    { label: "Pests_Grass_Measures_NutrientManagement_Status", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.NutrientManagement.Status" },
    { label: "Pests_Grass_Measures_NutrientManagement_Notes", key: "otherFields.Pests.ImprovedGrassland.ControlMeasures.NutrientManagement.Notes" },
    // Maize
    { label: "Pests_Maize_Wireworm", key: "otherFields.Pests.Maize.Wireworm" },
    { label: "Pests_Maize_EuropeanCornBorer", key: "otherFields.Pests.Maize.EuropeanCornBorer" },
    { label: "Pests_Maize_Measures_FieldHistoryRotation_Status", key: "otherFields.Pests.Maize.ControlMeasures.FieldHistoryRotation.Status" },
    { label: "Pests_Maize_Measures_FieldHistoryRotation_Notes", key: "otherFields.Pests.Maize.ControlMeasures.FieldHistoryRotation.Notes" },
    { label: "Pests_Maize_Measures_SeedRate_Status", key: "otherFields.Pests.Maize.ControlMeasures.SeedRate.Status" },
    { label: "Pests_Maize_Measures_SeedRate_Notes", key: "otherFields.Pests.Maize.ControlMeasures.SeedRate.Notes" },
    { label: "Pests_Maize_Measures_Seedbed_Status", key: "otherFields.Pests.Maize.ControlMeasures.Seedbed.Status" },
    { label: "Pests_Maize_Measures_Seedbed_Notes", key: "otherFields.Pests.Maize.ControlMeasures.Seedbed.Notes" },
    { label: "Pests_Maize_Measures_LowRiskLocations_Status", key: "otherFields.Pests.Maize.ControlMeasures.LowRiskLocations.Status" },
    { label: "Pests_Maize_Measures_LowRiskLocations_Notes", key: "otherFields.Pests.Maize.ControlMeasures.LowRiskLocations.Notes" },
    // // OilseedRape
    { label: "Pests_OilseedRape_BrassicaPodMidge", key: "otherFields.Pests.OilseedRape.BrassicaPodMidge" },
    { label: "Pests_OilseedRape_CabbageRootFly", key: "otherFields.Pests.OilseedRape.CabbageRootFly" },
    { label: "Pests_OilseedRape_CabbageSeedWeevil", key: "otherFields.Pests.OilseedRape.CabbageSeedWeevil" },
    { label: "Pests_OilseedRape_CabbageStemFleaBeetle", key: "otherFields.Pests.OilseedRape.CabbageStemFleaBeetle" },
    { label: "Pests_OilseedRape_MealyCabbageAphid", key: "otherFields.Pests.OilseedRape.MealyCabbageAphid" },
    { label: "Pests_OilseedRape_PollenBeetle", key: "otherFields.Pests.OilseedRape.PollenBeetle" },
    { label: "Pests_OilseedRape_Slugs", key: "otherFields.Pests.OilseedRape.Slugs" },
    { label: "Pests_OilseedRape_TurnipSawfly", key: "otherFields.Pests.OilseedRape.TurnipSawfly" },
    { label: "Pests_OilseedRape_TuyvVectors", key: "otherFields.Pests.OilseedRape.TuyvVectors" },
    { label: "Pests_OilseedRape_Measures_DecisionSupport_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.DecisionSupport.Status" },
    { label: "Pests_OilseedRape_Measures_DecisionSupport_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.DecisionSupport.Notes" },
    { label: "Pests_OilseedRape_Measures_Defoliation_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.Defoliation.Status" },
    { label: "Pests_OilseedRape_Measures_Defoliation_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.Defoliation.Notes" },
    { label: "Pests_OilseedRape_Measures_RollingSoil_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.RollingSoil.Status" },
    { label: "Pests_OilseedRape_Measures_RollingSoil_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.RollingSoil.Notes" },
    { label: "Pests_OilseedRape_Measures_SeedRate_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.SeedRate.Status" },
    { label: "Pests_OilseedRape_Measures_SeedRate_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.SeedRate.Notes" },
    { label: "Pests_OilseedRape_Measures_SeedbedQuality_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.SeedbedQuality.Status" },
    { label: "Pests_OilseedRape_Measures_SeedbedQuality_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.SeedbedQuality.Notes" },
    { label: "Pests_OilseedRape_Measures_SowingDate_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.SowingDate.Status" },
    { label: "Pests_OilseedRape_Measures_SowingDate_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.SowingDate.Notes" },
    { label: "Pests_OilseedRape_Measures_SpatialSeparation_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.SpatialSeparation.Status" },
    { label: "Pests_OilseedRape_Measures_SpatialSeparation_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.SpatialSeparation.Notes" },
    { label: "Pests_OilseedRape_Measures_TrapCrops_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.TrapCrops.Status" },
    { label: "Pests_OilseedRape_Measures_TrapCrops_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.TrapCrops.Notes" },
    { label: "Pests_OilseedRape_Measures_VarietalChoice_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.VarietalChoice.Status" },
    { label: "Pests_OilseedRape_Measures_VarietalChoice_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.VarietalChoice.Notes" },
    { label: "Pests_OilseedRape_Measures_NonCroppedAreas_Status", key: "otherFields.Pests.OilseedRape.ControlMeasures.NonCroppedAreas.Status" },
    { label: "Pests_OilseedRape_Measures_NonCroppedAreas_Notes", key: "otherFields.Pests.OilseedRape.ControlMeasures.NonCroppedAreas.Notes" },
    // Peas
    { label: "Pests_Peas_PeaAphid", key: "otherFields.Pests.Peas.PeaAphid" },
    { label: "Pests_Peas_PeaMoth", key: "otherFields.Pests.Peas.PeaMoth" },
    { label: "Pests_Peas_Measures_DecisionSupport_Status", key: "otherFields.Pests.Peas.ControlMeasures.DecisionSupport.Status" },
    { label: "Pests_Peas_Measures_DecisionSupport_Notes", key: "otherFields.Pests.Peas.ControlMeasures.DecisionSupport.Notes" },
    { label: "Pests_Peas_Measures_LowRiskLocations_Status", key: "otherFields.Pests.Peas.ControlMeasures.LowRiskLocations.Status" },
    { label: "Pests_Peas_Measures_LowRiskLocations_Notes", key: "otherFields.Pests.Peas.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Pests_Peas_Measures_NonCroppedArea_Status", key: "otherFields.Pests.Peas.ControlMeasures.NonCroppedArea.Status" },
    { label: "Pests_Peas_Measures_NonCroppedArea_Notes", key: "otherFields.Pests.Peas.ControlMeasures.NonCroppedArea.Notes" },
    { label: "Pests_Peas_Measures_BioprotectantsNaturalSubstances_Status", key: "otherFields.Pests.Peas.ControlMeasures.BioprotectantsNaturalSubstances.Status" },
    { label: "Pests_Peas_Measures_BioprotectantsNaturalSubstances_Notes", key: "otherFields.Pests.Peas.ControlMeasures.BioprotectantsNaturalSubstances.Notes" },
    { label: "Pests_Peas_Measures_BioprotectantsSemiochemical_Status", key: "otherFields.Pests.Peas.ControlMeasures.BioprotectantsSemiochemical.Status" },
    { label: "Pests_Peas_Measures_BioprotectantsSemiochemical_Notes", key: "otherFields.Pests.Peas.ControlMeasures.BioprotectantsSemiochemical.Notes" },
    // Potatoes
    { label: "Pests_Potatoes_Cutworms", key: "otherFields.Pests.Potatoes.Cutworms" },
    { label: "Pests_Potatoes_FlnAndSpraing", key: "otherFields.Pests.Potatoes.FlnAndSpraing" },
    { label: "Pests_Potatoes_PotatoCystNematode", key: "otherFields.Pests.Potatoes.PotatoCystNematode" },
    { label: "Pests_Potatoes_Slugs", key: "otherFields.Pests.Potatoes.Slugs" },
    { label: "Pests_Potatoes_AphidBorne", key: "otherFields.Pests.Potatoes.AphidBorne" },
    { label: "Pests_Potatoes_Wireworm", key: "otherFields.Pests.Potatoes.Wireworm" },
    { label: "Pests_Potatoes_Measures_DecisionSupport_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.DecisionSupport.Status" },
    { label: "Pests_Potatoes_Measures_DecisionSupport_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.DecisionSupport.Notes" },
    { label: "Pests_Potatoes_Measures_FieldHistory_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.FieldHistory.Status" },
    { label: "Pests_Potatoes_Measures_FieldHistory_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.FieldHistory.Notes" },
    { label: "Pests_Potatoes_Measures_PrimaryCultivations_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Pests_Potatoes_Measures_PrimaryCultivations_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Pests_Potatoes_Measures_Biofumigation_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.Biofumigation.Status" },
    { label: "Pests_Potatoes_Measures_Biofumigation_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.Biofumigation.Notes" },
    { label: "Pests_Potatoes_Measures_SecondaryCultivations_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.SecondaryCultivations.Status" },
    { label: "Pests_Potatoes_Measures_SecondaryCultivations_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.SecondaryCultivations.Notes" },
    { label: "Pests_Potatoes_Measures_SeedTesting_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.SeedTesting.Status" },
    { label: "Pests_Potatoes_Measures_SeedTesting_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.SeedTesting.Notes" },
    { label: "Pests_Potatoes_Measures_BioprotectionLowRiskPPP_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.BioprotectionLowRiskPPP.Status" },
    { label: "Pests_Potatoes_Measures_BioprotectionLowRiskPP_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.BioprotectionLowRiskPPP.Notes" },
    { label: "Pests_Potatoes_Measures_LowRiskLocations_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.LowRiskLocations.Status" },
    { label: "Pests_Potatoes_Measures_LowRiskLocations_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Pests_Potatoes_Measures_ControlVolunteersAndWeeds_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.ControlVolunteersAndWeeds.Status" },
    { label: "Pests_Potatoes_Measures_ControlVolunteersAndWeeds_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.ControlVolunteersAndWeeds.Notes" },
    { label: "Pests_Potatoes_Measures_SpatialSeparation_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.SpatialSeparation.Status" },
    { label: "Pests_Potatoes_Measures_SpatialSeparation_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.SpatialSeparation.Notes" },
    { label: "Pests_Potatoes_Measures_Hygiene_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.Hygiene.Status" },
    { label: "Pests_Potatoes_Measures_Hygiene_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.Hygiene.Notes" },
    { label: "Pests_Potatoes_Measures_EarlyHarvest_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.EarlyHarvest.Status" },
    { label: "Pests_Potatoes_Measures_EarlyHarvest_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.EarlyHarvest.Notes" },
    { label: "Pests_Potatoes_Measures_ControlledIrrigation_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.ControlledIrrigation.Status" },
    { label: "Pests_Potatoes_Measures_ControlledIrrigation_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.ControlledIrrigation.Notes" },
    { label: "Pests_Potatoes_Measures_NonCroppedArea_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.NonCroppedArea.Status" },
    { label: "Pests_Potatoes_Measures_NonCroppedArea_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.NonCroppedArea.Notes" },
    { label: "Pests_Potatoes_Measures_TrapCrops_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.TrapCrops.Status" },
    { label: "Pests_Potatoes_Measures_TrapCrops_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.TrapCrops.Notes" },
    { label: "Pests_Potatoes_Measures_VarietalChoice_Status", key: "otherFields.Pests.Potatoes.ControlMeasures.VarietalChoice.Status" },
    { label: "Pests_Potatoes_Measures_VarietalChoice_Notes", key: "otherFields.Pests.Potatoes.ControlMeasures.VarietalChoice.Notes" },
    // SugarBeet
    { label: "Pests_SugarBeet_BeetCystNematode", key: "otherFields.Pests.SugarBeet.BeetCystNematode" },
    { label: "Pests_SugarBeet_VirusYellows", key: "otherFields.Pests.SugarBeet.VirusYellows" },
    { label: "Pests_SugarBeet_Wireworm", key: "otherFields.Pests.SugarBeet.Wireworm" },
    { label: "Pests_SugarBeet_Measures_DecisionSupport_Status", key: "otherFields.Pests.SugarBeet.ControlMeasures.DecisionSupport.Status" },
    { label: "Pests_SugarBeet_Measures_DecisionSupport_Notes", key: "otherFields.Pests.SugarBeet.ControlMeasures.DecisionSupport.Notes" },
    { label: "Pests_SugarBeet_Measures_FieldHistory_Status", key: "otherFields.Pests.SugarBeet.ControlMeasures.FieldHistory.Status" },
    { label: "Pests_SugarBeet_Measures_FieldHistory_Notes", key: "otherFields.Pests.SugarBeet.ControlMeasures.FieldHistory.Notes" },
    { label: "Pests_SugarBeet_Measures_Hygiene_Status", key: "otherFields.Pests.SugarBeet.ControlMeasures.Hygiene.Status" },
    { label: "Pests_SugarBeet_Measures_Hygiene_Notes", key: "otherFields.Pests.SugarBeet.ControlMeasures.Hygiene.Notes" },
    { label: "Pests_SugarBeet_Measures_SeedRate_Status", key: "otherFields.Pests.SugarBeet.ControlMeasures.SeedRate.Status" },
    { label: "Pests_SugarBeet_Measures_SeedRate_Notes", key: "otherFields.Pests.SugarBeet.ControlMeasures.SeedRate.Notes" },
    { label: "Pests_SugarBeet_Measures_LowRiskLocations_Status", key: "otherFields.Pests.SugarBeet.ControlMeasures.LowRiskLocations.Status" },
    { label: "Pests_SugarBeet_Measures_LowRiskLocations_Notes", key: "otherFields.Pests.SugarBeet.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Pests_SugarBeet_Measures_VarietyChoice_Status", key: "otherFields.Pests.SugarBeet.ControlMeasures.VarietyChoice.Status" },
    { label: "Pests_SugarBeet_Measures_VarietyChoice_Notes", key: "otherFields.Pests.SugarBeet.ControlMeasures.VarietyChoice.Notes" },
    { label: "Pests_SugarBeet_Measures_SoilAnalysis_Status", key: "otherFields.Pests.SugarBeet.ControlMeasures.SoilAnalysis.Status" },
    { label: "Pests_SugarBeet_Measures_SoilAnalysis_Notes", key: "otherFields.Pests.SugarBeet.ControlMeasures.SoilAnalysis.Notes" },
    { label: "Pests_SugarBeet_Measures_NonCroppedAreas_Status", key: "otherFields.Pests.SugarBeet.ControlMeasures.NonCroppedAreas.Status" },
    { label: "Pests_SugarBeet_Measures_NonCroppedAreas_Notes", key: "otherFields.Pests.SugarBeet.ControlMeasures.NonCroppedAreas.Notes" },
    // WinterBarley
    { label: "Pests_WinterBarley_BydvVectors", key: "otherFields.Pests.WinterBarley.BydvVectors" },
    { label: "Pests_WinterBarley_FritFly", key: "otherFields.Pests.WinterBarley.FritFly" },
    { label: "Pests_WinterBarley_GoutFly", key: "otherFields.Pests.WinterBarley.GoutFly" },
    { label: "Pests_WinterBarley_Leatherjackets", key: "otherFields.Pests.WinterBarley.Leatherjackets" },
    { label: "Pests_WinterBarley_SaddleGallMidge", key: "otherFields.Pests.WinterBarley.SaddleGallMidge" },
    { label: "Pests_WinterBarley_Slugs", key: "otherFields.Pests.WinterBarley.Slugs" },
    { label: "Pests_WinterBarley_WheatBulbFly", key: "otherFields.Pests.WinterBarley.WheatBulbFly" },
    { label: "Pests_WinterBarley_Wireworms", key: "otherFields.Pests.WinterBarley.Wireworms" },
    { label: "Pests_WinterBarley_Measures_DecisionSupport_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.DecisionSupport.Status" },
    { label: "Pests_WinterBarley_Measures_DecisionSupport_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.DecisionSupport.Notes" },
    { label: "Pests_WinterBarley_Measures_FieldHistory_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.FieldHistory.Status" },
    { label: "Pests_WinterBarley_Measures_FieldHistory_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.FieldHistory.Notes" },
    { label: "Pests_WinterBarley_Measures_PrimaryCultivations_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Pests_WinterBarley_Measures_PrimaryCultivations_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Pests_WinterBarley_Measures_RollingSoil_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.RollingSoil.Status" },
    { label: "Pests_WinterBarley_Measures_RollingSoil_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.RollingSoil.Notes" },
    { label: "Pests_WinterBarley_Measures_SecondaryCultivations_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.SecondaryCultivations.Status" },
    { label: "Pests_WinterBarley_Measures_SecondaryCultivations_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.SecondaryCultivations.Notes" },
    { label: "Pests_WinterBarley_Measures_SeedRate_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.SeedRate.Status" },
    { label: "Pests_WinterBarley_Measures_SeedRate_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.SeedRate.Notes" },
    { label: "Pests_WinterBarley_Measures_SeedTesting_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.SeedTesting.Status" },
    { label: "Pests_WinterBarley_Measures_SeedTesting_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.SeedTesting.Notes" },
    { label: "Pests_WinterBarley_Measures_SeedbedQuality_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.SeedbedQuality.Status" },
    { label: "Pests_WinterBarley_Measures_SeedbedQuality_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.SeedbedQuality.Notes" },
    { label: "Pests_WinterBarley_Measures_LowRiskLocations_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.LowRiskLocations.Status" },
    { label: "Pests_WinterBarley_Measures_LowRiskLocations_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Pests_WinterBarley_Measures_SowingDate_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.SowingDate.Status" },
    { label: "Pests_WinterBarley_Measures_SowingDate_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.SowingDate.Notes" },
    { label: "Pests_WinterBarley_Measures_SpatialSeparation_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.SpatialSeparation.Status" },
    { label: "Pests_WinterBarley_Measures_SpatialSeparation_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.SpatialSeparation.Notes" },
    { label: "Pests_WinterBarley_Measures_NonCroppedAreas_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.NonCroppedAreas.Status" },
    { label: "Pests_WinterBarley_Measures_NonCroppedAreas_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.NonCroppedAreas.Notes" },
    { label: "Pests_WinterBarley_Measures_VarietalChoice_Status", key: "otherFields.Pests.WinterBarley.ControlMeasures.VarietalChoice.Status" },
    { label: "Pests_WinterBarley_Measures_VarietalChoice_Notes", key: "otherFields.Pests.WinterBarley.ControlMeasures.VarietalChoice.Notes" },
    // WinterBeans
    { label: "Pests_WinterBeans_BeanWeevil", key: "otherFields.Pests.WinterBeans.BeanWeevil" },
    { label: "Pests_WinterBeans_BlackbeanAphid", key: "otherFields.Pests.WinterBeans.BlackbeanAphid" },
    { label: "Pests_WinterBeans_BruchidBeetle", key: "otherFields.Pests.WinterBeans.BruchidBeetle" },
    { label: "Pests_WinterBeans_Measures_DecisionSupport_Status", key: "otherFields.Pests.WinterBeans.ControlMeasures.DecisionSupport.Status" },
    { label: "Pests_WinterBeans_Measures_DecisionSupport_Notes", key: "otherFields.Pests.WinterBeans.ControlMeasures.DecisionSupport.Notes" },
    { label: "Pests_WinterBeans_Measures_LowRiskLocations_Status", key: "otherFields.Pests.WinterBeans.ControlMeasures.LowRiskLocations.Status" },
    { label: "Pests_WinterBeans_Measures_LowRiskLocations_Notes", key: "otherFields.Pests.WinterBeans.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Pests_WinterBeans_Measures_SowingDate_Status", key: "otherFields.Pests.WinterBeans.ControlMeasures.SowingDate.Status" },
    { label: "Pests_WinterBeans_Measures_SowingDate_Notes", key: "otherFields.Pests.WinterBeans.ControlMeasures.SowingDate.Notes" },
    { label: "Pests_WinterBeans_Measures_EarlyHarvest_Status", key: "otherFields.Pests.WinterBeans.ControlMeasures.EarlyHarvest.Status" },
    { label: "Pests_WinterBeans_Measures_EarlyHarvest_Notes", key: "otherFields.Pests.WinterBeans.ControlMeasures.EarlyHarvest.Notes" },
    // WinterOats
    { label: "Pests_WinterOats_BydvVectors", key: "otherFields.Pests.WinterOats.BydvVectors" },
    { label: "Pests_WinterOats_FritFly", key: "otherFields.Pests.WinterOats.FritFly" },
    { label: "Pests_WinterOats_Leatherjackets", key: "otherFields.Pests.WinterOats.Leatherjackets" },
    { label: "Pests_WinterOats_Slugs", key: "otherFields.Pests.WinterOats.Slugs" },
    { label: "Pests_WinterOats_Wireworms", key: "otherFields.Pests.WinterOats.Wireworms" },
    { label: "Pests_WinterOats_Measures_DecisionSupport_Status", key: "otherFields.Pests.WinterOats.ControlMeasures.DecisionSupport.Status" },
    { label: "Pests_WinterOats_Measures_DecisionSupport_Notes", key: "otherFields.Pests.WinterOats.ControlMeasures.DecisionSupport.Notes" },
    { label: "Pests_WinterOats_Measures_FieldHistory_Status", key: "otherFields.Pests.WinterOats.ControlMeasures.FieldHistory.Status" },
    { label: "Pests_WinterOats_Measures_FieldHistory_Notes", key: "otherFields.Pests.WinterOats.ControlMeasures.FieldHistory.Notes" },
    { label: "Pests_WinterOats_Measures_PrimaryCultivations_Status", key: "otherFields.Pests.WinterOats.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Pests_WinterOats_Measures_PrimaryCultivations_Notes", key: "otherFields.Pests.WinterOats.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Pests_WinterOats_Measures_RollingSoil_Status", key: "otherFields.Pests.WinterOats.ControlMeasures.RollingSoil.Status" },
    { label: "Pests_WinterOats_Measures_RollingSoil_Notes", key: "otherFields.Pests.WinterOats.ControlMeasures.RollingSoil.Notes" },
    { label: "Pests_WinterOats_Measures_SecondaryCultivations_Status", key: "otherFields.Pests.WinterOats.ControlMeasures.SecondaryCultivations.Status" },
    { label: "Pests_WinterOats_Measures_SecondaryCultivations_Notes", key: "otherFields.Pests.WinterOats.ControlMeasures.SecondaryCultivations.Notes" },
    { label: "Pests_WinterOats_Measures_SeedbedQuality_Status", key: "otherFields.Pests.WinterOats.ControlMeasures.SeedbedQuality.Status" },
    { label: "Pests_WinterOats_Measures_SeedbedQuality_Notes", key: "otherFields.Pests.WinterOats.ControlMeasures.SeedbedQuality.Notes" },
    { label: "Pests_WinterOats_Measures_SowingDate_Status", key: "otherFields.Pests.WinterOats.ControlMeasures.SowingDate.Status" },
    { label: "Pests_WinterOats_Measures_SowingDate_Notes", key: "otherFields.Pests.WinterOats.ControlMeasures.SowingDate.Notes" },
    { label: "Pests_WinterOats_Measures_NonCroppedAreas_Status", key: "otherFields.Pests.WinterOats.ControlMeasures.NonCroppedAreas.Status" },
    { label: "Pests_WinterOats_Measures_NonCroppedAreas_Notes", key: "otherFields.Pests.WinterOats.ControlMeasures.NonCroppedAreas.Notes" },
    { label: "Pests_WinterOats_Measures_VarietalChoice_Status", key: "otherFields.Pests.WinterOats.ControlMeasures.VarietalChoice.Status" },
    { label: "Pests_WinterOats_Measures_VarietalChoice_Notes", key: "otherFields.Pests.WinterOats.ControlMeasures.VarietalChoice.Notes" },
    // WinterWheat
    { label: "Pests_WinterWheat_BydvVectors", key: "otherFields.Pests.WinterWheat.BydvVectors" },
    { label: "Pests_WinterWheat_FritFly", key: "otherFields.Pests.WinterWheat.FritFly" },
    { label: "Pests_WinterWheat_GoutFly", key: "otherFields.Pests.WinterWheat.GoutFly" },
    { label: "Pests_WinterWheat_Leatherjackets", key: "otherFields.Pests.WinterWheat.Leatherjackets" },
    { label: "Pests_WinterWheat_OrangeWheatBlossomMidge", key: "otherFields.Pests.WinterWheat.OrangeWheatBlossomMidge" },
    { label: "Pests_WinterWheat_SaddleGallMidge", key: "otherFields.Pests.WinterWheat.SaddleGallMidge" },
    { label: "Pests_WinterWheat_Slugs", key: "otherFields.Pests.WinterWheat.Slugs" },
    { label: "Pests_WinterWheat_WheatBulbFly", key: "otherFields.Pests.WinterWheat.WheatBulbFly" },
    { label: "Pests_WinterWheat_Wireworms", key: "otherFields.Pests.WinterWheat.Wireworms" },
    { label: "Pests_WinterWheat_YellowWheatBlossomMidge", key: "otherFields.Pests.WinterWheat.YellowWheatBlossomMidge" },
    { label: "Pests_WinterWheat_Measures_DecisionSupport_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.DecisionSupport.Status" },
    { label: "Pests_WinterWheat_Measures_DecisionSupport_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.DecisionSupport.Notes" },
    { label: "Pests_WinterWheat_Measures_FieldHistory_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.FieldHistory.Status" },
    { label: "Pests_WinterWheat_Measures_FieldHistory_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.FieldHistory.Notes" },
    { label: "Pests_WinterWheat_Measures_PrimaryCultivations_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Pests_WinterWheat_Measures_PrimaryCultivations_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Pests_WinterWheat_Measures_RollingSoil_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.RollingSoil.Status" },
    { label: "Pests_WinterWheat_Measures_RollingSoil_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.RollingSoil.Notes" },
    { label: "Pests_WinterWheat_Measures_SecondaryCultivations_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.SecondaryCultivations.Status" },
    { label: "Pests_WinterWheat_Measures_SecondaryCultivations_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.SecondaryCultivations.Notes" },
    { label: "Pests_WinterWheat_Measures_SeedRate_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.SeedRate.Status" },
    { label: "Pests_WinterWheat_Measures_SeedRate_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.SeedRate.Notes" },
    { label: "Pests_WinterWheat_Measures_SeedbedQuality_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.SeedbedQuality.Status" },
    { label: "Pests_WinterWheat_Measures_SeedbedQuality_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.SeedbedQuality.Notes" },
    { label: "Pests_WinterWheat_Measures_LowRiskLocations_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.LowRiskLocations.Status" },
    { label: "Pests_WinterWheat_Measures_LowRiskLocations_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Pests_WinterWheat_Measures_SowingDate_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.SowingDate.Status" },
    { label: "Pests_WinterWheat_Measures_SowingDate_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.SowingDate.Notes" },
    { label: "Pests_WinterWheat_Measures_SpatialSeparation_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.SpatialSeparation.Status" },
    { label: "Pests_WinterWheat_Measures_SpatialSeparation_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.SpatialSeparation.Notes" },
    { label: "Pests_WinterWheat_Measures_NonCroppedAreas_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.NonCroppedAreas.Status" },
    { label: "Pests_WinterWheat_Measures_NonCroppedAreas_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.NonCroppedAreas.Notes" },
    { label: "Pests_WinterWheat_Measures_VarietalChoice_Status", key: "otherFields.Pests.WinterWheat.ControlMeasures.VarietalChoice.Status" },
    { label: "Pests_WinterWheat_Measures_VarietalChoice_Notes", key: "otherFields.Pests.WinterWheat.ControlMeasures.VarietalChoice.Notes" },
    //
    // Diseases
    //        
    // Apple
    { label: "Diseases_Apple_AppleScab", key: "otherFields.Diseases.Apple.AppleScab" },
    { label: "Diseases_Apple_Canker", key: "otherFields.Diseases.Apple.Canker" },
    { label: "Diseases_Apple_PowderyMildew", key: "otherFields.Diseases.Apple.PowderyMildew" },
    { label: "Diseases_Apple_ReplantDisease", key: "otherFields.Diseases.Apple.ReplantDisease" },
    { label: "Diseases_Apple_Measures_Biostimulants_Status", key: "otherFields.Diseases.Apple.ControlMeasures.Biostimulants.Status" },
    { label: "Diseases_Apple_Measures_Biostimulants_Notes", key: "otherFields.Diseases.Apple.ControlMeasures.Biostimulants.Notes" },
    { label: "Diseases_Apple_Measures_DecisionSupport_Status", key: "otherFields.Diseases.Apple.ControlMeasures.DecisionSupport.Status" },
    { label: "Diseases_Apple_Measures_DecisionSupport_Notes", key: "otherFields.Diseases.Apple.ControlMeasures.DecisionSupport.Notes" },
    { label: "Diseases_Apple_Measures_FieldHistory_Status", key: "otherFields.Diseases.Apple.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_Apple_Measures_FieldHistory_Notes", key: "otherFields.Diseases.Apple.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_Apple_Measures_NutrientManagement_Status", key: "otherFields.Diseases.Apple.ControlMeasures.NutrientManagement.Status" },
    { label: "Diseases_Apple_Measures_NutrientManagement_Notes", key: "otherFields.Diseases.Apple.ControlMeasures.NutrientManagement.Notes" },
    { label: "Diseases_Apple_Measures_OrganicAmendments_Status", key: "otherFields.Diseases.Apple.ControlMeasures.OrganicAmendments.Status" },
    { label: "Diseases_Apple_Measures_OrganicAmendments_Notes", key: "otherFields.Diseases.Apple.ControlMeasures.OrganicAmendments.Notes" },
    { label: "Diseases_Apple_Measures_CanopyManagement_Status", key: "otherFields.Diseases.Apple.ControlMeasures.CanopyManagement.Status" },
    { label: "Diseases_Apple_Measures_CanopyManagement_Notes", key: "otherFields.Diseases.Apple.ControlMeasures.CanopyManagement.Notes" },
    { label: "Diseases_Apple_Measures_LowriskLocations_Status", key: "otherFields.Diseases.Apple.ControlMeasures.LowriskLocations.Status" },
    { label: "Diseases_Apple_Measures_LowriskLocations_Notes", key: "otherFields.Diseases.Apple.ControlMeasures.LowriskLocations.Notes" },
    { label: "Diseases_Apple_Measures_VarietyChoice_Status", key: "otherFields.Diseases.Apple.ControlMeasures.VarietyChoice.Status" },
    { label: "Diseases_Apple_Measures_VarietyChoice_Notes", key: "otherFields.Diseases.Apple.ControlMeasures.VarietyChoice.Notes" },
    { label: "Diseases_Apple_MeasuresHygieneAndPrevention__Status", key: "otherFields.Diseases.Apple.ControlMeasures.HygieneAndPrevention.Status" },
    { label: "Diseases_Apple_Measures_HygieneAndPrevention_Notes", key: "otherFields.Diseases.Apple.ControlMeasures.HygieneAndPrevention.Notes" },
    // Brassicas
    { label: "Diseases_Brassicas_DarkLeafSpot", key: "otherFields.Diseases.Brassicas.DarkLeafSpot" },
    { label: "Diseases_Brassicas_DownyMildew", key: "otherFields.Diseases.Brassicas.DownyMildew" },
    { label: "Diseases_Brassicas_LightLeafSpot", key: "otherFields.Diseases.Brassicas.LightLeafSpot" },
    { label: "Diseases_Brassicas_Ringspot", key: "otherFields.Diseases.Brassicas.Ringspot" },
    { label: "Diseases_Brassicas_Xanthomonas", key: "otherFields.Diseases.Brassicas.Xanthomonas" },
    { label: "Diseases_Brassicas_Measures_DecisionSupport_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.DecisionSupport.Status" },
    { label: "Diseases_Brassicas_Measures_DecisionSupport_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.DecisionSupport.Notes" },
    { label: "Diseases_Brassicas_Measures_EarlyProductionUnderCropCovers_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.EarlyProductionUnderCropCovers.Status" },
    { label: "Diseases_Brassicas_Measures_EarlyProductionUnderCropCovers_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.EarlyProductionUnderCropCovers.Notes" },
    { label: "Diseases_Brassicas_Measures_SoilManagement_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.SoilManagement.Status" },
    { label: "Diseases_Brassicas_Measures_SoilManagement_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.SoilManagement.Notes" },
    { label: "Diseases_Brassicas_Measures_FieldHistory_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_Brassicas_Measures_FieldHistory_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_Brassicas_Measures_Hygiene_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.Hygiene.Status" },
    { label: "Diseases_Brassicas_Measures_Hygiene_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.Hygiene.Notes" },
    { label: "Diseases_Brassicas_Measures_PrecisionIrrigation_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.PrecisionIrrigation.Status" },
    { label: "Diseases_Brassicas_Measures_PrecisionIrrigation_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.PrecisionIrrigation.Notes" },
    { label: "Diseases_Brassicas_Measures_SeedTesting_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.SeedTesting.Status" },
    { label: "Diseases_Brassicas_Measures_SeedTesting_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.SeedTesting.Notes" },
    { label: "Diseases_Brassicas_Measures_LowRiskLocations_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.LowRiskLocations.Status" },
    { label: "Diseases_Brassicas_Measures_LowRiskLocations_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Diseases_Brassicas_Measures_VarietyChoice_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.VarietyChoice.Status" },
    { label: "Diseases_Brassicas_Measures_VarietyChoice_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.VarietyChoice.Notes" },
    { label: "Diseases_Brassicas_Measures_ControlVolunteers_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.ControlVolunteers.Status" },
    { label: "Diseases_Brassicas_Measures_ControlVolunteers_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.ControlVolunteers.Notes" },
    { label: "Diseases_Brassicas_Measures_PrimaryCultivations	_Status", key: "otherFields.Diseases.Brassicas.ControlMeasures.PrimaryCultivations	.Status" },
    { label: "Diseases_Brassicas_Measures_PrimaryCultivations	_Notes", key: "otherFields.Diseases.Brassicas.ControlMeasures.PrimaryCultivations	.Notes" },
    // ImprovedGrassland
    { label: "Diseases_Grass_LeafBlotch", key: "otherFields.Diseases.ImprovedGrassland.LeafBlotch" },
    { label: "Diseases_Grass_Mildew", key: "otherFields.Diseases.ImprovedGrassland.Mildew" },
    { label: "Diseases_Grass_CrownRust", key: "otherFields.Diseases.ImprovedGrassland.CrownRust" },
    { label: "Diseases_Grass_DrechsleraLeafDisease", key: "otherFields.Diseases.ImprovedGrassland.DrechsleraLeafDisease" },
    { label: "Diseases_Grass_VirusDiseases", key: "otherFields.Diseases.ImprovedGrassland.VirusDiseases" },
    { label: "Diseases_Grass_CloverRot", key: "otherFields.Diseases.ImprovedGrassland.CloverRot" },
    { label: "Diseases_Grass_LeafDiseases", key: "otherFields.Diseases.ImprovedGrassland.LeafDiseases" },
    { label: "Diseases_Grass_VerticilliumWilt", key: "otherFields.Diseases.ImprovedGrassland.VerticilliumWilt" },
    { label: "Diseases_Grass_Measures_PloughingInCropResidue_Status", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.PloughingInCropResidue.Status" },
    { label: "Diseases_Grass_Measures_PloughingInCropResidue_Notes", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.PloughingInCropResidue.Notes" },
    { label: "Diseases_Grass_Measures_ResistanceVariables_Status", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.ResistanceVariables.Status" },
    { label: "Diseases_Grass_Measures_ResistanceVariables_Notes", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.ResistanceVariables.Notes" },
    { label: "Diseases_Grass_Measures_ControlVolunteersAndWeeds_Status", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.ControlVolunteersAndWeeds.Status" },
    { label: "Diseases_Grass_Measures_ControlVolunteersAndWeeds_Notes", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.ControlVolunteersAndWeeds.Notes" },
    { label: "Diseases_Grass_Measures_NutrientManagement_Status", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.NutrientManagement.Status" },
    { label: "Diseases_Grass_Measures_NutrientManagement_Notes", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.NutrientManagement.Notes" },
    { label: "Diseases_Grass_Measures_RegularGrazing_Status", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.RegularGrazing.Status" },
    { label: "Diseases_Grass_Measures_RegularGrazing_Notes", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.RegularGrazing.Notes" },
    { label: "Diseases_Grass_Measures_EarlyHarvest_Status", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.EarlyHarvest.Status" },
    { label: "Diseases_Grass_Measures_EarlyHarvest_Notes", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.EarlyHarvest.Notes" },
    { label: "Diseases_Grass_Measures_HygieneAndPrevention_Status", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.HygieneAndPrevention.Status" },
    { label: "Diseases_Grass_Measures_HygieneAndPrevention_Notes", key: "otherFields.Diseases.ImprovedGrassland.ControlMeasures.HygieneAndPrevention.Notes" },
    // Maize
    { label: "Diseases_Maize_Eyespot", key: "otherFields.Diseases.Maize.Eyespot" },
    { label: "Diseases_Maize_Fusarium", key: "otherFields.Diseases.Maize.Fusarium" },
    { label: "Diseases_Maize_Measures_FieldHistoryRotation_Status", key: "otherFields.Diseases.Maize.ControlMeasures.FieldHistoryRotation.Status" },
    { label: "Diseases_Maize_Measures_FieldHistoryRotation_Notes", key: "otherFields.Diseases.Maize.ControlMeasures.FieldHistoryRotation.Notes" },
    { label: "Diseases_Maize_Measures_PrimaryCultivations_Status", key: "otherFields.Diseases.Maize.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Diseases_Maize_Measures_PrimaryCultivations_Notes", key: "otherFields.Diseases.Maize.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Diseases_Maize_Measures_VarietyChoice_Status", key: "otherFields.Diseases.Maize.ControlMeasures.VarietyChoice.Status" },
    { label: "Diseases_Maize_Measures_VarietyChoice_Notes", key: "otherFields.Diseases.Maize.ControlMeasures.VarietyChoice.Notes" },
    // OilseedRape
    { label: "Diseases_OilseedRape_Clubroot", key: "otherFields.Diseases.OilseedRape.Clubroot" },
    { label: "Diseases_OilseedRape_LightLeafSpot", key: "otherFields.Diseases.OilseedRape.LightLeafSpot" },
    { label: "Diseases_OilseedRape_PhomaStemCanker", key: "otherFields.Diseases.OilseedRape.PhomaStemCanker" },
    { label: "Diseases_OilseedRape_SclerotiniaStemRot", key: "otherFields.Diseases.OilseedRape.SclerotiniaStemRot" },
    { label: "Diseases_OilseedRape_VerticilliumStemStripe", key: "otherFields.Diseases.OilseedRape.VerticilliumStemStripe" },
    { label: "Diseases_OilseedRape_Measures_ControlVolunteers_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.ControlVolunteers.Status" },
    { label: "Diseases_OilseedRape_Measures_ControlVolunteers_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.ControlVolunteers.Notes" },
    { label: "Diseases_OilseedRape_Measures_DecisionSupport_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.DecisionSupport.Status" },
    { label: "Diseases_OilseedRape_Measures_DecisionSupport_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.DecisionSupport.Notes" },
    { label: "Diseases_OilseedRape_Measures_FieldHistory_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_OilseedRape_Measures_FieldHistory_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_OilseedRape_Measures_GoodDrainage_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.GoodDrainage.Status" },
    { label: "Diseases_OilseedRape_Measures_GoodDrainage_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.GoodDrainage.Notes" },
    { label: "Diseases_OilseedRape_Measures_Hygiene_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.Hygiene.Status" },
    { label: "Diseases_OilseedRape_Measures_Hygiene_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.Hygiene.Notes" },
    { label: "Diseases_OilseedRape_Measures_Lime_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.Lime.Status" },
    { label: "Diseases_OilseedRape_Measures_Lime_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.Lime.Notes" },
    { label: "Diseases_OilseedRape_Measures_PrimaryCultivations_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Diseases_OilseedRape_Measures_PrimaryCultivations_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Diseases_OilseedRape_Measures_LowRiskLocations_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.LowRiskLocations.Status" },
    { label: "Diseases_OilseedRape_Measures_LowRiskLocations_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Diseases_OilseedRape_Measures_SowingDate_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.SowingDate.Status" },
    { label: "Diseases_OilseedRape_Measures_SowingDate_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.SowingDate.Notes" },
    { label: "Diseases_OilseedRape_Measures_SpatialSeparation_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.SpatialSeparation.Status" },
    { label: "Diseases_OilseedRape_Measures_SpatialSeparation_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.SpatialSeparation.Notes" },
    { label: "Diseases_OilseedRape_Measures_VarietalChoice_Status", key: "otherFields.Diseases.OilseedRape.ControlMeasures.VarietalChoice.Status" },
    { label: "Diseases_OilseedRape_Measures_VarietalChoice_Notes", key: "otherFields.Diseases.OilseedRape.ControlMeasures.VarietalChoice.Notes" },
    // Peas
    { label: "Diseases_Peas_Botrytis", key: "otherFields.Diseases.Peas.Botrytis" },
    { label: "Diseases_Peas_DownyMildew", key: "otherFields.Diseases.Peas.DownyMildew" },
    { label: "Diseases_Peas_LeafSpot", key: "otherFields.Diseases.Peas.LeafSpot" },
    { label: "Diseases_Peas_Measures_FieldHistory_Status", key: "otherFields.Diseases.Peas.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_Peas_Measures_FieldHistory_Notes", key: "otherFields.Diseases.Peas.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_Peas_Measures_PrimaryCultivations_Status", key: "otherFields.Diseases.Peas.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Diseases_Peas_Measures_PrimaryCultivations_Notes", key: "otherFields.Diseases.Peas.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Diseases_Peas_Measures_SeedTesting_Status", key: "otherFields.Diseases.Peas.ControlMeasures.SeedTesting.Status" },
    { label: "Diseases_Peas_Measures_SeedTesting_Notes", key: "otherFields.Diseases.Peas.ControlMeasures.SeedTesting.Notes" },
    { label: "Diseases_Peas_Measures_NutrientManagement_Status", key: "otherFields.Diseases.Peas.ControlMeasures.NutrientManagement.Status" },
    { label: "Diseases_Peas_Measures_NutrientManagement_Notes", key: "otherFields.Diseases.Peas.ControlMeasures.NutrientManagement.Notes" },
    { label: "Diseases_Peas_Measures_VarietalChoice_Status", key: "otherFields.Diseases.Peas.ControlMeasures.VarietalChoice.Status" },
    { label: "Diseases_Peas_Measures_VarietalChoice_Notes", key: "otherFields.Diseases.Peas.ControlMeasures.VarietalChoice.Notes" },
    // Potatoes
    { label: "Diseases_Potatoes_BlackDot", key: "otherFields.Diseases.Potatoes.BlackDot" },
    { label: "Diseases_Potatoes_Blackleg", key: "otherFields.Diseases.Potatoes.Blackleg" },
    { label: "Diseases_Potatoes_CommonScab", key: "otherFields.Diseases.Potatoes.CommonScab" },
    { label: "Diseases_Potatoes_DryRot", key: "otherFields.Diseases.Potatoes.DryRot" },
    { label: "Diseases_Potatoes_EarlyBlight", key: "otherFields.Diseases.Potatoes.EarlyBlight" },
    { label: "Diseases_Potatoes_Gangrene", key: "otherFields.Diseases.Potatoes.Gangrene" },
    { label: "Diseases_Potatoes_LateBlight", key: "otherFields.Diseases.Potatoes.LateBlight" },
    { label: "Diseases_Potatoes_PowderyScab", key: "otherFields.Diseases.Potatoes.PowderyScab" },
    { label: "Diseases_Potatoes_SilverScurf", key: "otherFields.Diseases.Potatoes.SilverScurf" },
    { label: "Diseases_Potatoes_StemCankerAndBlackScurf", key: "otherFields.Diseases.Potatoes.StemCankerAndBlackScurf" },
    { label: "Diseases_Potatoes_StorageDiseases", key: "otherFields.Diseases.Potatoes.StorageDiseases" },
    { label: "Diseases_Potatoes_Viruses", key: "otherFields.Diseases.Potatoes.Viruses" },
    { label: "Diseases_Potatoes_Measures_DecisionSupport_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.DecisionSupport.Status" },
    { label: "Diseases_Potatoes_Measures_DecisionSupport_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.DecisionSupport.Notes" },
    { label: "Diseases_Potatoes_Measures_FieldHistory_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_Potatoes_Measures_FieldHistory_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_Potatoes_Measures_PrimaryCultivations_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Diseases_Potatoes_Measures_PrimaryCultivations_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Diseases_Potatoes_Measures_Biofumigation_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.Biofumigation.Status" },
    { label: "Diseases_Potatoes_Measures_Biofumigation_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.Biofumigation.Notes" },
    { label: "Diseases_Potatoes_Measures_SecondaryCultivations_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.SecondaryCultivations.Status" },
    { label: "Diseases_Potatoes_Measures_SecondaryCultivations_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.SecondaryCultivations.Notes" },
    { label: "Diseases_Potatoes_Measures_SeedTesting_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.SeedTesting.Status" },
    { label: "Diseases_Potatoes_Measures_SeedTesting_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.SeedTesting.Notes" },
    { label: "Diseases_Potatoes_Measures_SeedbedQuality_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.SeedbedQuality.Status" },
    { label: "Diseases_Potatoes_Measures_SeedbedQuality_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.SeedbedQuality.Notes" },
    { label: "Diseases_Potatoes_Measures_NutrientManagement_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.NutrientManagement.Status" },
    { label: "Diseases_Potatoes_Measures_NutrientManagement_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.NutrientManagement.Notes" },
    { label: "Diseases_Potatoes_Measures_GoodDrainage_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.GoodDrainage.Status" },
    { label: "Diseases_Potatoes_Measures_GoodDrainage_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.GoodDrainage.Notes" },
    { label: "Diseases_Potatoes_Measures_LowRiskLocations_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.LowRiskLocations.Status" },
    { label: "Diseases_Potatoes_Measures_LowRiskLocations_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Diseases_Potatoes_Measures_ControlVolunteersAndWeeds_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.ControlVolunteersAndWeeds.Status" },
    { label: "Diseases_Potatoes_Measures_ControlVolunteersAndWeeds_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.ControlVolunteersAndWeeds.Notes" },
    { label: "Diseases_Potatoes_Measures_SpatialSeparation_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.SpatialSeparation.Status" },
    { label: "Diseases_Potatoes_Measures_SpatialSeparation_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.SpatialSeparation.Notes" },
    { label: "Diseases_Potatoes_Measures_Hygiene_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.Hygiene.Status" },
    { label: "Diseases_Potatoes_Measures_Hygiene_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.Hygiene.Notes" },
    { label: "Diseases_Potatoes_Measures_AppropriateHarvest_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.AppropriateHarvest.Status" },
    { label: "Diseases_Potatoes_Measures_AppropriateHarvest_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.AppropriateHarvest.Notes" },
    { label: "Diseases_Potatoes_Measures_SowingDate_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.SowingDate.Status" },
    { label: "Diseases_Potatoes_Measures_SowingDate_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.SowingDate.Notes" },
    { label: "Diseases_Potatoes_Measures_VarietalChoice_Status", key: "otherFields.Diseases.Potatoes.ControlMeasures.VarietalChoice.Status" },
    { label: "Diseases_Potatoes_Measures_VarietalChoice_Notes", key: "otherFields.Diseases.Potatoes.ControlMeasures.VarietalChoice.Notes" },
    // SugarBeet
    { label: "Diseases_SugarBeet_Cercospera", key: "otherFields.Diseases.SugarBeet.Cercospera" },
    { label: "Diseases_SugarBeet_Mildew", key: "otherFields.Diseases.SugarBeet.Mildew" },
    { label: "Diseases_SugarBeet_Rhizoctonia", key: "otherFields.Diseases.SugarBeet.Rhizoctonia" },
    { label: "Diseases_SugarBeet_Rhizomania", key: "otherFields.Diseases.SugarBeet.Rhizomania" },
    { label: "Diseases_SugarBeet_Rust", key: "otherFields.Diseases.SugarBeet.Rust" },
    { label: "Diseases_SugarBeet_VioletRootRot", key: "otherFields.Diseases.SugarBeet.VioletRootRot" },
    { label: "Diseases_SugarBeet_Measures_DecisionSupport_Status", key: "otherFields.Diseases.SugarBeet.ControlMeasures.DecisionSupport.Status" },
    { label: "Diseases_SugarBeet_Measures_DecisionSupport_Notes", key: "otherFields.Diseases.SugarBeet.ControlMeasures.DecisionSupport.Notes" },
    { label: "Diseases_SugarBeet_Measures_FieldHistory_Status", key: "otherFields.Diseases.SugarBeet.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_SugarBeet_Measures_FieldHistory_Notes", key: "otherFields.Diseases.SugarBeet.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_SugarBeet_Measures_Hygiene_Status", key: "otherFields.Diseases.SugarBeet.ControlMeasures.Hygiene.Status" },
    { label: "Diseases_SugarBeet_Measures_Hygiene_Notes", key: "otherFields.Diseases.SugarBeet.ControlMeasures.Hygiene.Notes" },
    { label: "Diseases_SugarBeet_Measures_LowRiskLocations_Status", key: "otherFields.Diseases.SugarBeet.ControlMeasures.LowRiskLocations.Status" },
    { label: "Diseases_SugarBeet_Measures_LowRiskLocations_Notes", key: "otherFields.Diseases.SugarBeet.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Diseases_SugarBeet_Measures_VarietyChoice_Status", key: "otherFields.Diseases.SugarBeet.ControlMeasures.VarietyChoice.Status" },
    { label: "Diseases_SugarBeet_Measures_VarietyChoice_Notes", key: "otherFields.Diseases.SugarBeet.ControlMeasures.VarietyChoice.Notes" },
    // WinterBarley
    { label: "Diseases_WinterBarley_BrownRust", key: "otherFields.Diseases.WinterBarley.BrownRust" },
    { label: "Diseases_WinterBarley_EarBlight", key: "otherFields.Diseases.WinterBarley.EarBlight" },
    { label: "Diseases_WinterBarley_Eyespot", key: "otherFields.Diseases.WinterBarley.Eyespot" },
    { label: "Diseases_WinterBarley_LeafAndGlumeBlotch", key: "otherFields.Diseases.WinterBarley.LeafAndGlumeBlotch" },
    { label: "Diseases_WinterBarley_Mildew", key: "otherFields.Diseases.WinterBarley.Mildew" },
    { label: "Diseases_WinterBarley_Ramularia", key: "otherFields.Diseases.WinterBarley.Ramularia" },
    { label: "Diseases_WinterBarley_SeedBorneDiseases", key: "otherFields.Diseases.WinterBarley.SeedBorneDiseases" },
    { label: "Diseases_WinterBarley_TakeAll", key: "otherFields.Diseases.WinterBarley.TakeAll" },
    { label: "Diseases_WinterBarley_YellowRust", key: "otherFields.Diseases.WinterBarley.YellowRust" },
    { label: "Diseases_WinterBarley_Measures_ControlVolunteers_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.ControlVolunteers.Status" },
    { label: "Diseases_WinterBarley_Measures_ControlVolunteers_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.ControlVolunteers.Notes" },
    { label: "Diseases_WinterBarley_Measures_DecisionSupport_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.DecisionSupport.Status" },
    { label: "Diseases_WinterBarley_Measures_DecisionSupport_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.DecisionSupport.Notes" },
    { label: "Diseases_WinterBarley_Measures_FieldHistory_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_WinterBarley_Measures_FieldHistory_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_WinterBarley_Measures_Hygiene_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.Hygiene.Status" },
    { label: "Diseases_WinterBarley_Measures_Hygiene_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.Hygiene.Notes" },
    { label: "Diseases_WinterBarley_Measures_NutrientManagement_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.NutrientManagement.Status" },
    { label: "Diseases_WinterBarley_Measures_NutrientManagement_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.NutrientManagement.Notes" },
    { label: "Diseases_WinterBarley_Measures_PrimaryCultivations_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Diseases_WinterBarley_Measures_PrimaryCultivations_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Diseases_WinterBarley_Measures_SeedTesting_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.SeedTesting.Status" },
    { label: "Diseases_WinterBarley_Measures_SeedTesting_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.SeedTesting.Notes" },
    { label: "Diseases_WinterBarley_Measures_SeedbedQuality_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.SeedbedQuality.Status" },
    { label: "Diseases_WinterBarley_Measures_SeedbedQuality_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.SeedbedQuality.Notes" },
    { label: "Diseases_WinterBarley_Measures_LowRiskLocations_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.LowRiskLocations.Status" },
    { label: "Diseases_WinterBarley_Measures_LowRiskLocations_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Diseases_WinterBarley_Measures_SowingDate_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.SowingDate.Status" },
    { label: "Diseases_WinterBarley_Measures_SowingDate_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.SowingDate.Notes" },
    { label: "Diseases_WinterBarley_Measures_VarietalChoice_Status", key: "otherFields.Diseases.WinterBarley.ControlMeasures.VarietalChoice.Status" },
    { label: "Diseases_WinterBarley_Measures_VarietalChoice_Notes", key: "otherFields.Diseases.WinterBarley.ControlMeasures.VarietalChoice.Notes" },
    // WinterBeans
    { label: "Diseases_WinterBeans_Botrytis", key: "otherFields.Diseases.WinterBeans.Botrytis" },
    { label: "Diseases_WinterBeans_Rust", key: "otherFields.Diseases.WinterBeans.Rust" },
    { label: "Diseases_WinterBeans_Sclerotinia", key: "otherFields.Diseases.WinterBeans.Sclerotinia" },
    { label: "Diseases_WinterBeans_Measures_ControlVolunteersAndWeeds_Status", key: "otherFields.Diseases.WinterBeans.ControlMeasures.ControlVolunteersAndWeeds.Status" },
    { label: "Diseases_WinterBeans_Measures_ControlVolunteersAndWeeds_Notes", key: "otherFields.Diseases.WinterBeans.ControlMeasures.ControlVolunteersAndWeeds.Notes" },
    { label: "Diseases_WinterBeans_Measures_DecisionSupport_Status", key: "otherFields.Diseases.WinterBeans.ControlMeasures.DecisionSupport.Status" },
    { label: "Diseases_WinterBeans_Measures_DecisionSupport_Notes", key: "otherFields.Diseases.WinterBeans.ControlMeasures.DecisionSupport.Notes" },
    { label: "Diseases_WinterBeans_Measures_FieldHistory_Status", key: "otherFields.Diseases.WinterBeans.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_WinterBeans_Measures_FieldHistory_Notes", key: "otherFields.Diseases.WinterBeans.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_WinterBeans_Measures_MicrobialBioprotectants_Status", key: "otherFields.Diseases.WinterBeans.ControlMeasures.MicrobialBioprotectants.Status" },
    { label: "Diseases_WinterBeans_Measures_MicrobialBioprotectants_Notes", key: "otherFields.Diseases.WinterBeans.ControlMeasures.MicrobialBioprotectants.Notes" },
    { label: "Diseases_WinterBeans_Measures_NutrientManagement_Status", key: "otherFields.Diseases.WinterBeans.ControlMeasures.NutrientManagement.Status" },
    { label: "Diseases_WinterBeans_Measures_NutrientManagement_Notes", key: "otherFields.Diseases.WinterBeans.ControlMeasures.NutrientManagement.Notes" },
    { label: "Diseases_WinterBeans_Measures_PrimaryCultivations_Status", key: "otherFields.Diseases.WinterBeans.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Diseases_WinterBeans_Measures_PrimaryCultivations_Notes", key: "otherFields.Diseases.WinterBeans.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Diseases_WinterBeans_Measures_VarietalChoice_Status", key: "otherFields.Diseases.WinterBeans.ControlMeasures.VarietalChoice.Status" },
    { label: "Diseases_WinterBeans_Measures_VarietalChoice_Notes", key: "otherFields.Diseases.WinterBeans.ControlMeasures.VarietalChoice.Notes" },
    // WinterOats
    { label: "Diseases_WinterOats_EarBlight", key: "otherFields.Diseases.WinterOats.EarBlight" },
    { label: "Diseases_WinterOats_Eyespot", key: "otherFields.Diseases.WinterOats.Eyespot" },
    { label: "Diseases_WinterOats_Mildew", key: "otherFields.Diseases.WinterOats.Mildew" },
    { label: "Diseases_WinterOats_SeedBorneDiseases", key: "otherFields.Diseases.WinterOats.SeedBorneDiseases" },
    { label: "Diseases_WinterOats_Measures_ControlVolunteers_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.ControlVolunteers.Status" },
    { label: "Diseases_WinterOats_Measures_ControlVolunteers_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.ControlVolunteers.Notes" },
    { label: "Diseases_WinterOats_Measures_DecisionSupport_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.DecisionSupport.Status" },
    { label: "Diseases_WinterOats_Measures_DecisionSupport_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.DecisionSupport.Notes" },
    { label: "Diseases_WinterOats_Measures_FieldHistory_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_WinterOats_Measures_FieldHistory_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_WinterOats_Measures_Hygiene_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.Hygiene.Status" },
    { label: "Diseases_WinterOats_Measures_Hygiene_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.Hygiene.Notes" },
    { label: "Diseases_WinterOats_Measures_NutrientManagement_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.NutrientManagement.Status" },
    { label: "Diseases_WinterOats_Measures_NutrientManagement_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.NutrientManagement.Notes" },
    { label: "Diseases_WinterOats_Measures_PrimaryCultivations_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Diseases_WinterOats_Measures_PrimaryCultivations_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Diseases_WinterOats_Measures_SeedTesting_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.SeedTesting.Status" },
    { label: "Diseases_WinterOats_Measures_SeedTesting_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.SeedTesting.Notes" },
    { label: "Diseases_WinterOats_Measures_LowRiskLocations_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.LowRiskLocations.Status" },
    { label: "Diseases_WinterOats_Measures_LowRiskLocations_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Diseases_WinterOats_Measures_SowingDate_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.SowingDate.Status" },
    { label: "Diseases_WinterOats_Measures_SowingDate_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.SowingDate.Notes" },
    { label: "Diseases_WinterOats_Measures_VarietalChoice_Status", key: "otherFields.Diseases.WinterOats.ControlMeasures.VarietalChoice.Status" },
    { label: "Diseases_WinterOats_Measures_VarietalChoice_Notes", key: "otherFields.Diseases.WinterOats.ControlMeasures.VarietalChoice.Notes" },
    // WinterWheat
    { label: "Diseases_WinterWheat_BrownRust", key: "otherFields.Diseases.WinterWheat.BrownRust" },
    { label: "Diseases_WinterWheat_EarBlight", key: "otherFields.Diseases.WinterWheat.EarBlight" },
    { label: "Diseases_WinterWheat_Eyespot", key: "otherFields.Diseases.WinterWheat.Eyespot" },
    { label: "Diseases_WinterWheat_LeafAndGlumeBlotch", key: "otherFields.Diseases.WinterWheat.LeafAndGlumeBlotch" },
    { label: "Diseases_WinterWheat_Mildew", key: "otherFields.Diseases.WinterWheat.Mildew" },
    { label: "Diseases_WinterWheat_SeedBorneDiseases", key: "otherFields.Diseases.WinterWheat.SeedBorneDiseases" },
    { label: "Diseases_WinterWheat_Septoria", key: "otherFields.Diseases.WinterWheat.Septoria" },
    { label: "Diseases_WinterWheat_TakeAll", key: "otherFields.Diseases.WinterWheat.TakeAll" },
    { label: "Diseases_WinterWheat_YellowRust", key: "otherFields.Diseases.WinterWheat.YellowRust" },
    { label: "Diseases_WinterWheat_Measures_ControlVolunteers_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.ControlVolunteers.Status" },
    { label: "Diseases_WinterWheat_Measures_ControlVolunteers_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.ControlVolunteers.Notes" },
    { label: "Diseases_WinterWheat_Measures_DecisionSupport_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.DecisionSupport.Status" },
    { label: "Diseases_WinterWheat_Measures_DecisionSupport_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.DecisionSupport.Notes" },
    { label: "Diseases_WinterWheat_Measures_FieldHistory_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.FieldHistory.Status" },
    { label: "Diseases_WinterWheat_Measures_FieldHistory_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.FieldHistory.Notes" },
    { label: "Diseases_WinterWheat_Measures_Hygiene_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.Hygiene.Status" },
    { label: "Diseases_WinterWheat_Measures_Hygiene_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.Hygiene.Notes" },
    { label: "Diseases_WinterWheat_Measures_NutrientManagement_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.NutrientManagement.Status" },
    { label: "Diseases_WinterWheat_Measures_NutrientManagement_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.NutrientManagement.Notes" },
    { label: "Diseases_WinterWheat_Measures_PrimaryCultivations_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.PrimaryCultivations.Status" },
    { label: "Diseases_WinterWheat_Measures_PrimaryCultivations_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.PrimaryCultivations.Notes" },
    { label: "Diseases_WinterWheat_Measures_SeedTesting_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.SeedTesting.Status" },
    { label: "Diseases_WinterWheat_Measures_SeedTesting_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.SeedTesting.Notes" },
    { label: "Diseases_WinterWheat_Measures_SeedbedQuality_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.SeedbedQuality.Status" },
    { label: "Diseases_WinterWheat_Measures_SeedbedQuality_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.SeedbedQuality.Notes" },
    { label: "Diseases_WinterWheat_Measures_LowRiskLocations_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.LowRiskLocations.Status" },
    { label: "Diseases_WinterWheat_Measures_LowRiskLocations_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Diseases_WinterWheat_Measures_SowingDate_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.SowingDate.Status" },
    { label: "Diseases_WinterWheat_Measures_SowingDate_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.SowingDate.Notes" },
    { label: "Diseases_WinterWheat_Measures_VarietalChoice_Status", key: "otherFields.Diseases.WinterWheat.ControlMeasures.VarietalChoice.Status" },
    { label: "Diseases_WinterWheat_Measures_VarietalChoice_Notes", key: "otherFields.Diseases.WinterWheat.ControlMeasures.VarietalChoice.Notes" },
    // Weeds
    { label: "Weeds_AllCrops_CreepingThistle", key: "otherFields.Weeds.AllCrops.CreepingThistle" },
    { label: "Weeds_AllCrops_VolunteerPotatoes", key: "otherFields.Weeds.AllCrops.VolunteerPotatoes" },
    { label: "Weeds_AllCrops_WeedBeet", key: "otherFields.Weeds.AllCrops.WeedBeet" },
    { label: "Weeds_AllCrops_WildOats", key: "otherFields.Weeds.AllCrops.WildOats" },
    { label: "Weeds_AllCrops_Couch", key: "otherFields.Weeds.AllCrops.Couch" },
    { label: "Weeds_CombinableCrops_TapRootBlwCombine", key: "otherFields.Weeds.CombinableCrops.TapRootBlwCombine" },
    { label: "Weeds_CombinableCrops_FibrousRootBlwCombine", key: "otherFields.Weeds.CombinableCrops.FibrousRootBlwCombine" },
    { label: "Weeds_CombinableCrops_AnnualGrassesCombine", key: "otherFields.Weeds.CombinableCrops.AnnualGrassesCombine" },
    { label: "Weeds_WideRowCrops_TapRootBlwCombine", key: "otherFields.Weeds.WideRowCrops.TapRootBlwCombine" },
    { label: "Weeds_WideRowCrops_FibrousRootBlwCombine", key: "otherFields.Weeds.WideRowCrops.FibrousRootBlwCombine" },
    { label: "Weeds_WideRowCrops_AnnualGrassesCombine", key: "otherFields.Weeds.WideRowCrops.AnnualGrassesCombine" },
    { label: "Weeds_ImprovedGrassland_Ragwort", key: "otherFields.Weeds.ImprovedGrassland.Ragwort" },
    { label: "Weeds_ImprovedGrassland_CreepingButtercup", key: "otherFields.Weeds.ImprovedGrassland.CreepingButtercup" },
    { label: "Weeds_ImprovedGrassland_CommonNettle", key: "otherFields.Weeds.ImprovedGrassland.CommonNettle" },
    { label: "Weeds_ImprovedGrassland_Docks", key: "otherFields.Weeds.ImprovedGrassland.Docks" },
    { label: "Weeds_ImprovedGrassland_CreepingThistle", key: "otherFields.Weeds.ImprovedGrassland.CreepingThistle" },
    { label: "Weeds_ImprovedGrassland_Rushes", key: "otherFields.Weeds.ImprovedGrassland.Rushes" },
    { label: "Weeds_ImprovedGrassland_Bracken", key: "otherFields.Weeds.ImprovedGrassland.Bracken" },
    { label: "Weeds_ImprovedGrassland_Gorse", key: "otherFields.Weeds.ImprovedGrassland.Gorse" },
    { label: "Weeds_ImprovedGrassland_Broadleaved", key: "otherFields.Weeds.ImprovedGrassland.Broadleaved" },
    { label: "Weeds_ImprovedGrassland_Meadow", key: "otherFields.Weeds.ImprovedGrassland.Meadow" },
    { label: "Weeds_ImprovedGrassland_SpearThistle", key: "otherFields.Weeds.ImprovedGrassland.SpearThistle" },
    { label: "Weeds_ImprovedGrassland_Measures_DecisionSupport_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.DecisionSupport.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_DecisionSupport_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.DecisionSupport.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_Rotation_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Rotation.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_Rotation_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Rotation.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_ImproveSoilCondition_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.ImproveSoilCondition.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_ImproveSoilCondition_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.ImproveSoilCondition.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_StaleSeedbed_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.StaleSeedbed.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_StaleSeedbed_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.StaleSeedbed.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_Hygiene_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Hygiene.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_Hygiene_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Hygiene.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_Mowing_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Mowing.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_Mowing_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Mowing.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_HandPulling_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.HandPulling.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_HandPulling_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.HandPulling.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_Harrowing_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Harrowing.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_Harrowing_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Harrowing.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_Ploughing_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Ploughing.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_Ploughing_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Ploughing.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_SeedRate_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.SeedRate.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_SeedRate_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.SeedRate.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_ReSeeding_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.ReSeeding.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_ReSeeding_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.ReSeeding.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_Grazing_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Grazing.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_Grazing_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Grazing.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_Bioprotectans_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Bioprotectans.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_Bioprotectans_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.Bioprotectans.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_SpotTreatment_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.SpotTreatment.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_SpotTreatment_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.SpotTreatment.Notes" },
    { label: "Weeds_ImprovedGrassland_Measures_WeedWiper_Status", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.WeedWiper.Status" },
    { label: "Weeds_ImprovedGrassland_Measures_WeedWiper_Notes", key: "otherFields.Weeds.ImprovedGrassland.ControlMeasures.WeedWiper.Notes" },
    { label: "Weeds_Apple_WindBlownWeeds", key: "otherFields.Weeds.Apple.WindBlownWeeds" },
    { label: "Weeds_Apple_TapRootBlwApple", key: "otherFields.Weeds.Apple.TapRootBlwApple" },
    { label: "Weeds_Apple_FibrousRootBlwApple", key: "otherFields.Weeds.Apple.FibrousRootBlwApple" },
    { label: "Weeds_Apple_Couch", key: "otherFields.Weeds.Apple.Couch" },
    { label: "Weeds_Apple_Docks", key: "otherFields.Weeds.Apple.Docks" },
    { label: "Weeds_Apple_CreepingThistle", key: "otherFields.Weeds.Apple.CreepingThistle" },
    { label: "Weeds_Apple_SpearThistle", key: "otherFields.Weeds.Apple.SpearThistle" },
    { label: "Weeds_Apple_Measures_Mowing_Status", key: "otherFields.Weeds.Apple.ControlMeasures.Mowing.Status" },
    { label: "Weeds_Apple_Measures_Mowing_Notes", key: "otherFields.Weeds.Apple.ControlMeasures.Mowing.Notes" },
    { label: "Weeds_Apple_Measures_Hygiene_Status", key: "otherFields.Weeds.Apple.ControlMeasures.Hygiene.Status" },
    { label: "Weeds_Apple_Measures_Hygiene_Notes", key: "otherFields.Weeds.Apple.ControlMeasures.Hygiene.Notes" },
    { label: "Weeds_Apple_Measures_Harrowing_Status", key: "otherFields.Weeds.Apple.ControlMeasures.Harrowing.Status" },
    { label: "Weeds_Apple_Measures_Harrowing_Notes", key: "otherFields.Weeds.Apple.ControlMeasures.Harrowing.Notes" },
    { label: "Weeds_Apple_Measures_PhysicalMulches_Status", key: "otherFields.Weeds.Apple.ControlMeasures.PhysicalMulches.Status" },
    { label: "Weeds_Apple_Measures_PhysicalMulches_Notes", key: "otherFields.Weeds.Apple.ControlMeasures.PhysicalMulches.Notes" },
    { label: "Weeds_Apple_Measures_ThermalWeedControl_Status", key: "otherFields.Weeds.Apple.ControlMeasures.ThermalWeedControl.Status" },
    { label: "Weeds_Apple_Measures_ThermalWeedControl_Notes", key: "otherFields.Weeds.Apple.ControlMeasures.ThermalWeedControl.Notes" },
    { label: "Weeds_Apple_Measures_HandPulling_Status", key: "otherFields.Weeds.Apple.ControlMeasures.HandPulling.Status" },
    { label: "Weeds_Apple_Measures_HandPulling_Notes", key: "otherFields.Weeds.Apple.ControlMeasures.HandPulling.Notes" },
    { label: "Weeds_Apple_Measures_SpotTreatment_Status", key: "otherFields.Weeds.Apple.ControlMeasures.SpotTreatment.Status" },
    { label: "Weeds_Apple_Measures_SpotTreatment_Notes", key: "otherFields.Weeds.Apple.ControlMeasures.SpotTreatment.Notes" },
    // ResistanceManagement
    { label: "Resistance_Pests_InsecticideResistance_InsecticideResistance_Status", key: "otherFields.ResistanceManagement.Pests.InsecticideResistance.InsecticideResistance.Status" },
    { label: "Resistance_Pests_InsecticideResistance_InsecticideResistance_Notes", key: "otherFields.ResistanceManagement.Pests.InsecticideResistance.InsecticideResistance.Notes" },
    { label: "Resistance_Pests_InsecticideResistance_InsecticideAffected_Status", key: "otherFields.ResistanceManagement.Pests.InsecticideResistance.InsecticideAffected.Status" },
    { label: "Resistance_Pests_InsecticideResistance_InsecticideAffected_Notes", key: "otherFields.ResistanceManagement.Pests.InsecticideResistance.InsecticideAffected.Notes" },
    { label: "Resistance_Diseases_InsecticideResistance_FungicideResistance_Status", key: "otherFields.ResistanceManagement.Diseases.InsecticideResistance.FungicideResistance.Status" },
    { label: "Resistance_Diseases_InsecticideResistance_FungicideResistance_Notes", key: "otherFields.ResistanceManagement.Diseases.InsecticideResistance.FungicideResistance.Notes" },
    { label: "Resistance_Diseases_InsecticideResistance_FungicideAffected_Status", key: "otherFields.ResistanceManagement.Diseases.InsecticideResistance.FungicideAffected.Status" },
    { label: "Resistance_Diseases_InsecticideResistance_FungicideAffected_Notes", key: "otherFields.ResistanceManagement.Diseases.InsecticideResistance.FungicideAffected.Notes" },
    { label: "Resistance_Weeds_HerbicideResistance_FungicideResistance_Status", key: "otherFields.ResistanceManagement.Weeds.InsecticideResistance.HerbicideResistance.Status" },
    { label: "Resistance_Weeds_HerbicideResistance_FungicideResistance_Notes", key: "otherFields.ResistanceManagement.Weeds.InsecticideResistance.HerbicideResistance.Notes" },
    { label: "Resistance_Weeds_InsecticideResistance_HerbicideAffected_Status", key: "otherFields.ResistanceManagement.Weeds.InsecticideResistance.HerbicideAffected.Status" },
    { label: "Resistance_Weeds_InsecticideResistance_HerbicideAffected_Notes", key: "otherFields.ResistanceManagement.Weeds.InsecticideResistance.HerbicideAffected.Notes" },
    // Weeds second part as requested
    { label: "Weeds_AllCrops_CreepingThistle", key: "otherFields.Weeds.AllCrops.CreepingThistle" },
    { label: "Weeds_AllCrops_VolunteerPotatoes", key: "otherFields.Weeds.AllCrops.VolunteerPotatoes" },
    { label: "Weeds_AllCrops_WeedBeet", key: "otherFields.Weeds.AllCrops.WeedBeet" },
    { label: "Weeds_AllCrops_WildOats", key: "otherFields.Weeds.AllCrops.WildOats" },
    { label: "Weeds_AllCrops_Couch", key: "otherFields.Weeds.AllCrops.Couch" },
    { label: "Weeds_CombinableCrops_TapRootBlwCombine", key: "otherFields.Weeds.CombinableCrops.TapRootBlwCombine" },
    { label: "Weeds_CombinableCrops_FibrousRootBlwCombine", key: "otherFields.Weeds.CombinableCrops.FibrousRootBlwCombine" },
    { label: "Weeds_CombinableCrops_AnnualGrassesCombine", key: "otherFields.Weeds.CombinableCrops.AnnualGrassesCombine" },
    { label: "Weeds_WideRowCrops_TapRootBlwCombine", key: "otherFields.Weeds.WideRowCrops.TapRootBlwCombine" },
    { label: "Weeds_WideRowCrops_FibrousRootBlwCombine", key: "otherFields.Weeds.WideRowCrops.FibrousRootBlwCombine" },
    { label: "Weeds_WideRowCrops_AnnualGrassesCombine", key: "otherFields.Weeds.WideRowCrops.AnnualGrassesCombine" },
    { label: "Weeds_Measures_Rotation_Status", key: "otherFields.Weeds.ControlMeasures.Rotation.Status" },
    { label: "Weeds_Measures_Rotation_Notes", key: "otherFields.Weeds.ControlMeasures.Rotation.Notes" },
    { label: "Weeds_Measures_LowRiskLocations_Status", key: "otherFields.Weeds.ControlMeasures.LowRiskLocations.Status" },
    { label: "Weeds_Measures_LowRiskLocations_Notes", key: "otherFields.Weeds.ControlMeasures.LowRiskLocations.Notes" },
    { label: "Weeds_Measures_Fallow_Status", key: "otherFields.Weeds.ControlMeasures.Fallow.Status" },
    { label: "Weeds_Measures_Fallow_Notes", key: "otherFields.Weeds.ControlMeasures.Fallow.Notes" },
    { label: "Weeds_Measures_ImproveSoilCondition_Status", key: "otherFields.Weeds.ControlMeasures.ImproveSoilCondition.Status" },
    { label: "Weeds_Measures_ImproveSoilCondition_Notes", key: "otherFields.Weeds.ControlMeasures.ImproveSoilCondition.Notes" },
    { label: "Weeds_Measures_StubbleManagement_Status", key: "otherFields.Weeds.ControlMeasures.StubbleManagement.Status" },
    { label: "Weeds_Measures_StubbleManagement_Notes", key: "otherFields.Weeds.ControlMeasures.StubbleManagement.Notes" },
    { label: "Weeds_Measures_StaleSeedbed_Status", key: "otherFields.Weeds.ControlMeasures.StaleSeedbed.Status" },
    { label: "Weeds_Measures_StaleSeedbed_Notes", key: "otherFields.Weeds.ControlMeasures.StaleSeedbed.Notes" },
    { label: "Weeds_Measures_CoverCrops_Status", key: "otherFields.Weeds.ControlMeasures.CoverCrops.Status" },
    { label: "Weeds_Measures_CoverCrops_Notes", key: "otherFields.Weeds.ControlMeasures.CoverCrops.Notes" },
    { label: "Weeds_Measures_Hygiene_Status", key: "otherFields.Weeds.ControlMeasures.Hygiene.Status" },
    { label: "Weeds_Measures_Hygiene_Notes", key: "otherFields.Weeds.ControlMeasures.Hygiene.Notes" },
    { label: "Weeds_Measures_Mowing_Status", key: "otherFields.Weeds.ControlMeasures.Mowing.Status" },
    { label: "Weeds_Measures_Mowing_Notes", key: "otherFields.Weeds.ControlMeasures.Mowing.Notes" },
    { label: "Weeds_Measures_EarlyHarvest_Status", key: "otherFields.Weeds.ControlMeasures.EarlyHarvest.Status" },
    { label: "Weeds_Measures_EarlyHarvest_Notes", key: "otherFields.Weeds.ControlMeasures.EarlyHarvest.Notes" },
    { label: "Weeds_Measures_HandPulling_Status", key: "otherFields.Weeds.ControlMeasures.HandPulling.Status" },
    { label: "Weeds_Measures_HandPulling_Notes", key: "otherFields.Weeds.ControlMeasures.HandPulling.Notes" },
    { label: "Weeds_Measures_Harrowing_Status", key: "otherFields.Weeds.ControlMeasures.Harrowing.Status" },
    { label: "Weeds_Measures_Harrowing_Notes", key: "otherFields.Weeds.ControlMeasures.Harrowing.Notes" },
    { label: "Weeds_Measures_ThermalWeedControl_Status", key: "otherFields.Weeds.ControlMeasures.ThermalWeedControl.Status" },
    { label: "Weeds_Measures_ThermalWeedControl_Notes", key: "otherFields.Weeds.ControlMeasures.ThermalWeedControl.Notes" },
    { label: "Weeds_Measures_PrecisionApplication_Status", key: "otherFields.Weeds.ControlMeasures.PrecisionApplication.Status" },
    { label: "Weeds_Measures_PrecisionApplication_Notes", key: "otherFields.Weeds.ControlMeasures.PrecisionApplication.Notes" },
    { label: "Weeds_Measures_Ploughing_Status", key: "otherFields.Weeds.ControlMeasures.Ploughing.Status" },
    { label: "Weeds_Measures_Ploughing_Notes", key: "otherFields.Weeds.ControlMeasures.Ploughing.Notes" },
    { label: "Weeds_Measures_NonInversionTillage_Status", key: "otherFields.Weeds.ControlMeasures.NonInversionTillage.Status" },
    { label: "Weeds_Measures_NonInversionTillage_Notes", key: "otherFields.Weeds.ControlMeasures.NonInversionTillage.Notes" },
    { label: "Weeds_Measures_DirectDrilling_Status", key: "otherFields.Weeds.ControlMeasures.DirectDrilling.Status" },
    { label: "Weeds_Measures_DirectDrilling_Notes", key: "otherFields.Weeds.ControlMeasures.DirectDrilling.Notes" },
    { label: "Weeds_Measures_SeedRate_Status", key: "otherFields.Weeds.ControlMeasures.SeedRate.Status" },
    { label: "Weeds_Measures_SeedRate_Notes", key: "otherFields.Weeds.ControlMeasures.SeedRate.Notes" },
    { label: "Weeds_Measures_SowingDate_Status", key: "otherFields.Weeds.ControlMeasures.SowingDate.Status" },
    { label: "Weeds_Measures_SowingDate_Notes", key: "otherFields.Weeds.ControlMeasures.SowingDate.Notes" },
    { label: "Weeds_Measures_VarietalChoice_Status", key: "otherFields.Weeds.ControlMeasures.VarietalChoice.Status" },
    { label: "Weeds_Measures_VarietalChoice_Notes", key: "otherFields.Weeds.ControlMeasures.VarietalChoice.Notes" },
];

export default UserDataHeaders;