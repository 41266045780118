import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import { QuestionCircle } from 'react-bootstrap-icons';
import PeasDiseaseList from './peasDiseaseList';
import MeasuresButtons from '../measuresButtons';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddPeasDiseaseMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`63a182dafc7fb794cae43b8a`);


    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.diseases !== null
        && (location.state.surveyData.diseases !== undefined)
        && (location.state.surveyData.diseases.peas !== null)
        && (location.state.surveyData.diseases.peas !== undefined)
        && (location.state.surveyData.diseases.peas.controlMeasures !== null)
        && (location.state.surveyData.diseases.peas.controlMeasures !== undefined)) ?
        location.state.surveyData.diseases.peas.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    const listOfIssues = getListOfIssues(location?.state?.surveyData?.diseases?.peas);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.diseases.peas.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>peas disease control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>

            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('botrytis'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Field History, Rotation & Break Crops</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="fieldHistoryDesc">
                                <p>
                                    Crop rotations are crucial for peas and beans to reduce levels of disease pressure. A 1 in 5 years rotation is recommended to reduce levels of infection from diseases such as botrytis and downy mildew, and for bean rust at least 1 in 4 years rotation is recommended.  Where pulses are grown in short rotations (1 in 3 years), this is likely to increase the build up of soil borne pathogens such as sclerotinia spp., fusarium spp., and pythium spp. (which can cause foot and roots rots), with a minimum 1 in 5 years rotation recommended.
                                </p>
                            </div>

                            <PeasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'botrytis'.toLowerCase()
                            ]} />

                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.fieldHistory}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('botrytis'.toLowerCase()) ||
                        listOfIssues.includes('downyMildew'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Nutrient Management</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#nutrientManagementDesc" aria-expanded="false" aria-controls="nutrientManagementDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="nutrientManagementDesc">
                                    <p>
                                        Crops which are nutrient deficient can be predisposed to disease infection. Ensure appropriate soil nutrient supply by regular soil sampling and testing and use of appropriate fertilisers.
                                    </p>
                                </div>
                                <PeasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'botrytis'.toLowerCase(),
                                    'downyMildew'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.nutrientManagement}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('botrytis'.toLowerCase()) ||
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('leafSpot'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Primary Cultivations (Crop Residue Burial)</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#primaryCultivationsDesc" aria-expanded="false" aria-controls="primaryCultivationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="primaryCultivationsDesc">
                                    <p>
                                        Burial of crop debris by ploughing can reduce inoculum for some pathogens which produce inoculum on plant debris, which can be beneficial for pea and beans diseases including leaf and pod spot, downy mildew, botrytis and bean rust. On the negative side the use of ploughing can reduce soil biodiversity. Sclerotia can be deeply buried by ploughing but can remain dormant for many years and will germinate if brought into the topsoil by future cultivations. Sclerotia left on the soil surface can lose viability and reduce the sclerotia bank in the soil, and can form part of a minimum tillage strategy for control.
                                    </p>
                                </div>
                                <PeasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'botrytis'.toLowerCase(),
                                    'downyMildew'.toLowerCase(),
                                    'leafSpot'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.primaryCultivations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('leafSpot'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Seed Testing</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#seedTestingDesc" aria-expanded="false" aria-controls="seedTestingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="seedTestingDesc">
                                <p>
                                    Seed testing of home saved seed should be used to identify common seed-borne diseases. In peas, farm-saved seeds should be tested against leaf spot pathogens to ensure disease-free material is planted.
                                </p>
                            </div>

                            <PeasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'leafSpot'.toLowerCase(),
                            ]} />

                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.seedTesting}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />

                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('botrytis'.toLowerCase()) ||
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('leafSpot'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Variety Choice</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#varietalChoiceDesc" aria-expanded="false" aria-controls="varietalChoiceDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="varietalChoiceDesc">
                                    <p>
                                        Resistant varieties are a key part of non-chemical disease control. There are good sources of information on disease resistance to many of the major pathogens in the descriptive lists for combining peas and winter and spring beans published by PGRO.  This information is updated annually to account for new pathogen strains which can infect previously resistant varieties.
                                    </p>
                                    <p>For specific guidance click <a href="https://www.pgro.org/pulse-descriptive-list/" target="_blank" rel="noreferrer">here</a></p>
                                </div>

                                <PeasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'botrytis'.toLowerCase(),
                                    'downyMildew'.toLowerCase(),
                                    'leafSpot'.toLowerCase()
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.varietalChoice}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                </>
            )}

            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"diseases"}
                    finalUrl={"/add-diseases-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddPeasDiseaseMeasures;