import { clearRiskText } from "./clearRiskText";
import { addIssuesToRisk } from "./addIssuesToRisk";

export function addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, issuesWithDisplayName) {
    const notInMeasures = listOfIssues.filter(obj => {
        const name = obj.name;
        const pestKeyProperty = "pestKeys";
        for (const inUseObj of listOfMeasuresWithPests.inUse) {
            if (inUseObj[pestKeyProperty].includes(name)) {
                return false;
            }
        }
        for (const inUseObj of listOfMeasuresWithPests.futureUse) {
            if (inUseObj[pestKeyProperty].includes(name)) {
                return false;
            }
        }
        return true;
    }).map(obj => obj.name);
    if (notInMeasures.length === 0) return;
    let measureWithPests = {
        measures: 'No IPM control measures implemented',
        significantRisk: '',
        moderateRisk: '',
        slightRisk: '',
        pestKeys: '',
        isWarning: true
    };
    const selectedIssues = issuesWithDisplayName.filter(item => notInMeasures.includes(item.name));
    addIssuesToRisk(selectedIssues, listOfIssues, measureWithPests);
    clearRiskText(measureWithPests);
    listOfMeasuresWithPests[notUseStatus].unshift(measureWithPests);
}
