import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const appleDiseaseSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const appleScab = { name: 'appleScab', display: "Apple Scab" };
    const canker = { name: 'canker', display: "Canker" };
    const powderyMildew = { name: 'powderyMildew', display: "Powdery Mildew" };
    const replantDisease = { name: 'replantDisease', display: "Replant Disease" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'biostimulants'.toLowerCase()) {
                    measureWithPests.measures = 'Biostimulants & elicitors'
                    const issuesInControl = [canker, powderyMildew];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support, incl. monitoring and forecasting'
                    const issuesInControl = [appleScab, powderyMildew];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, rotation & break crops'
                    const issuesInControl = [appleScab, replantDisease];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nutrientManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Nutrient Management'
                    const issuesInControl = [appleScab, canker, powderyMildew, replantDisease];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'organicAmendments'.toLowerCase()) {
                    measureWithPests.measures = 'Organic Amendments'
                    const issuesInControl = [replantDisease];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'canopyManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Canopy Management'
                    const issuesInControl = [appleScab, canker, powderyMildew, replantDisease];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Select low-risk locations'
                    const issuesInControl = [canker, replantDisease];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietyChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Variety choice and rootstock'
                    const issuesInControl = [appleScab, powderyMildew];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygieneAndPrevention'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene and Prevention'
                    const issuesInControl = [canker];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [appleScab, canker, powderyMildew, replantDisease]);
    return listOfMeasuresWithPests;
};

export default appleDiseaseSummaryMeasures;