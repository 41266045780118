import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const improvedGrasslandDiseaseSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const leafBlotch = { name: 'leafBlotch', display: "Leaf Blotch" };
    const mildew = { name: 'mildew', display: "Mildew" };
    const crownRust = { name: 'crownRust', display: "Crown Rust" };
    const drechsleraLeafDisease = { name: 'drechsleraLeafDisease', display: "Drechslera Leaf Disease" };
    const virusDiseases = { name: 'virusDiseases', display: "Virus Diseases" };
    const cloverRot = { name: 'cloverRot', display: "Clover Rot" };
    const leafDiseases = { name: 'leafDiseases', display: "Leaf Diseases" };
    const verticilliumWilt = { name: 'verticilliumWilt', display: "Verticillium Wilt" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'ploughingInCropResidue'.toLowerCase()) {
                    measureWithPests.measures = 'Ploughing in crop residue'
                    const issuesInControl = [
                        leafBlotch,
                        mildew,
                        crownRust,
                        drechsleraLeafDisease,
                        cloverRot,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'resistanceVariables'.toLowerCase()) {
                    measureWithPests.measures = 'Resistance Variables'
                    const issuesInControl = [
                        leafBlotch,
                        mildew,
                        crownRust,
                        drechsleraLeafDisease,
                        virusDiseases,
                        verticilliumWilt
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'controlVolunteersAndWeeds'.toLowerCase()) {
                    measureWithPests.measures = 'Control Volunteers And Weeds'
                    const issuesInControl = [
                        leafBlotch,
                        mildew,
                        crownRust,
                        drechsleraLeafDisease,
                        virusDiseases
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nutrientManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Nutrient Management'
                    const issuesInControl = [
                        leafBlotch,
                        mildew,
                        crownRust,
                        drechsleraLeafDisease,
                        virusDiseases,
                        cloverRot,
                        leafDiseases,
                        verticilliumWilt
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'regularGrazing'.toLowerCase()) {
                    measureWithPests.measures = 'Regular grazing of young material'
                    const issuesInControl = [
                        leafBlotch,
                        mildew,
                        crownRust,
                        drechsleraLeafDisease,
                        leafDiseases
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'earlyHarvest'.toLowerCase()) {
                    measureWithPests.measures = 'Harvest at first sign / early to reduce loss / spread'
                    const issuesInControl = [
                        leafBlotch,
                        mildew,
                        crownRust,
                        drechsleraLeafDisease,
                        virusDiseases,
                        leafDiseases,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygieneAndPrevention'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene And Prevention'
                    const issuesInControl = [
                        cloverRot,
                        verticilliumWilt
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus,
        [leafBlotch, mildew, crownRust, drechsleraLeafDisease, virusDiseases, cloverRot, leafDiseases, verticilliumWilt]);
    return listOfMeasuresWithPests;
};

export default improvedGrasslandDiseaseSummaryMeasures;