import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import { QuestionCircle } from 'react-bootstrap-icons';
import MeasuresButtons from '../measuresButtons';
import WheatPestList from './wheatPestList';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddSpringWheatPestMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`637f8d4cf16228a14699718f`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.springWheat !== null)
        && (location.state.surveyData.pests.springWheat !== undefined)
        && (location.state.surveyData.pests.springWheat.controlMeasures !== null)
        && (location.state.surveyData.pests.springWheat.controlMeasures !== undefined)) ?
        location.state.surveyData.pests.springWheat.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.springWheat);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.springWheat.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year,
            isSpring: true
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>spring wheat pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('bydvVectors'.toLowerCase()) ||
                        listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('orangeWheatBlossomMidge'.toLowerCase()) ||
                        listOfIssues.includes('saddleGallMidge'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase()) ||
                        listOfIssues.includes('wheatBulbFly'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Decision Support (including thresholds)</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="decisionSupportDesc">
                                    <p>
                                        IPM decisions should be made based on the results of monitoring and forecasting combined with threshold information where available. Treatment thresholds are the population level or density that must be reached before it becomes economically beneficial. Thresholds enable growers to make decisions based on the level at which pests will impact economic crop yield. They are essential in guiding pest control decisions and preventing the unnecessary use of pesticides.
                                    </p>
                                    <p>Pest forecasts are available from the <a href="https://platform.ipmdecisions.net/" target="_blank" rel="noreferrer">IPM Decisions platform</a>. Forecasts are only active during the growing season.</p>
                                </div>
                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'bydvVectors'.toLowerCase(),
                                    'fritFly'.toLowerCase(),
                                    'orangeWheatBlossomMidge'.toLowerCase(),
                                    'saddleGallMidge'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                    'wheatBulbFly'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.decisionSupport}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('orangeWheatBlossomMidge'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase()) ||
                        listOfIssues.includes('wheatBulbFly'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase()) ||
                        listOfIssues.includes('yellowWheatBlossomMidge'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Field History, Rotation & Break Crops</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="fieldHistoryDesc">
                                    <p>
                                        Some pests are relatively immobile, and numbers increase only when their host crop is grown too often in the same field.
                                        Examples include orange wheat blossom midge (OWBM) and saddle gall midge.  Other pests such as wireworms have a long-life cycle so need the host crop to remain for several years. Growing alternative, non-host crops for appropriate periods can avoid this build-up.
                                        The length of the break may vary between pests.
                                    </p>
                                </div>

                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'fritFly'.toLowerCase(),
                                    'leatherjackets'.toLowerCase(),
                                    'orangeWheatBlossomMidge'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                    'wheatBulbFly'.toLowerCase(),
                                    'wireworms'.toLowerCase(),
                                    'yellowWheatBlossomMidge'.toLowerCase(),
                                ]} />                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.fieldHistory}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('bydvVectors'.toLowerCase()) ||
                        listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Primary Cultivations / Crop Residue Burial</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#primaryCultivationsDesc" aria-expanded="false" aria-controls="primaryCultivationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="primaryCultivationsDesc">
                                    <p>Undisturbed trash or crop residue can provide shelter and food for pests such as slugs.  Allowing cereal volunteers to persist can also create a ‘green bridge’ which allows aphids to colonise newly established cereal crops and spread BYDV. Conversely, presence of crop residues or trash can reduce pest infestation by providing shelter and refuge for and increased abundance of natural enemies.</p>
                                </div>

                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'bydvVectors'.toLowerCase(),
                                    'fritFly'.toLowerCase(),
                                    'leatherjackets'.toLowerCase(),
                                    'wireworms'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.primaryCultivations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('slugs'.toLowerCase())) && (
                        <>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Rolling Soil Post-Planting</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#rollingSoilDesc" aria-expanded="false" aria-controls="rollingSoilDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="rollingSoilDesc">
                                <p>Rolling soil post-planting can improve the seedbed quality, resulting in more rapid germination of crops and reducing access of soil-borne pests, such as slugs, to seeds.  Rolling may also kill some pests or reduce their mobility.  </p>
                            </div>

                            <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                'slugs'.toLowerCase(),
                            ]} />                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.rollingSoil}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('bydvVectors'.toLowerCase()) ||
                        listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase())
                    ) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Secondary Cultivations / Drilling Method</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#secondaryCultivationsDesc" aria-expanded="false" aria-controls="secondaryCultivationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="secondaryCultivationsDesc">
                                    <p>The mechanical action of cultivations can reduce soil populations of pests such as slugs, wireworms, and leatherjackets.  This can occur in three ways:
                                        <br />•	The pests are killed.
                                        <br />•	Pest are brought to the surface and eaten by birds.
                                        <br />•	Pests are brought to the surface and become dehydrated.
                                        <br />On the negative side increased cultivations increase carbon footprint and can reduce soil biodiversity.
                                        <br />Direct drilling in dry conditions can maintain a consolidated seedbed and limit slug damage.  However, in wet conditions it may produce slots that give slugs easy access to seeds.  Depth of drilling can also have an impact on slug damage.  Ideally cereals should be drilled at about 30-40 mm to minimise damage.
                                        <br />Sowing deeper than this will reduce establishment and increase susceptibility to slugs and other pests.
                                    </p>
                                </div>

                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'bydvVectors'.toLowerCase(),
                                    'fritFly'.toLowerCase(),
                                    'leatherjackets'.toLowerCase(),
                                    'wireworms'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.secondaryCultivations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase()) ||
                        listOfIssues.includes('wheatBulbFly'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Seed Rate</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedRateDesc" aria-expanded="false" aria-controls="seedRateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="seedRateDesc">
                                    <p>Increasing seed rates can compensate for the loss of plants to pests such as slugs, wheat bulb fly, frit fly or leatherjackets.   </p>
                                </div>
                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'fritFly'.toLowerCase(),
                                    'leatherjackets'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                    'wheatBulbFly'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.seedRate}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('slugs'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Seedbed Quality</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedbedQualityDesc" aria-expanded="false" aria-controls="seedbedQualityDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="seedbedQualityDesc">
                                    <p>Higher seedbed quality can result in more rapid germination of crops and reduce access of soil-borne pests, such as slugs, to seeds. </p>
                                </div>

                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'slugs'.toLowerCase(),
                                ]} />                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.seedbedQuality}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase()) ||
                        listOfIssues.includes('goutFly'.toLowerCase())
                    ) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Select Low-Risk Locations</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="lowRiskLocationsDesc">
                                    <p>
                                        Populations of some pests can build up under long-term leys, such as wireworms and leatherjackets.
                                        These will potentially feed on any crop following a grass ley.
                                        Cereals sown soon after the destruction of grass leys are susceptible to the direct transfer of frit fly larvae, so a gap of at least six weeks is advised between sward destruction and drilling of the following cereal crop.
                                    </p>
                                </div>

                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'fritFly'.toLowerCase(),
                                    'goutFly'.toLowerCase(),
                                    'leatherjackets'.toLowerCase(),
                                    'wireworms'.toLowerCase(),
                                ]} />                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.lowRiskLocations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('bydvVectors'.toLowerCase()) ||
                        listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('goutFly'.toLowerCase()) ||
                        listOfIssues.includes('wheatBulbFly'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Sowing Date</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#sowingDateDesc" aria-expanded="false" aria-controls="sowingDateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="sowingDateDesc">
                                    <p>
                                        Delayed drilling of spring cereal crops due to wet soil conditions may result in later emerging seedlings being more susceptible to effects of BYDV infection.
                                        Spring wheat is more at risk from wheat bulb fly if sown before March but experience little or no damage if sown later.
                                        Late-sown spring crops are most at risk from gout fly, and in areas where damage is prevalent sowing spring cereals as early as possible and before mid-April is advised.
                                    </p>
                                </div>

                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'bydvVectors'.toLowerCase(),
                                    'fritFly'.toLowerCase(),
                                    'goutFly'.toLowerCase(),
                                    'wheatBulbFly'.toLowerCase(),
                                ]} />                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.sowingDate}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('orangeWheatBlossomMidge'.toLowerCase()) ||
                        listOfIssues.includes('wheatBulbFly'.toLowerCase()) ||
                        listOfIssues.includes('yellowWheatBlossomMidge'.toLowerCase())
                    ) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Spatial Separation</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#spatialSeparationDesc" aria-expanded="false" aria-controls="spatialSeparationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="spatialSeparationDesc">
                                    <p>Some pest species are poor fliers so do not travel long distances between host crops in a single season.  Wide spatial separation between host crops in successive years can make it difficult for the pest to find them. </p>
                                </div>

                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'orangeWheatBlossomMidge'.toLowerCase(),
                                    'wheatBulbFly'.toLowerCase(),
                                    'yellowWheatBlossomMidge'.toLowerCase(),
                                ]} />                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.spatialSeparation}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('bydvVectors'.toLowerCase())
                    ) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>In Field Non-Cropped Areas</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#nonCroppedAreasDesc" aria-expanded="false" aria-controls="nonCroppedAreasDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="nonCroppedAreasDesc">
                                    <p>Beetle banks consist of stands of wildflowers and grasses and are designed to act as reservoirs of beneficial insects such as ground beetles and parasitoids, which help to provide natural biological control of insect pests.
                                        Diverse crop margins and strips act in a similar way to beetle banks to increase natural enemies. Plain grass strips are more likely to predominantly harbour cereal insect pests, whereas more diverse strips should harbour greater biodiversity and greater numbers of beneficials.</p>
                                </div>


                                <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                    'bydvVectors'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.nonCroppedAreas}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )} {(listOfIssues.includes('bydvVectors'.toLowerCase()) ||
                            listOfIssues.includes('orangeWheatBlossomMidge'.toLowerCase())

                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Varietal Choice</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#varietalChoiceDesc" aria-expanded="false" aria-controls="varietalChoiceDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>

                                    <div className="collapse" id="varietalChoiceDesc">
                                        <p>Varieties are now available that are resistant to orange wheat blossom midge (OWBM) or barley yellow dwarf virus (BYDV). </p>
                                        <p>For specific guidance click <a href="https://ahdb.org.uk/rl" target="_blank" rel="noreferrer">here</a></p>
                                    </div>
                                    <WheatPestList listOfIssues={listOfIssues} toDisplay={[
                                        'bydvVectors'.toLowerCase(),
                                        'orangeWheatBlossomMidge'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.varietalChoice}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}                 </>
            )}
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}
                >

                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddSpringWheatPestMeasures;