const ImprovedGrasslandWeedsList = ({ issues, toDisplay }) => {
    if (issues.length === 0) {
        return <></>;
    }
    const values = issues.filter(value => toDisplay.includes(value));
    if (values.length === 0) {
        return <></>;
    } return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('ragwort'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/distribution-and-biology-of-ragwort-in-the-uk" target="_blank" rel="noreferrer">Ragwort</a>
                    </li>
                    : <></>
                }
                {values.includes('creepingButtercup'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/grassland-reseeds-weed-control" target="_blank" rel="noreferrer">Creeping Buttercup</a>
                    </li>
                    : <></>
                }
                {values.includes('commonNettle'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/grassland-reseeds-weed-control" target="_blank" rel="noreferrer">Common Nettle</a>
                    </li>
                    : <></>
                }
                {values.includes('docks'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/grassland-reseeds-weed-control" target="_blank" rel="noreferrer">Docks</a>
                    </li>
                    : <></>
                }
                {values.includes('creepingThistle'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/distribution-and-biology-of-creeping-thistle-in-the-uk" target="_blank" rel="noreferrer">Creeping thistle</a>
                    </li>
                    : <></>
                }
                {values.includes('rushes'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://www.rspb.org.uk/our-work/conservation/conservation-and-sustainability/farming/advice/techniques-to-help-wildlife/rush-management/" target="_blank" rel="noreferrer">Rushes</a>
                    </li>
                    : <></>
                }
                {values.includes('bracken'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://www.brackencontrol.co.uk/" target="_blank" rel="noreferrer">Bracken</a>
                    </li>
                    : <></>
                }
                {values.includes('gorse'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://www.rspb.org.uk/our-work/conservation/conservation-and-sustainability/farming/advice/techniques-to-help-wildlife/managing-gorse/" target="_blank" rel="noreferrer">Gorse</a>
                    </li>
                    : <></>
                }
                {values.includes('broadleaved'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/how-to-identify-broad-leaved-weeds-in-arable-fields" target="_blank" rel="noreferrer">Annual broadleaved weeds (chickweed, fat hen, mayweed)</a>
                    </li>
                    : <></>
                }
                {values.includes('meadow'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/how-to-identify-grass-weeds-in-arable-fields" target="_blank" rel="noreferrer">Annual meadow grass</a>
                    </li>
                    : <></>
                }
                {values.includes('spearThistle'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="- https://ahdb.org.uk/knowledge-library/distribution-and-biology-of-spear-thistle-in-the-uk" target="_blank" rel="noreferrer">Spear thistle</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default ImprovedGrasslandWeedsList;