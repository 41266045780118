import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import LoadingIndicator from "../../common/loadingIndicator";
import loadingStatus from "../../../helpers/loadingStatus";
import useJsonForm from '../../../hooks/useJsonForm';
import usePutRequest from '../../../hooks/usePutRequest'
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import SurveyNavigation from '../surveyNavigation';
import getListOfCrops from '../../../helpers/getListOfCrops';
import UrlControl, {
    urlControlTester,
} from '../../customControls/UrlControl';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../customControls/BenchMarkingControl';

const AddGeneralPractices = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`634d62ad4083e2c844a899c5`);
    const initialData = (location.state !== null && location.state.surveyData !== null) ? location.state.surveyData.generalPractices : {};
    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const [data, setData] = useState(initialData);
    const [crops, setCrops] = useState();

    const { put } = usePutRequest(`/api/survey/${location.state?.surveyData?.id}`, location.state?.surveyData);

    useEffect(() => {
        if (location.state?.surveyData.landParcels !== undefined) {
            setCrops(getListOfCrops(location.state.surveyData.landParcels));
        }
    }, [location.state?.isNewSurvey, location.state?.surveyData.landParcels]);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    if (schema) {
        let parcels = getParcelsAsEnumForJsonSchema(location);
        schema.enums.parcels = parcels;
    }

    const uiSchema = JSON.parse(jsonForm.uiSchema);

    const formOnChange = ({ data, errors }) => {
        // setData(data); This makes the Biopesticides and PrecisionTechniques to reset
        location.state.surveyData.generalPractices = data;
    };

    const onAddGeneralSubmitClick = async (goToNextPage) => {
        const response = await put();

        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!goToNextPage) {
            toast.info("Saved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        navigate("/add-weeds",
            {
                state: {
                    farmData: location.state.farmData,
                    surveyData: location.state.surveyData
                }
            });
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: urlControlTester,
            renderer: UrlControl,
        },
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year,
            sectionString: "To see how other users answer this question: "
        },
    ];

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>general practices</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the practices you undertake or plan to undertake on your farm and the fields these are in or you plan to put them</p>
            </div>
            <div className="row my-3">
                <JsonForms
                    schema={schema}
                    data={data}
                    uischema={uiSchema}
                    renderers={renderers}
                    cells={materialCells}
                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                    validationMode={'NoValidation'}
                    readonly={status === 'completed'}
                />
            </div>
            <div className="row justify-content-between my-5">
                <div className="alert alert-info" role="alert">
                    To save your work, click <strong>Save</strong> before using the <strong>Go To</strong> or <strong>Go back</strong> button.
                </div>
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                <Link className="col-2 mx-3"
                    to="/add-ipm-plan"
                    state={{
                        farmData: location.state.farmData,
                        surveyData: location.state.surveyData,
                        isNewSurvey: false
                    }}>
                    <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                </Link>
                <SurveyNavigation farmData={location.state.farmData} surveyData={location.state.surveyData} crops={crops} />
                <button type="button" className="col-2 mx-3 btn btn-primary" onClick={() => onAddGeneralSubmitClick(false)} disabled={status === 'completed'} >Save</button>
                {status === 'completed' ? (
                    <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddGeneralSubmitClick(true)}>Continue</button>
                ) : (
                    <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddGeneralSubmitClick(true)}>Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight></button>
                )}
            </div>
        </>
    );
};

export default AddGeneralPractices;

function getParcelsAsEnumForJsonSchema(location) {
    let parcels = {
        "type": "array",
        "uniqueItems": true,
        "items": {
            "oneOf": []
        }
    };
    location.state.surveyData.landParcels.forEach(
        function callback(parcel) {
            let crops = parcel.crops.map(function (crop) {
                return crop.cropType;
            }).join('; ');
            if (!crops)
                crops = 'No crops selected';
            const parcelWithData = {
                "const": parcel.rpaId,
                "title": `${parcel.name} (${parcel.rpaId}) - ${crops}`
            };
            parcels.items.oneOf.push(parcelWithData);
        }
    );
    return parcels;
}