import { ColumnsCurrent, ColumnsFuture, ColumnsNotSelected, TableStyle, ColumnsNotes } from './tableStyle';
import SummaryTable from './summaryTable';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import SummaryNotesTables from './summaryNotesTables';
import controlNamesDictionary from '../../../helpers/controlNameDictionary';

function SummaryIssuesTables(props) {
    const CurrentColumns = ColumnsCurrent;
    const FutureColumns = ColumnsFuture;
    const NotSelectedColumns = ColumnsNotSelected;

    let pestsControls = [];
    for (const control in props.listOfPestsControls) {
        pestsControls.push({ name: control, ...props.listOfPestsControls[control] })
    }
    let diseaseControls = [];
    for (const control in props.listOfDiseasesControls) {
        diseaseControls.push({ name: control, ...props.listOfDiseasesControls[control] })
    }
    let weedControls = [];
    for (const control in props.listOfWeedsControls) {
        weedControls.push({ name: control, ...props.listOfWeedsControls[control] })
    }
    pestsControls = pestsControls.filter(control => control.notes !== null && control.notes !== undefined);
    diseaseControls = diseaseControls.filter(control => control.notes !== null && control.notes !== undefined);
    weedControls = weedControls.filter(control => control.notes !== null && control.notes !== undefined);

    if (props.isOther === undefined) {
        pestsControls = pestsControls.map(control => {
            return {
                ...control,
                name : controlNamesDictionary[control.name]
            }
        })
    }

    if (props.isOther === undefined) {
        diseaseControls = diseaseControls.map(control => {
            return {
                ...control,
                name: controlNamesDictionary[control.name]
            }
        })
    }

    if (props.isOther === undefined) {
        weedControls = weedControls.map(control => {
            return {
                ...control,
                name: controlNamesDictionary[control.name]
            }
        })
    }

     return (
        <>
            {
                props.listOfPests !== undefined && (
                    <div>
                        <h4 className="fw-bolder text-decoration-underline">Pests</h4>
                         {
                             (props.listOfPests.length === 0) ? (
                            <>
                                <h5>No issues selected</h5>
                            </>
                        ) : (
                            <>
                                {props.pestData.notUse.length !== 0 &&
                                    <>
                                        <TableStyle>
                                            <SummaryTable columns={NotSelectedColumns} data={props.pestData.notUse} tableId={`${props.crop.toLowerCase()}-pests-no-selected`}></SummaryTable>
                                        </TableStyle>
                                    </>
                                }
                                <h5>Use in current cropping season</h5>
                                {props.pestData.inUse.length === 0 ? (
                                    <>
                                        <p>
                                            <ExclamationTriangle color="#FFDB58" size={25} className='me-2' />
                                            No control measures selected
                                        </p>
                                    </>
                                ) : (
                                    <TableStyle>
                                        <SummaryTable columns={CurrentColumns} data={props.pestData.inUse} tableId={`${props.crop.toLowerCase()}-pests-current-selected`}></SummaryTable>
                                    </TableStyle>
                                )}
                                <h5>Intend to use in future seasons</h5>
                                {props.pestData.futureUse.length === 0 ? (
                                    <>
                                        <p>
                                            <ExclamationTriangle color="#FFDB58" size={25} className='me-2' />
                                            No control measures selected
                                        </p>
                                    </>
                                ) : (
                                    <TableStyle>
                                        <SummaryTable columns={FutureColumns} data={props.pestData.futureUse} tableId={`${props.crop.toLowerCase()}-pests-future-selected`}></SummaryTable>
                                    </TableStyle>
                                         )}
                                <h5>Control measure notes</h5>
                                
                                {
                                         (pestsControls.map(control => control.notes).filter(notes => notes !== undefined).length !== 0) ? (
                                             <>
                                                 <TableStyle>
                                                     <SummaryNotesTables columns={ColumnsNotes} data={pestsControls} tableId={`${props.crop.toLowerCase()}-pests-notes`} ></SummaryNotesTables>
                                                 </TableStyle>
                                             </>
                                         ) : (
                                             <p>
                                                 <ExclamationTriangle color="#FFDB58" size={25} className='me-2' />
                                                 No notes
                                             </p>
                                         )
                                }     
                            </>
                        )}
                    </div>
                )
            }
            {
                props.listOfDiseases !== undefined && (
                    <div>
                        <h4 className="fw-bolder text-decoration-underline">Diseases</h4>
                        {
                             (props.listOfDiseases.length === 0) ? (
                                <>
                                    <h5>No issues selected</h5>
                                </>
                            ) : (
                                <>
                                    {props.diseaseData.notUse.length !== 0 &&
                                        <>
                                            <TableStyle>
                                                <SummaryTable columns={NotSelectedColumns} data={props.diseaseData.notUse} tableId={`${props.crop.toLowerCase()}-diseases-no-selected`}></SummaryTable>
                                            </TableStyle>
                                        </>
                                    }
                                    <h5>Use in current cropping season</h5>
                                    {props.diseaseData.inUse.length === 0 ? (
                                        <>
                                            <p>
                                                <ExclamationTriangle color="#FFDB58" size={25} className='mr-2' />
                                                No control measures selected
                                            </p>
                                        </>
                                    ) : (
                                        <TableStyle>
                                            <SummaryTable columns={CurrentColumns} data={props.diseaseData.inUse} tableId={`${props.crop.toLowerCase()}-diseases-current-selected`}></SummaryTable>
                                        </TableStyle>
                                    )}
                                    <h5>Intend to use in future seasons</h5>
                                    {props.diseaseData.futureUse.length === 0 ? (
                                        <>
                                            <p>
                                                <ExclamationTriangle color="#FFDB58" size={25} className='mr-2' />
                                                No control measures selected
                                            </p>
                                        </>
                                    ) : (
                                        <TableStyle>
                                            <SummaryTable columns={FutureColumns} data={props.diseaseData.futureUse} tableId={`${props.crop.toLowerCase()}-diseases-future-selected`}></SummaryTable>
                                        </TableStyle>
                                         )}
                                         <h5>Control measure notes</h5>
                                         {
                                             (diseaseControls.map(control => control.notes).filter(notes => notes !== undefined).length !== 0) ? (
                                                 <>
                                                     <TableStyle>
                                                         <SummaryNotesTables columns={ColumnsNotes} data={diseaseControls} tableId={`${props.crop.toLowerCase()}-diseases-notes`} ></SummaryNotesTables>
                                                     </TableStyle>
                                                 </>
                                             ) : (
                                                 <p>
                                                     <ExclamationTriangle color="#FFDB58" size={25} className='me-2' />
                                                     No notes
                                                 </p>
                                             )
                                         }  
                                </>
                            )
                        }
                    </div>
                )
            }
            {
                props.listOfWeeds !== undefined && (
                    <div>
                        <h4 className="fw-bolder text-decoration-underline">Weeds</h4>
                         {(props.listOfWeeds.length === 0) ? (
                            <>
                                <h5>No issues selected</h5>
                            </>
                        ) : (
                            <>

                                     {
                                         (props.weedsData.notUse.length === 0) ? (
                                             <>
                                             </>
                                         ) : (
                                                 (props.weedsData.notUse[0].pestKeys.length === 0) ? (
                                                     <>
                                                     </>
                                                 ) : (
                                                     <>
                                                         <TableStyle>
                                                             <SummaryTable columns={NotSelectedColumns} data={props.weedsData.notUse} tableId={`${props.crop.toLowerCase()}-weeds-no-selected`}></SummaryTable>
                                                         </TableStyle>
                                                     </>
                                                 )
                                         )

                                         
                                     }
                                <h5>Use in current cropping season</h5>
                                {props.weedsData.inUse.length === 0 ? (
                                    <>
                                        <p>
                                            <ExclamationTriangle color="#FFDB58" size={25} className='mr-2' />
                                            No control measures selected
                                        </p>
                                    </>
                                ) : (
                                    <TableStyle>
                                        <SummaryTable columns={CurrentColumns} data={props.weedsData.inUse} crop={props.crop} tableId={`${props.crop.toLowerCase()}-weeds-current-selected`}></SummaryTable>
                                    </TableStyle>
                                )}
                                <h5>Intend to use in future seasons</h5>
                                {props.weedsData.futureUse.length === 0 ? (
                                    <>
                                        <p>
                                            <ExclamationTriangle color="#FFDB58" size={25} className='mr-2' />
                                            No control measures selected
                                        </p>
                                    </>
                                ) : (
                                    <TableStyle>
                                        <SummaryTable columns={FutureColumns} data={props.weedsData.futureUse} crop={props.crop} tableId={`${props.crop.toLowerCase()}-weeds-future-selected`}></SummaryTable>
                                    </TableStyle>
                                     )}
                                     <h5>Control measure notes</h5>
                                     {
                                         (weedControls.map(control => control.notes).filter(notes => notes !== undefined).length !== 0) ? (
                                             <>
                                                 <TableStyle>
                                                     <SummaryNotesTables columns={ColumnsNotes} data={weedControls} tableId={`${props.crop.toLowerCase()}-weeds-notes`} ></SummaryNotesTables>
                                                 </TableStyle>
                                             </>
                                         ) : (
                                             <p>
                                                 <ExclamationTriangle color="#FFDB58" size={25} className='me-2' />
                                                 No notes
                                             </p>
                                         )
                                     }
                            </>
                        )}
                    </div>
                )
            }
        </>
    )
}

export default SummaryIssuesTables;