const getListOfCrops = (landParcels, allowOther = false) => {
    let listOfCrops = [];
    if (landParcels == null) return;
    landParcels.forEach(
        function callback(parcel) {
            let crops = parcel.crops.map(function (crop) {
                if (crop.cropType.toLowerCase() !== 'other') {
                    listOfCrops.push(crop.cropType)
                }
                else if (crop.cropType.toLowerCase() === 'other' && allowOther) {
                    listOfCrops.push({
                        cropType: crop.cropType,
                        name: crop.name
                    });
                }
            });
        }
    );

    let uniqueCrops = [...new Set(listOfCrops)];
    if (allowOther) {
        uniqueCrops = uniqueCrops.filter((item, index, self) => {
            return item.cropType !== "other" || index === self.findIndex(t => t.cropType === "other");
        });
    }

    return uniqueCrops.sort();
}

export default getListOfCrops;