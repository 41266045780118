const AllCropsWeedsList = ({ issues, toDisplay }) => {
    if (issues.length === 0) {
        return <></>;
    }
    const values = issues.filter(value => toDisplay.includes(value));
    if (values.length === 0) {
        return <></>;
    } return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                <li className="list-group-item list-group-item-success">
                    General Weeds
                </li>
                {values.includes('creepingThistle'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/distribution-and-biology-of-creeping-thistle-in-the-uk" target="_blank" rel="noreferrer">Creeping thistle</a>
                    </li>
                    : <></>
                }
                {values.includes('volunteerPotatoes'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/distribution-and-biology-of-potato-volunteer-weeds-in-the-uk" target="_blank" rel="noreferrer">Volunteer Potatoes</a>
                    </li>
                    : <></>
                }
                {values.includes('weedBeet'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/distribution-and-biology-of-sugar-beet-volunteer-weeds-in-the-uk" target="_blank" rel="noreferrer">Weed beet</a>
                    </li>
                    : <></>
                }
                {values.includes('wildOats'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/distribution-and-biology-of-wild-oat-in-the-uk" target="_blank" rel="noreferrer">Wild oats</a>
                    </li>
                    : <></>
                }
                {values.includes('couch'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/distribution-and-biology-of-common-couch-in-the-uk" target="_blank" rel="noreferrer">Couch</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default AllCropsWeedsList;