import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import LoadingIndicator from "../../common/loadingIndicator";
import loadingStatus from "../../../helpers/loadingStatus";
import useJsonForm from '../../../hooks/useJsonForm';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import usePutRequest from '../../../hooks/usePutRequest';
import getListOfNonOtherCrops from '../../../helpers/getListOfNonOtherCrops';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import removeOldIssues from '../../../helpers/removeOldIssues';
import UrlControl, {
    urlControlTester,
} from '../../customControls/UrlControl';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../customControls/BenchMarkingControl';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import SurveyNavigation from '../surveyNavigation';
import getListOfOtherCrops from '../../../helpers/getListOfOtherCrops';

const AddPest = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });
    const { put } = usePutRequest(`/api/survey/${location.state?.surveyData?.id}`, location.state?.surveyData);
    const { jsonForm, loadingState } = useJsonForm(`63591e0c02daf0aa771cefd4`);

    const initialData = (location.state !== null && location.state.surveyData !== null && location.state.surveyData.pests !== null) ?
        location.state.surveyData.pests :
        {
            'apple': {}, 'brassicas': {}, 'improvedGrassland': {}, 'maize': {},
            'oilseedRape': {}, 'peas': {}, 'potatoes': {}, 'sugarBeet': {},
            'springBarley': {}, 'springBeans': {}, 'springOats': {}, 'springWheat': {},
            'winterBarley': {}, 'winterBeans': {}, 'winterOats': {}, 'winterWheat': {},
        };

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const [allData] = useState(initialData);
    const [appleData, setAppleData] = useState({ 'apple': initialData.apple });
    const [brassicasData, setBrassicasData] = useState({ 'brassicas': initialData.brassicas });
    const [improvedGrasslandData, setImprovedGrasslandData] = useState({ 'improvedGrassland': initialData.improvedGrassland });
    const [maizeData, setMaizeData] = useState({ 'maize': initialData.maize });
    const [oilseedRapeData, setOilseedRapeData] = useState({ 'oilseedRape': initialData.oilseedRape });
    const [peasData, setPeasData] = useState({ 'peas': initialData.peas });
    const [potatoesData, setPotatoesData] = useState({ 'potatoes': initialData.potatoes });
    const [sugarBeetData, setSugarBeetData] = useState({ 'sugarBeet': initialData.sugarBeet });
    const [springBarleyData, setSpringBarleyData] = useState({ 'springBarley': initialData.springBarley });
    const [springBeansData, setSpringBeansData] = useState({ 'springBeans': initialData.springBeans });
    const [springOatsData, setSpringOatsData] = useState({ 'springOats': initialData.springOats });
    const [springWheatData, setSpringWheatData] = useState({ 'springWheat': initialData.springWheat });
    const [winterBarleyData, setWinterBarleyData] = useState({ 'winterBarley': initialData.winterBarley });
    const [winterBeansData, setWinterBeansData] = useState({ 'winterBeans': initialData.winterBeans });
    const [winterOatsData, setWinterOatsData] = useState({ 'winterOats': initialData.winterOats });
    const [winterWheatData, setWinterWheatData] = useState({ 'winterWheat': initialData.winterWheat });

    const currentMeasuresIndex = 0;
    const listOfCrops = getListOfNonOtherCrops(location.state?.surveyData.landParcels);
    removeOldIssues(location.state?.surveyData?.pests, listOfCrops);
    removeOldIssues(location.state?.surveyData?.diseases, listOfCrops);

    let prevUrl;
    if (listOfCrops.includes("improvedgrassland")) {
        prevUrl = "/add-weeds-improvedgrassland-measures";
    } else if (listOfCrops.includes("apple")) {
        prevUrl = "/add-weeds-apple-measures";
    } else {
        prevUrl = "/add-weeds-resistance";
    }

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    const onAddPestSubmitClick = async (goToNextPage) => {
        allData.apple = appleData.apple;
        allData.brassicas = brassicasData.brassicas;
        allData.improvedGrassland = improvedGrasslandData.improvedGrassland;
        allData.maize = maizeData.maize;
        allData.oilseedRape = oilseedRapeData.oilseedRape;
        allData.peas = peasData.peas;
        allData.potatoes = potatoesData.potatoes;
        allData.sugarBeet = sugarBeetData.sugarBeet;
        allData.springBarley = springBarleyData.springBarley;
        allData.springBeans = springBeansData.springBeans;
        allData.springOats = springOatsData.springOats;
        allData.springWheat = springWheatData.springWheat;
        allData.winterBarley = winterBarleyData.winterBarley;
        allData.winterBeans = winterBeansData.winterBeans;
        allData.winterOats = winterOatsData.winterOats;
        allData.winterWheat = winterWheatData.winterWheat;

        location.state.surveyData.pests = allData;
        const response = await put();
        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!goToNextPage) {
            toast.info("Saved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
      //  let listOfOtherCrops = getListOfOtherCrops(location.state?.surveyData.landParcels)
        let cropsWithMeasures = location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther);
        let finalUrl
        if (cropsWithMeasures.length !== 0) {
            finalUrl = `/add-pests-measures/${cropsWithMeasures[currentMeasuresIndex].cropType}`
        }
        else {
            finalUrl = '/add-pests-other'
        }
        navigate(finalUrl,
            {
                state: {
                    farmData: location.state.farmData,
                    surveyData: location.state.surveyData,
                    crops: listOfCrops,
                    index: currentMeasuresIndex
                }
            });
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: urlControlTester,
            renderer: UrlControl,
        },
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year,
            sectionString: "To see how other users assess this risk :"
        },
    ];

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>pest issues</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category you feel best represents the level of risk on your farm</p>
            </div>
            <div className="row my-3">
                {listOfCrops.length === 0 &&
                    <h3>No crops selected</h3>
                }
                {listOfCrops.includes('apple'.toLowerCase()) &&
                    <>
                        <h2>Apple</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={appleData}
                            uischema={uiSchema.uiSchemas.apple}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setAppleData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('brassicas'.toLowerCase()) &&
                    <>
                        <h2>Brassicas</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={brassicasData}
                            uischema={uiSchema.uiSchemas.brassicas}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setBrassicasData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('improvedgrassland'.toLowerCase()) &&
                    <>
                        <h2>Improved Grassland</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={improvedGrasslandData}
                            uischema={uiSchema.uiSchemas.improvedGrassland}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setImprovedGrasslandData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('maize'.toLowerCase()) &&
                    <>
                        <h2>Maize</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={maizeData}
                            uischema={uiSchema.uiSchemas.maize}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setMaizeData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('oilseedRape'.toLowerCase()) &&
                    <>
                        <h2>Oilseed Rape</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={oilseedRapeData}
                            uischema={uiSchema.uiSchemas.oilseedRape}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setOilseedRapeData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('peas'.toLowerCase()) &&
                    <>
                        <h2>Peas</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={peasData}
                            uischema={uiSchema.uiSchemas.peas}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setPeasData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('potatoes'.toLowerCase()) &&
                    <>
                        <h2>Potatoes</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={potatoesData}
                            uischema={uiSchema.uiSchemas.potatoes}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setPotatoesData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('sugarbeet'.toLowerCase()) &&
                    <>
                        <h2>Sugar Beet</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={sugarBeetData}
                            uischema={uiSchema.uiSchemas.sugarBeet}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setSugarBeetData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('springbarley'.toLowerCase()) &&
                    <>
                        <h2>Spring Barley</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={springBarleyData}
                            uischema={uiSchema.uiSchemas.springBarley}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setSpringBarleyData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('springbeans'.toLowerCase()) &&
                    <>
                        <h2>Spring Beans</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={springBeansData}
                            uischema={uiSchema.uiSchemas.springBeans}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setSpringBeansData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('springoats'.toLowerCase()) &&
                    <>
                        <h2>Spring Oats</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={springOatsData}
                            uischema={uiSchema.uiSchemas.springOats}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setSpringOatsData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('springwheat'.toLowerCase()) &&
                    <>
                        <h2>Spring Wheat</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={springWheatData}
                            uischema={uiSchema.uiSchemas.springWheat}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setSpringWheatData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('winterbarley'.toLowerCase()) &&
                    <>
                        <h2>Winter Barley</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={winterBarleyData}
                            uischema={uiSchema.uiSchemas.winterBarley}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setWinterBarleyData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('winterbeans'.toLowerCase()) &&
                    <>
                        <h2>Winter Beans</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={winterBeansData}
                            uischema={uiSchema.uiSchemas.winterBeans}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setWinterBeansData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('winteroats'.toLowerCase()) &&
                    <>
                        <h2>Winter Oats</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={winterOatsData}
                            uischema={uiSchema.uiSchemas.winterOats}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setWinterOatsData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
                {listOfCrops.includes('winterwheat'.toLowerCase()) &&
                    <>
                        <h2>Winter Wheat</h2>
                        <JsonForms validationMode={'NoValidation'}
                            schema={schema}
                            data={winterWheatData}
                            uischema={uiSchema.uiSchemas.winterWheat}
                            renderers={renderers}
                            cells={materialCells}
                            onChange={({ errors, data }) => setWinterWheatData(data)}
                            readonly={status === 'completed'}
                        />
                    </>
                }
            </div>
            <div className="row justify-content-between my-5">
                <div className="alert alert-info" role="alert">
                    To save your work, click <strong>Save</strong> before using the <strong>Go To</strong> or <strong>Go back</strong> button.
                </div>
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                <Link className="col-2 mx-3"
                    to={ prevUrl }
                    state={{
                        farmData: location.state.farmData,
                        surveyData: location.state.surveyData,
                        crops: listOfCrops
                    }}>
                    <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                </Link>
                <SurveyNavigation farmData={location.state.farmData} surveyData={location.state.surveyData} crops={location.state.crops} />
                <button type="button" className="col-2 mx-3 btn btn-primary" onClick={() => onAddPestSubmitClick(false)} disabled={status === 'completed'} >Save</button>
                {status === 'completed' ? (
                    <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddPestSubmitClick(true)}>Continue</button>
                ) : (
                    <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddPestSubmitClick(true)}>Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight></button>
                )}
            </div>
        </>
    );
};

export default AddPest;