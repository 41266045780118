import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import { QuestionCircle } from 'react-bootstrap-icons';
import PeasPestList from './peasPestList';
import MeasuresButtons from '../measuresButtons';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddPeasPestMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`63a182dafc7fb794cae43b8a`);


    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.peas !== null)
        && (location.state.surveyData.pests.peas !== undefined)
        && (location.state.surveyData.pests.peas.controlMeasures !== null)
        && (location.state.surveyData.pests.peas.controlMeasures !== undefined)) ?
        location.state.surveyData.pests.peas.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.peas);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.peas.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>peas pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>

            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('peaAphid'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Bioprotectants Natural Substances</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#bioprotectantsNaturalSubstancesDesc" aria-expanded="false" aria-controls="bioprotectantsNaturalSubstancesDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="bioprotectantsNaturalSubstancesDesc">
                                <p>
                                    Majestik is a contact insecticide containing the natural substance maltodextrin approved for use on all edible crops and has been known to give some reduction of aphid numbers.
                                </p>
                            </div>

                            <PeasPestList listOfIssues={listOfIssues} toDisplay={[
                                'peaAphid'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.bioprotectantsNaturalSubstances}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('peaMoth'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Bioprotectants Semiochemical</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#bioprotectantsSemiochemicalDesc" aria-expanded="false" aria-controls="bioprotectantsSemiochemicalDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="bioprotectantsSemiochemicalDesc">
                                <p>
                                    Pheromone traps are available for pea and bean weevil and pea moth.
                                </p>
                            </div>
                            <PeasPestList listOfIssues={listOfIssues} toDisplay={[
                                'peaMoth'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.bioprotectantsSemiochemical}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('peaAphid'.toLowerCase()) ||
                        listOfIssues.includes('peaMoth'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Decision Support, Incl. Monitoring</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="decisionSupportDesc">
                                    <p>
                                        IPM decisions should be made based on the results of monitoring and forecasting combined with threshold information where available. Treatment thresholds are the population level or density that must be reached before it becomes economically beneficial. Thresholds enable growers to make decisions based on the level at which pests will impact economic crop yield. They are essential in guiding pest control decisions and preventing the unnecessary use of pesticides.
                                    </p>
                                </div>
                                <PeasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'peaAphid'.toLowerCase(),
                                    'peaMoth'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.decisionSupport}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('peaAphid'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Select Low-Risk Locations</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="lowRiskLocationsDesc">
                                <p>
                                    Some pests are relatively immobile, and numbers increase only when their host crop is grown too often in the same field. To limit the impact of bean weevils and pea aphids, sites where infestations have previously occurred should be avoided. Other pests such as wireworms have a long-life cycle so need the host crop to remain for several years. Growing alternative, non-host crops for appropriate periods can avoid this build-up. The length of the break may vary between pests.
                                </p>
                            </div>

                            <PeasPestList listOfIssues={listOfIssues} toDisplay={[
                                'peaAphid'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.lowRiskLocations}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('peaAphid'.toLowerCase()) ||
                        listOfIssues.includes('peaMoth'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>In Field Non-Cropped Areas</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#nonCroppedAreaDesc" aria-expanded="false" aria-controls="nonCroppedAreaDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="nonCroppedAreaDesc">
                                    <p>
                                        Beetle banks consist of stands of wildflowers and grasses and are designed to act as reservoirs of beneficial insects such as ground beetles and parasitoids, which help to provide natural biological control of insect pests. Diverse crop margins and strips act in a similar way to beetle banks to increase natural enemies. More diverse strips should harbour greater biodiversity and greater numbers of beneficials. Ladybirds, hoverflies and lacewings are natural enemies of pea aphids, and the pea moth is attacked by four species of parasitic wasp.
                                    </p>
                                </div>

                                <PeasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'peaAphid'.toLowerCase(),
                                    'peaMoth'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.nonCroppedArea}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                </>
            )}

            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddPeasPestMeasures;