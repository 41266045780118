const PeasDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('botrytis'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://www.pgro.org/pests-and-diseases1/" target="_blank" rel="noreferrer">Botrytis</a>
                    </li>
                    : <></>
                }
                {values.includes('rust'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://www.pgro.org/pests-and-diseases1/" target="_blank" rel="noreferrer">Rust</a>
                    </li>
                    : <></>
                }
                {values.includes('sclerotinia'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://www.pgro.org/pests-and-diseases1/" target="_blank" rel="noreferrer">Sclerotinia</a>
                    </li>
                    : <></>
                }
            </ul>
        </>
    );
};

export default PeasDiseaseList;