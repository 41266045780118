import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import LoadingIndicator from "../../common/loadingIndicator";
import loadingStatus from "../../../helpers/loadingStatus";
import useJsonForm from '../../../hooks/useJsonForm';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import usePutRequest from '../../../hooks/usePutRequest';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import getListOfCrops from '../../../helpers/getListOfCrops';
import UrlControl, {
    urlControlTester,
} from '../../customControls/UrlControl';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import SurveyNavigation from '../surveyNavigation';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../customControls/BenchMarkingControl';

const AddAppleWeeds = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { put } = usePutRequest(`/api/survey/${location.state?.surveyData?.id}`, location.state?.surveyData);
    const { jsonForm, loadingState } = useJsonForm(`637f2fa2f16228a14699718d`);

    if (location.state.surveyData.weeds === null) location.state.surveyData.weeds = {};
    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && (location.state.surveyData.weeds !== null)
        && (location.state.surveyData.weeds !== undefined)) ?
        location.state.surveyData.weeds : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const [data, setData] = useState(initialData);
    const listOfCrops = getListOfCrops(location.state?.surveyData.landParcels);
    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    const formOnChange = ({ data }) => {
        setData(data);
        location.state.surveyData.weeds.apple = data.apple;
    };

    const onAddWeedSubmitClick = async (goToNextPage) => {
        setData(data);
        location.state.surveyData.weeds.apple = data.apple;
        const response = await put();

        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!goToNextPage) {
            toast.info("Saved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        let finalUrl = "/add-weeds-apple-measures";

        navigate(finalUrl,
            {
                state: {
                    farmData: location.state.farmData,
                    surveyData: location.state.surveyData,
                    crops: location.state.crops
                }
            });
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: urlControlTester,
            renderer: UrlControl,
        },
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year,
            sectionString: "To see how other users assess this risk :"
        },
    ];

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>apple weed issues</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category you feel best represents the level of risk on your farm</p>
            </div>
            <div className="row my-3">
                <h2>Weed Issues for Apple crop</h2>
                <JsonForms validationMode={'NoValidation'}
                    schema={schema}
                    data={data}
                    uischema={uiSchema.uiSchemas.apple}
                    renderers={renderers}
                    cells={materialCells}
                    onChange={({ data }) => formOnChange({ data })}
                    readonly={status === 'completed'}
                />
            </div>
            <div className="row justify-content-between my-5">
                <div className="alert alert-info" role="alert">
                    To save your work, click <strong>Save</strong> before using the <strong>Go To</strong> or <strong>Go back</strong> button.
                </div>
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                <Link className="col-2 mx-3"
                    to="/add-weeds-resistance"
                    state={{
                        farmData: location.state.farmData,
                        surveyData: location.state.surveyData,
                        crops: location.state.crops
                    }}>
                    <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                </Link>
                <SurveyNavigation farmData={location.state.farmData} surveyData={location.state.surveyData} crops={location.state.crops} />
                <button type="button" className="col-2 mx-3 btn btn-primary" onClick={() => onAddWeedSubmitClick(false)} disabled={status === 'completed'} >Save</button>
                {status === 'completed' ? (
                    <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddWeedSubmitClick(true)}>Continue</button>
                ) : (
                    <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddWeedSubmitClick(true)}>Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight></button>
                )}
            </div>
        </>
    );
};

export default AddAppleWeeds;