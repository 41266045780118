const CropOptions = [
    {
        "cropType": "apple",
        "title": "Apple"
    },
    {
        "cropType": "brassicas",
        "title": "Brassicas"
    },
    {
        "cropType": "improvedgrassland",
        "title": "Improved Grassland"
    },
    {
        "cropType": "maize",
        "title": "Maize"
    },
    {
        "cropType": "oilseedrape",
        "title": "Oilseed Rape"
    },
    {
        "cropType": "peas",
        "title": "Peas"
    },
    {
        "cropType": "potatoes",
        "title": "Potatoes"
    },
    {
        "cropType": "sugarbeet",
        "title": "Sugar Beet"
    },
    {
        "cropType": "springbarley",
        "title": "Spring Barley"
    },
    {
        "cropType": "springbeans",
        "title": "Spring Beans"
    },
    {
        "cropType": "springoats",
        "title": "Spring Oats"
    },
    {
        "cropType": "springwheat",
        "title": "Spring Wheat"
    },
    {
        "cropType": "winterbarley",
        "title": "Winter Barley"
    },
    {
        "cropType": "winterbeans",
        "title": "Winter Beans"
    },
    {
        "cropType": "winteroats",
        "title": "Winter Oats"
    },
    {
        "cropType": "winterwheat",
        "title": "Winter Wheat"
    }
];

export default CropOptions;