const PeasPestList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('peaAphid'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://www.pgro.org/downloads/Peaaphidcontrol.pdf" target="_blank" rel="noreferrer">Pea Aphid</a>
                    </li>
                    : <></>
                }
                {values.includes('peaMoth'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/biology-and-management-of-the-pea-moth" target="_blank" rel="noreferrer">Pea Moth</a>
                    </li>
                    : <></>
                }
            </ul>
        </>
    );
};

export default PeasPestList;