import { JsonForms } from '@jsonforms/react';
import { useEffect, useState } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import MeasuresButtons from '../measuresButtons';
import OilseedsDiseaseList from './oilseedsDiseaseList';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';


const AddOilseedsDiseaseMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });
    const { jsonForm, loadingState } = useJsonForm(`63a177ae95e69189d1e34f30`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.diseases !== null
        && (location.state.surveyData.diseases !== undefined)
        && (location.state.surveyData.diseases.oilseedRape !== null)
        && (location.state.surveyData.diseases.oilseedRape !== undefined)
        && location.state.surveyData.diseases.oilseedRape.controlMeasures !== null
        && location.state.surveyData.diseases.oilseedRape.controlMeasures !== undefined) ?
        location.state.surveyData.diseases.oilseedRape.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);
    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    if (initialData === null) initialData.oilseedRape = {};
    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.diseases?.oilseedRape);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.diseases.oilseedRape.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>oilseed rape disease control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            <div className="row my-3">
                {listOfIssues.length === 0 ? (
                    <h3>No issues selected</h3>
                ) : (
                    <>
                        {(
                            listOfIssues.includes('clubroot'.toLowerCase()) ||
                            listOfIssues.includes('sclerotiniaStemRot'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Control volunteers & weeds
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#controlVolunteersDesc" aria-expanded="false" aria-controls="controlVolunteersDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="controlVolunteersDesc">
                                        <p>Oilseed volunteers carry a range of diseases and are most significant as a 'green bridge' for powdery mildew, downy mildew, botrytis and light leaf spot. Ideally volunteers should be destroyed prior to the emergence of new crops. Sclerotinia has a very wide host range and can affect common weeds such as sow-thistle, dandelion and shepherds' purse. Removal of such weed species can reduce the risk of infection.</p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'clubroot'.toLowerCase(),
                                        'sclerotiniaStemRot'.toLowerCase(),
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.controlVolunteers}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                            listOfIssues.includes('phomaStemCanker'.toLowerCase()) ||
                            listOfIssues.includes('sclerotiniaStemRot'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Decision support (incl. thresholds)
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="decisionSupportDesc">
                                        <p>IPM decisions should be made based on the results of monitoring and forecasting combined with threshold information where available. Treatment thresholds are the disease level or density that must be reached before intervention is required or economically beneficial. Thresholds enable growers to make decisions based on the level at which pests will impact economic crop yield. Decision support and disease monitoring tools are available for phoma leaf spot, light leaf spot and sclerotinia stem rot.</p>
                                        <p>Disease forecasts are available from the <a href="https://platform.ipmdecisions.net/" target="_blank" rel="noreferrer">IPM Decisions platform</a>. Forecasts are only active during the growing season.</p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'lightLeafSpot'.toLowerCase(),
                                        'phomaStemCanker'.toLowerCase(),
                                        'sclerotiniaStemRot'.toLowerCase(),
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.decisionSupport}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('clubroot'.toLowerCase()) ||
                            listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                            listOfIssues.includes('phomaStemCanker'.toLowerCase()) ||
                            listOfIssues.includes('sclerotiniaStemRot'.toLowerCase()) ||
                            listOfIssues.includes('verticilliumStemStripe'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Field history, Rotation & break crops
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="fieldHistoryDesc">
                                        <p>Where oilseed rape is grown in short rotations (1 in 3 years), this is likely to increase the build up of soil borne pathogens such as sclerotinia spp., club root, verticillium spp., and pythium spp. Extending rotation to 1 in 5 years or longer is recommended.</p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'clubroot'.toLowerCase(),
                                        'lightLeafSpot'.toLowerCase(),
                                        'phomaStemCanker'.toLowerCase(),
                                        'sclerotiniaStemRot'.toLowerCase(),
                                        'verticilliumStemStripe'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.fieldHistory}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('clubroot'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Good drainage
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#goodDrainageDesc" aria-expanded="false" aria-controls="goodDrainageDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="goodDrainageDesc">
                                        <p>Good drainage is effective for managing clubroot infection and the spread of clubroot in oilseed rape fields. Soils which are compacted, poorly drained or even prone to flooding will encourage proliferation of clubroot zoospores, especially in a warm autumn.</p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'clubroot'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.goodDrainage}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('clubroot'.toLowerCase()) ||
                            listOfIssues.includes('verticilliumStemStripe'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Hygiene and prevention
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="hygieneDesc">
                                        <p>This is the first defence against the introduction of soil-borne diseases into clean land, particularly for soil borne diseases like clubroot and verticillium. Machinery used in infested fields should be power-washed before use in uninfected fields, and soil should at least be knocked off from boots and tools. Clean fields should be visited first in the sequence of crops so that cleaning down equipment can be done at the end of the day.</p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'clubroot'.toLowerCase(),
                                        'verticilliumStemStripe'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.hygiene}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('clubroot'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Lime
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#limeDesc" aria-expanded="false" aria-controls="limeDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="limeDesc">
                                        <p>Crops which are nutrient deficient can be predisposed to disease infection. Ensure appropriate soil nutrient supply by regular soil sampling and testing and use of appropriate fertilisers. Soil pH maintained at 7.0-7.3 provide basic control of clubroot as acidic soils favour disease development. Liming can be beneficial on clubroot infected soils by raising the pH and the free calcium status.</p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'clubroot'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.lime}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                            listOfIssues.includes('phomaStemCanker'.toLowerCase()) ||
                            listOfIssues.includes('sclerotiniaStemRot'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Primary cultivations / Crop residue burial
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#primaryCultivationsDesc" aria-expanded="false" aria-controls="primaryCultivationsDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="primaryCultivationsDesc">
                                        <p>Burial of crop debris by ploughing can reduce inoculum for some pathogens which produce inoculum on plant debris. This is most important for pathogens which are initiated  from air borne  spores, such as light leaf spot and phoma leaf spot, which can infect neighbouring crops. On the negative side the use of ploughing can reduce soil biodiversity. Sclerotia can be deeply buried by ploughing but can remain dormant for many years and will germinate if brought into the topsoil by future cultivations. Sclerotia left on the soil surface can lose viability and reduce the sclerotia bank in the soil, and can form part of a minimum tillage strategy for control. </p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'lightLeafSpot'.toLowerCase(),
                                        'phomaStemCanker'.toLowerCase(),
                                        'sclerotiniaStemRot'.toLowerCase(),
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.primaryCultivations}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('clubroot'.toLowerCase()) ||
                            listOfIssues.includes('sclerotiniaStemRot'.toLowerCase()) ||
                            listOfIssues.includes('verticilliumStemStripe'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Select low-risk locations
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="lowRiskLocationsDesc">
                                        <p>Clubroot has a relatively broad range of hosts including oilseed rape, cabbage, cauliflower, swede, calabrese, mustard and weed species shepherds purse and charlock. Sclerotinia can also infect crops of potatoes, dwarf beans, carrots, celery, lettuce, peas and spring field beans. Fields with a previous history of these diseases in these hosts crops and a history of verticillium should be avoided for planting oilseed rape.</p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'clubroot'.toLowerCase(),
                                        'sclerotiniaStemRot'.toLowerCase(),
                                        'verticilliumStemStripe'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.lowRiskLocations}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('clubroot'.toLowerCase()) ||
                            listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                            listOfIssues.includes('phomaStemCanker'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Sowing Date
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#sowingDateDesc" aria-expanded="false" aria-controls="sowingDateDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="sowingDateDesc">
                                        <p>Early sown oilseed crops tend to suffer more damage from light leaf spot and phoma leaf spot than later  sown crops, but although incidence of the disease may be more common on earlier sown crops it may have less impact due to larger plant size.                              Delaying sowing can reduce the severity of some diseases.  For example, late drilling substantially reduces the risk from light leaf spot and phoma leaf spot, as later emerging crops have less time exposure to air spores, which initiate autumn infections, from previous crop residues. However later sown oilseed rape crops can be at greater risk from developing stem canker from autumn phoma leaf infections due to the smaller plant size. Later drilling can reduce the risk of autumn clubroot infections, as host infection is favoured by temperatures above 16oC, but crops will need to be monitored as temperatures warm into the spring.    </p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'clubroot'.toLowerCase(),
                                        'lightLeafSpot'.toLowerCase(),
                                        'phomaStemCanker'.toLowerCase(),
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.sowingDate}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                            listOfIssues.includes('phomaStemCanker'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Spatial Separation
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#spatialSeparationDesc" aria-expanded="false" aria-controls="spatialSeparationDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="spatialSeparationDesc">
                                        <p>Spatial separation of new oilseed rape crops as far away as possible from previous fields is advised, as it reduces the risk of infection from diseases such as light leaf spot and phoma leaf spot which are initiated from air borne spores from the previous crops stubbles and plant debris.</p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'lightLeafSpot'.toLowerCase(),
                                        'phomaStemCanker'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.spatialSeparation}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('clubroot'.toLowerCase()) ||
                            listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                            listOfIssues.includes('phomaStemCanker'.toLowerCase()) ||
                            listOfIssues.includes('sclerotiniaStemRot'.toLowerCase()) ||
                            listOfIssues.includes('verticilliumStemStripe'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Varietal Choice
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#varietalChoiceDesc" aria-expanded="false" aria-controls="varietalChoiceDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="varietalChoiceDesc">
                                        <p>Resistant varieties are a key part of non-chemical disease control. There are good sources of information on disease resistance to many of the major pathogens in the Recommended List of oilseeds varieties published by AHDB.  This information is updated annually to account for new pathogen strains which can infect previously resistant varieties. </p>
                                        <p>For specific guidance click <a href=" https://ahdb.org.uk/knowledge-library/recommended-lists-for-cereals-and-oilseeds-rl" target="_blank" rel="noreferrer">here</a></p>
                                    </div>
                                    <OilseedsDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'clubroot'.toLowerCase(),
                                        'lightLeafSpot'.toLowerCase(),
                                        'phomaStemCanker'.toLowerCase(),
                                        'sclerotiniaStemRot'.toLowerCase(),
                                        'verticilliumStemStripe'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.varietalChoice}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                    </>
                )}
            </div>
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"diseases"}
                    finalUrl={"/add-diseases-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddOilseedsDiseaseMeasures;