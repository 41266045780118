import { useEffect, useState } from 'react';
import useGetRequest from '../../hooks/useGetRequest';
import { CSVLink } from "react-csv";
import userDataHeaders from '../../helpers/userDataHeaders'

const UsersData = () => {

    useEffect(() => {
        handleDataFetch();
    }, [])

    const { get } = useGetRequest(`/api/admin/usersdata`);
    const [listOfIpmPlans, setListOfIpmPlans] = useState([]);
    const [loading, setLoading] = useState(false);
    const headers = userDataHeaders;

    const handleDataFetch = async () => {
        setLoading(true);
        const data = await get();
        if (data !== undefined) {
            setListOfIpmPlans(data);
            setLoading(false);
        }
    }
    return (
        <>
            <h3 className='mt-4'>Get Users Data</h3>
            <CSVLink
                data={listOfIpmPlans}
                headers={headers}
                filename={`ipmToolUserData_${new Date().toLocaleString()}.csv`}
                className="btn btn-primary"
                target="_blank"
            >
                {loading ? 'Loading csv...' : 'Download User Data'}
            </CSVLink>
        </>
    );
};

export default UsersData;