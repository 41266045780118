import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import { QuestionCircle } from 'react-bootstrap-icons';
import ImprovedGrasslandPestList from './improvedGrasslandPestList';
import MeasuresButtons from '../measuresButtons';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddImprovedGrasslandPestMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`6380b5dae250d623d32622c3`);



    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.improvedGrassland !== null)
        && (location.state.surveyData.pests.improvedGrassland !== undefined)
        && (location.state.surveyData.pests.improvedGrassland.controlMeasures !== null)
        && (location.state.surveyData.pests.improvedGrassland.controlMeasures !== undefined)) ?
        location.state.surveyData.pests.improvedGrassland.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.improvedGrassland);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.improvedGrassland.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>improved grassland pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement </p>
            </div>

            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase()) ||
                        listOfIssues.includes('cloverWeevil'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Avoid Following Long-term Leys</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#longTermLeysDesc" aria-expanded="false" aria-controls="longTermLeysDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="longTermLeysDesc">
                                    <p>
                                        Populations of some pests can build up under long-term leys such as wireworms and leatherjackets.  These will potentially feed on any crop following a grass ley, including a grass reseed.
                                    </p>
                                </div>

                                <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                    'fritFly'.toLowerCase(),
                                    'leatherjackets'.toLowerCase(),
                                    'wireworms'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                    'cloverWeevil'.toLowerCase()

                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.longTermLeys}
                                    cells={materialCells}
                                    renderers={renderers}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('fritFly'.toLowerCase())) && (
                        <>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Sowing Date</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#sowingDateDesc" aria-expanded="false" aria-controls="sowingDateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="sowingDateDesc">
                                <p>
                                    Autumn reseeds are the most susceptible to frit fly infestation as this is the main egg laying period for frit fly. Moving the sowing date to spring instead of autumn or delaying sowing for at least six weeks after ploughing are effective ways of reducing damage.
                                </p>
                            </div>
                            <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                'fritFly'.toLowerCase()

                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.sowingDate}
                                cells={materialCells}
                                renderers={renderers}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase()) ||
                        listOfIssues.includes('cloverWeevil'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Extra Cultivations</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#extraCultivationsDesc" aria-expanded="false" aria-controls="extraCultivationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="extraCultivationsDesc">
                                    <p>
                                        The mechanical action of cultivations can reduce soil populations of pests such including wireworms, leatherjackets and slugs.  This can occur as the pests are killed or pests are brought to the surface and eaten by birds. On the negative side increased cultivations increase carbon footprint and can reduce soil biodiversity.
                                    </p>
                                </div>

                                <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                    'leatherjackets'.toLowerCase(),
                                    'wireworms'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                    'cloverWeevil'.toLowerCase()

                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.extraCultivations}
                                    cells={materialCells}
                                    renderers={renderers}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Larvae Counts in Soil</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#larvaeCountsInSoilDesc" aria-expanded="false" aria-controls="larvaeCountsInSoilDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="larvaeCountsInSoilDesc">
                                    <p>
                                        It is possible to take soil samples to estimate pest numbers and assess the risk of economic damage to the following crop.  Examples are wireworms and leatherjackets.
                                    </p>
                                </div>


                                <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                    'leatherjackets'.toLowerCase(),
                                    'wireworms'.toLowerCase()

                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.larvaeCountsInSoil}
                                    cells={materialCells}
                                    renderers={renderers}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Pest Monitoring</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#pestMonitoringDesc" aria-expanded="false" aria-controls="pestMonitoringDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="pestMonitoringDesc">
                                    <p>
                                        Pest monitoring is an essential component of pest management.  This can involve visual inspection of the crop or some sort of trapping system.
                                    </p>
                                </div>

                                <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                    'fritFly'.toLowerCase(),
                                    'leatherjackets'.toLowerCase(),
                                    'wireworms'.toLowerCase(),
                                    'slugs'.toLowerCase()

                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.pestMonitoring}
                                    cells={materialCells}
                                    renderers={renderers}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('wireworms'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Rolling Soil Post-Planting</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#rollingSoilPostPlantingDesc" aria-expanded="false" aria-controls="rollingSoilPostPlantingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="rollingSoilPostPlantingDesc">
                                    <p>
                                        Rolling soil post-planting can improve the seedbed quality, resulting in more rapid germination of crops.  Rolling may also kill some pests or reduce their mobility.
                                    </p>
                                </div>

                                <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                    'wireworms'.toLowerCase(),
                                    'slugs'.toLowerCase()

                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.rollingSoilPostPlanting}
                                    cells={materialCells}
                                    renderers={renderers}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('fritFly'.toLowerCase())) && (
                        <>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Narrow Stemmed Grasses</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#narrowStemmedGrassesDesc" aria-expanded="false" aria-controls="narrowStemmedGrassesDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="narrowStemmedGrassesDesc">
                                <p>
                                    Clover, timothy and cocksfoot are not affected by frit fly, as they have a narrower stem compared to perennial and Italian ryegrass.
                                </p>
                            </div>

                            <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                'fritFly'.toLowerCase()

                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.narrowStemmedGrasses}
                                cells={materialCells}
                                renderers={renderers}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(
                        listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('leatherjackers'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase()) ||
                        listOfIssues.includes('cloverStemNematode'.toLowerCase())

                    ) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Field History, Rotation & Break Crops</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="fieldHistoryDesc">
                                    <p>
                                        Where grass leys are being reseeded, the inclusion of a break crop such as a brassica before reseeding can help control frit fly, wireworm and leatherjackets. For red clover, stem nematodes are best avoided by rotation and a break of at least 3 years.  Different strains of stem nematode effect white and red clover, so white clover can be sown between red clover crops to break the pest cycle.
                                    </p>
                                </div>

                                <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                    'fritFly'.toLowerCase(),
                                    'leatherjackets'.toLowerCase(),
                                    'wireworms'.toLowerCase(),
                                    'cloverStemNematode'.toLowerCase()

                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.fieldHistory}
                                    cells={materialCells}
                                    renderers={renderers}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('cloverStemNematode'.toLowerCase())) && (
                        <>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Hygiene And Prevention</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#hygieneAndPreventionDesc" aria-expanded="false" aria-controls="hygieneAndPreventionDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="hygieneAndPreventionDesc">
                                <p>
                                    Proper sanitation in fields and of equipment is essential in preventing and controlling the spread of stem nematode, because they can survive and reproduce in infected plants and residues.  Machinery used in infested fields should be power-washed before use in uninfected fields, and soil should at least be knocked off from boots and tools. Where possible, all infected plant tissues should be removed from growing sites and destroyed to control populations.
                                </p>
                            </div>

                            <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                'cloverStemNematode'.toLowerCase()

                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.hygieneAndPrevention}
                                cells={materialCells}
                                renderers={renderers}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('fritFly'.toLowerCase()) ||
                        listOfIssues.includes('leatherjackets'.toLowerCase()) ||
                        listOfIssues.includes('wireworms'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase()) ||
                        listOfIssues.includes('cloverWeevil'.toLowerCase()) ||
                        listOfIssues.includes('cloverStemNematode'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Nutrient Management</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#nutrientManagementDesc" aria-expanded="false" aria-controls="nutrientManagementDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="nutrientManagementDesc">
                                    <p>
                                        Excessive nitrogen will exacerbate insect pests which thrive in a dense lush canopy. Avoid excessive nitrogen application whilst ensuring the correct phosphorous, potash and pH status to stimulate vigorous growth.
                                    </p>
                                </div>

                                <ImprovedGrasslandPestList listOfIssues={listOfIssues} toDisplay={[
                                    'fritFly'.toLowerCase(),
                                    'leatherjackets'.toLowerCase(),
                                    'wireworms'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                    'cloverWeevil'.toLowerCase()

                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.nutrientManagement}
                                    cells={materialCells}
                                    renderers={renderers}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                </>
            )}

            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddImprovedGrasslandPestMeasures;