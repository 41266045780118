const CombineCropsWeedsList = ({ issues, toDisplay, listOfCrops }) => {
    if (issues.length === 0) {
        return <></>;
    }
    const values = issues.filter(value => toDisplay.includes(value));
    const cropsToDisplay = [];
    if (listOfCrops.includes('oilseedrape'.toLowerCase())) cropsToDisplay.push('Oilseed Rape');
    if (listOfCrops.includes('peas'.toLowerCase())) cropsToDisplay.push('Peas');
    if (listOfCrops.includes('springbarley'.toLowerCase())) cropsToDisplay.push('Spring Barley');
    if (listOfCrops.includes('springbeans'.toLowerCase())) cropsToDisplay.push('Spring Beans');
    if (listOfCrops.includes('springoats'.toLowerCase())) cropsToDisplay.push('Spring Oats');
    if (listOfCrops.includes('springwheat'.toLowerCase())) cropsToDisplay.push('Spring Wheat');
    if (listOfCrops.includes('winterbarley'.toLowerCase())) cropsToDisplay.push('Winter Barley');
    if (listOfCrops.includes('winterbeans'.toLowerCase())) cropsToDisplay.push('Winter Beans');
    if (listOfCrops.includes('winteroats'.toLowerCase())) cropsToDisplay.push('Winter Oats');
    if (listOfCrops.includes('winterwheat'.toLowerCase())) cropsToDisplay.push('Winter Wheat');
    const cropString = cropsToDisplay.join(' - ');
    if (values.length === 0 || !cropString) {
        return <></>;
    }
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                <li className="list-group-item list-group-item-success">
                    {cropString}
                </li>
                {values.includes('annualGrassesCombine'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/how-to-identify-grass-weeds-in-arable-fields" target="_blank" rel="noreferrer">Annual grasses</a>
                    </li>
                    : <></>
                }
                {values.includes('tapRootBlwCombine'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/how-to-identify-broad-leaved-weeds-in-arable-fields" target="_blank" rel="noreferrer">Tap Root BLW</a>
                    </li>
                    : <></>
                }
                {values.includes('fibrousRootBlwCombine'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/how-to-identify-broad-leaved-weeds-in-arable-fields" target="_blank" rel="noreferrer">Fibrous Root BLW</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default CombineCropsWeedsList;