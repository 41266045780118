import { useCallback } from "react";
import authService from '../components/api-authorization/AuthorizeService'

const usePostRequest = (url, body) => {

    const post = useCallback(async () => {
        try {
            const token = await authService.getAccessToken();

            const requestOptions = {
                method: 'POST',
                headers: !token ? {} :
                    {
                        Accept: "application/json",
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                body: JSON.stringify(body)
            };

            const response = await fetch(url, requestOptions);
            const result = await response.json();
            result.isValid = true;
            if (!response.ok) {
                result.isValid = false;
            };

            return result;
        }
        catch (e) {
            console.error(e);
        }
    }, [url, body]);

    return { post };
};

export default usePostRequest;