import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const improvedGrasslandPestSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const fritFly = { name: 'fritFly', display: "Frit Fly" };
    const leatherjackets = { name: 'leatherjackets', display: "Leatherjackets" };
    const wireworms = { name: 'wireworms', display: "Wireworms" };
    const slugs = { name: 'slugs', display: "Slugs" };
    const cloverWeevil = { name: 'cloverWeevil', display: "Clover Weevil" };
    const cloverStemNematode = { name: 'cloverStemNematode', display: "Clover stem nematode" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'longTermLeys'.toLowerCase()) {
                    measureWithPests.measures = 'Avoid following long-term leys'
                    const issuesInControl = [
                        fritFly,
                        leatherjackets,
                        wireworms,
                        slugs,
                        cloverWeevil,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'sowingDate'.toLowerCase()) {
                    measureWithPests.measures = 'Sowing date'
                    const issuesInControl = [
                        fritFly,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'extraCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Extra cultivations'
                    const issuesInControl = [
                        leatherjackets,
                        wireworms,
                        slugs,
                        cloverWeevil,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'larvaeCountsInSoil'.toLowerCase()) {
                    measureWithPests.measures = 'Larvae counts in soil'
                    const issuesInControl = [
                        leatherjackets,
                        wireworms,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'pestMonitoring'.toLowerCase()) {
                    measureWithPests.measures = 'Pest monitoring'
                    const issuesInControl = [
                        fritFly,
                        leatherjackets,
                        wireworms,
                        slugs,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'rollingSoilPostPlanting'.toLowerCase()) {
                    measureWithPests.measures = 'Rolling soil post-planting'
                    const issuesInControl = [
                        wireworms,
                        slugs,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'narrowStemmedGrasses'.toLowerCase()) {
                    measureWithPests.measures = 'Narrow stemmed grasses'
                    const issuesInControl = [
                        cloverStemNematode
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, rotation & break crops'
                    const issuesInControl = [
                        fritFly,
                        leatherjackets,
                        wireworms,
                        cloverStemNematode
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygieneAndPrevention'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene and prevention'
                    const issuesInControl = [
                        cloverStemNematode
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nutrientManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Nutrient management'
                    const issuesInControl = [
                        fritFly,
                        leatherjackets,
                        wireworms,
                        slugs,
                        cloverWeevil,
                        cloverStemNematode
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [
        fritFly,
        leatherjackets,
        wireworms,
        slugs,
        cloverWeevil,
        cloverStemNematode]);
    return listOfMeasuresWithPests;
};

export default improvedGrasslandPestSummaryMeasures;