import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { useEffect, useState } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import MeasuresButtons from '../measuresButtons';
import BrassicasDiseaseList from './brassicasDiseaseList';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddBrassicasDiseaseMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`63c968831ed833bb5c355aaf`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.diseases !== null
        && (location.state.surveyData.diseases !== undefined)
        && (location.state.surveyData.diseases.brassicas !== null)
        && (location.state.surveyData.diseases.brassicas !== undefined)
        && (location.state.surveyData.diseases.brassicas.controlMeasures !== null)
        && (location.state.surveyData.diseases.brassicas.controlMeasures !== undefined)) ?
        location.state.surveyData.diseases.brassicas.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.diseases?.brassicas);
    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.diseases.brassicas.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>brassicas disease control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement </p>
            </div>
            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(
                        listOfIssues.includes('darkLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('ringspot'.toLowerCase()) ||
                        listOfIssues.includes('xanthomonas'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Decision support, incl. monitoring</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="decisionSupportDesc">
                                    <p>Regular monitoring, both during propagation and in the field, coupled with correct identification of diseases, is an important element in minimising fungicide use. Regularly field walk and monitor the crop for diseases, in conjunction with monitoring pests, to establish the need to take corrective action. The decision on whether it is worthwhile to apply fungicides should consider the disease, time of year, degree of infection and proximity to harvest date. The effect of prevailing weather conditions on disease multiplication should also be considered. Correct identification of diseases is essential to determine the correct fungicide product to use. Early alerts to treat from disease epicentres can reduce the area that requires treatment as it will reduce spore spread to new growth and neighbouring plants. Treatment can be triggered when infection is likely to occur based on disease alerts utilising local weather and in-crop monitoring. </p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'darkLeafSpot'.toLowerCase(),
                                    'downyMildew'.toLowerCase(),
                                    'lightLeafSpot'.toLowerCase(),
                                    'ringspot'.toLowerCase(),
                                    'xanthomonas'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.decisionSupport}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('downyMildew'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Early production under crop covers</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#earlyProductionUnderCropCoversDesc" aria-expanded="false" aria-controls="earlyProductionUnderCropCoversDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="earlyProductionUnderCropCoversDesc">
                                    <p>Physiological problems soon arise if cover sheets are left on too long and can encourage moisture and humidity for downy mildew infection. Uncover when the heads are just visible (10-15mm in diameter). Crops should also be uncovered if the weather conditions are hot and humid. It is worth sacrificing crop advancement in exchange for a reduced disease problem.</p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'downyMildew'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.earlyProductionUnderCropCovers}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('darkLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('ringspot'.toLowerCase()) ||
                        listOfIssues.includes('xanthomonas'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Soil management and good drainage</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#soilManagementDesc" aria-expanded="false" aria-controls="soilManagementDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="soilManagementDesc">
                                    <p>Wet areas and fields subject to flooding are likely to be more prone to diseases not only because of poor plant growth but also because some pathogens are adapted to conditions of high soil moisture. Deficiencies of soil nutrients and adverse soil seed bed conditions because of poor drainage are also likely to predispose plants to disease. Improve soil drainage and structure to allow good root system development and thus stronger growing, less stressed, plants that with greater disease tolerance. </p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'darkLeafSpot'.toLowerCase(),
                                    'downyMildew'.toLowerCase(),
                                    'lightLeafSpot'.toLowerCase(),
                                    'ringspot'.toLowerCase(),
                                    'xanthomonas'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.soilManagement}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('darkLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('ringspot'.toLowerCase()) ||
                        listOfIssues.includes('xanthomonas'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Field history, rotation & break crops</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="fieldHistoryDesc">
                                    <p>Alternaria, light leaf spot, ringspot and Xanthomonas will spread between cruciferous host debris. It is good agronomic practice to rotate crops with non-host species to prevent the build-up of soil-borne diseases, and it also allows an opportunity to control volunteers which can act as a reservoir of disease. A four-year rotation is recommended.</p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'darkLeafSpot'.toLowerCase(),
                                    'lightLeafSpot'.toLowerCase(),
                                    'ringspot'.toLowerCase(),
                                    'xanthomonas'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.fieldHistory}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('ringspot'.toLowerCase()) ||
                        listOfIssues.includes('xanthomonas'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Hygiene (particularly in propagation)</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="hygieneDesc">
                                    <p>Adequately sterilise propagation trays and other glasshouse surfaces to prevent carry-over of root and leaf pathogens. Reduce movement of pathogens on workers coming from the field by disinfection of footwear and use of clean coveralls and hand sanitisation.</p>
                                    <p>The introduction of soil-borne diseases and leaf pathogens carried on plant debris into clean land can be stopped by not moving soil or fresh plant waste between fields. Machinery used in infested fields should be power-washed after use in infected fields, and soil should be cleaned from boots and tools and ideally disinfected. Clean fields should be visited first in the sequence of crops so that cleaning down equipment can be done at the end of the day.</p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'downyMildew'.toLowerCase(),
                                    'lightLeafSpot'.toLowerCase(),
                                    'ringspot'.toLowerCase(),
                                    'xanthomonas'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.hygiene}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('darkLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('ringspot'.toLowerCase()) ||
                        listOfIssues.includes('xanthomonas'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Precision irrigation</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#precisionIrrigationDesc" aria-expanded="false" aria-controls="precisionIrrigationDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="precisionIrrigationDesc">
                                    <p>Field irrigation that leaves puddles facilitates pathogen spore splash from the soil onto foliage and thus closer monitoring of set timings are required. Devices that monitor the amount of rain and irrigation water falling onto plants and/or soil or growing-media moisture content should be utilised ideally alongside visual inspection to adjust irrigation timings. The ideal would be to avoid overhead irrigation, especially over module trays, and use sand-beds, drip-hose or similar systems that water the roots and so do not wet the leaves and provide good conditions for spores to germinate.</p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'darkLeafSpot'.toLowerCase(),
                                    'downyMildew'.toLowerCase(),
                                    'lightLeafSpot'.toLowerCase(),
                                    'ringspot'.toLowerCase(),
                                    'xanthomonas'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.precisionIrrigation}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('darkLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('xanthomonas'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Seed testing</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedTestingDesc" aria-expanded="false" aria-controls="seedTestingDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="seedTestingDesc">
                                    <p>Major seed lots are batch tested, in particular for alternaria and xanthomonas. A negative result does not guarantee complete freedom from the disease but more usually subsequent disease expression is not economically significant. Only certified seed should be used for the planting of new crops.  </p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'darkLeafSpot'.toLowerCase(),
                                    'xanthomonas'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.seedTesting}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('darkLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('ringspot'.toLowerCase()) ||
                        listOfIssues.includes('xanthomonas'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Select low-risk locations</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="lowRiskLocationsDesc">
                                    <p>Wetter areas in the West of England can be more at risk of ringspot. Proximity to other brassicas should be avoided to reduce the chance of cross-infection. If possible, site away from crops such as oilseed rape and other brassicas. In intensive brassica areas, where this is not possible, plough in plant remains immediately after harvesting. </p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'darkLeafSpot'.toLowerCase(),
                                    'downyMildew'.toLowerCase(),
                                    'lightLeafSpot'.toLowerCase(),
                                    'ringspot'.toLowerCase(),
                                    'xanthomonas'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.lowRiskLocations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('ringspot'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Variety choice</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#varietyChoiceDesc" aria-expanded="false" aria-controls="varietyChoiceDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="varietyChoiceDesc">
                                    <p>Resistant varieties are an important part of non-chemical disease control. Some brassica varieties have resistance to ringspot and tolerance to systemic downy mildew. Specific resistances of individual varieties can be found in individual seed houses brochures. Provided they have good commercial qualities, disease resistant varieties should be included in any integrated pest management system.</p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'downyMildew'.toLowerCase(),
                                    'ringspot'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.varietyChoice}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('darkLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('ringspot'.toLowerCase()) ||
                        listOfIssues.includes('xanthomonas'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Control volunteers and weeds</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#controlVolunteersDesc" aria-expanded="false" aria-controls="controlVolunteersDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="controlVolunteersDesc">
                                    <p>Cruciferous volunteers and weeds act as a 'green bridge' allowing continued pathogen survival and from which spores and insect-borne viruses can be spread to infect new brassica crops. Most brassica pathogens can also be transmitted from plant host debris, although mildews require living hosts for survival. Ideally, infection sources in nearby fields should be destroyed prior to the emergence of new crops as well as in the field to be planted. A fallow period will allow time for pathogen viability to be reduced. Disease hosts can be killed by herbicides, but pathogens can remain active on dead material at least until the plant tissue disintegrates and so chopping up plant tissue will hasten disintegration.</p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'darkLeafSpot'.toLowerCase(),
                                    'downyMildew'.toLowerCase(),
                                    'lightLeafSpot'.toLowerCase(),
                                    'ringspot'.toLowerCase(),
                                    'xanthomonas'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.controlVolunteers}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('darkLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('downyMildew'.toLowerCase()) ||
                        listOfIssues.includes('lightLeafSpot'.toLowerCase()) ||
                        listOfIssues.includes('ringspot'.toLowerCase()) ||
                        listOfIssues.includes('xanthomonas'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Primary cultivations (crop residue burial)</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#primaryCultivationsDesc" aria-expanded="false" aria-controls="primaryCultivationsDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="primaryCultivationsDesc">
                                    <p>Carry out primary cultivations such as ploughing to bury plant matter out of contact with the next crop. If this is not done and no interval left between cruciferous crops, break crops or weeds, then disease transfer is a possibility. Burial of debris will reduce the spread of light leaf spot, ringspot and other diseases by stopping splash-dispersed spores from living tissue, and halt formation of the ascospore stage which is released from debris and dispersed widely in the air. Where xanthomonas is found, quick removal or soil incorporation of crop residue is advised and a rotational break of at least two years should be practised. However, a four-year interval is in general advised between brassica crops.</p>
                                </div>
                                <BrassicasDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'darkLeafSpot'.toLowerCase(),
                                    'downyMildew'.toLowerCase(),
                                    'lightLeafSpot'.toLowerCase(),
                                    'ringspot'.toLowerCase(),
                                    'xanthomonas'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.primaryCultivations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                </>
            )}
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"diseases"}
                    finalUrl={"/add-diseases-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddBrassicasDiseaseMeasures;