import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const potatoesDiseaseSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const blackDot = { name: 'blackDot', display: "Black Dot" };
    const blackleg = { name: 'blackleg', display: "Blackleg" };
    const commonScab = { name: 'commonScab', display: "Common Scab" };
    const dryRot = { name: 'dryRot', display: "Dry Rot" };
    const earlyBlight = { name: 'earlyBlight', display: "Early Blight" };
    const gangrene = { name: 'gangrene', display: "Gangrene" };
    const lateBlight = { name: 'lateBlight', display: "Late Blight" };
    const powderyScab = { name: 'powderyScab', display: "PowderyScab" };
    const silverScurf = { name: 'silverScurf', display: "Silver Scurf" };
    const stemCankerAndBlackScurf = { name: 'stemCankerAndBlackScurf', display: "Stem Canker And Black Scurf" };
    const storageDiseases = { name: 'storageDiseases', display: "Storage Diseases" };
    const viruses = { name: 'viruses', display: "Viruses (Soil Borne)" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'controlVolunteersAndWeeds'.toLowerCase()) {
                    measureWithPests.measures = 'Control volunteers & weeds'
                    const issuesInControl = [
                        blackDot,
                        blackleg,
                        commonScab,
                        gangrene,
                        lateBlight,
                        powderyScab,
                        silverScurf,
                        stemCankerAndBlackScurf,
                        viruses
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support (including thresholds)'
                    const issuesInControl = [
                        blackDot,
                        blackleg,
                        lateBlight,
                        powderyScab,
                        silverScurf,
                        stemCankerAndBlackScurf
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'appropriateHarvest'.toLowerCase()) {
                    measureWithPests.measures = 'Appropriate Harvest'
                    const issuesInControl = [
                        blackDot,
                        blackleg,
                        gangrene,
                        lateBlight,
                        silverScurf,
                        stemCankerAndBlackScurf
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, Rotation & break crops'
                    const issuesInControl = [
                        blackDot,
                        blackleg,
                        commonScab,
                        lateBlight,
                        powderyScab,
                        silverScurf,
                        stemCankerAndBlackScurf,
                        viruses
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'goodDrainage'.toLowerCase()) {
                    measureWithPests.measures = 'Good Drainage'
                    const issuesInControl = [
                        blackleg,
                        lateBlight,
                        powderyScab
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygiene'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene'
                    const issuesInControl = [
                        blackleg,
                        lateBlight,
                        powderyScab,
                        storageDiseases,
                        viruses
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nutrientManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Nutrient Management'
                    const issuesInControl = [
                        lateBlight
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'primaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Primary cultivations (crop residue burial)'
                    const issuesInControl = [
                        blackDot,
                        commonScab,
                        lateBlight,
                        silverScurf,
                        stemCankerAndBlackScurf
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedTesting'.toLowerCase()) {
                    measureWithPests.measures = 'Seed Testing'
                    const issuesInControl = [
                        powderyScab
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedbedQuality'.toLowerCase()) {
                    measureWithPests.measures = 'Seedbed Quality'
                    const issuesInControl = [
                        blackDot,
                        blackleg,
                        commonScab,
                        lateBlight,
                        stemCankerAndBlackScurf
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Select low-risk locations'
                    const issuesInControl = [
                        lateBlight,
                        powderyScab
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'sowingDate'.toLowerCase()) {
                    measureWithPests.measures = 'Planting Date'
                    const issuesInControl = [
                        lateBlight,
                        stemCankerAndBlackScurf,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'spatialSeparation'.toLowerCase()) {
                    measureWithPests.measures = 'Spatial Separation'
                    const issuesInControl = [
                        lateBlight
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietalChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Varietal Choice'
                    const issuesInControl = [
                        blackDot,
                        commonScab,
                        earlyBlight,
                        lateBlight,
                        powderyScab,
                        storageDiseases,
                        viruses
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus,
        [blackDot, blackleg, commonScab, dryRot, earlyBlight, gangrene, lateBlight, powderyScab, silverScurf, stemCankerAndBlackScurf, storageDiseases, viruses]);
    return listOfMeasuresWithPests;
};

export default potatoesDiseaseSummaryMeasures;