import { useEffect, useState } from "react";
import useGetRequest from "./useGetRequest";

const useJsonForm = (formId) => {
    const [ jsonForm, setJsonForm ] = useState([]);
    const { get, loadingState } = useGetRequest(`/api/jsonform/${formId}`);

    useEffect(() => {
        const fetchJsonForm = async () => {
            const form = await get();
            setJsonForm(form);
        };
        fetchJsonForm();
    }, [get]);

    return { jsonForm, setJsonForm, loadingState };
};

export default useJsonForm;
