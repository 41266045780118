import getStatusTitle from "../../helpers/getStatusTitle";

const BenchMarkingTable = ({ tableTitle, tableData }) => {
    const sortedData = tableData.data.filter(item => item.key !== null).sort((a, b) => b.count - a.count);
    const totalCount = sortedData.reduce((acc, item) => acc + item.count, 0);
    const dataWithValuesCount = sortedData.reduce((acc, item) => {
        if (item.key !== null) {
            return acc + item.count;
        }
        return acc;
    }, 0);
    return (
        <>
            <h5>{`${tableTitle} ${totalCount} responses from other users to this question`}</h5>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Percentage of users</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((item, index) => (
                        <tr key={index}>
                            <td>{getStatusTitle(item.key)}</td>
                            <td>{(item.count / dataWithValuesCount * 100).toFixed(0)}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default BenchMarkingTable;