const SummarySurvey = (props) => {
    return (
        <>
            <h2 className="font-weight-bold mt-3">
                <u>Feedback to improve the IPM Tool</u>
            </h2>
            <p>We would welcome your feedback on using the tool, by sending an email to <a href="mailto:ipm@adas.co.uk" rel="noreferrer">ipm@adas.co.uk</a></p>
        </>
    );
};

export default SummarySurvey;