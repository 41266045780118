const OilseedsDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value))
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('clubroot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/clubroot-management-in-oilseed-rape-cropping-factors" target="_blank" rel="noreferrer">Clubroot</a>
                    </li>
                    : <></>
                }
                {values.includes('lightLeafSpot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/light-leaf-spot-in-oilseed-rape" target="_blank" rel="noreferrer">Light Leaf Spot</a>
                    </li>
                    : <></>
                }
                {values.includes('phomaStemCanker'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/phoma-leaf-spot-and-stem-canker-in-oilseed-rape" target="_blank" rel="noreferrer">Phoma Stem Canker</a>
                    </li>
                    : <></>
                }
                {values.includes('sclerotiniaStemRot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/sclerotinia" target="_blank" rel="noreferrer">Sclerotinia Stem Rot</a>
                    </li>
                    : <></>
                }
                {values.includes('verticilliumStemStripe'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/verticillium-stem-stripe-in-oilseed-rape" target="_blank" rel="noreferrer">Verticillium Stem Stripe</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default OilseedsDiseaseList;