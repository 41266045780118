import { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../helpers/loadingStatus';
import useJsonForm from '../../hooks/useJsonForm';
import LoadingIndicator from '../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { ArrowRight } from 'react-bootstrap-icons';

const Survey = () => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.farmData === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`634408cede7d100007cd35f6`);
    const data = location.state.surveyData;

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    return (
        <>
            <div className="row my-3">
                <h1>IPM Plan - <span>{location.state.farmData.name}</span> - <span>{data.year}</span></h1>
                <JsonForms
                    schema={schema}
                    data={data}
                    uischema={uiSchema.uiSchemas.viewPlan}
                    renderers={materialRenderers}
                    cells={materialCells}
                    readonly
                />
            </div>
            <div className="row justify-content-between my-5">
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                <Link className="col-2 mx-3"
                    to="/add-general-practices"
                    state={{
                        farmData: location.state.farmData,
                        surveyData: location.state.surveyData,
                        isInViewMode: true
                    }}>
                    <button type="button" className="btn btn-success">Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight></button>
                </Link>
            </div>
        </>
    );
};

export default Survey;
