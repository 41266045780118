import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';
import usePutRequest from '../../../hooks/usePutRequest';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import SurveyNavigation from '../surveyNavigation';
import getListOfOtherCrops from '../../../helpers/getListOfOtherCrops';

const MeasuresButtons = ({ farmData, surveyData, index, crops, measureType, finalUrl, readonly }) => {
    
    const navigate = useNavigate();
    const { put } = usePutRequest(`/api/survey/${surveyData.id}`, surveyData);

    useEffect(() => {
        if (surveyData === null) {
            navigate("/add-survey");
        };
    });

    let listOfOtherCrops = getListOfOtherCrops(surveyData.landParcels);
    if (listOfOtherCrops.length !== 0) {
        finalUrl = `/add-${measureType}-other`
    }
    let nextUrl = (index + 1 >= crops.length || index === undefined) ? finalUrl : `/add-${measureType}-measures/${crops[index + 1].cropType}`;
    let prevUrl = (index - 1 < 0 || index === undefined) ? `/add-${measureType}` : `/add-${measureType}-measures/${crops[index - 1].cropType}`;

    const onControlMeasureSubmitClick = async (goToNextPage) => {
        const response = await put();

        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!goToNextPage) {
            toast.info("Saved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        navigate(nextUrl,
            {
                state: {
                    farmData: farmData,
                    surveyData: surveyData,
                    crops: crops,
                    index: index + 1
                }
            });
    };

    const onGoBackSubmitClick = () => {
        navigate(prevUrl,
            {
                state: {
                    farmData: farmData,
                    surveyData: surveyData,
                    crops: crops,
                    index: index - 1
                }
            });
    };

    return (
        <>
            <div className="alert alert-info" role="alert">
                To save your work, click <strong>Save</strong> before using the <strong>Go To</strong> or <strong>Go back</strong> button.
            </div>
            <Link className="col-2 mx-3" to="/farm-list">
                <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
            </Link>
            <button type="button" className="col-2 mx-3 btn btn-warning" onClick={() => onGoBackSubmitClick(false)} >
                <ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back
            </button>
            <SurveyNavigation farmData={farmData} surveyData={surveyData} crops={crops} />
            <button type="button" className="col-2 mx-3 btn btn-primary" onClick={() => onControlMeasureSubmitClick(false)} disabled={readonly} >Save</button>
            {readonly === true ? (
                <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onControlMeasureSubmitClick(true)}>
                    Continue
                </button>
            ) : (
                <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onControlMeasureSubmitClick(true)}>
                    Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight>
                </button>
            )}
        </>
    );
};

export default MeasuresButtons;