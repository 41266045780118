const PotatoesDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('blackDot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/black-dot" target="_blank" rel="noreferrer">Black Dot</a>
                    </li>
                    : <></>
                }
                {values.includes('blackleg'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/managing-blackleg-disease-and-soft-rot-in-potatoes" target="_blank" rel="noreferrer">Blackleg</a>
                    </li>
                    : <></>
                }
                {values.includes('commonScab'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/common-scab" target="_blank" rel="noreferrer">Common Scab</a>
                    </li>
                    : <></>
                }{values.includes('earlyBlight'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/alternaria" target="_blank" rel="noreferrer">Early Blight</a>
                    </li>
                    : <></>
                }{values.includes('gangrene'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/gangrene" target="_blank" rel="noreferrer">Gangrene</a>
                    </li>
                    : <></>
                }{values.includes('lateBlight'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/late-blight-disease-and-its-management-in-potatoes" target="_blank" rel="noreferrer">Late Blight</a>
                    </li>
                    : <></>
                }{values.includes('powderyScab'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/powdery-scab" target="_blank" rel="noreferrer">Powdery Scab</a>
                    </li>
                    : <></>
                }{values.includes('silverScurf'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/silver-scurf" target="_blank" rel="noreferrer">Silver Scurf</a>
                    </li>
                    : <></>
                }{values.includes('stemCankerAndBlackScurf'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/black-scurf-and-stem-canker" target="_blank" rel="noreferrer">Stem Canker and Black Scurf</a>
                    </li>
                    : <></>
                }{values.includes('storageDiseases'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/disease-defects" target="_blank" rel="noreferrer">Storage Diseases</a>
                    </li>
                    : <></>
                }{values.includes('viruses'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://potatoes.ahdb.org.uk/knowledge-library/potato-viruses-symptoms" target="_blank" rel="noreferrer">Viruses (Soil Borne) </a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default PotatoesDiseaseList;