import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { QuestionCircle } from 'react-bootstrap-icons';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import MeasuresButtons from '../measuresButtons';
import SugarBeetPestList from './sugarBeetPestList';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddSugarBeetPestMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`63b7f3731c3f997664e23eb2`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.sugarBeet !== null)
        && (location.state.surveyData.pests.sugarBeet !== undefined)
        && location.state.surveyData.pests.sugarBeet.controlMeasures !== null
        && location.state.surveyData.pests.sugarBeet.controlMeasures !== undefined) ?
        location.state.surveyData.pests.sugarBeet.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);
    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.sugarBeet);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.sugarBeet.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>sugar beet pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('virusYellows'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Decision support, incl. monitoring</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="decisionSupportDesc">
                                <p>IPM decisions should be made based on the results of monitoring and forecasting combined with threshold information where available. Treatment thresholds are the population level or density that must be reached before intervention is required or economically beneficial. Thresholds enable growers to make decisions based on the level at which pests will impact economic crop yield. They are essential in guiding pest control decisions and preventing the unnecessary use of pesticides.</p>
                                <p>Pest forecasts are available from the <a href="https://plus.bbro.co.uk/on-farm/member-area/aphid-survey/aphids-site-map/" target="_blank" rel="noreferrer">British Beet Research Organization website</a></p>
                            </div>
                            <SugarBeetPestList listOfIssues={listOfIssues} toDisplay={[
                                'virusYellows'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.decisionSupport}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('beetCystNematode'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Field history, rotation & break crops</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="fieldHistoryDesc">
                                <p>If a field has a history of BCN, it is likely that this will return as a problem in the future, through survival in the soil. Knowledge of field history allows steps to be taken to minimise the risks.</p>
                                <p>For specific guidance click <a href="https://bbro.co.uk/media/50815/2020-january-a-growers-guide-to-bcn.pdf" target="_blank" rel="noreferrer">here</a></p>
                            </div>
                            <SugarBeetPestList listOfIssues={listOfIssues} toDisplay={[
                                'beetCystNematode'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.fieldHistory}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('virusYellows'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Hygiene and prevention</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="hygieneDesc">
                                <p>Sugar beet spoil heaps can provide shelter and food for pests such as aphids.  Allowing sugar beet volunteers to persist can also create a 'green bridge' which allows aphids to colonise newly established sugar beet crops and spread virus yellows.</p>
                            </div>
                            <SugarBeetPestList listOfIssues={listOfIssues} toDisplay={[
                                'virusYellows'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.hygiene}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('virusYellows'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Seed rate</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#seedRateDesc" aria-expanded="false" aria-controls="seedRateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="seedRateDesc">
                                <p>Increasing seed rates can compensate for the loss of plants to pests.</p>
                            </div>
                            <SugarBeetPestList listOfIssues={listOfIssues} toDisplay={[
                                'virusYellows'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.seedRate}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('wireworm'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Select low-risk locations</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="lowRiskLocationsDesc">
                                <p>Sugarbeet ideally should not be grown following long term grass leys to minimise the risk of wireworm infestation. Cereals or maize in rotation with sugar beet can also increase the risk of wireworm.</p>
                            </div>
                            <SugarBeetPestList listOfIssues={listOfIssues} toDisplay={[
                                'wireworm'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.lowRiskLocations}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('beetCystNematode'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Variety choice</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#varietyChoiceDesc" aria-expanded="false" aria-controls="varietyChoiceDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="varietyChoiceDesc">
                                <p>Varieties are now available that claim to have tolerance to virus yellows. There are also varieties available that show tolerance to BCN which means they yield well in the presence of BCN but also allow BCN reproduction.</p>
                                <p>For specific guidance click <a href="https://bbro.co.uk/sugar-beet-varieties/recommended-list/" target="_blank" rel="noreferrer">here</a></p>
                            </div>
                            <SugarBeetPestList listOfIssues={listOfIssues} toDisplay={[
                                'beetCystNematode'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.varietyChoice}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('beetCystNematode'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Soil analysis for pests</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#soilAnalysisDesc" aria-expanded="false" aria-controls="soilAnalysisDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="soilAnalysisDesc">
                                <p>Essential for planning control of BCN.  It will establish the level of BCN infestation and can give an indication of soil populations. Typically, there is an economic benefit associated with using BCN-tolerant varieties when soil analysis shows above two eggs and larvae per gram of soil.</p>
                            </div>
                            <SugarBeetPestList listOfIssues={listOfIssues} toDisplay={[
                                'beetCystNematode'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.soilAnalysis}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('virusYellows'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>In field non-cropped areas</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#nonCroppedAreasDesc" aria-expanded="false" aria-controls="nonCroppedAreasDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="nonCroppedAreasDesc">
                                <p>Beetle banks consist of stands of wildflowers and grasses and are designed to act as reservoirs of beneficial insects such as ground beetles and parasitoids, which help to provide natural biological control of insect pests. Diverse crop margins and strips act in a similar way to beetle banks to increase natural enemies. More diverse strips should harbour greater biodiversity and greater numbers of beneficials. Ladybirds, hoverflies and lacewings are natural enemies of aphids in sugar beet.</p>
                            </div>
                            <SugarBeetPestList listOfIssues={listOfIssues} toDisplay={[
                                'virusYellows'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.nonCroppedAreas}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                </>
            )}
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}
                >
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddSugarBeetPestMeasures;