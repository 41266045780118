import isAnArray from "./isAnArray";
import isAnObject from "./isAnObject";

const getListOfIssues = (crop, includeRisk = false) => {
    let listOfIssues = [];
    for (var propertyName in crop) {
        var objectProperty = crop[propertyName];
        if (objectProperty !== null
            && objectProperty !== undefined
            && !isAnObject(objectProperty)
            && !isAnArray(objectProperty)
            && objectProperty.toLowerCase() !== 'norisk') {
            if (includeRisk) {
                listOfIssues.push({ name: propertyName, risk: objectProperty });
            } else {
                listOfIssues.push(propertyName.toLowerCase());
            }
        }
    }
    listOfIssues.sort();
    return [...new Set(listOfIssues)];
}

export default getListOfIssues;