import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const peasDiseaseSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const botrytis = { name: 'botrytis', display: "Botrytis" };
    const downyMildew = { name: 'downyMildew', display: "Downy Mildew" };
    const leafSpot = { name: 'leafSpot', display: "Leaf Spot" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, rotation & break crops'
                    const issuesInControl = [botrytis];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nutrientManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Nutrient Management'
                    const issuesInControl = [botrytis, downyMildew];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'primaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Primary cultivations (crop residue burial)'
                    const issuesInControl = [botrytis, downyMildew, leafSpot];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedTesting'.toLowerCase()) {
                    measureWithPests.measures = 'Seed Testing'
                    const issuesInControl = [leafSpot];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietalChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Varietal Choice'
                    const issuesInControl = [botrytis, downyMildew, leafSpot];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [botrytis, downyMildew, leafSpot]);
    return listOfMeasuresWithPests;
};

export default peasDiseaseSummaryMeasures;