export function addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests) {
    issuesInControl.forEach(function (issue) {
        const selectedIssue = listOfIssues.find(i => i.name.toLowerCase() === issue.name.toLowerCase());
        if (selectedIssue) {
            if (selectedIssue.risk.toLowerCase() === 'significantrisk') {
                measureWithPests.significantRisk += `${issue.display}, `;
            } else if (selectedIssue.risk.toLowerCase() === 'moderaterisk') {
                measureWithPests.moderateRisk += `${issue.display}, `;
            }
            else if (selectedIssue.risk.toLowerCase() === 'slightrisk') {
                measureWithPests.slightRisk += `${issue.display}, `;
            }
            measureWithPests.pestKeys += `${issue.name}, `;
        }
    });
}
