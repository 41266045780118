import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const weedsIssuesSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const creepingThistle = { name: 'creepingThistle', display: "Creeping Thistle" };
    const volunteerPotatoes = { name: 'volunteerPotatoes', display: "Volunteer Potatoes" };
    const weedBeet = { name: 'weedBeet', display: "Weed Beet" };
    const wildOats = { name: 'wildOats', display: "Wild Oats" };
    const couch = { name: 'couch', display: "Couch" };
    const tapRootBlwCombine = { name: 'tapRootBlwCombine', display: "Tap Root broad leaved weeds (combine crops)" };
    const fibrousRootBlwCombine = { name: 'fibrousRootBlwCombine', display: "Fibrous Root broad leaved weeds (combine crops)" };
    const annualGrassesCombine = { name: 'annualGrassesCombine', display: "Annual Grasses (combine crops)" };
    const tapRootBlwWideRow = { name: 'tapRootBlwWideRow', display: "Tap Root broad leaved weeds (wide row crops)" };
    const fibrousRootBlwWideRow = { name: 'fibrousRootBlwWideRow', display: "Fibrous Root broad leaved weeds (wide row crops)" };
    const annualGrassesWideRow = { name: 'annualGrassesWideRow', display: "Annual Grasses" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };
                if (measure.toLowerCase() === 'rotation'.toLowerCase()) {
                    measureWithPests.measures = 'Rotation'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow,
                        creepingThistle,
                        volunteerPotatoes,
                        weedBeet,
                        wildOats,
                        couch
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Select low-risk locations'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow,
                        creepingThistle,
                        volunteerPotatoes,
                        weedBeet,
                        wildOats,
                        couch
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fallow'.toLowerCase()) {
                    measureWithPests.measures = 'Fallow'
                    const issuesInControl = [
                        annualGrassesCombine,
                        annualGrassesWideRow,
                        fibrousRootBlwWideRow,
                        creepingThistle,
                        volunteerPotatoes,
                        weedBeet,
                        wildOats,
                        couch
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'improveSoilCondition'.toLowerCase()) {
                    measureWithPests.measures = 'Improve Soil Condition'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'stubbleManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Stubble Management'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow,
                        creepingThistle,
                        volunteerPotatoes,
                        weedBeet,
                        wildOats,
                        couch
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'staleSeedbed'.toLowerCase()) {
                    measureWithPests.measures = 'Stale Seedbed'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow,
                        volunteerPotatoes
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygiene'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene/prevention'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow,
                        creepingThistle,
                        volunteerPotatoes,
                        weedBeet,
                        wildOats
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'earlyHarvest'.toLowerCase()) {
                    measureWithPests.measures = 'Early Harvest'
                    const issuesInControl = [
                        annualGrassesCombine
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'handPulling'.toLowerCase()) {
                    measureWithPests.measures = 'Hand pulling/roguing/cutting/digging'
                    const issuesInControl = [
                        annualGrassesCombine,
                        volunteerPotatoes,
                        weedBeet,
                        wildOats
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'harrowing'.toLowerCase()) {
                    measureWithPests.measures = 'Harrowing/mechanical weeding'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow,
                        weedBeet
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'thermalWeedControl'.toLowerCase()) {
                    measureWithPests.measures = 'Thermal Weed Control'
                    const issuesInControl = [
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'precisionApplication'.toLowerCase()) {
                    measureWithPests.measures = 'Precision application band spraying/weed wiper/spot spray'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow,
                        creepingThistle,
                        volunteerPotatoes,
                        weedBeet,
                        wildOats,
                        couch
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'ploughing'.toLowerCase()) {
                    measureWithPests.measures = 'Ploughing'
                    const issuesInControl = [
                        annualGrassesCombine,
                        fibrousRootBlwCombine,
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow,
                        creepingThistle,
                        volunteerPotatoes,
                        weedBeet,
                        wildOats,
                        couch
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nonInversionTillage'.toLowerCase()) {
                    measureWithPests.measures = 'Non-inversion tillage'
                    const issuesInControl = [
                        annualGrassesWideRow
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'directDrilling'.toLowerCase()) {
                    measureWithPests.measures = 'Direct Drilling'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        volunteerPotatoes,
                        wildOats
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedRate'.toLowerCase()) {
                    measureWithPests.measures = 'Seed Rate'
                    const issuesInControl = [
                        annualGrassesCombine,
                        fibrousRootBlwCombine
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'sowingDate'.toLowerCase()) {
                    measureWithPests.measures = 'Species/Varietal choice'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine,
                        annualGrassesWideRow,
                        tapRootBlwWideRow,
                        fibrousRootBlwWideRow
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietalChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Varietal Choice'
                    const issuesInControl = [
                        annualGrassesCombine,
                        tapRootBlwCombine,
                        fibrousRootBlwCombine
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [
        creepingThistle,
        volunteerPotatoes,
        weedBeet,
        wildOats,
        couch,
        tapRootBlwCombine,
        fibrousRootBlwCombine,
        annualGrassesCombine,
        tapRootBlwWideRow,
        fibrousRootBlwWideRow,
        annualGrassesWideRow]);
    return listOfMeasuresWithPests;
};

export default weedsIssuesSummaryMeasures;