const MaizeDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value))
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('eyespot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/pest-weed-disease-threats-maize" target="_blank" rel="noreferrer">Eyespot</a>
                    </li>
                    : <></>
                }
                {values.includes('fusarium'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/pest-weed-disease-threats-maize" target="_blank" rel="noreferrer">Fusarium</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default MaizeDiseaseList;