import { Link, useLocation, useNavigate } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { ArrowLeft, ArrowRight, QuestionCircle } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import usePutRequest from '../../../../hooks/usePutRequest';
import { useEffect, useState } from 'react';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import ImprovedGrasslandWeedsList from './improvedGrasslandWeedsList';
import SurveyNavigation from '../../surveyNavigation';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddWeedsImprovedGrasslandMeasures = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`660fe788c4b8f9555a676cd6`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && (location.state.surveyData.weeds !== null)
        && (location.state.surveyData.weeds !== undefined)
        && (location.state.surveyData.weeds.improvedGrassland !== null)
        && (location.state.surveyData.weeds.improvedGrassland !== undefined)
        && (location.state.surveyData.weeds.improvedGrassland.controlMeasures !== null)
        && (location.state.surveyData.weeds.improvedGrassland.controlMeasures !== undefined)) ?
        location.state.surveyData.weeds.improvedGrassland.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const { put } = usePutRequest(`/api/survey/${location.state?.surveyData?.id}`, location.state?.surveyData);
    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;


    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    let listOfIssues = getListOfIssues(location.state.surveyData.weeds.improvedGrassland);
    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.weeds.improvedGrassland.controlMeasures = data;
    };

    const onAddWeedsResistanceSubmitClick = async (goToNextPage) => {
        const response = await put();
        if (location.state.surveyData.weeds.improvedGrassland !== undefined)
            location.state.surveyData.weeds.improvedGrassland.controlMeasures = data;
        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!goToNextPage) {
            toast.info("Saved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        navigate("/add-pests",
            {
                state: {
                    farmData: location.state.farmData,
                    surveyData: location.state.surveyData,
                    crops: location.state.crops
                }
            });
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>weeds improved grassland control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            {listOfIssues.length === 0 ? (
                <>
                    <h3>No issues selected</h3>
                </>
            ) : (
                <>
                    {(
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('rushes'.toLowerCase()) ||
                        listOfIssues.includes('bracken'.toLowerCase()) ||
                        listOfIssues.includes('gorse'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Mowing/topping/crimping/rolling</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#mowingDesc" aria-expanded="false" aria-controls="mowingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="mowingDesc">
                                    <p>Primary action of mowing and topping is to remove the growing point of weeds that are extending above the sward and to prevent flowering. Seed production is prevented and the seedbank is diminished. The timing of mowing an topping is crucial in some species, too early and the weed will branch and produce flowers on a shorter stem, too late and fertile  seeds will already have been set.</p>
                                    <p>Mowing can kill some annual weed species as they are overcome by a competitive grass sward. Repeated cutting (3-4 times per year) can gradually reduce perennial weed numbers.</p>
                                    <p>Ragwort should be cut at the start of flowering, cut material should be removed as it is toxic to stock.</p>
                                    <p>Rushes should be cut prior to flowering; mowing should be repeated to weaken the plant.</p>
                                    <p>Rolling/crushing/bruising is designed to bruise the active stalk of bracken causing bleeding and eventual weakening of the rhizomes.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'docks'.toLowerCase(),
                                        'rushes'.toLowerCase(),
                                        'bracken'.toLowerCase(),
                                        'gorse'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.mowing}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase()) ||
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('commonNettle'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Ploughing</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#ploughingDesc" aria-expanded="false" aria-controls="ploughingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="ploughingDesc">
                                    <p>Primary cultivation is the first cultivation that is conducted to prepare the soil for the next crop and can be classified into four groups: plough, deep and shallow till, and no-till.</p>
                                    <p>Changing the primary cultivation is an effective measure in changing weed populations.</p>
                                    <p>Ploughing inverts, the soil, burying 95% of freshly shed seed to 15-20cm but brings up 35% of old seed buried by previous cultivations.</p>
                                    <p>Non-inversion tillage mixes the soil in the upper layers to the working depth of the implement. The weeds that germinate are a mixture of freshly shed seed and those from previous seasons. Approximately 50% of newly shed seed is buried below germination depth (5 cm) and 10% of old seed returns to the surface.</p>
                                    <p>With no-till the only soil movement that occurs is that done by the drill and the freshly shed seed remains on the soil surface, some weed seed falls down cracks in the soil.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                        'creepingButtercup'.toLowerCase(),
                                        'commonNettle'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.ploughing}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('broadleaved'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Harrowing/mechanical weeding (seedling weeds)</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#harrowingDesc" aria-expanded="false" aria-controls="harrowingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="harrowingDesc">
                                    <p>Harrows and tine weeders uproot seedling weeds and cover with a thin layer of soil. These work best where the soil surface has a medium to fine tilth and can be used in all soil types. They disturb the soil at a depth of 2-3 cm and are effective on weeds at the early growth stages (up to 2.5 cm in height).</p>
                                    <p>Weeds are generally controlled by burial, but there is some uprooting where working depth and speed of travel are increased. This technique works best in dry conditions.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'creepingButtercup'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'broadleaved'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.harrowing}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase()) ||
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('commonNettle'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('rushes'.toLowerCase()) ||
                        listOfIssues.includes('bracken'.toLowerCase()) ||
                        listOfIssues.includes('gorse'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Re-seeding</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#reSeedingDesc" aria-expanded="false" aria-controls="reSeedingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="reSeedingDesc">
                                    <p>In time a long-term ryegrass based ley may contain only 50% of the sown species with the remainder consisting of weed grasses such as bents and meadow-grass.</p>
                                    <p>Spraying off an established ley with glyphosate or ploughing prior to a reseed is an effective method of clearing a field of established perennial weeds. </p>
                                    <p>Tight grazing and over-seeding with ryegrass can reduce weed populations.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/grassland-reseeding" rel="noreferrer">here</a></p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                        'creepingButtercup'.toLowerCase(),
                                        'commonNettle'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'rushes'.toLowerCase(),
                                        'bracken'.toLowerCase(),
                                        'gorse'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.reSeeding}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase()) ||
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('commonNettle'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('creepingThistle'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Hygiene</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="hygieneDesc">
                                    <p>This is the first defence against the introduction of weed seeds onto clean land. Machinery used in infested fields should be power-washed before use in uninfected fields, and soil should at least be knocked off from boots and tools. Clean fields should be visited first in the sequence of crops so that cleaning down equipment can be done at the end of the day.</p>
                                    <p>Prevent weed seeds being introduced into the field. Avoid the use of hay and silage contaminated with seed seeds for feeding, if necessary limit it to specific areas of the field or use a feeder. Silage contains fewer seeds if it has been preserved correctly.</p>
                                    <p>Check if the straw used for bedding is weed free, seeds can be spread onto fields in fresh manure. Composting manures so they reach a temperature of 60°C will destroy weed seed, including docks. Heaps should be turned regularly. </p>
                                    <p>Remove weeds from around slurry lagoons or yards to prevent seeds entering the slurry.</p>
                                    <p>Keep uncropped areas tidy of possible, mow regularly to prevent weeds flowering and setting seed, or sow  competitive grasses.</p>
                                    <p>Don't overstock or overgraze fields to prevent poaching and the development of bare areas where weeds can establish.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                        'creepingButtercup'.toLowerCase(),
                                        'commonNettle'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'rushes'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.hygiene}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase()) ||
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('broadleaved'.toLowerCase()) ||
                        listOfIssues.includes('meadow'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Increased seed rate (seedling weeds)</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedRateDesc" aria-expanded="false" aria-controls="seedRateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="seedRateDesc">
                                    <p>A high seedrate at drilling or when reseeding will help to prevent annual meadow grass germination and suppresses annual broad-leaved weeds such as chickweed, mayweed, small nettle and fat hen from establishing.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                        'creepingButtercup'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'broadleaved'.toLowerCase(),
                                        'meadow'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.seedRate}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase()) ||
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('broadleaved'.toLowerCase()) ||
                        listOfIssues.includes('meadow'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Grazing (seedling weeds)</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#grazingDesc" aria-expanded="false" aria-controls="grazingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="grazingDesc">
                                    <p>Rotational grazing is the practice of moving grazing livestock between pastures or paddocks as needed or on a regular basis to control weeds.</p>
                                    <p>Manage grazing to prevent bare patches, poaching and compaction when wet</p>
                                    <p>Mob or intensive grazing or high stocking rates will force stock to graze weeds and allow grass to establish more successfully.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                        'creepingButtercup'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'broadleaved'.toLowerCase(),
                                        'meadow'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.grazing}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Botanical bioprotectants</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#bioprotectansDesc" aria-expanded="false" aria-controls="bioprotectansDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="bioprotectansDesc">
                                    <p>Natural chemical such as plant extracts (e.g. citronella oil for ragwort control and pelargonic acid) can provide good control for fat hen, groundsel, and dock after repeat applications.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.bioprotectans}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase()) ||
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('commonNettle'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('creepingThistle'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Spot treatment</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#spotTreatmentDesc" aria-expanded="false" aria-controls="spotTreatmentDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="spotTreatmentDesc">
                                    <p>Targeted herbicide sprays to control individual weeds such as dock and patches of weeds. Used to treat individual weeds such as docks and spear thistle. Herbicide usage is targeted with minimal damage to clover in the sward. This methodology is being developed on machinery to sense and treat weeds in real time.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                        'creepingButtercup'.toLowerCase(),
                                        'commonNettle'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.spotTreatment}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('commonNettle'.toLowerCase()) ||
                        listOfIssues.includes('creepingThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Weed wiper</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#weedWiperDesc" aria-expanded="false" aria-controls="weedWiperDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="weedWiperDesc">
                                    <p>Weed wipers apply herbicides in a targeted manner. Currently restricted to glyphosate. Weeds hould be a minimum of 10cm, (4") taller than the desired vegetation for safe application. Two passes in opposite directions increases success. Good for thistles which are best treated when flowering and nettles.</p>
                                    <p>Less effective on rushes (glyphosate doesn’t easily get into the plant), docks (too late), ragwort TOXIC –(need to remove dead material), buttercups (too late).</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'commonNettle'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.weedWiper}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase()) ||
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('commonNettle'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('rushes'.toLowerCase()) ||
                        listOfIssues.includes('bracken'.toLowerCase()) ||
                        listOfIssues.includes('gorse'.toLowerCase()) ||
                        listOfIssues.includes('broadleaved'.toLowerCase()) ||
                        listOfIssues.includes('meadow'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Stale Seedbed</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#staleSeedbedDesc" aria-expanded="false" aria-controls="staleSeedbedDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="staleSeedbedDesc">
                                    <p>A seedbed is created for drilling, weeds are allowed to germinate and are sprayed off, more beneficial if two flushes of emergence are allowed. When drilling minimize soil disturbance to prevent new weed seeds appearing from depth.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                        'creepingButtercup'.toLowerCase(),
                                        'commonNettle'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'rushes'.toLowerCase(),
                                        'bracken'.toLowerCase(),
                                        'gorse'.toLowerCase(),
                                        'broadleaved'.toLowerCase(),
                                        'meadow'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.staleSeedbed}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Hand pulling/cutting/digging</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#handPullingDesc" aria-expanded="false" aria-controls="handPullingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="handPullingDesc">
                                    <p>Perennial weeds can be directly dug or pulled. Specialised handheld tools have been developed to remove specific weeds e.g. prongs or forks to remove tap rooted weeds such as docks or ragwort. Easier when soil is moist.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.handPulling}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('ragwort'.toLowerCase()) ||
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('commonNettle'.toLowerCase()) ||
                        listOfIssues.includes('docks'.toLowerCase()) ||
                        listOfIssues.includes('spearThistle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Rotation</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#rotationDesc" aria-expanded="false" aria-controls="rotationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="rotationDesc">
                                    <p>In a rotation the choice of crop affects the type and timing of cultivations and time of drilling.</p>
                                    <p>In spring crops there is selection for spring germinating weeds and likewise selection for autumn germinating weeds in autumn sown crops.</p>
                                    <p>Disrupting these crop/weed associations is a basic approach to actively discouraging the growth and reproduction of certain weed species.</p>
                                    <p>Allows for alternative methods of weed control outside of a grass crop. A forage crop could be used before a reseed.</p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'ragwort'.toLowerCase(),
                                        'creepingButtercup'.toLowerCase(),
                                        'commonNettle'.toLowerCase(),
                                        'docks'.toLowerCase(),
                                        'spearThistle'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.rotation}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('creepingButtercup'.toLowerCase()) ||
                        listOfIssues.includes('commonNettle'.toLowerCase()) ||
                        listOfIssues.includes('rushes'.toLowerCase()) ||
                        listOfIssues.includes('bracken'.toLowerCase()) ||
                        listOfIssues.includes('gorse'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Improving Soil Condition</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#improveDesc" aria-expanded="false" aria-controls="improveDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="improveDesc">
                                    <p>Improving soil conditions including compaction, drainage, pH and nutrient status can help with weed control. Removing compaction improves establishment and makes the crop more competitive against weeds. To avoid compaction, do not graze vulnerable fields when wet to prevent surface compaction.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/healthy-grassland-soils" rel="noreferrer">here</a></p>
                                </div>
                                <ImprovedGrasslandWeedsList
                                    issues={listOfIssues}
                                    toDisplay={[
                                        'creepingButtercup'.toLowerCase(),
                                        'commonNettle'.toLowerCase(),
                                        'rushes'.toLowerCase(),
                                        'bracken'.toLowerCase(),
                                        'gorse'.toLowerCase()
                                    ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.improveSoilCondition}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                </>
            )}
            <div className="row justify-content-between my-5">
                <div className="alert alert-info" role="alert">
                    To save your work, click <strong>Save</strong> before using the <strong>Go To</strong> or <strong>Go back</strong> button.
                </div>
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                <Link className="col-2 mx-3"
                    to={`/add-weeds-improvedgrassland`}
                    state={{
                        farmData: location.state.farmData,
                        surveyData: location.state.surveyData,
                        crops: location.state.crops
                    }}>
                    <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                </Link>
                <SurveyNavigation farmData={location.state.farmData} surveyData={location.state.surveyData} crops={location.state.crops} />
                <button type="button" className="col-2 mx-3 btn btn-primary" onClick={() => onAddWeedsResistanceSubmitClick(false)} disabled={status === 'completed'} >Save</button>
                {status === 'completed' ? (
                    <>
                        <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddWeedsResistanceSubmitClick(true)}>Continue</button>
                    </>
                ) : (
                    <>
                        <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddWeedsResistanceSubmitClick(true)}>Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight></button>
                    </>
                )}
            </div>
        </>
    );
};

export default AddWeedsImprovedGrasslandMeasures;