import { useCallback } from "react";
import authService from '../components/api-authorization/AuthorizeService'

const usePutRequest = (url, body) => {

    const put = useCallback(async () => {
        try
        {            
            const token = await authService.getAccessToken();

            const requestOptions = {
                method: 'PUT',
                headers: !token ? {} :
                    {
                        Accept: "application/json",
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },                
                body: JSON.stringify(body)
            };
            const response = await fetch(url, requestOptions);
            let result = { };
            result.isValid = true;
            if (!response.ok) {
                result = await response.json();
                result.isValid = false;
            };
            return result;
        }
        catch (e)
        {
            console.error(e);
        }
    }, [url, body]);

    return { put };
};

export default usePutRequest;