const SugarBeetPestList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('beetCystNematode'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/management-of-the-beet-cyst-nematode-on-sugar-beet-and-brassicas" target="_blank" rel="noreferrer">Beet Cyst Nematode</a>
                    </li>
                    : <></>
                }
                {values.includes('virusYellows'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://bbro.co.uk/on-farm/pests/" target="_blank" rel="noreferrer">Virus Yellows (aphid transmitted)</a>
                    </li>
                    : <></>
                }
                {values.includes('wireworm'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/identification-and-management-of-wireworms-in-field-crops" target="_blank" rel="noreferrer">Wireworm</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default SugarBeetPestList;