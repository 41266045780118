import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import loadingStatus from "../../../../helpers/loadingStatus";
import useJsonForm from "../../../../hooks/useJsonForm";
import LoadingIndicator from "../../../common/loadingIndicator";
import { JsonForms } from "@jsonforms/react";
import {
    materialRenderers,
    materialCells,
} from "@jsonforms/material-renderers";
import getListOfIssues from "../../../../helpers/getListOfIssues";
import MeasuresButtons from "../measuresButtons";
import ApplePestList from "./applePestList";
import { QuestionCircle } from "react-bootstrap-icons";
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddApplePestMeasures = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`63764147b047e6603fae2592`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.apple !== null)
        && (location.state.surveyData.pests.apple !== undefined)) ?
        location.state.surveyData.pests.apple.controlMeasures : {};
    const [data, setData] = useState(initialData);
    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.apple);
    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.apple.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>apple pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>
                    Please select the category that best describes what actions you plan to implement or not implement
                </p>
            </div>
            <div className="row my-3">
                {listOfIssues.length === 0 ? (
                    <h3>No issues selected</h3>
                ) : (
                    <>
                        {(
                            listOfIssues.includes("aphids".toLowerCase()) ||
                            listOfIssues.includes("fruittreespidermite".toLowerCase()) ||
                            listOfIssues.includes("lepidopterouscaterpillars".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Bioprotectants Macrobiological / Natural enemies</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#bioprotectantsMacrobiologicalDesc" aria-expanded="false" aria-controls="bioprotectantsMacrobiologicalDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="bioprotectantsMacrobiologicalDesc">
                                        <p>There are many native predator species, which can be found in the orchard habitat especially when insecticide sprays are withheld or reduced. For example, Anthocorid species and the common earwig can help to control soft bodied insects, the predatory mite Typhlodromus pyri can provide good control of fruit tree red spider mite, and the parasitoid wasp Platygaster demades is the natural enemy of apple leaf midge. Earwigs are generalist predators of pests in apple orchards such as aphids and moth caterpillars. Earwigs can be considered a pest in soft fruit crops, but where fruit quality and skin finish are good, they are not considered a pest in top fruit as minimal damage is caused. Earwig nests can be used to encourage earwig populations, which can made from rolled up cardboard in milk bottles with dried cat food as a food source or commercial refuges are available. In organic orchards releases of five to six earwigs per tree reduced aphid numbers to 50 per tree compared with 2000 - 3000 aphids per tree in the untreated controls.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                            'aphids'.toLowerCase(),
                                            'fruittreespidermite'.toLowerCase(),
                                            'lepidopterouscaterpillars'.toLowerCase()
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.bioprotectantsMacrobiological}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("aphids".toLowerCase()) ||
                            listOfIssues.includes("fruittreespidermite".toLowerCase()) ||
                            listOfIssues.includes("lepidopterouscaterpillars".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Bioprotectants Microbial</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#bioprotectantsMicrobialDesc" aria-expanded="false" aria-controls="bioprotectantsMicrobialDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="bioprotectantsMicrobialDesc">
                                        <p>
                                            Granuloviruses are available which specifically target the codling moth and can be used to provide control without affecting other species. Madex Top (Cydia pomonella Granulovirus isolate V15) is currently approved for use on apples in the UK and it is best to apply the virus just before egg hatch to ensure that the vulnerable neonates will ingest the virus when feeding on the fruit surface. The infected larvae will continue feeding for two to four days before death, leaving shallow feeding holes known as ‘sting injury’, which may lead to downgrading of the fruit and is not recommended close to harvest. The bacterium Bacillus thuringiensis subspecies kurstaki ABTS-351 (DiPel DF, EAMU 3028/19) can be used to control lepidopterous larvae. Caterpillars ingest the bacteria which releases a fatal toxin as it breaks down and the caterpillar activity and feeding stops immediately, followed by death after four to five days. This biopesticide is most effective when applied to actively feeding, young larvae, however codling moth larvae only feed for a limited time before boring into the fruit and may not ingest a lethal dose.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                            'aphids'.toLowerCase(),
                                            'fruittreespidermite'.toLowerCase(),
                                            'lepidopterouscaterpillars'.toLowerCase()
                                        ]} />
                                    </ul>
                                   <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.bioprotectantsMicrobial}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(listOfIssues.includes("lepidopterouscaterpillars".toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Bioprotectants Semiochemicals</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#bioprotectantsSemiochemicalDesc" aria-expanded="false" aria-controls="bioprotectantsSemiochemicalDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="bioprotectantsSemiochemicalDesc">
                                    <p>
                                        Sex pheromones are a type of semiochemical that can be used for monitoring and mating disruption for codling moth and other lepidoptera. Mating disruption can be achieved with passive pheromone dispensers, regular aerial sprays or timed-release aerosols to coincide with the female's natural pheromone release to confuse males. Pheromone systems such as RAK 3+4 are available for codling mothand summer fruit tortrix moth mating disruption and are best used in orchards greater than 1 ha in size with a low pest population (where no more than 1% of fruit was damaged in the previous year), and if no other controls have been used and spatially isolated from other tall trees. Pheromones are available to monitor populations of other species such as fruit tree tortrix moth, summer fruit tortrix moth, and light brown apple moth.
                                    </p>
                                </div>
                                <ul className="list-group list-group-horizontal list-group-flush">
                                    <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                        'lepidopterouscaterpillars'.toLowerCase()
                                    ]} />
                                </ul>
                               <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.bioprotectantsSemiochemical}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                        {(listOfIssues.includes("lepidopterouscaterpillars".toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Hygiene and prevention</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#hygieneAndPreventionDesc" aria-expanded="false" aria-controls="hygieneAndPreventionDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="hygieneAndPreventionDesc">
                                    <p>Removing dropped fruit from the orchards floor can prevent codling moth caterpillars moving from dropped fruit into the soil to complete their development. This reduces the local population in the orchard the following spring. Grazing with sheep or pigs can be used instead of physically removing fruit, but livestock can cause damage to trees.
                                    </p>
                                </div>
                                <ul className="list-group list-group-horizontal list-group-flush">
                                    <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                        'lepidopterouscaterpillars'.toLowerCase()
                                    ]} />
                                </ul>
                               <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.hygieneAndPrevention}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                        {(listOfIssues.includes("aphids".toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Nutrient management</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#nutrientManagementDesc" aria-expanded="false" aria-controls="nutrientManagementDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="nutrientManagementDesc">
                                    <p>
                                        High soil organic matter content has been linked to lower pest pressure. Avoiding excessive nutrient inputs can help to prevent excessive new tree growth, which can result in high numbers of aphids.
                                    </p>
                                </div>
                                <ul className="list-group list-group-horizontal list-group-flush">
                                    <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                        'aphids'.toLowerCase(),
                                    ]} />
                                </ul>
                               <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.nutrientManagement}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                        {(listOfIssues.includes("aphids".toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Physical exclusion of pests</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#physicalExclusionDesc" aria-expanded="false" aria-controls="physicalExclusionDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="physicalExclusionDesc">
                                    <p>
                                        Netting individual trees to exclude insects and prevent access from the soil can reduce codling moth fruit damage. However, netting can act as a barrier for beneficial insects and natural enemies and has been known to allow populations of aphids to increase. Exclusion bands can be used to prevent ants from gaining access to defend aphid colonies. Supplementary sugar feeders can be used to distract ants from the aphids, enabling predators and parasitoids to access undefended aphids and decrease their population.
                                    </p>
                                </div>
                                <ul className="list-group list-group-horizontal list-group-flush">
                                    <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                        'aphids'.toLowerCase(),
                                    ]} />
                                </ul>
                               <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.physicalExclusion}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                        {(
                            listOfIssues.includes("aphids".toLowerCase())
                            || listOfIssues.includes("lepidopterouscaterpillars".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Pruning/canopy management</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#canopyManagementDesc" aria-expanded="false" aria-controls="canopyManagementDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="canopyManagementDesc">
                                        <p>
                                            Training the architecture of fruit trees as they grow, for example centrifugal pruning, can enable predators to access pests more easily and reduce the numbers of aphids. Removal of curled leaves in young orchards can reduce colonies of rosy apple aphid. In older orchards removing excess growth from the central tree zone will encourage aphids to establish on new peripheral growth, where they are more easily accessible to flying predators such as birds and parasitoids. Birds predate several pests in apple orchards and can reduce numbers of codling moth and other lepidopterous caterpillars during the nesting season. Pruning helps to open the canopy and allow birds access to the pests, however netting or bird scarers may be required to prevent any damage from birds when the fruit is near to ripening. Thinning the orhard canopies can also improve the spray coverage of biopesticides and insecticides.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                            'aphids'.toLowerCase(),
                                            'lepidopterouscaterpillars'.toLowerCase(),
                                        ]} />
                                    </ul>
                                   <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.canopyManagement}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("aphids".toLowerCase())
                            || listOfIssues.includes("fruittreespidermite".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Undersowing & companion cropping</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#companionCroppingDesc" aria-expanded="false" aria-controls="companionCroppingDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="companionCroppingDesc">
                                        <p>
                                            Providing alternative vegetation such as wildflower mixes in an orchard will provide many predators and parasitoids with shelter and alternative food sources, enabling them to decrease numbers of aphids and fruit tree spider mites. Providing access to flowering plants such as Alyssum can help increase the hoverfly population and improve aphid control by predation. Common nettle can be used as a banker plant to establish a nettle aphid population, which will not infest trees but provides a food source to beneficial insects such as ladybirds, lacewings, hoverflies, and aphid parasitoids.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                            'aphids'.toLowerCase(),
                                            'fruittreespidermite'.toLowerCase(),
                                        ]} />
                                    </ul>
                                   <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.companionCropping}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("aphids".toLowerCase())
                            || listOfIssues.includes("lepidopterouscaterpillars".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Variety choice / root stock choice</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#varietyChoiceDesc" aria-expanded="false" aria-controls="varietyChoiceDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="varietyChoiceDesc">
                                        <p>
                                            Apple scion varieties are available, such as Florina and Prima, which are less susceptible to the rosy apple aphid. Rosy apple aphid resistance is linked to the presence of hydroxycinnamic acids, which protect fruit skin from UV light and are common in cider apple varieties. Some apple varieties are susceptible to rosy apple aphid however their buds burst later in the season after egg hatch and tend to have fewer rosy apple aphids as the neonates cannot feed. Information on apple varieties is available direct from breeders.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                            'aphids'.toLowerCase(),
                                            'lepidopterouscaterpillars'.toLowerCase(),
                                        ]} />
                                    </ul>
                                   <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.varietyChoice}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(listOfIssues.includes("lepidopterouscaterpillars".toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Spatial separation</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#spatialSeparationDesc" aria-expanded="false" aria-controls="spatialSeparationDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="spatialSeparationDesc">
                                    <p>
                                        When establishing a new commercial apple orchard, it is beneficial for it to be situated far from unsprayed orchards or gardens as these can be a source of infestation of flighted pests such lepidoptera.
                                    </p>
                                </div>                                <ul className="list-group list-group-horizontal list-group-flush">
                                    <ApplePestList listOfIssues={listOfIssues} toDisplay={[
                                        'lepidopterouscaterpillars'.toLowerCase()
                                    ]} />
                                </ul>
                               <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.spatialSeparation}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    </>
                )}
            </div>
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}
                ></MeasuresButtons>
            </div>
        </>
    );
};

export default AddApplePestMeasures;