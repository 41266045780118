import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const barleyDiseaseSummaryMeasures = (controlMeasures, listOfIssues, isWinter) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const brownRust = { name: 'brownRust', display: "Brown Rust" };
    const earBlight = { name: 'earBlight', display: "Ear Blight" };
    const eyespot = { name: 'eyespot', display: "Eyespot" };
    const leafAndGlumeBlotch = { name: 'leafAndGlumeBlotch', display: "Leaf And Glume Blotch" };
    const mildew = { name: 'mildew', display: "Mildew" };
    const ramularia = { name: 'ramularia', display: "Ramularia" };
    const seedBorneDiseases = { name: 'seedBorneDiseases', display: "Seed Borne Diseases" };
    const takeAll = { name: 'takeAll', display: "Take-All" };
    const yellowRust = { name: 'yellowRust', display: "Yellow Rust" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'controlVolunteers'.toLowerCase()) {
                    measureWithPests.measures = 'Control volunteers & weeds'
                    const issuesInControl = [
                        brownRust,
                        mildew,
                        seedBorneDiseases,
                        takeAll,
                        yellowRust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support (including thresholds)'
                    const issuesInControl = [
                        brownRust,
                        earBlight,
                        eyespot,
                        mildew,
                        yellowRust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, rotation & break crops'
                    const issuesInControl = [
                        eyespot,
                        leafAndGlumeBlotch,
                        takeAll,
                        yellowRust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygiene'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene'
                    const issuesInControl = [
                        seedBorneDiseases
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nutrientManagement'.toLowerCase()) {
                    measureWithPests.measures = 'Nutrient Management'
                    const issuesInControl = [
                        mildew,
                        takeAll,
                        yellowRust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'primaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Primary cultivations (crop residue burial)'
                    const issuesInControl = [
                        earBlight,
                        eyespot
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedTesting'.toLowerCase()) {
                    measureWithPests.measures = 'Seed Testing'
                    const issuesInControl = [
                        seedBorneDiseases
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedbedQuality'.toLowerCase()) {
                    measureWithPests.measures = 'Seedbed Quality'
                    const issuesInControl = [
                        takeAll,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Select low-risk locations'
                    const issuesInControl = [
                        eyespot,
                        yellowRust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'sowingDate'.toLowerCase()) {
                    measureWithPests.measures = 'Sowing Date'
                    const issuesInControl = [
                        earBlight,
                        eyespot,
                        ramularia,
                        seedBorneDiseases,
                        takeAll,
                        yellowRust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietalChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Varietal Choice'
                    const issuesInControl = [
                        brownRust,
                        earBlight,
                        eyespot,
                        mildew,
                        takeAll,
                        yellowRust
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus,
        [brownRust, earBlight, eyespot, leafAndGlumeBlotch, mildew, ramularia, seedBorneDiseases, takeAll, yellowRust]);
    return listOfMeasuresWithPests;
};

export default barleyDiseaseSummaryMeasures;