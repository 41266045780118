import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { QuestionCircle } from 'react-bootstrap-icons';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import MeasuresButtons from '../measuresButtons';
import MaizePestList from './maizePestList';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';



const AddMaizePestMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };;

    });
    const { jsonForm, loadingState } = useJsonForm(`636a3826bd4b6cff8734de58`);


    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.maize !== null)
        && (location.state.surveyData.pests.maize !== undefined)
        && location.state.surveyData.pests.maize.controlMeasures !== null
        && location.state.surveyData.pests.maize.controlMeasures !== undefined) ?
        location.state.surveyData.pests.maize.controlMeasures : {};
    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const [data, setData] = useState(initialData);
    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    if (initialData === null) initialData.maize = {};
    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.maize);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.maize.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>maize pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            <div className="row my-3">
                {listOfIssues.length === 0 ? (
                    <h3>No issues selected</h3>
                ) : (
                    <>
                        {listOfIssues.includes('europeancornborer'.toLowerCase()) &&
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Field history, rotation & break crops</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryRotationDesc" aria-expanded="false" aria-controls="fieldHistoryRotationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="fieldHistoryRotationDesc">
                                    <p>Increasing the rotation so maize is grown at a minimum of 1 year in every 3 years, will reduce the population of European corn borer, by removing the host crop it needs to survive on.</p>
                                </div>
                                <MaizePestList listOfIssues={listOfIssues} toDisplay={[
                                    'europeancornborer'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.fieldHistoryRotation}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        }
                        {listOfIssues.includes('wireworm'.toLowerCase()) &&
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Seed rate</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedRateDesc" aria-expanded="false" aria-controls="seedRateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="seedRateDesc">
                                    <p>Increasing seed rates can compensate for the loss of plants to pests such as wireworm</p>
                                </div>
                                <MaizePestList listOfIssues={listOfIssues} toDisplay={[
                                    'wireworm'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.seedRate}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        }
                        {(listOfIssues.includes('wireworm'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Seedbed quality</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedbedDesc" aria-expanded="false" aria-controls="seedbedDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="seedbedDesc">
                                    <p>Poor seedbeds can have two effects on pest damage. Firstly, poor seed/soil contact can cause delays in germination, which can render maize plants more susceptible to pests such as wireworm. Secondly, rough, cobbly seedbeds can delay germination. Firm, fine seedbeds avoid both problems and encourage rapid germination and crop establishment, thus decreasing susceptibility to pest attack.</p>
                                </div>
                                <MaizePestList listOfIssues={listOfIssues} toDisplay={[
                                    'wireworm'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.seedbed}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                        {(listOfIssues.includes('wireworm'.toLowerCase())) &&
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Select low-risk locations</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="lowRiskLocationsDesc">
                                    <p>Populations of some pests can build up under long-term leys, such as wireworm.  These will potentially feed on any crop following a grass ley.</p>
                                </div>
                                <MaizePestList listOfIssues={listOfIssues} toDisplay={[
                                    'wireworm'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.lowRiskLocations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        }
                    </>)}
            </div>
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}></MeasuresButtons>
            </div>
        </>
    );
};

export default AddMaizePestMeasures;