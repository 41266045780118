import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const oilseedRapePestSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const brassicaPodMidge = { name: 'brassicaPodMidge', display: "Brassica Pod Midge" };
    const cabbageRootFly = { name: 'cabbageRootFly', display: "Cabbage Root Fly" };
    const cabbageSeedWeevil = { name: 'cabbageSeedWeevil', display: "Cabbage Seed Weevil" };
    const cabbageStemFleaBeetle = { name: 'cabbageStemFleaBeetle', display: "Cabbage Stem Flea Beetle" };
    const mealyCabbageAphid = { name: 'mealyCabbageAphid', display: "Mealy Cabbage Aphid" };
    const pollenBeetle = { name: 'pollenBeetle', display: "Pollen Beetle" };
    const slugs = { name: 'slugs', display: "Slugs" };
    const turnipSawfly = { name: 'turnipSawfly', display: "Turnip Sawfly" };
    const tuyvVectors = { name: 'tuyvVectors', display: "TuYV Vectors" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support (incl. thresholds)'
                    const issuesInControl = [
                        cabbageRootFly,
                        cabbageSeedWeevil,
                        cabbageStemFleaBeetle,
                        mealyCabbageAphid,
                        pollenBeetle,
                        slugs,
                        turnipSawfly,
                        tuyvVectors];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'defoliation'.toLowerCase()) {
                    measureWithPests.measures = 'Defoliation (incl. mowing and grazing)'
                    const issuesInControl = [
                        cabbageStemFleaBeetle
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'rollingSoil'.toLowerCase()) {
                    measureWithPests.measures = 'Rolling soil post-planting'
                    const issuesInControl = [
                        slugs
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedRate'.toLowerCase()) {
                    measureWithPests.measures = 'Seed rate'
                    const issuesInControl = [
                        cabbageStemFleaBeetle
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedbedQuality'.toLowerCase()) {
                    measureWithPests.measures = 'Seedbed quality'
                    const issuesInControl = [
                        cabbageStemFleaBeetle,
                        slugs,
                        turnipSawfly
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'sowingDate'.toLowerCase()) {
                    measureWithPests.measures = 'Sowing date'
                    const issuesInControl = [
                        cabbageRootFly,
                        cabbageStemFleaBeetle
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'spatialSeparation'.toLowerCase()) {
                    measureWithPests.measures = 'Spatial separation'
                    const issuesInControl = [
                        brassicaPodMidge
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'trapCrops'.toLowerCase()) {
                    measureWithPests.measures = 'Trap crops'
                    const issuesInControl = [
                        cabbageStemFleaBeetle,
                        pollenBeetle
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietalChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Varietal choice'
                    const issuesInControl = [
                        tuyvVectors
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nonCroppedAreas'.toLowerCase()) {
                    measureWithPests.measures = 'In field non-cropped areas'
                    const issuesInControl = [
                        tuyvVectors
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus,
        [brassicaPodMidge, cabbageRootFly, cabbageSeedWeevil, cabbageStemFleaBeetle, mealyCabbageAphid, pollenBeetle, slugs, turnipSawfly, tuyvVectors]);
    return listOfMeasuresWithPests;
};

export default oilseedRapePestSummaryMeasures;