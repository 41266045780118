const removeLastYearPropertiesFromJson = (survey) => {
    for (const key in survey) {
        const lowercaseKey = key.toLowerCase();
        if (['landparcels', 'generalpractices', 'id', 'year', 'farmid'].includes(lowercaseKey)) {
            continue;
        }
        if (key.endsWith('LastYear') && survey[key] !== null) {
            delete survey[key];
        } else if (typeof survey[key] === 'object') {
            removeLastYearPropertiesFromJson(survey[key]);
        }
    }
    return survey;
}
export default removeLastYearPropertiesFromJson;