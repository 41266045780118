const BrassicasDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('darkLeafSpot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Dark leaf spot - Alternaria
                    </li>
                    : <></>
                }
                {values.includes('downyMildew'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://warwick.ac.uk/fac/sci/lifesci/people/jvicente/downymildew/" target="_blank" rel="noreferrer">Downy Mildew</a>
                    </li>
                    : <></>
                }
                {values.includes('lightLeafSpot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/light-leaf-spot-in-oilseed-rape" target="_blank" rel="noreferrer">Light leaf spot</a>
                    </li>
                    : <></>
                }
                {values.includes('ringspot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Ringspot
                    </li>
                    : <></>
                }
                {values.includes('xanthomonas'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://warwick.ac.uk/fac/sci/lifesci/people/jvicente/blackrot/" target="_blank" rel="noreferrer">Xanthomonas</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default BrassicasDiseaseList;