const AppleWeedsList = ({ issues, toDisplay }) => {
    if (issues.length === 0) {
        return <></>;
    }
    const values = issues.filter(value => toDisplay.includes(value));
    if (values.length === 0) {
        return <></>;
    } return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('windBlownWeeds'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Wind blown weeds
                    </li>
                    : <></>
                }
                {values.includes('tapRootBlwApple'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Tap Root BLW
                    </li>
                    : <></>
                }
                {values.includes('fibrousRootBlwApple'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Fibrous Root BLW
                    </li>
                    : <></>
                }
                {values.includes('couch'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Couch
                    </li>
                    : <></>
                }
                {values.includes('docks'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Docks
                    </li>
                    : <></>
                }
                {values.includes('creepingThistle'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Creeping thistle
                    </li>
                    : <></>
                }
                {values.includes('spearThistle'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Spear thistle
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default AppleWeedsList;