import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import LoadingIndicator from "../common/loadingIndicator";
import loadingStatus from "../../helpers/loadingStatus";
import useJsonForm from '../../hooks/useJsonForm';
import { Link } from 'react-router-dom';
import usePostRequest from '../../hooks/usePostRequest';

const AddFarm = () => {

    const { jsonForm, loadingState } = useJsonForm(`63230d679250175d6c50014c`);
    const [data, setData] = useState();
    const [continueToNextPage, setContinueToNextPage] = useState(false);
    const { post } = usePostRequest("/api/farm", data);
    const navigate = useNavigate();

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    const onAddNewFarmSubmitClick = async () => {
        let newFarm = await post();
        navigate("/add-ipm-plan", {
            state: {
                farmData: newFarm,
                surveyData: {},
                isNewSurvey: true
            }
        });
    };

    const formOnChange = ({ data, errors }) => {
        setData(data);
        errors.length === 0 ? setContinueToNextPage(true) : setContinueToNextPage(false);        
    };
       
    return (
        <>
            <div className="row my-3">
                <JsonForms
                    schema={schema}
                    uischema={uiSchema}
                    data={data}
                    renderers={materialRenderers}
                    cells={materialCells}
                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                />
            </div>
            <div className="row justify-content-between my-5">
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>     
                </Link>
                <button type="button" className="col-2 mx-3 btn btn-success" disabled={!continueToNextPage} onClick={() => onAddNewFarmSubmitClick()}>Save and start a new IPM Plan</button>     
            </div>
        </>
    );
};

export default AddFarm;