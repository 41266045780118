import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import MeasuresButtons from '../measuresButtons';
import { QuestionCircle } from 'react-bootstrap-icons';
import WheatDiseaseList from './wheatDiseaseList';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddSpringWheatDiseaseMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`637f8d4cf16228a14699718f`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.diseases !== null
        && (location.state.surveyData.diseases !== undefined)
        && (location.state.surveyData.diseases.springWheat !== null)
        && (location.state.surveyData.diseases.springWheat !== undefined)
        && (location.state.surveyData.diseases.springWheat.controlMeasures !== null)
        && (location.state.surveyData.diseases.springWheat.controlMeasures !== undefined)) ?
        location.state.surveyData.diseases.springWheat.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.diseases?.springWheat);
    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.diseases.springWheat.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year,
            isSpring: true
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>spring wheat disease control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('brownRust'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('seedBorneDiseases'.toLowerCase()) ||
                        listOfIssues.includes('yellowRust'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Control volunteers & weeds</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#controlVolunteersDesc" aria-expanded="false" aria-controls="controlVolunteersDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="controlVolunteersDesc">
                                    <p>Cereal volunteers carry a range of diseases and are most significant as a ‘green bridge’ for powdery mildew, yellow rust, brown rust, and crown rust. Ideally volunteers should be destroyed prior to the emergence of new crops.
                                    </p>
                                </div>

                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'brownRust'.toLowerCase(),
                                    'seedBorneDiseases'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'yellowRust'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.controlVolunteers}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('brownRust'.toLowerCase()) ||
                        listOfIssues.includes('earBlight'.toLowerCase()) ||
                        listOfIssues.includes('eyespot'.toLowerCase()) ||
                        listOfIssues.includes('leafAndGlumeBlotch'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('septoria'.toLowerCase()) ||
                        listOfIssues.includes('yellowRust'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Decision support (including thresholds)</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="decisionSupportDesc">
                                    <p>IPM decisions should be made based on the results of monitoring and forecasting combined with threshold information where available. Treatment thresholds are the population level or density that must be reached before it becomes economically beneficial. Thresholds enable growers to make decisions based on the level at which pests will impact economic crop yield. They are essential in guiding pest control decisions and preventing the unnecessary use of pesticides.
                                    </p>
                                    <p>Disease forecasts are available from the <a href="https://platform.ipmdecisions.net/" target="_blank" rel="noreferrer">IPM Decisions platform</a>. Forecasts are only active during the growing season.</p>
                                </div>

                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'brownRust'.toLowerCase(),
                                    'earBlight'.toLowerCase(),
                                    'eyespot'.toLowerCase(),
                                    'leafAndGlumeBlotch'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'septoria'.toLowerCase(),
                                    'yellowRust'.toLowerCase(),
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.decisionSupport}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('eyespot'.toLowerCase()) ||
                        listOfIssues.includes('leafAndGlumeBlotch'.toLowerCase()) ||
                        listOfIssues.includes('septoria'.toLowerCase()) ||
                        listOfIssues.includes('takeAll'.toLowerCase()) ||
                        listOfIssues.includes('yellowRust'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Field history, rotation & break crops</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="fieldHistoryDesc">
                                    <p>Previous cropping has little or no effect on the likelihood of foliar disease such as rusts, Septoria, Rhynchosporium and net blotch. Eyespot is trash borne from previous cereal crops. Second cereals are at higher risk and a two year break from cereals is needed to prevent eyespot fully. Take all is usually most severe in second, third or fourth successive cereal crops and relies mainly on rotational strategies for control. Mosaic viruses, such as Barley yellow mosaic virus (BaYMV), Barley mild mosaic virus (BaMMV), and Oat mosaic virus (OMV) can persist in the soil for more than 25 years and are unlikely to benefit from break crops, although resistant varieties are available for barley.
                                    </p>
                                    <p>For specific guidance click <a href="https://ahdb.org.uk/knowledge-library/rotation-cultivation-and-drilling-options-for-cereal-disease-management" target="_blank" rel="noreferrer">here</a></p>
                                </div>

                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'eyespot'.toLowerCase(),
                                    'leafAndGlumeBlotch'.toLowerCase(),
                                    'takeAll'.toLowerCase(),
                                    'septoria'.toLowerCase(),
                                    'yellowRust'.toLowerCase(),
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.fieldHistory}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('seedBorneDiseases'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Hygiene</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="hygieneDesc">
                                    <p>This is the first defence against the introduction of soil-borne diseases into clean land. Machinery used in infested fields should be power-washed before use in uninfected fields, and soil should at least be knocked off from boots and tools. Clean fields should be visited first in the sequence of crops so that cleaning down equipment can be done at the end of the day.
                                    </p>
                                </div>

                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'seedBorneDiseases'.toLowerCase(),
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.hygiene}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('septoria'.toLowerCase()) ||
                        listOfIssues.includes('takeAll'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('yellowRust'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Nutrient Management</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#nutrientManagementDesc" aria-expanded="false" aria-controls="nutrientManagementDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="nutrientManagementDesc">
                                    <p>Crops which are nutrient deficient can be predisposed to disease infection. Ensure appropriate soil nutrient supply by regular soil sampling and testing and use of appropriate fertilisers.
                                        Excessive nitrogen will exacerbate powdery mildew and rusts. Where excess nitrogen is used, crops are more likely to lodge and this favours these pathogens and other that develop under very humid conditions ie. septoria tritici blotch and fusurium ear blight.
                                    </p>
                                </div>

                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'takeAll'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'septoria'.toLowerCase(),
                                    'yellowRust'.toLowerCase(),
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.nutrientManagement}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('earBlight'.toLowerCase()) ||
                        listOfIssues.includes('eyespot'.toLowerCase())
                    ) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Primary Cultivations (Crop Residue Burial)</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#primaryCultivationDesc" aria-expanded="false" aria-controls="primaryCultivationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="primaryCultivationDesc">
                                    <p>Burial of crop debris by ploughing can reduce inoculum for some pathogens which produce inoculum on plant debris such as Septoria, Rhynchosporium and net blotch. This is most important for pathogens, such as eyespot, which disperse spores mainly over short distances. However, if the preceding crop is a non-host, inversion tillage may increase eyespot by bringing older infected residue back to the surface. Also on the negative side the use of ploughing can reduce soil biodiversity.
                                    </p>
                                    <p>For specific guidance click <a href="https://ahdb.org.uk/knowledge-library/rotation-cultivation-and-drilling-options-for-cereal-disease-management" target="_blank" rel="noreferrer">here</a></p>
                                </div>

                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'earBlight'.toLowerCase(),
                                    'eyespot'.toLowerCase(),
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.primaryCultivations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('seedBorneDiseases'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Seed testing</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedTestingDesc" aria-expanded="false" aria-controls="seedTestingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="seedTestingDesc">
                                    <p>The use of certified seed is important for most crops to ensure that heavily infected seed stocks are not used and can be an effective approach to reducing some diseases. Seed testing of home saved seed should be used to identify common seed-borne diseases such as Fusarium spp., bunt and loose smut and contaminants such as ergot.
                                    </p>
                                    <p>For specific guidance click <a href="https://ahdb.org.uk/knowledge-library/seedborne-diseases-of-cereals-tests-and-thresholds" target="_blank" rel="noreferrer">here</a></p>
                                </div>

                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'seedBorneDiseases'.toLowerCase(),
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.seedTesting}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('takeAll'.toLowerCase())) && (
                        <>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Seedbed Quality</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#seedbedQualityDesc" aria-expanded="false" aria-controls="seedbedQualityDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="seedbedQualityDesc">
                                <p>Direct and non-inversion drilling increases the risk of disease transfer from previous crop residues and volunteers to the newly growing crop.  The main risk is from volunteers, which should be effectively controlled.
                                </p>
                                <p>For specific guidance click <a href="https://ahdb.org.uk/knowledge-library/rotation-cultivation-and-drilling-options-for-cereal-disease-management" target="_blank" rel="noreferrer">here</a></p>
                            </div>

                            <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'takeAll'.toLowerCase(),
                            ]} />

                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.seedbedQuality}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(
                        listOfIssues.includes('eyespot'.toLowerCase()) ||
                        listOfIssues.includes('yellowRust'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Select Low-Risk Locations</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="lowRiskLocationsDesc">
                                    <p>Epidemics of yellow rust tend to be more severe in coastal regions, particularly in the east, due to the favourable conditions of cooler summers and frequent sea mists. Eyespot favours heavy soils that retain water.
                                    </p>
                                </div>


                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'eyespot'.toLowerCase(),
                                    'yellowRust'.toLowerCase(),
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.lowRiskLocations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('earBlight'.toLowerCase()) ||
                        listOfIssues.includes('eyespot'.toLowerCase()) ||
                        listOfIssues.includes('seedBorneDiseases'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('takeAll'.toLowerCase()) ||
                        listOfIssues.includes('yellowRust'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Sowing date</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#sowingDateDesc" aria-expanded="false" aria-controls="sowingDateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="sowingDateDesc">
                                    <p>Spring cereal crops can easily become infected by Mildew airborne spores from nearby winter crops. Crops that are drilled late may be less prone to mildew and yellow rust attack.
                                    </p>
                                    <p>For specific guidance click <a href="https://ahdb.org.uk/knowledge-library/rotation-cultivation-and-drilling-options-for-cereal-disease-management" target="_blank" rel="noreferrer">here</a></p>
                                </div>


                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'earBlight'.toLowerCase(),
                                    'eyespot'.toLowerCase(),
                                    'seedBorneDiseases'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'takeAll'.toLowerCase(),
                                    'yellowRust'.toLowerCase(),
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.sowingDate}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('brownRust'.toLowerCase()) ||
                        listOfIssues.includes('earBlight'.toLowerCase()) ||
                        listOfIssues.includes('eyespot'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('septoria'.toLowerCase()) ||
                        listOfIssues.includes('takeAll'.toLowerCase()) ||
                        listOfIssues.includes('yellowRust'.toLowerCase())) && (
                            <>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Varietal choice</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#varietalChoiceDesc" aria-expanded="false" aria-controls="varietalChoiceDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="varietalChoiceDesc">
                                    <p>Resistant varieties are a key part of non-chemical disease control. There are good sources of information on disease resistance to many of the major pathogens in the Recommended List of cereals varieties published by AHDB.  This information is updated annually to account for new pathogen strains which can infect previously resistant varieties.
                                    </p>
                                    <p>For specific guidance click <a href="https://ahdb.org.uk/knowledge-library/an-introduction-to-disease-resistance-in-cereals-and-oilseed-rape" target="_blank" rel="noreferrer">here</a></p>
                                </div>

                                <WheatDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'brownRust'.toLowerCase(),
                                    'earBlight'.toLowerCase(),
                                    'eyespot'.toLowerCase(),
                                    'mildew'.toLowerCase(),
                                    'septoria'.toLowerCase(),
                                    'takeAll'.toLowerCase(),
                                    'yellowRust'.toLowerCase(),
                                ]} />

                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.varietalChoice}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                </>
            )}
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"diseases"}
                    finalUrl={"/add-diseases-resistance"}
                    readonly={status === 'completed'}
                >
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddSpringWheatDiseaseMeasures;