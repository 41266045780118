import { JsonForms } from '@jsonforms/react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import MeasuresButtons from '../measuresButtons';
import SugarBeetDiseaseList from './sugarBeetDiseaseList';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { QuestionCircle } from 'react-bootstrap-icons';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddSugarBeetDiseaseMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`63b7f3731c3f997664e23eb2`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.diseases !== null
        && (location.state.surveyData.diseases !== undefined)
        && (location.state.surveyData.diseases.sugarBeet !== null)
        && (location.state.surveyData.diseases.sugarBeet !== undefined)
        && location.state.surveyData.diseases.sugarBeet.controlMeasures !== null
        && location.state.surveyData.diseases.sugarBeet.controlMeasures !== undefined) ?
        location.state.surveyData.diseases.sugarBeet.controlMeasures : {};
    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);
    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.diseases?.sugarBeet);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.diseases.sugarBeet.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>sugar beet disease control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('cercospera'.toLowerCase()) ||
                        listOfIssues.includes('rhizomania'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Decision support, incl. monitoring</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="decisionSupportDesc">
                                <p>IPM decisions should be made based on the results of monitoring and forecasting combined with threshold information where available. Treatment thresholds are the disease level or density that must be reached before intervention is required or economically beneficial. Thresholds enable growers to make decisions based on the level at which pests will impact economic crop yield.</p>
                                <p>Disease forecasts are available from the <a href="https://bbro.co.uk/on-farm/cercospora-risk/" target="_blank" rel="noreferrer">British Beet Research Organization website</a></p>
                            </div>
                            <SugarBeetDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'cercospera'.toLowerCase(),
                                'rhizoctonia'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.decisionSupport}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('cercospera'.toLowerCase()) ||
                        listOfIssues.includes('rhizoctonia'.toLowerCase()) ||
                        listOfIssues.includes('violetRootRot'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Field history, rotation & break crops</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="fieldHistoryDesc">
                                <p>If a field has a history of a certain disease it is likely that this will return as a problem in the future, either through survival in the soil, on the soil surface or on a green bridge host plant as a disease or as an alternative host for a virus vector. Knowledge of this field history allows steps to be taken to minimise the risks. Crop rotation limits the build-up of many soil borne sugar beet diseases, it also allows an opportunity to control beet volunteers which can act as a reservoir of disease. Sugar beet should be grown no more frequently than 1 year in every 4.</p>
                            </div>
                            <SugarBeetDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'cercospera'.toLowerCase(),
                                'rhizoctonia'.toLowerCase(),
                                'violetRootRot'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.fieldHistory}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('cercospera'.toLowerCase()) ||
                        listOfIssues.includes('rhizoctonia'.toLowerCase()) ||
                        listOfIssues.includes('rhizomania'.toLowerCase()) ||
                        listOfIssues.includes('violetRootRot'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Hygiene and prevention</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="hygieneDesc">
                                <p>This is the first defence against the introduction of soil-borne diseases into clean land for example rhizomania. Machinery used in infested fields should be power-washed before use in uninfected fields, and soil should at least be knocked off from boots and tools. Clean fields should be visited first in the sequence of crops so that cleaning down equipment can be done at the end of the day. Sugar beet spoil heaps can host many diseases such as rhizoctonia, violet root rot and others, glyphosate should be used to achieve a zero tolerance of shoot or foliage production.</p>
                            </div>
                            <SugarBeetDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'cercospera'.toLowerCase(),
                                'rhizoctonia'.toLowerCase(),
                                'rhizomania'.toLowerCase(),
                                'violetRootRot'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.hygiene}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('rhizoctonia'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Select low-risk locations</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="lowRiskLocationsDesc">
                                <p>The strain of Rhizoctonia solani, which is responsible for Rhizoctonia root rot in sugar beet, has a relatively broad range of hosts including oilseed rape, broccoli, cabbage, cauliflower, peas, beans, radish, wheat, barley, potatoes and many weed species. Fields with a previous history of the disease should be avoided for planting sugar beet.</p>
                            </div>
                            <SugarBeetDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'rhizoctonia'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.lowRiskLocations}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                    {(listOfIssues.includes('cercospera'.toLowerCase()) ||
                        listOfIssues.includes('mildew'.toLowerCase()) ||
                        listOfIssues.includes('rust'.toLowerCase())) &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h2>Variety choice</h2></div>
                                <div data-bs-toggle="collapse" data-bs-target="#varietyChoiceDesc" aria-expanded="false" aria-controls="varietyChoiceDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>
                            <div className="collapse" id="varietyChoiceDesc">
                                <p>Resistant varieties are a key part of non-chemical disease control. There are good sources of information on disease resistance to many of the major pathogens in the recommended list of sugar beet varieties published by BBRO.  This information is updated annually.</p>
                                <p>For specific guidance click <a href="https://bbro.co.uk/sugar-beet-varieties/recommended-list/" target="_blank" rel="noreferrer">here</a></p>
                            </div>
                            <SugarBeetDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'cercospera'.toLowerCase(),
                                'mildew'.toLowerCase(),
                                'rust'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.varietyChoice}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    }
                </>
            )}
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"diseases"}
                    finalUrl={"/add-diseases-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddSugarBeetDiseaseMeasures;