import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import loadingStatus from '../../helpers/loadingStatus';
import useJsonForm from '../../hooks/useJsonForm';
import LoadingIndicator from '../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import useGetRequest from '../../hooks/useGetRequest';
import usePutRequest from '../../hooks/usePutRequest';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';

const EditFarm = () => {

    let { id } = useParams();
    const { jsonForm, loadingState } = useJsonForm(`63230d679250175d6c50014c`);
    const { get } = useGetRequest(`/api/farm/${id}`);
    const [data, setData] = useState([]);
    const [continueToNextPage, setContinueToNextPage] = useState(false);
    const { put } = usePutRequest(`/api/farm/${id}`, data);
    const navigate = useNavigate();   


    useEffect(() => {
        const fetchFarm = async () => {
            const data = await get();
            if (data.farm != null && data.farm.address === null) { delete data.farm.address };
            if (data.farm != null && data.farm.contactInformation === null) { delete data.farm.contactInformation };
            setData(data.farm);
        };
        fetchFarm();
    }, [get]);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        errors.length === 0 ? setContinueToNextPage(true) : setContinueToNextPage(false);
    };

    const onUpdateFarmSubmitClick = async (goToNextPage) => {
        const response = await put();

        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!goToNextPage) {
            toast.info("Saved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        navigate("/farm-list")
    };

    return (
        <>
            <h1 className="text-uppercase"> Edit your farm: <span className="fw-bold">{data.name}</span></h1>
            <div className="row my-3">
                <JsonForms
                    schema={schema}
                    uischema={uiSchema}
                    data={data}
                    renderers={materialRenderers}
                    cells={materialCells}
                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                />
            </div>
            <div className="row justify-content-between my-5">
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                <button type="button" className="col-2 mx-3 btn btn-success" disabled={!continueToNextPage} onClick={() => onUpdateFarmSubmitClick(true)}>Save and go back to your farms</button>
            </div>
        </>
    );
};

export default EditFarm;
