import AddToRole from './addToRole';
import ResetUserPassword from './resetUserPassword';
import UsersData from './usersData';

const Admin = () => {
    return (
        <>
            <h1>Admin area</h1>
            <AddToRole />
            <ResetUserPassword />
            <UsersData />
        </>
    );
};

export default Admin;
