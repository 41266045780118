import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import { toast } from 'react-toastify';
import usePutRequest from '../../../../hooks/usePutRequest';
import AllCropsWeedsList from './allCropsWeedsList';
import CombineCropsWeedsList from './combineCropsWeedsList';
import WideRowCropsWeedsList from './wideRowCropsWeedsList';
import { ArrowLeft, ArrowRight, QuestionCircle } from 'react-bootstrap-icons'
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import SurveyNavigation from '../../surveyNavigation';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';
import getListOfCrops from '../../../../helpers/getListOfCrops';

const AddWeedsMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`6380904be250d623d32622bb`);

    if (location.state.surveyData.weeds === null) location.state.surveyData.weeds = {
        'allCrops': {}, 'combinableCrops': {}, 'wideRowCrops': {}
    };
    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.weeds !== null
        && (location.state.surveyData.weeds !== undefined)) ?
        location.state.surveyData.weeds : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const { put } = usePutRequest(`/api/survey/${location.state?.surveyData?.id}`, location.state?.surveyData);

    const [data, setData] = useState(initialData.controlMeasures)

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);

    let listOfWeedsAllCrops = getListOfIssues(location.state.surveyData.weeds.allCrops);
    let listOfWeedsCombinableCrops = getListOfIssues(location.state.surveyData.weeds.combinableCrops);
    let listOfWeedsWideRowCrops = getListOfIssues(location.state.surveyData.weeds.wideRowCrops);
    const listOfCrops = getListOfCrops(location.state?.surveyData.landParcels);

    let prevUrl = "/add-weeds"
    ////if (listOfCrops.includes("improvedgrassland")) {
    ////    prevUrl = "/add-weeds-improvedgrassland-measures";
    ////} else if (listOfCrops.includes("apple")) {
    ////    prevUrl = "/add-weeds-apple-measures";
    ////} else {
    ////    prevUrl = "/add-weeds";
    ////}

    const allWeedsIssues = { 'allCropsIssues': listOfWeedsAllCrops, 'combinableCropsIssues': listOfWeedsCombinableCrops, 'wideRowCropsIssues': listOfWeedsWideRowCrops };
    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.weeds.controlMeasures = data;
    };

    const onAddWeedsSubmitClick = async (goToNextPage) => {
        const response = await put();
        location.state.surveyData.weeds.controlMeasures = data;
        if (!response.isValid) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (!goToNextPage) {
            toast.info("Saved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        navigate("/add-weeds-resistance",
            {
                state: {
                    farmData: location.state.farmData,
                    surveyData: location.state.surveyData,
                    crops: listOfCrops
                }
            });
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>weeds control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category you feel best represents the level of risk on your farm</p>
            </div>
            {listOfWeedsAllCrops.length === 0 && listOfWeedsCombinableCrops.length === 0 && listOfWeedsWideRowCrops.length === 0 ? (
                <>
                    <h3>No issues selected</h3>
                </>
            ) : (
                <>
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('creepingThistle'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('weedBeet'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('wildOats'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('couch'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Rotation</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#rotationDesc" aria-expanded="false" aria-controls="rotationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="rotationDesc">
                                    <p>In a rotation the choice of crop affects the type and timing of cultivations and time of drilling. A rotation allows a change in crop types and sowing dates which provide opportunities for different methods of weed control outside a cereal crop.</p>
                                    <p>In spring crops there is selection for spring germinating weeds and likewise selection for autumn germinating weeds in autumn sown crops. Disrupting these crop/weed associations is a basic approach to actively discouraging the growth and reproduction of certain weed species.</p>
                                    <p>Grass weeds are easier to control in broadleaved crops like oilseed rape and sugar beet. In oilseed rape propyzamide can be used to control blackgrass, and there is no known resistance to this herbicide.</p>
                                    <p>Including a grass ley in a rotation can prove effective in controlling blackgrass in problem fields. In grassland, mob or intensive grazing or high stocking rates will force stock to graze weeds and allow grass to establish more successfully. Avoid poaching and compaction.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/how-to-manage-weeds-in-arable-rotations-a-guide" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                        'volunteerPotatoes'.toLowerCase(),
                                        'weedBeet'.toLowerCase(),
                                        'wildOats'.toLowerCase(),
                                        'couch'.toLowerCase()]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.rotation}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('creepingThistle'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('weedBeet'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('wildOats'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('couch'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Select low-risk locations</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="lowRiskLocationsDesc">
                                    <p>Fields with known problem weeds that are difficult to control in cereals should be avoided such as brome in barley, and couch or black-nightshade in potatoes. Consider another crop for the field where the problem weed can be tackled more easily. </p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/how-to-manage-weeds-in-arable-rotations-a-guide" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                        'volunteerPotatoes'.toLowerCase(),
                                        'weedBeet'.toLowerCase(),
                                        'wildOats'.toLowerCase(),
                                        'couch'.toLowerCase()]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.lowRiskLocations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('creepingThistle'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('weedBeet'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('wildOats'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('couch'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Fallow</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#fallowDesc" aria-expanded="false" aria-controls="fallowDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="fallowDesc">
                                    <p>Fallowing is taking land out of production for a whole season. The aim is to cultivate the soil regularly, encouraging weeds to germinate and killing them before any seed is set. A single year fallow can reduce black-grass numbers by 70%. A fallow is also effective for bromes and Italian ryegrass. Fallows should be managed so that no weeds set seed, and this can be achieved through multiple mowing or use of a total herbicide. Cultivation can stimulate a greater number of weeds to emerge.</p>
                                    <p>For specific guidance click <a target="_blank" href="http://sciencesearch.defra.gov.uk/Default.aspx?Menu=Menu&Module=More&Location=None&ProjectID=18583&FromSearch=Y&Publisher=1&SearchText=ps2724&SortString=ProjectCode&SortOrder=Asc&Paging=10#Description" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'creepingThistle'.toLowerCase(),
                                        'volunteerPotatoes'.toLowerCase(),
                                        'weedBeet'.toLowerCase(),
                                        'wildOats'.toLowerCase(),
                                        'couch'.toLowerCase()]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.fallow}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}

                    {(
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Improve Soil Condition</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#improveDesc" aria-expanded="false" aria-controls="improveDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="improveDesc">
                                    <p>Improving soil conditions including compaction, drainage, pH and nutrient status can help with weed control. Removing compaction improves establishment and makes the crop more competitive against weeds. To avoid compaction, do not graze vulnerable fields when wet to prevent surface compaction.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/arable-soil-management-cultivation-and-crop-establishment" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.improveSoilCondition}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('creepingThistle'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('weedBeet'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('wildOats'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('couch'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Stubble management</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#stubbleManagementDesc" aria-expanded="false" aria-controls="stubbleManagementDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="stubbleManagementDesc">
                                    <p>Stubbles can be left uncultivated to allow birds and insects to eat weed seeds on the soil surface. Total herbicides such as glyphosate plus or minus 2,4-D (check label for following crop restrictions) can be used to remove all weed species.</p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                        'volunteerPotatoes'.toLowerCase(),
                                        'weedBeet'.toLowerCase(),
                                        'wildOats'.toLowerCase(),
                                        'couch'.toLowerCase()]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.stubbleManagement}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Stale Seedbed</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#staleSeedbedDesc" aria-expanded="false" aria-controls="staleSeedbedDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="staleSeedbedDesc">
                                    <p>A stale seedbed is defined as one prepared days, weeks, or months before establishing a crop. This technique is done to encourage a flush of weeds that are then killed by cultivation or non-selective chemical control, depleting the upper layers of the seed bank, and reducing subsequent weed emergence within the crop. Stale seedbeds are more beneficial if two flushes of emergence are achieved. When drilling minimize soil disturbance to prevent new weed seeds appearing from depth.</p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase(),
                                        'volunteerPotatoes'.toLowerCase(),
                                    ]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.staleSeedbed}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Use of cover crops</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#coverCropsDesc" aria-expanded="false" aria-controls="coverCropsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="coverCropsDesc">
                                    <p>Cover crops are sown before or after a crop to minimise the time the soil is left without green cover. Cover crops change the environment at the soil surface, generally discouraging weeds from emerging. The ideal cover crop is quick growing, so it competes with weeds and/or prevents their emergence, however information is limited on their effectiveness on weeds. Cover crops should be sown thinly so that weeds are encouraged to emerge, and then these can then be terminated with the cover crop.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/cover-crops" rel="noreferrer">here</a></p>
                                </div>
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase()
                                    ]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.coverCrops}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('creepingThistle'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('weedBeet'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('wildOats'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Hygiene/prevention</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="hygieneDesc">
                                    <p>Weed seeds are present in soil and can attach to machinery, vehicles and boots. Make sure to clean these between fields to prevent spread. Buy weed free seed and where home saved seed is used ensure that it is cleaned before sowing. Echinochloa (Barnyard grass) has become a problematic weed in maize as a contaminant in seed. Check seed being purchased for field margin, environmental areas and cover crops, as some samples can contain high levels of weed seeds particularly black-grass.</p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                        'volunteerPotatoes'.toLowerCase(),
                                        'weedBeet'.toLowerCase(),
                                        'wildOats'.toLowerCase()
                                    ]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.hygiene}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Early harvest</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#earlyHarvestDesc" aria-expanded="false" aria-controls="earlyHarvestDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="earlyHarvestDesc">
                                    <p>Cereals can be harvested early as whole crop silage to prevent high populations of weeds such as black-grass setting seed.</p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase()
                                    ]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.earlyHarvest}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('weedBeet'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('couch'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Hand pulling/roguing/cutting/digging</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#handPullingDesc" aria-expanded="false" aria-controls="handPullingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="handPullingDesc">
                                    <p>Hand pulling of small patches of weeds can be effective, for example to control wild oats and small numbers of herbicide resistant grass weeds like black-grass. The pulled weeds should be removed from the field to prevent further seed spread. Pulling is usually easier when the soil is moist.
                                        Perennial weeds can be directly dug or pulled. Specialised handheld tools have been developed to remove specific weeds e.g. prongs or forks to remove tap rooted weeds such as docks or ragwort.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/how-to-manage-weeds-in-arable-rotations-a-guide" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'volunteerPotatoes'.toLowerCase(),
                                        'weedBeet'.toLowerCase(),
                                        'wildOats'.toLowerCase()]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.handPulling}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Harrowing/mechanical weeding (seedling weeds)</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#harrowingDesc" aria-expanded="false" aria-controls="harrowingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="harrowingDesc">
                                    <p>Harrows and tine weeders uproot seedling weeds and cover with a thin layer of soil. These work best where the soil surface has a medium to fine tilth and can be used in all soil types. They disturb the soil at a depth of 2-3 cm and are effective on weeds at the early growth stages (up to 2.5 cm in height) and should be done before the crops get too big to avoid damage.  Weeds are generally controlled by burial, but there is some uprooting where working depth and speed of travel are increased. These techniques work best in dry conditions. In grassland this technique can be effective in recently sown leys for weeds with shallow fibrous roots such as chickweed, mayweed and fat hen.

                                        In rowcrops, inter row hoeing can be used as an effective weed control measure. Hoes cuts off weeds at 1-2 cm below the surface and are most effective on loose and moderately fine dry soil. Mounted on the front or rear of the tractor, blades are usually A or L shaped, they work at a depth of 2.5 - 5 cm and cut weeds off at the roots and/or bury them. Discs, plates, or protective hoods can be fitted to protect the crop from damage.</p>
                                    <p>For specific guidance click
                                        <a target="_blank" href="https://horticulture.ahdb.org.uk/potatoes-review-of-weed-control-options-and-future-opportunities-for-uk-crops" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase(),
                                        'weedBeet'.toLowerCase()
                                    ]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.harrowing}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Thermal weed control</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#thermalWeedControlDesc" aria-expanded="false" aria-controls="thermalWeedControlDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="thermalWeedControlDesc">
                                    <p>Flame weeders have been used successfully in potatoes, field brassicas and apple orchards for the control of small seedling weeds prior to the emergence of the crop. In apple orchards handheld machinery is currently available to apply hot foam direct to the soil, which kills surface vegetation but does not touch the roots, so is only effective on small annual weeds, both tap and fibrous rooted.</p
                                    ><p>For specific guidance click <a target="_blank" href="https://www.agricology.co.uk/sites/default/files/Weed%20management%20in%20organic%20potatoes.pdf" rel="noreferrer">here</a></p>
                                </div>
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase()
                                    ]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.thermalWeedControl}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('creepingThistle'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('weedBeet'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('wildOats'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('couch'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Precision application band spraying/weed wiper/spot spray</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#precisionApplicationDesc" aria-expanded="false" aria-controls="precisionApplicationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="precisionApplicationDesc">
                                    <p>Precision application to weeds can reduce the amount of herbicide used. The use of video cameras and image analysis computers locate weed position and target herbicide sprays.</p>
                                    <p>The use of weed wiping is currently restricted to glyphosate only. Weeds need to be 8 - 12 inches above the growing crop, and two passes in opposite directions increases success rate. Wipe wiping is good for thistles, which are best treated when flowering, and nettles. Weed wiping is less effective on rushes, docks, ragwort and buttercups. In cereals, the height differential between some grass weeds, such as black-grass, is not enough to all for the use of a weed wiper. Patch spraying can be used to control problem areas of black-grass and other grass weeds.</p>
                                    <p> On grassland herbicide can be sprayed through a knapsack or from a lance on a quadbike, and used to treat individual weeds such as docks and spear thistle. This causes minimal damage to clover in the sward.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/spray-application-and-herbicide-efficacy-in-cereals-and-oilseed-rape" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'creepingThistle'.toLowerCase(),
                                        'volunteerPotatoes'.toLowerCase(),
                                        'weedBeet'.toLowerCase(),
                                        'wildOats'.toLowerCase(),
                                        'couch'.toLowerCase()]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.precisionApplication}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('creepingThistle'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('wildOats'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('couch'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Ploughing (crop residue burial)</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#ploughingDesc" aria-expanded="false" aria-controls="ploughingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="ploughingDesc">
                                    <p>Primary cultivation is the first cultivation that is conducted to prepare the soil for the next crop and can be classified into four groups: plough, deep and shallow till, and no-till. Changing the primary cultivation is an effective measure in changing weed populations.</p>
                                    <p>Ploughing inverts the soil, burying 95% of freshly shed seed to 15-20cm at which they cannot germinate, but brings up 35% of old seed buried by previous cultivations however these are fewer in number than freshly shed seed due to the annual decline through seed death and predation. Ploughing also buries weeds that have already germinated.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/how-cultivating-affects-the-arable-weed-seedbank" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase(),
                                        'creepingThistle'.toLowerCase(),
                                        'volunteerPotatoes'.toLowerCase(),
                                        'weedBeet'.toLowerCase(),
                                        'wildOats'.toLowerCase(),
                                        'couch'.toLowerCase()]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.ploughing}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Non-inversion tillage</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#nonIversionDesc" aria-expanded="false" aria-controls="nonIversionDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="nonIversionDesc">
                                    <p>Primary cultivation is the first cultivation that is conducted to prepare the soil for the next crop and can be classified into four groups: plough, deep and shallow till, and no-till. Changing the primary cultivation is an effective measure in changing weed populations.</p>
                                    <p>Non-inversion tillage mixes the soil in the upper layers to the working depth of the implement. This can either be intensive, moving soil down to 30cm, or very minimal through the use shallow tillage. Shallow cultivations create a tilth suitable for crops to germinate and working depth may depend on the crop, but is typically around 10cm, to kill any germinating weeds. Varying the number and timing of this cultivation can be used as a technique for weed control. The weeds that germinate are a mixture of freshly shed seed and those from previous seasons. Approximately 50% of newly shed seed is buried below germination depth (5 cm) and 10% of old seed returns to the surface. Moving less soil means fewer weed seeds are exposed and do not germinate.</p>
                                    <p>With no-till and direct drilling the only soil movement that occurs is that done by the drill and the freshly shed seed remains on the soil surface, and some weed seed falls down cracks in the soil. Freshly shed seed on the surface is subject to predation and death through desiccation or disease.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/how-cultivating-affects-the-arable-weed-seedbank" rel="noreferrer">here</a></p>
                                </div>
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.nonInversionTillage}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('creepingThistle'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('volunteerPotatoes'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('weedBeet'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('wildOats'.toLowerCase()) ||
                        allWeedsIssues.allCropsIssues.includes('couch'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Direct drilling</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#directDrillingDesc" aria-expanded="false" aria-controls="directDrillingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="directDrillingDesc">
                                    <p>Primary cultivation is the first cultivation that is conducted to prepare the soil for the next crop and can be classified into four groups: plough, deep and shallow till, and no-till. Changing the primary cultivation is an effective measure in changing weed populations.</p>
                                    <p>With no-till and direct drilling the only soil movement that occurs is that done by the drill and the freshly shed seed remains on the soil surface, and some weed seed falls down cracks in the soil. Freshly shed seed on the surface is subject to predation and death through desiccation or disease.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/how-to-manage-weeds-in-arable-rotations-a-guide" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase(),
                                        'volunteerPotatoes'.toLowerCase(),
                                        'wildOats'.toLowerCase()
                                    ]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.directDrilling}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Seed rate</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedRateDesc" aria-expanded="false" aria-controls="seedRateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="seedRateDesc">
                                    <p>A high seedrate  will help to increase the competitivity of the crop suppressing annual broad-leaved weeds such as chickweed, speedwells, groundsel, mayweed, small nettle and others from establishing.</p>
                                    <p> When establishing grass leys a high seedrate at drilling or when reseeding will help to prevent annual meadow grass germination and suppresses annual broad-leaved weeds such as chickweed, mayweed, small nettle and fat hen from establishing.</p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.seedRate}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.allCropsIssues.includes('preEmergence'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('tapRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.combinableCropsIssues.includes('fibrousRootBlwCombine'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('annualGrassesWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('tapRootBlwWideRow'.toLowerCase()) ||
                        allWeedsIssues.wideRowCropsIssues.includes('fibrousRootBlwWideRow'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Sowing date</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#sowingDateDesc" aria-expanded="false" aria-controls="sowingDateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="sowingDateDesc">
                                    <p>Changing the sowing date can have a significant effect on weed densities. A delay can be as small as a few weeks or changing from a winter crop to a spring one. Weeds have a main germination period, with brome and  black-grass germinating predominantly in the early autumn and delaying drilling can miss the peak period. Weeds emerging before drilling can be sprayed off with glyphosate or soils cultivated. On any farm field drilling dates can be prioritised for drilling as to their weed burden, with cleaner fields being drilled first. Seedrate may need to be increased to maintain crop competitivity in order to compensate for delayed drilling.</p>
                                    <p>Spring sowing allows for fallow for part of the year (bastard fallow) can be useful and achieve similar effects to a full fallow. Incorporating a spring crop into a winter dominated rotation allows a bastard fallow.</p>
                                    <p>For specific guidance click <a target="_blank" href="https://ahdb.org.uk/knowledge-library/how-drilling-date-affects-arable-weed-populations" rel="noreferrer">here</a></p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <WideRowCropsWeedsList issues={allWeedsIssues.wideRowCropsIssues}
                                    toDisplay={[
                                        'annualGrassesWideRow'.toLowerCase(),
                                        'tapRootBlwWideRow'.toLowerCase(),
                                        'fibrousRootBlwWideRow'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <AllCropsWeedsList issues={allWeedsIssues.allCropsIssues}
                                    toDisplay={[
                                        'preEmergence'.toLowerCase()
                                    ]}
                                />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.sowingDate}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        allWeedsIssues.combinableCropsIssues.includes('annualGrassesCombine'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h2>Species/Varietal choice</h2></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#varietalChoiceDesc" aria-expanded="false" aria-controls="varietalChoiceDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>
                                <div className="collapse" id="varietalChoiceDesc">
                                    <p>Hybrid winter barley has been shown to reduce the productivity of blackgrass through competition. This is due to the increased height and broader leaves of the crop. Oats are generally more competitive but the number of herbicides for use in the crop is lower and grass weeds are more difficult to control.</p>
                                </div>
                                <CombineCropsWeedsList
                                    issues={allWeedsIssues.combinableCropsIssues}
                                    toDisplay={[
                                        'annualGrassesCombine'.toLowerCase(),
                                        'tapRootBlwCombine'.toLowerCase(),
                                        'fibrousRootBlwCombine'.toLowerCase()
                                    ]}
                                    listOfCrops={listOfCrops} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.varietalChoice}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}

                </>
            )}
            <div className="row justify-content-between my-5">
                <div className="alert alert-info" role="alert">
                    To save your work, click <strong>Save</strong> before using the <strong>Go To</strong> or <strong>Go back</strong> button.
                </div>
                <Link className="col-2 mx-3" to="/farm-list">
                    <button type="button" className="btn btn-danger">Cancel and go back to your farms</button>
                </Link>
                <Link className="col-2 mx-3"
                    to={prevUrl}
                    state={{
                        farmData: location.state.farmData,
                        surveyData: location.state.surveyData
                    }}>
                    <button type="button" className="btn btn-warning w-100 h-100"><ArrowLeft size={20} className="mx-2"></ArrowLeft>Go back</button>
                </Link>
                <SurveyNavigation farmData={location.state.farmData} surveyData={location.state.surveyData} crops={listOfCrops} />
                <button type="button" className="col-2 mx-3 btn btn-primary" onClick={() => onAddWeedsSubmitClick(false)} disabled={status === 'completed'} >Save</button>
                {status === 'completed' ? (
                    <>
                        <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddWeedsSubmitClick(true)}>Continue</button>
                    </>
                ) : (
                    <>
                        <button type="button" className="col-2 mx-3 btn btn-success" onClick={() => onAddWeedsSubmitClick(true)}>Save and continue<ArrowRight size={20} className="mx-2"></ArrowRight></button>
                    </>
                )}
            </div>
        </>
    );
};

export default AddWeedsMeasures;