import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const appleWeedsSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const windBlownWeeds = { name: 'windBlownWeeds', display: "Wind Blown Weeds" };
    const tapRootBlwApple = { name: 'tapRootBlwApple', display: "Tap Root broad leaf weeds" };
    const fibrousRootBlwApple = { name: 'fibrousRootBlwApple', display: "Fibrous Root broad leaf weeds" };
    const couch = { name: 'couch', display: "Couch" };
    const docks = { name: 'docks', display: "Docks" };
    const creepingThistle = { name: 'creepingThistle', display: "Creeping Thistle" };
    const spearThistle = { name: 'spearThistle', display: "Spear Thistle" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'mowing'.toLowerCase()) {
                    measureWithPests.measures = 'Mowing/topping/crimping/rolling'
                    const issuesInControl = [windBlownWeeds, tapRootBlwApple, fibrousRootBlwApple, docks];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'hygiene'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene/prevention'
                    const issuesInControl = [windBlownWeeds, creepingThistle, spearThistle];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'harrowing'.toLowerCase()) {
                    measureWithPests.measures = 'Harrowing/mechanical weeding'
                    const issuesInControl = [windBlownWeeds, tapRootBlwApple, fibrousRootBlwApple, spearThistle];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'physicalMulches'.toLowerCase()) {
                    measureWithPests.measures = 'Physical Mulches'
                    const issuesInControl = [windBlownWeeds, tapRootBlwApple, fibrousRootBlwApple, docks, spearThistle];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'thermalWeedControl'.toLowerCase()) {
                    measureWithPests.measures = 'Thermal Weed Control'
                    const issuesInControl = [windBlownWeeds, tapRootBlwApple, fibrousRootBlwApple, docks, spearThistle];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'handPulling'.toLowerCase()) {
                    measureWithPests.measures = 'Hand pulling/cutting/digging'
                    const issuesInControl = [docks, spearThistle];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                if (measure.toLowerCase() === 'spotTreatment'.toLowerCase()) {
                    measureWithPests.measures = 'Spot Treatment'
                    const issuesInControl = [couch, docks, creepingThistle, spearThistle];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [windBlownWeeds, tapRootBlwApple, fibrousRootBlwApple, couch, docks, creepingThistle, spearThistle]);
    return listOfMeasuresWithPests;
};

export default appleWeedsSummaryMeasures;