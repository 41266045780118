import { Component } from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import './Home.css';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <>
                <header className="py-5 header-image">
                    <div className="container-fluid px-5">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-6">
                                <div className="text-center my-5">
                                    <h1 className="display-5 fw-bolder mb-2">IPM Tool</h1>
                                    <p className="lead text-50 mb-4 text-white fw-bolder">Create IPM plans for your farm</p>
                                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center">
                                        <a className="btn btn-primary btn-lg px-4 me-sm-3" href="/farm-list">Start now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="py-2 border-bottom">
                    <div className="container px-5 my-5">
                        <div className="row gx-5">
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <h2 className="h4 fw-bolder">What is the IPM Tool for?</h2>
                                <p>The tool provides specific guidance on the IPM control measures that are relevant to the crops you grow, and the particular pests, weeds and diseases that are a problem on your farm.</p>
                                <p>Using the Tool will also complete and record an IPM plan for your crops.</p>
                            </div>
                            <div className="col-lg-4 mb-5 mb-lg-0">
                                <h2 className="h4 fw-bolder">How do I use the IPM Tool?</h2>
                                <div className='my-2'>
                                    <div>For a set of short videos showing how to use the tool, click here:</div>
                                    <div>
                                        <a className="text-decoration-none" href="https://youtu.be/c2PwGGGrrDU" target="_blank" rel="noreferrer">
                                            On how to set up
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div className="mb-2">
                                        <a className="text-decoration-none" href="https://youtu.be/ftwDe8D48aA" target="_blank" rel="noreferrer">
                                            On how to produce a plan
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="https://youtu.be/k8ox5bjmabk" target="_blank" rel="noreferrer">
                                            On how to produce a summary report
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                </div>
                                <div className='my-2'>
                                    <div>Introductory videos on IPM:</div>
                                    <div>
                                        <a className="text-decoration-none" href="https://youtu.be/R9iQWUCFoMw" target="_blank" rel="noreferrer">
                                            Arable here
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="https://youtu.be/GH_XjpGME_U" target="_blank" rel="noreferrer">
                                            Grassland here
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="https://youtu.be/wlaQf-5W7a0" target="_blank" rel="noreferrer">
                                            Horticulture here
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                </div>
                                <div>Written guidance on IPM here:
                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_apple.pdf" target="_blank" rel="noreferrer">
                                            Apple
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_brassicas.pdf" target="_blank" rel="noreferrer">
                                            Brassicas
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_improved_grassland.pdf" target="_blank" rel="noreferrer">
                                            Improved Grassland
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_maize.pdf" target="_blank" rel="noreferrer">
                                            Maize
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_oilseed_rape.pdf" target="_blank" rel="noreferrer">
                                            Oilseed Rape
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_peas_beans.pdf" target="_blank" rel="noreferrer">
                                            Peas & Beans
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_potatoes.pdf" target="_blank" rel="noreferrer">
                                            Potatoes
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>

                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_sugar_beet.pdf" target="_blank" rel="noreferrer">
                                            Sugar Beet
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_wheat_barley_oats.pdf" target="_blank" rel="noreferrer">
                                            Wheat, Barley & Oats
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-decoration-none" href="documents/guidance_weeds.pdf" target="_blank" rel="noreferrer">
                                            Weeds
                                            <ArrowRight className="mx-1" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <h2 className="h4 fw-bolder">Who created the IPM Tool?</h2>
                                <p>The tool was produced by crop protection and IPM specialists at ADAS and SRUC.</p>
                                <p>It links to guidance from AHDB and other independent sources, and development of the Tool was funded by Defra as part of a Test and Trial project.</p>
                                <div className="row my-3">
                                    <img className="col-4 img-fluid h-100 d-inline-block max-logo-width" src="images/logos/adas.png" alt="ADAS logo"></img>
                                    <img className="col-4 img-fluid h-100 d-inline-block" src="images/logos/sruc.png" alt="SRUC logo"></img>
                                    <img className="col-4 img-fluid h-100 d-inline-block" src="images/logos/ahdb.jpg" alt="AHDB logo"></img>
                                </div>
                                <div className="row my-3">
                                    <img className="col-4 img-fluid h-100 d-inline-block" src="images/logos/nfu.jpg" alt="NFU logo"></img>
                                    <img className="col-4 img-fluid h-100 d-inline-block" src="images/logos/defra.jpg" alt="DEFRA logo"></img>
                                    <img className="col-4 img-fluid h-100 d-inline-block max-logo-width" src="images/logos/pgro.jpg" alt="Processors and Growers Research Organisation logo"></img>
                                </div>
                                <div className="row my-3">
                                    <img className="col-4 img-fluid h-100 d-inline-block max-logo-width" src="images/logos/bbro.jpg" alt="British Beet Research Organisation logo"></img>
                                    <img className="col-4 img-fluid h-100 d-inline-block" src="images/logos/vi_ipm.png" alt="The Voluntary Initiative logo"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <footer className="py-3">
                    <div className="container-fluid px-5">
                        <p className="m-0 text-center text-muted">Copyright &copy; IPM Tool {(new Date().getFullYear())}</p>
                        <p className="m-2 text-center text-muted">
                            Contact us: <a href="mailto:ipm@adas.co.uk" rel="noreferrer">ipm@adas.co.uk</a>
                        </p>
                        <p className="m-0 text-center text-muted">
                            <div>
                                IPM Tool was designed and developed <a href="https://adas.co.uk/" target="_blank" rel="noreferrer">RSK ADAS</a>
                            </div>
                            <div>
                                IPM Tool <a href="/terms-and-conditions" target="_self" rel="noreferrer">Terms & Conditions</a>
                            </div>

                        </p>
                    </div>
                </footer>
            </>
        );
    }
}