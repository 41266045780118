import { getBothIssues } from "../../../../helpers/getBothIssues";
import SummaryIssuesParcels from "../summaryIssuesParcels";
import SummaryIssuesTables from "../summaryIssuesTables";
import wheatDiseaseSummaryMeasures from "./wheatDiseaseSummaryMeasures";
import wheatPestSummaryMeasures from "./wheatPestSummaryMeasures";

const WheatSummary = (props) => {
    const surveyData = props.surveyData;
    const cropName = props.cropName;
    const { listOfPests, listOfDiseases } = getBothIssues(surveyData, cropName);
    let pestData = { notUse: [], inUse: [], futureUse: [] };
    let diseaseData = { notUse: [], inUse: [], futureUse: [] };
    let weedsData = { notUse: [], inUse: [], futureUse: [] };
    let isWinter = cropName.toLowerCase().includes("winter")

    if (listOfPests.length !== 0) {
        pestData = wheatPestSummaryMeasures(surveyData.pests[cropName].controlMeasures, listOfPests, isWinter);
    }
    if (listOfDiseases.length !== 0) {
        diseaseData = wheatDiseaseSummaryMeasures(surveyData.diseases[cropName].controlMeasures, listOfDiseases, isWinter);
    }
    return (
        <>
            <SummaryIssuesParcels landParcels={props.surveyData.landParcels} cropName={cropName}></SummaryIssuesParcels>
            <SummaryIssuesTables
                listOfPestsControls={surveyData?.pests ? surveyData?.pests[cropName]?.controlMeasures : null}
                listOfDiseasesControls={surveyData?.diseases ? surveyData?.diseases[cropName]?.controlMeasures : null}
                //listOfWeedsControls={surveyData.weeds[cropName].controlMeasures}
                listOfPests={listOfPests}
                listOfDiseases={listOfDiseases}
                pestData={pestData}
                diseaseData={diseaseData}
                weedsData={weedsData}
                crop={cropName}>
            </SummaryIssuesTables>
        </>
    );
};

export default WheatSummary;