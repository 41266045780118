import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import PotatoesDiseaseList from './potatoesDiseaseList';
import { QuestionCircle } from 'react-bootstrap-icons'
import MeasuresButtons from '../measuresButtons';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddPotatoesDiseaseMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`6380ae971ae8c36a287b41de`);


    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.diseases !== null
        && (location.state.surveyData.diseases !== undefined)
        && (location.state.surveyData.diseases.potatoes !== null)
        && (location.state.surveyData.diseases.potatoes !== undefined)
        && (location.state.surveyData.diseases.potatoes.controlMeasures !== null)
        && (location.state.surveyData.diseases.potatoes.controlMeasures !== undefined)) ?
        location.state.surveyData.diseases.potatoes.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.diseases?.potatoes);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.diseases.potatoes.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>potatoes disease control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>

            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('blackDot'.toLowerCase()) ||
                        listOfIssues.includes('blackleg'.toLowerCase()) ||
                        listOfIssues.includes('commonScab'.toLowerCase()) ||
                        listOfIssues.includes('gangrene'.toLowerCase()) ||
                        listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('powderyScab'.toLowerCase()) ||
                        listOfIssues.includes('silverScurf'.toLowerCase()) ||
                        listOfIssues.includes('stemCankerAndBlackScurf'.toLowerCase()) ||
                        listOfIssues.includes('viruses'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Control Volunteers & Weeds</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#controlVolunteersAndWeedsDesc" aria-expanded="false" aria-controls="controlVolunteersAndWeedsDesc"><QuestionCircle color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="controlVolunteersAndWeedsDesc">
                                    <p>
                                        Potato volunteers are most significant as a "green bridge" for late blight and, if untreated with fungicide, a massive reservoir of inoculum.  Ideally the volunteers should be destroyed prior to the emergence of new crops. Certain weed species, such as shepherd’s purse, can act as alternative hosts for tobacco rattle virus which causes spraing in potatoes.
                                    </p>
                                </div>
                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'blackDot'.toLowerCase(),
                                    'blackleg'.toLowerCase(),
                                    'commonScab'.toLowerCase(),
                                    'gangrene'.toLowerCase(),
                                    'lateBlight'.toLowerCase(),
                                    'powderyScab'.toLowerCase(),
                                    'silverScurf'.toLowerCase(),
                                    'stemCankerAndBlackScurf'.toLowerCase(),
                                    'viruses'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.controlVolunteersAndWeeds}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('blackDot'.toLowerCase()) ||
                        listOfIssues.includes('blackleg'.toLowerCase()) ||
                        listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('powderyScab'.toLowerCase()) ||
                        listOfIssues.includes('stemCankerAndBlackScurf'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Decision Support (including thresholds)</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="decisionSupportDesc">
                                    <p>
                                        Late blight forecasts and monitoring are available from commercial and public sources.
                                    </p>
                                    <p>For specific guidance visit: <a target="_blank" href="https://blight.hutton.ac.uk/BlightReport" rel="noreferrer">Fight Against Blight | The James Hutton Institute</a></p>
                                </div>

                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'blackDot'.toLowerCase(),
                                    'blackleg'.toLowerCase(),
                                    'lateBlight'.toLowerCase(),
                                    'powderyScab'.toLowerCase(),
                                    'stemCankerAndBlackScurf'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.decisionSupport}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('blackDot'.toLowerCase()) ||
                        listOfIssues.includes('blackleg'.toLowerCase()) ||
                        listOfIssues.includes('gangrene'.toLowerCase()) ||
                        listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('silverScurf'.toLowerCase()) ||
                        listOfIssues.includes('stemCankerAndBlackScurf'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Appropriate Harvest</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#appropriateHarvestDesc" aria-expanded="false" aria-controls="appropriateHarvestDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="appropriateHarvestDesc">
                                    <p>
                                        Delaying harvest after desiccation can increase disease incidence on tubers.  Black Dot is known to be more severe when harvest is delayed. Similarly, delayed harvest has been associated with increased  incidence of silver scurf and black scurf. The downside to earlier harvest is that skins may not be set, making long-term storage challenging. For blackleg, lifting early in dry conditions once skin set complete is recommended and this will help to prevent the development of other tuber diseases. Crops should not be lifted until at least 14 days after the haulm is dead following desiccation to minimise tuber blight infection.
                                    </p>
                                </div>

                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'blackDot'.toLowerCase(),
                                    'blackleg'.toLowerCase(),
                                    'gangrene'.toLowerCase(),
                                    'lateBlight'.toLowerCase(),
                                    'silverScurf'.toLowerCase(),
                                    'stemCankerAndBlackScurf'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.appropriateHarvest}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('blackDot'.toLowerCase()) ||
                        listOfIssues.includes('blackleg'.toLowerCase()) ||
                        listOfIssues.includes('commonScab'.toLowerCase()) ||
                        listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('powderyScab'.toLowerCase()) ||
                        listOfIssues.includes('silverScurf'.toLowerCase()) ||
                        listOfIssues.includes('stemCankerAndBlackScurf'.toLowerCase()) ||
                        listOfIssues.includes('viruses'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Field History, Rotation & Break Crops</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="fieldHistoryDesc">
                                    <p>
                                        If a field has a history of a certain disease it is likely that this will return as a problem in the future, either through survival in the soil, on the soil surface or  via the ‘green bridge’, where plants  from the previous season are infected with a disease or virus vector. Knowledge of field history allows steps to be taken to minimise risks. Crop rotation limits the build-up of many soil borne disease. It also allows an opportunity to control potato volunteers which can act as a reservoir of disease.                                    </p>
                                </div>

                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'blackDot'.toLowerCase(),
                                    'blackleg'.toLowerCase(),
                                    'commonScab'.toLowerCase(),
                                    'lateBlight'.toLowerCase(),
                                    'powderyScab'.toLowerCase(),
                                    'silverScurf'.toLowerCase(),
                                    'stemCankerAndBlackScurf'.toLowerCase(),
                                    'viruses'.toLowerCase()
                                ]} />                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.fieldHistory}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('blackleg'.toLowerCase()) ||
                        listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('powderyScab'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Good Drainage</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#goodDrainageDesc" aria-expanded="false" aria-controls="goodDrainageDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="goodDrainageDesc">
                                    <p>
                                        Wet areas and fields subject to flooding are likely to be more prone to diseases not only because of poor plant growth but also because some pathogens are adapted to conditions of high soil moisture. Soil nutrient deficiencies, adverse seed bed conditions and poor drainage are likely to predispose plants to disease.
                                    </p>
                                </div>

                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'blackleg'.toLowerCase(),
                                    'lateBlight'.toLowerCase(),
                                    'powderyScab'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.goodDrainage}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('blackleg'.toLowerCase()) ||
                        listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('powderyScab'.toLowerCase()) ||
                        listOfIssues.includes('storageDiseases'.toLowerCase()) ||
                        listOfIssues.includes('viruses'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Hygiene</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#hygieneDesc" aria-expanded="false" aria-controls="hygieneDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="hygieneDesc">
                                    <p>
                                        This is the first defence against the introduction of soil-borne diseases onto clean land. Machinery used in infested fields should be power-washed before use in uninfected fields, and soil should be knocked off from boots and tools. Clean fields should be visited first in the sequence of crops so that cleaning down equipment can be done at the end of the day. Outgrade and waste piles act as a reservoir of late blight.  Black sheeting or glyphosate should be used to achieve a zero tolerance of shoot or foliage production. For blackleg, it is recommended that rots are removed early when grading and grading equipment is cleaned if there were obvious signs of rots in a previously graded stock.
                                    </p>
                                </div>
                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'blackleg'.toLowerCase(),
                                    'lateBlight'.toLowerCase(),
                                    'powderyScab'.toLowerCase(),
                                    'silverScurf'.toLowerCase(),
                                    'storageDiseases'.toLowerCase(),
                                    'viruses'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.hygiene}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('lateBlight'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Nutrient Management</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#nutrientManagementDesc" aria-expanded="false" aria-controls="nutrientManagementDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="nutrientManagementDesc">
                                <p>
                                    Excessive nitrogen will exacerbate diseases which thrive in a dense lush canopy.  Crops which are nutrient deficient are likely to be predisposed to disease infection. Ensure appropriate soil nutrient supply by regular soil sampling and testing and use of appropriate fertilisers.
                                </p>
                            </div>

                            <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'lateBlight'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.nutrientManagement}
                                    renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('blackDot'.toLowerCase()) ||
                        listOfIssues.includes('commonScab'.toLowerCase()) ||
                        listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('silverScurf'.toLowerCase()) ||
                        listOfIssues.includes('stemCankerAndBlackScurf'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Primary Cultivations (Crop Residue Burial)</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#primaryCultivationsDesc" aria-expanded="false" aria-controls="primaryCultivationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="primaryCultivationsDesc">
                                    <p>
                                        For late blight, soils should be cultivated deep enough to ensure there is sufficient tilth to adequately cover the tubers to decrease the risk from tuber blight. Cultivations that manage weed populations, bury overwintering crop debris and reduce volunteers can substantially decrease disease risk.
                                    </p>
                                </div>

                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'blackDot'.toLowerCase(),
                                    'commonScab'.toLowerCase(),
                                    'lateBlight'.toLowerCase(),
                                    'silverScurf'.toLowerCase(),
                                    'stemCankerAndBlackScurf'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.primaryCultivations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('powderyScab'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Seed Testing</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#seedTestingDesc" aria-expanded="false" aria-controls="seedTestingDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="seedTestingDesc">
                                <p>
                                    The use of certified seed can help ensure that heavily infected seed stocks are not used and can be an effective approach to preventing notifiable diseases and reducing the risk from other diseases.
                                </p>
                            </div>

                            <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'powderyScab'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.seedTesting}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('blackDot'.toLowerCase()) ||
                        listOfIssues.includes('blackleg'.toLowerCase()) ||
                        listOfIssues.includes('commonScab'.toLowerCase()) ||
                        listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('stemCankerAndBlackScurf'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Seedbed Quality</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedbedQualityDesc" aria-expanded="false" aria-controls="seedbedQualityDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="seedbedQualityDesc">
                                    <p>
                                        Adverse soil seed bed conditions are likely to predispose plants to disease, planting should be delayed if conditions are poor for crop establishment. Poor seed bed quality can increase the risk of tuber blight if there is not adequate soil coverage of the tubers.
                                    </p>
                                </div>

                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'blackDot'.toLowerCase(),
                                    'blackleg'.toLowerCase(),
                                    'commonScab'.toLowerCase(),
                                    'lateBlight'.toLowerCase(),
                                    'stemCankerAndBlackScurf'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.seedbedQuality}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('powderyScab'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Select Low-Risk Locations</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="lowRiskLocationsDesc">
                                    <p>
                                        Proximity to gardens and allotments can increase the risk, as home-grown potatoes can act as reservoirs of infection.
                                    </p>
                                </div>

                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'lateBlight'.toLowerCase(),
                                    'powderyScab'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.lowRiskLocations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('stemCankerAndBlackScurf'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Planting Date</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#sowingDateDesc" aria-expanded="false" aria-controls="sowingDateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="sowingDateDesc">
                                    <p>
                                        Planting earlier in the growing season mean crops are harvested before late blight increases. Planting early in the season can mean cold and wet weather and slower emergence which increases the risk of stem canker. Seed tubers should be planted when soil conditions are warmer and drier.
                                    </p>
                                </div>

                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'lateBlight'.toLowerCase(),
                                    'stemCankerAndBlackScurf'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.sowingDate}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('lateBlight'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Spatial Separation</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#spatialSeparationDesc" aria-expanded="false" aria-controls="spatialSeparationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="spatialSeparationDesc">
                                <p>
                                    Control volunteers before sowing and avoid sowing next year’s crops adjacent to fields with potato volunteers.
                                </p>
                            </div>

                            <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                'lateBlight'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.disease}
                                data={data}
                                uischema={uiSchema.uiSchemas.disease.spatialSeparation}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('blackDot'.toLowerCase()) ||
                        listOfIssues.includes('commonScab'.toLowerCase()) ||
                        listOfIssues.includes('earlyBlight'.toLowerCase()) ||
                        listOfIssues.includes('lateBlight'.toLowerCase()) ||
                        listOfIssues.includes('powderyScab'.toLowerCase()) ||
                        listOfIssues.includes('storageDiseases'.toLowerCase()) ||
                        listOfIssues.includes('viruses'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Varietal Choice</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#varietalChoiceDesc" aria-expanded="false" aria-controls="varietalChoiceDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="varietalChoiceDesc">
                                    <p>
                                        Resistant varieties are an important part of non-chemical disease control. There are good sources of information on disease resistance to many of the major pathogens in the potato variety database. https://potatoes.agricrops.org/
                                    </p>
                                </div>

                                <PotatoesDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'blackDot'.toLowerCase(),
                                    'commonScab'.toLowerCase(),
                                    'earlyBlight'.toLowerCase(),
                                    'lateBlight'.toLowerCase(),
                                    'powderyScab'.toLowerCase(),
                                    'storageDiseases'.toLowerCase(),
                                    'viruses'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.varietalChoice}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}

                </>
            )}

            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"diseases"}
                    finalUrl={"/add-diseases-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddPotatoesDiseaseMeasures;