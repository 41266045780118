const BarleyPestList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('bydvVectors'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/co-aphids" target="_blank" rel="noreferrer">BYDV Vectors</a>
                    </li>
                    : <></>
                }
                {values.includes('fritFly'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/identification-and-management-of-frit-fly-in-cereals" target="_blank" rel="noreferrer">Frit Fly</a>
                    </li>
                    : <></>
                }
                {values.includes('goutFly'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/risk-factors-and-management-of-gout-fly-in-cereals" target="_blank" rel="noreferrer">Gout fly</a>
                    </li>
                    : <></>
                }
                {values.includes('leatherjackets'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/risk-factors-and-management-of-leatherjackets-in-field-crops" target="_blank" rel="noreferrer">Leatherjackets</a>
                    </li>
                    : <></>
                }
                {values.includes('saddleGallMidge'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/saddle-gall-midge" target="_blank" rel="noreferrer">Saddle Gall Midge</a>
                    </li>
                    : <></>
                }
                {values.includes('slugs'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/slugs" target="_blank" rel="noreferrer">Slugs</a>
                    </li>
                    : <></>
                }
                {values.includes('wheatBulbFly'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/how-to-manage-wheat-bulb-fly-risk-in-cereals" target="_blank" rel="noreferrer">Wheat bulb fly</a>
                    </li>
                    : <></>
                }
                {values.includes('wireworms'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/identification-and-management-of-wireworms-in-field-crops" target="_blank" rel="noreferrer">Wireworms</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default BarleyPestList;