import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";
import { clearRiskText } from "../../../../helpers/clearRiskText";

const oilseedRapeDiseaseSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const clubroot = { name: 'clubroot', display: "Clubroot" };
    const lightLeafSpot = { name: 'lightLeafSpot', display: "Light Leaf Spot" };
    const phomaStemCanker = { name: 'phomaStemCanker', display: "Phoma Stem Canker" };
    const sclerotiniaStemRot = { name: 'sclerotiniaStemRot', display: "Sclerotinia StemRot" };
    const verticilliumStemStripe = { name: 'verticilliumStemStripe', display: "Verticillium Stem Stripe" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'controlVolunteers'.toLowerCase()) {
                    measureWithPests.measures = 'Control volunteers & weeds'
                    const issuesInControl = [
                        clubroot,
                        sclerotiniaStemRot
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support (incl. thresholds)'
                    const issuesInControl = [
                        lightLeafSpot,
                        phomaStemCanker,
                        sclerotiniaStemRot
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, Rotation & break crops'
                    const issuesInControl = [
                        clubroot,
                        lightLeafSpot,
                        phomaStemCanker,
                        sclerotiniaStemRot,
                        verticilliumStemStripe
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'goodDrainage'.toLowerCase()) {
                    measureWithPests.measures = 'Good Drainage'
                    const issuesInControl = [
                        clubroot
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygiene'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene and prevention'
                    const issuesInControl = [
                        clubroot,
                        verticilliumStemStripe
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lime'.toLowerCase()) {
                    measureWithPests.measures = 'Lime'
                    const issuesInControl = [
                        clubroot
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'primaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Primary cultivations / Crop residue burial'
                    const issuesInControl = [
                        lightLeafSpot,
                        phomaStemCanker,
                        sclerotiniaStemRot
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Select low-risk locations'
                    const issuesInControl = [
                        clubroot,
                        sclerotiniaStemRot,
                        verticilliumStemStripe
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'sowingDate'.toLowerCase()) {
                    measureWithPests.measures = 'Sowing Date'
                    const issuesInControl = [
                        clubroot,
                        lightLeafSpot,
                        phomaStemCanker,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'spatialSeparation'.toLowerCase()) {
                    measureWithPests.measures = 'Spatial Separation'
                    const issuesInControl = [
                        clubroot,
                        lightLeafSpot,
                        phomaStemCanker,
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietalChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Varietal Choice'
                    const issuesInControl = [
                        clubroot,
                        lightLeafSpot,
                        phomaStemCanker,
                        verticilliumStemStripe
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }

                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus,
        [clubroot, lightLeafSpot, phomaStemCanker, sclerotiniaStemRot, verticilliumStemStripe]);
    return listOfMeasuresWithPests;
};

export default oilseedRapeDiseaseSummaryMeasures;