import { JsonForms } from '@jsonforms/react';
import { useEffect, useState } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import LoadingIndicator from '../../../common/loadingIndicator';
import MeasuresButtons from '../measuresButtons';
import BrassicasPestList from './brassicasPestList';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddBrassicasPestMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`63c968831ed833bb5c355aaf`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.brassicas !== null)
        && (location.state.surveyData.pests.brassicas !== undefined)
        && (location.state.surveyData.pests.brassicas.controlMeasures !== null)
        && (location.state.surveyData.pests.brassicas.controlMeasures !== undefined)) ?
        location.state.surveyData.pests.brassicas.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;
    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.brassicas);
    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.brassicas.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>brassicas pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(
                        listOfIssues.includes('whiteFly'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Selecting low-risk locations</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="lowRiskLocationsDesc">
                                    <p>Where possible choose sites away from existing brassica and oilseed rape production to avoid a continuous 'green bridge' throughout the year. Avoid proximity to the previous crops residues if they are still present on the soil surface and not incorporated.  Vegetable brassicas and oilseed rape crops represent a significant winter host for whitefly, and where practical avoid planting/sowing spring crops near winter whitefly hosts.</p>
                                </div>
                                <BrassicasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'whiteFly'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.lowRiskLocations}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('aphids'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Control volunteers and weeds</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#controlVolunteersDesc" aria-expanded="false" aria-controls="controlVolunteersDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="controlVolunteersDesc">
                                    <p>Cabbage aphids will overwinter on brassica crops and weeds through eggs laid in the autumn. A key cultural practice for the management of cabbage aphid is to destroy and/or bury brassica crops and weed residues to bury the eggs. Similarly, the management of brassica weeds e.g. mustards, is of significance especially with the recent rise in popularity of brassica based cover crop mixes.</p>
                                </div>
                                <BrassicasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'aphids'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.controlVolunteers}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('aphids'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Seed bed management</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#seedbedManagementDesc" aria-expanded="false" aria-controls="seedbedManagementDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="seedbedManagementDesc">
                                    <p>Pests can be killed or brought to the surface where they are eaten by birds or die due to dehydration.  Most cabbage aphid infestations develop from colonies that overwinter on old brassica crops and autumn sown oilseed rape. Undisturbed trash or crop residue can provide shelter and food for pests such as slugs. Plough in or otherwise destroy such crop residues. Cloddy seed beds can potentially allow slugs access to developing brassicas. Instead, consolidate soils to inhibit slug movement where necessary. Rolling soil post-planting can improve the seedbed quality, resulting in more rapid germination of crops and reducing access of soil-borne pests, such as slugs, to seeds.  Rolling may also kill some pests or reduce their mobility.</p>
                                </div>
                                <BrassicasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'aphids'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.seedbedManagement}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('aphids'.toLowerCase()) ||
                        listOfIssues.includes('cabbageRootFly'.toLowerCase()) ||
                        listOfIssues.includes('caterpillars'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>In field non-cropped areas</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#nonCroppedAreasDesc" aria-expanded="false" aria-controls="nonCroppedAreasDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="nonCroppedAreasDesc">
                                    <p>Beetle banks consist of stands of wildflowers and grasses and are designed to act as reservoirs of beneficial insects such as ground beetles and parasitoids, which help to provide natural biological control of insect pests.</p>
                                    <p>Diverse crop margins and strips act in a similar way to beetle banks to increase natural enemies.  However, some of the plant species could benefit pests. More diverse strips should harbour greater biodiversity and greater numbers of beneficials. Ladybirds, hoverflies and lacewings are natural enemies of aphids in brassicas.  The cabbage root fly is attacked by two main parasitoids, wasp and  rove beetle. Cabbage aphids have only one parasitoid, the small wasp Diaeretiella rapae, which also attacks the peach potato aphid. Caterpillar pests are also attacked by a range of parasitoids, mainly wasps and flies. </p>
                                </div>
                                <BrassicasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'aphids'.toLowerCase(),
                                    'cabbageRootFly'.toLowerCase(),
                                    'caterpillars'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.nonCroppedAreas}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('caterpillars'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Bioprotectants Microbial</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#bioprotectantsMicrobialDesc" aria-expanded="false" aria-controls="bioprotectantsMicrobialDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="bioprotectantsMicrobialDesc">
                                    <p>In brassicas, the bacterium Bacillus thuringiensis subspecies kurstaki ABTS-351 (DiPel DF, EAMU 3028/19) is approved for use against young caterpillars of most species infesting brassica crops, although rates of control of cabbage moth can vary. Caterpillars ingest the bacteria which releases a fatal toxin as it breaks down and the caterpillar activity and feeding stops immediately, followed by death after four to five days. This biopesticide is most effective when applied to actively feeding, young larvae. </p>
                                </div>
                                <BrassicasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'caterpillars'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.bioprotectantsMicrobial}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('aphids'.toLowerCase()) ||
                        listOfIssues.includes('whiteFly'.toLowerCase()) ||
                        listOfIssues.includes('slugs'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Bioprotectants natural substances</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#bioprotectantsNaturalSubstancesDesc" aria-expanded="false" aria-controls="bioprotectantsNaturalSubstancesDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="bioprotectantsNaturalSubstancesDesc">
                                    <p>The use of nematode slug treatments such as Nemaslug®, has been shown to provide protection against many species of slugs including grey field slug (Deroceras reticulatum). As this product is a biological it requires both the right temperature (above 5oC) and high humidity to work optimally, which mirrors the conditions under which slugs are most active, however irrigation may be needed to keep the nematodes alive during dry spells. To achieve optimal protection multiple applications are usually required. Majestik is a contact insecticide containing the natural substance maltodextrin approved for use on all edible crops and has been known to give control of whitefly and some reduction of aphid numbers. </p>
                                </div>
                                <BrassicasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'aphids'.toLowerCase(),
                                    'whiteFly'.toLowerCase(),
                                    'slugs'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.bioprotectantsNaturalSubstances}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('aphids'.toLowerCase()) ||
                        listOfIssues.includes('cabbageRootFly'.toLowerCase()) ||
                        listOfIssues.includes('caterpillars'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Decision support, incl. monitoring</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="decisionSupportDesc">
                                    <p>Crops should be regularly and systematically inspected to monitor crop development, and pest and disease levels. Increase the frequency of crop walking during periods of high pest incidence particularly during hot weather. In addition to crop walking, consider the use of insect traps e.g. pheromone traps, chemical attractant traps and soil sampling (cabbage root fly eggs) as monitoring tools.</p>
                                    <p>IPM decisions should be made based on the results of monitoring and forecasting combined with threshold information where available. Treatment thresholds are the population level or density that must be reached before intervention becomes economically beneficial. Thresholds enable growers to make decisions based on the level at which pests will impact economic crop yield. They are essential in guiding pest control decisions and preventing the unnecessary use of pesticides.</p>
                                    <p>For more information click <a href="https://insectsurvey.com/" target="_blank" rel="noreferrer">here</a>.</p>
                                </div>
                                <BrassicasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'aphids'.toLowerCase(),
                                    'cabbageRootFly'.toLowerCase(),
                                    'caterpillars'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.decisionSupport}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('cabbageRootFly'.toLowerCase()) ||
                        listOfIssues.includes('caterpillars'.toLowerCase()) ||
                        listOfIssues.includes('whiteFly'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Precision irrigation</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#precisionIrrigationDesc" aria-expanded="false" aria-controls="precisionIrrigationDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="precisionIrrigationDesc">
                                    <p>At transplanting avoid over watering as this can leach out cabbage root fly insecticides. Plants under drought stress tend to be susceptible to attack by pests. The use of well-timed irrigation, especially overhead, has been shown to reduce incidence of caterpillars, as it can kill larvae. For example, cutworm attacks are most severe in hot dry summers, and irrigation can wash young cutworm off plants before they descend to the soil to feed. Use precision irrigation in response to cutworm warnings which define high risk periods.</p>
                                </div>
                                <BrassicasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'cabbageRootFly'.toLowerCase(),
                                    'caterpillars'.toLowerCase(),
                                    'whiteFly'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.precisionIrrigation}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(
                        listOfIssues.includes('aphids'.toLowerCase()) ||
                        listOfIssues.includes('cabbageRootFly'.toLowerCase()) ||
                        listOfIssues.includes('caterpillars'.toLowerCase()) ||
                        listOfIssues.includes('whiteFly'.toLowerCase())
                    ) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Physical exclusion of pests</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#physicalExclusionDesc" aria-expanded="false" aria-controls="physicalExclusionDesc">
                                        <QuestionCircle className='collapsible' color="grey" size={30} />
                                    </div>
                                </div>
                                <div className="collapse" id="physicalExclusionDesc">
                                    <p>Fine mesh netting (crop covers) may prevent pests from laying eggs on or burrowing into susceptible crops. They can lay eggs on or through netting that touches the crop and if needed hoops can be installed beneath the net to prevent the crop touching the netting.</p>
                                </div>
                                <BrassicasPestList listOfIssues={listOfIssues} toDisplay={[
                                    'aphids'.toLowerCase(),
                                    'cabbageRootFly'.toLowerCase(),
                                    'caterpillars'.toLowerCase(),
                                    'whiteFly'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.physicalExclusion}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                </>
            )}
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddBrassicasPestMeasures;