const ImprovedGrasslandDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('leafBlotch'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                       Leaf Blotch
                    </li>
                    : <></>
                }
                {values.includes('mildew'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Mildew
                    </li>
                    : <></>
                }
                {values.includes('crownRust'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/dealing-with-pests-and-diseases" target="_blank" rel="noreferrer">Crown Rust</a>
                    </li>
                    : <></>
                }
                {values.includes('drechsleraLeafDisease'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        Drechslera Leaf Disease
                    </li>
                    : <></>
                }
                {values.includes('virusDiseases'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/dealing-with-pests-and-diseases" target="_blank" rel="noreferrer">Virus Diseases</a>
                    </li>
                    : <></>
                }
                {values.includes('cloverRot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/knowledge-library/clover-pests-disease" target="_blank" rel="noreferrer">Clover Rot</a>
                    </li>
                    : <></>
                }
                {values.includes('leafDiseases'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/knowledge-library/clover-pests-disease" target="_blank" rel="noreferrer">Leaf Diseases</a>
                    </li>
                    : <></>
                }
                {values.includes('verticilliumWilt'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/knowledge-library/clover-pests-disease" target="_blank" rel="noreferrer">Verticillium Wilt</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default ImprovedGrasslandDiseaseList;