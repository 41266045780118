import getStatusTitle from "./getStatusTitle";

const addLastYearDataToJson = (survey, lastYearSurvey, isNewSurvey) => {
    for (const key in lastYearSurvey) {
        const lowercaseKey = key.toLowerCase();
        if (['landparcels', 'generalpractices', 'id', 'year', 'farmid'].includes(lowercaseKey)) {
            continue;
        }
        if (survey === undefined || key.endsWith('LastYear')) {
            continue;
        }
        else if (typeof lastYearSurvey[key] === 'string') {
            let lastSurveyKeyValue = getStatusTitle(lastYearSurvey[key]);
            if (isNewSurvey) {
                if (!survey) {
                    survey = {};
                }
                survey[key] = null;
            }
            survey[key + 'LastYear'] = lastSurveyKeyValue;
        } else if (typeof lastYearSurvey[key] === 'object' && lastYearSurvey[key] !== null) {
            if (isNewSurvey && survey[key] === null) {
                survey[key] = lastYearSurvey[key];
                addLastYearDataToJson(survey[key], lastYearSurvey[key], isNewSurvey);
            }
            else if (survey[key] === null) {
                survey[key] = null;
            }
            else {
                addLastYearDataToJson(survey[key], lastYearSurvey[key], isNewSurvey);
            }
        }
    }
    return survey;
}
export default addLastYearDataToJson;