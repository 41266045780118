const autogenerateFieldWithCrops = (listOfCrops, landParcel, geometry) => {
    if (landParcel === undefined || landParcel.rpaId === '') landParcel = { rpaId: `AutoGenerated`, name: `AutoGenerated` };
    var autoGeneratedLandParcel = {
        rpaId: landParcel.rpaId,
        name: landParcel.name,
        crops: listOfCrops,
        geometry: geometry
    };
    return autoGeneratedLandParcel;
}

export default autogenerateFieldWithCrops;