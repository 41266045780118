const ImprovedGrasslandPestList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('fritFly'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/dealing-with-pests-and-diseases" target="_blank" rel="noreferrer">Frit fly / Bibionid</a>
                    </li>
                    : <></>
                }
                {values.includes('leatherjackets'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/dealing-with-pests-and-diseases" target="_blank" rel="noreferrer">Leatherjackets</a>
                    </li>
                    : <></>
                }
                {values.includes('wireworms'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/identification-and-management-of-wireworms-in-field-crops" target="_blank" rel="noreferrer">Wireworms</a>
                    </li>
                    : <></>
                }
                {values.includes('slugs'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/dealing-with-pests-and-diseases" target="_blank" rel="noreferrer">Slugs</a>
                    </li>
                    : <></>
                }
                {values.includes('cloverWeevil'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/knowledge-library/clover-pests-disease" target="_blank" rel="noreferrer">Clover Weevil</a>
                    </li>
                    : <></>
                }
                {values.includes('cloverStemNematode'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/knowledge-library/clover-pests-disease" target="_blank" rel="noreferrer">Clover Stem Nematode</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default ImprovedGrasslandPestList;