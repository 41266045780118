import styled from 'styled-components'

const TableStyle = styled.div` padding: 1rem;
table {
    border-spacing: 0;
    border: 1px solid black;

    tr,
    th,
    td {
        margin: 0;
        padding: 0.5rem;
        border: 0;
    }
}
`

const ColumnsCurrent = [
    {
        Header: 'Control Measures Selected',
        accessor: 'measures',
        backgroundColor: '#a7c81e',
        width: 200,
        borderRight: '3px solid black'
    },
    {
        Header: 'Level of risk identified for pest targeted by selected control measure',
        columns: [
            {
                Header: 'Significant Risk',
                accessor: 'significantRisk',
                backgroundColor: '#0da4a3',
                width: 200,
            },
            {
                Header: 'Moderate Risk',
                accessor: 'moderateRisk',
                backgroundColor: '#0da4a3',
                width: 200,
            },
            {
                Header: 'Slight Risk',
                accessor: 'slightRisk',
                backgroundColor: '#0da4a3',
                width: 200,
            },
        ],
    },
];

const ColumnsFuture = [
    {
        Header: 'Control Measures Selected',
        accessor: 'measures',
        backgroundColor: '#a7c81e',
        width: 200,
        borderRight: '3px solid black'
    },
    {
        Header: 'Level of risk identified for pest targeted by selected control measure',
        columns: [
            {
                Header: 'Significant Risk',
                accessor: 'significantRisk',
                backgroundColor: '#0da4a3',
                width: 200,
            },
            {
                Header: 'Moderate Risk',
                accessor: 'moderateRisk',
                backgroundColor: '#0da4a3',
                width: 200,
            },
            {
                Header: 'Slight Risk',
                accessor: 'slightRisk',
                backgroundColor: '#0da4a3',
                width: 200,
            },
        ],
    },
];

const ColumnsNotSelected = [
    {
        Header: '',
        accessor: 'measures',
        width: 200,
        borderRight: '3px solid black'
    },
    {
        Header: 'Level of risk identified for pest targeted by selected control measure',
        columns: [
            {
                Header: 'Significant Risk',
                accessor: 'significantRisk',
                backgroundColor: '#0da4a3',
                width: 200,
            },
            {
                Header: 'Moderate Risk',
                accessor: 'moderateRisk',
                backgroundColor: '#0da4a3',
                width: 200,
            },
            {
                Header: 'Slight Risk',
                accessor: 'slightRisk',
                backgroundColor: '#0da4a3',
                width: 200,
            },
        ],
    },
];

const ColumnsNotes = [
    {
        Header: 'Control Measure',
        accessor: 'name',
        backgroundColor: '#a7c81e',
        width: 200,
        borderRight: '3px solid black'
    },
    {
        Header: 'Notes',
        accessor: 'notes',
        backgroundColor: '#0da4a3',
        width: 600,
    },
];


export { TableStyle, ColumnsCurrent, ColumnsFuture, ColumnsNotSelected, ColumnsNotes };