const statusMapping = {
  inUse: 'Use in current cropping season',
  futureUse: 'Intend to use in future seasons',
  notSuitable: 'Not suitable for my farming system',
  notUsed: 'No intention to implement', 
  significantRisk: 'Significant Risk',
  moderateRisk: 'Moderate Risk',
  slightRisk: 'Slight Risk',
  noRisk: 'No Risk',
  self: 'My self',
  selfAgronomist: 'My self and an agronomist',
  employee: 'An employee',
  employeeAgronomist: 'An employee and an agronomist',
  agronomist: 'Agronomist',
  inHead: 'Yes, in my head',
  paperRecord: 'Yes, on paper',
  electronicRecord: 'Yes, electronic record',
  no: 'No',
  planningTo: 'Planning to',
  yes: 'Yes',
};
export default statusMapping;