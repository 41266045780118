import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import MeasuresButtons from '../measuresButtons';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { QuestionCircle } from 'react-bootstrap-icons';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import MaizeDiseaseList from './maizeDiseaseList';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';


const AddMaizeDiseaseMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };;

    });
    const { jsonForm, loadingState } = useJsonForm(`636a3826bd4b6cff8734de58`);


    const initialData = (location.state !== undefined
        && location.state.surveyData !== undefined
        && location.state.surveyData.diseases !== null
        && location.state.surveyData.diseases !== undefined
        && location.state.surveyData.diseases.maize !== null
        && location.state.surveyData.diseases.maize !== undefined
        && location.state.surveyData.diseases.maize.controlMeasures !== null
        && location.state.surveyData.diseases.maize.controlMeasures !== undefined) ?
        location.state.surveyData.diseases.maize.controlMeasures : {};
    if (initialData === null) initialData.maize = {};
    const [data, setData] = useState(initialData);
    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    if (initialData === null) initialData.maize = {};
    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.diseases?.maize);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.diseases.maize.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>maize disease control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement </p>
            </div>
            <div className="row my-3">
                {listOfIssues.length === 0 ? (
                    <h3>No issues selected</h3>
                ) : (
                    <>
                        {(listOfIssues.includes('eyespot'.toLowerCase()) ||
                            listOfIssues.includes('fusarium'.toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Field history, rotation & break crops</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryRotationDesc" aria-expanded="false" aria-controls="fieldHistoryRotationDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="fieldHistoryRotationDesc">
                                        <p>Where maize is grown continuously this is likely to increase the build up of pathogens such as fusarium and eyespot. Extending rotation to 1 in 3 years or longer is recommended. </p>
                                    </div>
                                    <MaizeDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'eyespot'.toLowerCase(),
                                        'fusarium'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.fieldHistoryRotation}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(listOfIssues.includes('eyespot'.toLowerCase()) ||
                            listOfIssues.includes('fusarium'.toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Primary cultivations (crop residue burial)</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#primaryCultivationsDesc" aria-expanded="false" aria-controls="primaryCultivationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>

                                    <div className="collapse" id="primaryCultivationsDesc">
                                        <p>Burial of crop debris by ploughing can reduce inoculum for some pathogens which produce inoculum on plant debris. This is most important for pathogens which are initiated from air borne spores, such as eyespot and fusarium, which can infect neighbouring crops. On the negative side the use of ploughing can reduce soil biodiversity. </p>
                                    </div>
                                    <MaizeDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                        'eyespot'.toLowerCase(),
                                        'fusarium'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.primaryCultivations}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(listOfIssues.includes('eyespot'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Variety choice</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#varietyChoiceDesc" aria-expanded="false" aria-controls="varietyChoiceDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="varietyChoiceDesc">
                                    <p>Eyespot rating in the NIAB description list of maize varieties is a key part of non-chemical disease control. Varieties are ranked based on their susceptibility to the disease.</p>
                                    <p>For specific guidance click <a href="https://www.niab.com/research/agronomy-and-farming-systems/variety-evaluation-and-management/bspb-forage-maize" target="_blank" rel="noreferrer">here</a></p>
                                </div>
                                <MaizeDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                    'eyespot'.toLowerCase(),
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.disease}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.disease.varietyChoice}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    </>)}
            </div>
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"diseases"}
                    finalUrl={"/add-diseases-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddMaizeDiseaseMeasures;