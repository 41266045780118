import { addIssuesToRisk } from "../../../../helpers/addIssuesToRisk";
import { clearRiskText } from "../../../../helpers/clearRiskText";
import { addNoIpmMeasureImplemented } from "../../../../helpers/addNoIpmMeasureImplemented";

const potatoesPestSummaryMeasures = (controlMeasures, listOfIssues) => {
    const inUseStatus = 'inUse';
    const futureUseStatus = 'futureUse';
    const notUseStatus = 'notUse';
    const listOfMeasuresWithPests = {
        inUse: [],
        futureUse: [],
        notUse: []
    };
    const cutworms = { name: 'cutworms', display: "Cutworms" };
    const flnAndSpraing = { name: 'flnAndSpraing', display: "FLN and Spraing" };
    const potatoCystNematode = { name: 'potatoCystNematode', display: "Potato Cyst Nematode" };
    const slugs = { name: 'slugs', display: "Slugs" };
    const aphidBorne = { name: 'aphidBorne', display: "Aphid Borne" };
    const wireworm = { name: 'wireworm', display: "Wireworm" };
    for (const measure in controlMeasures) {
        if (controlMeasures.hasOwnProperty(measure)) {
            const status = controlMeasures[measure] ? controlMeasures[measure].status : null;
            if (status === inUseStatus || status === futureUseStatus) {
                let measureWithPests = {
                    measures: '',
                    controlMeasures: 'Control Measures Selected',
                    significantRisk: '',
                    moderateRisk: '',
                    slightRisk: '',
                    pestKeys: ''
                };

                if (measure.toLowerCase() === 'biofumigation'.toLowerCase()) {
                    measureWithPests.measures = 'Biofumigation'
                    const issuesInControl = [
                        potatoCystNematode
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'controlledIrrigation'.toLowerCase()) {
                    measureWithPests.measures = 'Controlled irrigation'
                    const issuesInControl = [
                        cutworms
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'controlVolunteersAndWeeds'.toLowerCase()) {
                    measureWithPests.measures = 'Control volunteers & weeds'
                    const issuesInControl = [
                        cutworms,
                        potatoCystNematode,
                        aphidBorne
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'decisionSupport'.toLowerCase()) {
                    measureWithPests.measures = 'Decision support (including thresholds) '
                    const issuesInControl = [
                        cutworms,
                        flnAndSpraing,
                        potatoCystNematode,
                        slugs,
                        aphidBorne,
                        wireworm
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'earlyHarvest'.toLowerCase()) {
                    measureWithPests.measures = 'Early harvest'
                    const issuesInControl = [
                        cutworms,
                        slugs,
                        wireworm
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'fieldHistory'.toLowerCase()) {
                    measureWithPests.measures = 'Field history, Rotation & break crops'
                    const issuesInControl = [
                        flnAndSpraing,
                        potatoCystNematode,
                        slugs,
                        wireworm];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'hygiene'.toLowerCase()) {
                    measureWithPests.measures = 'Hygiene'
                    const issuesInControl = [
                        potatoCystNematode,
                        aphidBorne
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'nonCroppedArea'.toLowerCase()) {
                    measureWithPests.measures = 'In field non-cropped areas'
                    const issuesInControl = [
                        aphidBorne
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'primaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Primary culitvations / Crop residue burial'
                    const issuesInControl = [
                        slugs,
                        wireworm
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'secondaryCultivations'.toLowerCase()) {
                    measureWithPests.measures = 'Secondary cultivations'
                    const issuesInControl = [
                        slugs
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'seedTesting'.toLowerCase()) {
                    measureWithPests.measures = 'Seed testing'
                    const issuesInControl = [
                        potatoCystNematode,
                        aphidBorne
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'lowRiskLocations'.toLowerCase()) {
                    measureWithPests.measures = 'Select low-risk locations'
                    const issuesInControl = [
                        flnAndSpraing,
                        potatoCystNematode,
                        aphidBorne,
                        wireworm
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'spatialSeparation'.toLowerCase()) {
                    measureWithPests.measures = 'Spatial separation'
                    const issuesInControl = [
                        aphidBorne
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'trapCrops'.toLowerCase()) {
                    measureWithPests.measures = 'Trap crops'
                    const issuesInControl = [
                        potatoCystNematode
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                if (measure.toLowerCase() === 'varietalChoice'.toLowerCase()) {
                    measureWithPests.measures = 'Varietal Choice '
                    const issuesInControl = [
                        flnAndSpraing,
                        potatoCystNematode,
                        aphidBorne
                    ];
                    addIssuesToRisk(issuesInControl, listOfIssues, measureWithPests);
                }
                clearRiskText(measureWithPests);
                listOfMeasuresWithPests[status].push(measureWithPests);
            }
        }
    }
    addNoIpmMeasureImplemented(listOfIssues, listOfMeasuresWithPests, notUseStatus, [cutworms, flnAndSpraing, potatoCystNematode, slugs, aphidBorne, wireworm]);
    return listOfMeasuresWithPests;
};

export default potatoesPestSummaryMeasures;