export function clearRiskText(measureWithPests) {
    if (measureWithPests.significantRisk !== '') {
        measureWithPests.significantRisk = measureWithPests.significantRisk.slice(0, -2);
    }
    if (measureWithPests.moderateRisk !== '') {
        measureWithPests.moderateRisk = measureWithPests.moderateRisk.slice(0, -2);
    }
    if (measureWithPests.slightRisk !== '') {
        measureWithPests.slightRisk = measureWithPests.slightRisk.slice(0, -2);
    }
    if (measureWithPests.pestKeys !== '') {
        measureWithPests.pestKeys = measureWithPests.pestKeys.slice(0, -2);
    }
}
