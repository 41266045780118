import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from "../../../../helpers/loadingStatus";
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from "../../../common/loadingIndicator";
import { JsonForms } from "@jsonforms/react";
import {
    materialRenderers,
    materialCells,
} from "@jsonforms/material-renderers";
import MeasuresButtons from '../measuresButtons';
import getListOfIssues from "../../../../helpers/getListOfIssues";
import { QuestionCircle } from "react-bootstrap-icons";
import AppleDiseaseList from "./appleDiseaseList";
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddAppleDiseaseMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });
    const { jsonForm, loadingState } = useJsonForm(`63764147b047e6603fae2592`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.diseases !== null
        && (location.state.surveyData.diseases !== undefined)
        && (location.state.surveyData.diseases.apple !== null)
        && (location.state.surveyData.diseases.apple !== undefined)) ?
        location.state.surveyData.diseases.apple.controlMeasures : {};
    const [data, setData] = useState(initialData);
    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.diseases?.apple);
    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.diseases.apple.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>apple disease control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement </p>
            </div>
            <div className="row my-3">
                {listOfIssues.length === 0 ? (
                    <h3>No issues selected</h3>
                ) : (
                    <>
                        {(
                            listOfIssues.includes("canker".toLowerCase()) ||
                            listOfIssues.includes("powderymildew".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Biostimulants & elicitors</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#biostimulantsDesc" aria-expanded="false" aria-controls="biostimulantsDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="biostimulantsDesc">
                                        <p>
                                            Biostimulants can provide significant yield increases which may offset the effects of diseases which have not been fully controlled in apples, such as canker, in the absence of chemical fungicides. Alternation of conventional fungicides with biostimulants and physical acting products can reduce reliance on fungicides whilst maintaining acceptable powdery mildew control. A range of biostimulant products are available for use on apple orchards in the UK and further information can be found direct from distributors.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <AppleDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                            'canker'.toLowerCase(),
                                            'powderymildew'.toLowerCase(),
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.biostimulants}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("applescab".toLowerCase()) ||
                            listOfIssues.includes("powderymildew".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Decision support, incl. monitoring and forecasting</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="decisionSupportDesc">
                                        <p>
                                            Maintaining records of infection levels from the previous year (such as for scab), knowledge of varietal susceptibility and disease lifecycles in relation to crop growth stages, along with regular orchard walking and observations of weather conditions conducive to disease development and monitoring disease levels, can help predict and prevent disease outbreaks. Various thresholds for management options for apple scab and powdery mildew are available. Forecasting systems can be helpful for targeting fungicide timings only to high-risk infection dates, and reduce the number of routine applications. Apple scab monitoring and forecasting systems such as RIM pro or other models based on Mills periods can help target fungicide use to high-risk weather phases, particularly in early season, and are likely to result in reduced spray applications. It is important to monitor trees frequently to make best use of DSS, and this includes the whole season for scab for incidence in the orchard pre-bloom and during apple development, and then post bloom on rosette leaves and shoots and into harvest to estimate the likely scab inoculum carryover for the following year.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <AppleDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                            'applescab'.toLowerCase(),
                                            'powderymildew'.toLowerCase(),
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.decisionSupport}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("applescab".toLowerCase()) ||
                            listOfIssues.includes("replantdisease".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Field history, rotation & break crops</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#fieldHistoryDesc" aria-expanded="false" aria-controls="fieldHistoryDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="fieldHistoryDesc">
                                        <p>
                                            Crop rotations of five years or more are likely to be effective at reducing apple diseases such as apple scab and apple replant disease (ARD), with longer rotations showing an increased benefit from avoiding planting the same crop in the same soil continuously. Grass with clover swards can acts as a break crop prior to where trees are to be planted.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <AppleDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                            'applescab'.toLowerCase(),
                                            'replantdisease'.toLowerCase(),
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.fieldHistory}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("applescab".toLowerCase()) ||
                            listOfIssues.includes("canker".toLowerCase()) ||
                            listOfIssues.includes("powderymildew".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Nutrient management</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#nutrientManagementDesc" aria-expanded="false" aria-controls="nutrientManagementDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="nutrientManagementDesc">
                                        <p>
                                            Growth cracks are sites for pathogen entry for fungal diseases and can be exacerbated by high nitrogen and irrigation inputs and subsequent rapid host growth. This risk can be reduced by optimising the amount and timing of nitrogen rich fertilisers and irrigation.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <AppleDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                            'applescab'.toLowerCase(),
                                            'canker'.toLowerCase(),
                                            'powderymildew'.toLowerCase(),
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.nutrientManagement}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("replantdisease".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Organic amendments</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#organicAmendmentsDesc" aria-expanded="false" aria-controls="organicAmendmentsDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="organicAmendmentsDesc">
                                        <p>
                                            Generally, strategies to maintain a healthy soil, with a good population of microbes and organisms, such as the use of organic amendments are likely to reduce the severity of apple replant disease (ARD).
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <AppleDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                            'replantdisease'.toLowerCase(),
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.organicAmendments}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("applescab".toLowerCase()) ||
                            listOfIssues.includes("canker".toLowerCase()) ||
                            listOfIssues.includes("powderymildew".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Pruning/canopy management</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#canopyManagementDesc" aria-expanded="false" aria-controls="canopyManagementDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="canopyManagementDesc">
                                        <p>
                                            Canopy management is important for reducing infection by fungal pathogens, which often spread and infect more rapidly in humid environments, such as apple scab. Pruning to allow good circulation encourages faster drying of leaves and fruit following rain fall or dew, which will decrease infections of apple scab fungus and powdery mildew. Cutting out overwintering wood infected with apple scab or canker is an effective eradication method but can be labour intensive. Care must be taken when cutting or pruning to use wound protectant treatments to reduce the risk of further infections. Pruning and discarding infected shoots showing symptoms of powdery mildew in early spring will reduce the inoculum load.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <AppleDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                            'applescab'.toLowerCase(),
                                            'canker'.toLowerCase(),
                                            'powderymildew'.toLowerCase(),
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.canopyManagement}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("canker".toLowerCase()) ||
                            listOfIssues.includes("replantdisease".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Select low-risk locations</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="lowRiskLocationsDesc">
                                        <p>
                                            Selecting as low a risk location as possible for apple orchards is effective at reducing apple replant disease (ARD). ARD occurs when new trees are planted in the same soil as previous trees of the same pome or stone fruit crop, for example apples following apples, and they may develop various symptoms including stunted growth, shorter internodes, reduced biomass and reduced yields.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <AppleDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                            'canker'.toLowerCase(),
                                            'replantdisease'.toLowerCase(),
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.lowRiskLocations}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("applescab".toLowerCase()) ||
                            listOfIssues.includes("powderymildew".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Variety choice and rootstock</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#varietyChoiceDesc" aria-expanded="false" aria-controls="varietyChoiceDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="varietyChoiceDesc">
                                        <p>
                                            Rootstock selection for resistant varieties will help reduce the risk and incidence of canker and powdery mildew.  However, in general the effects of climate, soil factors, and management factors such as mowing and tree spacing will have a greater effect on apple diseases than the rootstock selection. No varieties have complete resistance to powdery mildew, however Discovery and Grenadier have a very low susceptibility to mildew, whereas varieties including Cox, Golden Delicious and Gala are very susceptible.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <AppleDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                            'applescab'.toLowerCase(),
                                            'powderymildew'.toLowerCase(),
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.varietyChoice}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes("canker".toLowerCase())) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Hygiene and Prevention</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#hygieneAndPreventionDesc" aria-expanded="false" aria-controls="hygieneAndPreventionDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                    </div>
                                    <div className="collapse" id="hygieneAndPreventionDesc">
                                        <p>
                                            Removal of cankered and fungal disease infected prunings and apples from orchards is essential because infected material can continue to produce ascospores for at least 1 - 2 years. For trees heavily infected with canker, complete removal maybe required. Prunings and infected material should be burned on site, or if this is not possible, macerated to ensure rapid decay and breakdown.
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-horizontal list-group-flush">
                                        <AppleDiseaseList listOfIssues={listOfIssues} toDisplay={[
                                            'canker'.toLowerCase(),
                                        ]} />
                                    </ul>
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.disease}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.disease.hygieneAndPrevention}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                    </>
                )}
            </div>
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"diseases"}
                    finalUrl={"/add-diseases-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddAppleDiseaseMeasures;