const OatsDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value))
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('earblight'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/fusarium-and-microdochium-in-cereals" target="_blank" rel="noreferrer">Ear Blight</a>
                    </li>
                    : <></>
                }
                {values.includes('eyespot'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/eyespot-in-cereals-and-risk-assessment-in-wheat" target="_blank" rel="noreferrer">Eyespot</a>
                    </li>
                    : <></>
                }
                {values.includes('mildew'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/management-of-powdery-mildew-in-cereals" target="_blank" rel="noreferrer">Mildew</a>
                    </li>
                    : <></>
                }
                {values.includes('seedBorneDiseases'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/seedborne-diseases-of-cereals-tests-and-thresholds" target="_blank" rel="noreferrer">Seed Borne Diseases</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default OatsDiseaseList;