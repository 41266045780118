import { useEffect, useState } from "react";
import authService from "../components/api-authorization/AuthorizeService";
import useGetRequest from "./useGetRequest";

const useFarms = () => {
    const [farms, setFarms] = useState([]);
    const { get, loadingState } = useGetRequest("/api/farm");

    useEffect(() => {
        const fetchFarms = async () => {
            const farms = await get();
            setFarms(farms);
        };
        fetchFarms();
    }, [get]);

    const deleteFarm = async (farm) => {
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'DELETE',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        };
        await fetch(`/api/farm/${farm.id}`, requestOptions)
            .then(async response => {
                if (!response.ok) {
                    // Todo
                    // send message to user farm not deleted     
                    return false;
                }
                return true;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });;
    };

    const postFarm = async (farm) => {
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'POST',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(farm)
        };
        await fetch(`/api/farm`, requestOptions)
            .then(async response => {
                if (!response.ok) {
                    // Todo
                    // send message to user farm not deleted     
                    return null;
                }
                return await response.json();
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const putFarm = async (farm) => { };

    const removeFarm = (farm) => {
        var response = deleteFarm(farm);
        if (response) setFarms([...farms.filter(f => f.id !== farm.id)]);
    };

    const editFarm = (farm) => {
        putFarm(farm);
        //setFarms([...farms]);
    };

    const addFarm = (farm) => {
        var response = postFarm(farm);
        if (response != null) setFarms([...farms, farm]);
    };

    return { addFarm, editFarm, farms, loadingState, removeFarm };
};

export default useFarms;
