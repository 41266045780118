const WideRowCropsWeedsList = ({ issues, toDisplay, listOfCrops }) => {
    if (issues.length === 0) {
        return <></>;
    }
    const values = issues.filter(value => toDisplay.includes(value));
    const cropsToDisplay = [];
    if (listOfCrops.includes('brassicas'.toLowerCase())) cropsToDisplay.push('Brassicas');
    if (listOfCrops.includes('maize'.toLowerCase())) cropsToDisplay.push('Maize');
    if (listOfCrops.includes('potatoes'.toLowerCase())) cropsToDisplay.push('Potatoes');
    if (listOfCrops.includes('sugarbeet'.toLowerCase())) cropsToDisplay.push('Sugar Beet');
    const cropString = cropsToDisplay.join(' - ');
    if (values.length === 0 || !cropString) {
        return <></>;
    }
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                <li className="list-group-item list-group-item-success">
                    {cropString}
                </li>
                {values.includes('annualGrassesWideRow'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/how-to-identify-grass-weeds-in-arable-fields" target="_blank" rel="noreferrer">Annual grasses</a>
                    </li>
                    : <></>
                }
                {values.includes('tapRootBlwWideRow'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/how-to-identify-broad-leaved-weeds-in-arable-fields" target="_blank" rel="noreferrer">Tap Root BLW</a>
                    </li>
                    : <></>
                }
                {values.includes('fibrousRootBlwWideRow'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://ahdb.org.uk/knowledge-library/how-to-identify-broad-leaved-weeds-in-arable-fields" target="_blank" rel="noreferrer">Fibrous Root BLW</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default WideRowCropsWeedsList;