import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import { QuestionCircle } from 'react-bootstrap-icons';
import BeansPestList from './beansPestList';
import MeasuresButtons from '../measuresButtons';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddBeansPestMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });

    const { jsonForm, loadingState } = useJsonForm(`63a1de439884fb4e71489ad5`);


    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.winterBeans !== null)
        && (location.state.surveyData.pests.winterBeans !== undefined)
        && (location.state.surveyData.pests.winterBeans.controlMeasures !== null)
        && (location.state.surveyData.pests.winterBeans.controlMeasures !== undefined)) ?
        location.state.surveyData.pests.winterBeans.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);

    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);


    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.winterBeans);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.winterBeans.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year,
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>winter beans pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement </p>
            </div>

            {listOfIssues.length === 0 ? (
                <h3>No issues selected</h3>
            ) : (
                <>
                    {(listOfIssues.includes('beanWeevil'.toLowerCase()) ||
                        listOfIssues.includes('blackbeanAphid'.toLowerCase()) ||
                        listOfIssues.includes('bruchidBeetle'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Decision Support, Incl. Monitoring</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="decisionSupportDesc">
                                    <p>
                                        IPM decisions should be made based on the results of monitoring and forecasting combined with threshold information where available. Treatment thresholds are the population level or density that must be reached before it becomes economically beneficial. Thresholds enable growers to make decisions based on the level at which pests will impact economic crop yield. They are essential in guiding pest control decisions and preventing the unnecessary use of pesticides.
                                    </p>
                                    <p>Disease forecasts are available from the <a href="https://platform.ipmdecisions.net/" target="_blank" rel="noreferrer">IPM Decisions platform</a>. Forecasts are only active during the growing season.</p>
                                </div>
                                <BeansPestList listOfIssues={listOfIssues} toDisplay={[
                                    'beanWeevil'.toLowerCase(),
                                    'blackbeanAphid'.toLowerCase(),
                                    'bruchidBeetle'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.decisionSupport}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('bruchidBeetle'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Select Low-Risk Locations</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#lowRiskLocationsDesc" aria-expanded="false" aria-controls="lowRiskLocationsDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="lowRiskLocationsDesc">
                                <p>
                                    Some pests are relatively immobile, and numbers increase only when their host crop is grown too often in the same field. To limit the impact of bean weevils and pea aphids, sites where infestations have previously occurred should be avoided. Other pests such as wireworms have a long-life cycle so need the host crop to remain for several years. Growing alternative, non-host crops for appropriate periods can avoid this build-up. The length of the break may vary between pests.
                                </p>
                            </div>

                            <BeansPestList listOfIssues={listOfIssues} toDisplay={[
                                'bruchidBeetle'.toLowerCase(),
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.lowRiskLocations}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                    {(listOfIssues.includes('beanWeevil'.toLowerCase()) ||
                        listOfIssues.includes('blackbeanAphid'.toLowerCase())) && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}><h3>Sowing Date</h3></div>
                                    <div data-bs-toggle="collapse" data-bs-target="#sowingDateDesc" aria-expanded="false" aria-controls="sowingDateDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                                </div>

                                <div className="collapse" id="sowingDateDesc">
                                    <p>
                                        Early sowing or planting of spring crops, from late February onwards for spring beans and early March onwards for peas, can result in rapid plant establishment which in turn can increase the tolerance of the crop to pest damage and drought. <br />
                                        Late sown spring crops of peas and field beans can be more susceptible to damage caused by pea and black bean aphids as flowering is more likely to coincide with aphid migration into the crop. Also later sowing can delay harvest and may subject spring crops to summer drought stress at flowering. Winter sown beans are less susceptible to bean weevil damage than spring beans, as their growth is more advanced when the weevils are active so they are better able to tolerate feeding damage. Pea moth are a risk to pea crops which are in flower or pod in June or July, therefore early or late-sown peas may miss the moth flight period and reduce the risk.
                                    </p>
                                </div>

                                <BeansPestList listOfIssues={listOfIssues} toDisplay={[
                                    'beanWeevil'.toLowerCase(),
                                    'blackbeanAphid'.toLowerCase()
                                ]} />
                                <JsonForms validationMode={'NoValidation'}
                                    schema={schema.properties.pest}
                                    data={data}
                                    uischema={uiSchema.uiSchemas.pest.sowingDate}
                                    renderers={renderers}
                                    cells={materialCells}
                                    onChange={({ data, errors }) => formOnChange({ data, errors })}
                                    readonly={status === 'completed'}
                                />
                                <hr />
                            </>
                        )}
                    {(listOfIssues.includes('bruchidBeetle'.toLowerCase())) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}><h3>Early Harvest</h3></div>
                                <div data-bs-toggle="collapse" data-bs-target="#earlyHarvestDesc" aria-expanded="false" aria-controls="earlyHarvestDesc"><QuestionCircle className='collapsible' color="grey" size={30} /></div>
                            </div>

                            <div className="collapse" id="earlyHarvestDesc">
                                <p>
                                    The impact of bruchid beetles can be reduced by harvesting beans before they reach maturity.  If the beans are allowed to mature, the beetle can complete its lifecycle and overwinter creating the potential for infestation in the following year.
                                </p>
                            </div>

                            <BeansPestList listOfIssues={listOfIssues} toDisplay={[
                                'bruchidBeetle'.toLowerCase()
                            ]} />
                            <JsonForms validationMode={'NoValidation'}
                                schema={schema.properties.pest}
                                data={data}
                                uischema={uiSchema.uiSchemas.pest.earlyHarvest}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ data, errors }) => formOnChange({ data, errors })}
                                readonly={status === 'completed'}
                            />
                            <hr />
                        </>
                    )}
                </>
            )}

            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddBeansPestMeasures;