const getListOfOtherCrops = (landParcels) => {
    let listOfOtherCrops = [];
    if (landParcels == null) return;
    landParcels.forEach(
        function callback(parcel) {
            let crops = parcel.crops.map(function (crop) {
                if (crop.isOther) {
                    listOfOtherCrops.push(crop.cropType)
                }
            })
        }
    )
    return listOfOtherCrops;
}

export default getListOfOtherCrops;