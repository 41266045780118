import { useCallback, useState } from "react";
import loadingStatus from "../helpers/loadingStatus";
import authService from '../components/api-authorization/AuthorizeService'

const useGetRequest = (url) => {

    const [loadingState, setLoadingState] = useState(loadingStatus.isLoading);

    const get = useCallback(async () => {
        setLoadingState(loadingStatus.isLoading);
        try {
            const token = await authService.getAccessToken();
            const requestOptions = {
                method: 'GET',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            };
            const response = await fetch(url, requestOptions);
            const result = await response.json();
            setLoadingState(loadingStatus.loaded);
            return result;
        }
        catch
        {
            setLoadingState(loadingStatus.hasErrored);
        }
    }, [url]);

    return { get, loadingState };
};

export default useGetRequest;