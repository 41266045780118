import { JsonForms } from '@jsonforms/react';
import { useEffect, useState } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import getListOfIssues from '../../../../helpers/getListOfIssues';
import loadingStatus from '../../../../helpers/loadingStatus';
import useJsonForm from '../../../../hooks/useJsonForm';
import LoadingIndicator from '../../../common/loadingIndicator';
import MeasuresButtons from '../measuresButtons';
import OilseedsPestList from './oilseedsPestList';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import BenchMarkingControl, {
    benchMarkingControlTester,
} from '../../../customControls/BenchMarkingControl';

const AddOilseedsPestMeasures = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state === null || location.state.surveyData === null) {
            navigate("/farm-list");
        };
    });
    const { jsonForm, loadingState } = useJsonForm(`63a177ae95e69189d1e34f30`);

    const initialData = ((location.state !== undefined)
        && (location.state?.surveyData !== undefined)
        && location.state.surveyData.pests !== null
        && (location.state.surveyData.pests !== undefined)
        && (location.state.surveyData.pests.oilseedRape !== null)
        && (location.state.surveyData.pests.oilseedRape !== undefined)
        && location.state.surveyData.pests.oilseedRape.controlMeasures !== null
        && location.state.surveyData.pests.oilseedRape.controlMeasures !== undefined) ?
        location.state.surveyData.pests.oilseedRape.controlMeasures : {};

    const status = (location.state !== null && location.state.surveyData !== null) && location.state.surveyData.status;

    const [data, setData] = useState(initialData);
    if (loadingState !== loadingStatus.loaded)
        return <LoadingIndicator loadingState={loadingState} />;

    if (initialData === null) initialData.oilseedRape = {};
    const schema = JSON.parse(jsonForm.schema);
    const uiSchema = JSON.parse(jsonForm.uiSchema);
    const listOfIssues = getListOfIssues(location?.state?.surveyData?.pests?.oilseedRape);

    const formOnChange = ({ data, errors }) => {
        setData(data);
        location.state.surveyData.pests.oilseedRape.controlMeasures = data;
    };

    const renderers = [
        ...materialRenderers,
        {
            tester: benchMarkingControlTester,
            renderer: BenchMarkingControl,
            year: location.state.surveyData.year
        },
    ]

    return (
        <>
            <div className="row my-3">
                <h1 className="text-uppercase font-weight-bold">
                    {location.state.farmData.name} - <u>oilseed rape pest control measures</u> - {location.state.surveyData.year}
                </h1>
                <p>Please select the category that best describes what actions you plan to implement or not implement</p>
            </div>
            <div className="row my-3">
                {listOfIssues.length === 0 ? (
                    <h3>No issues selected</h3>
                ) : (
                    <>
                        {(
                            listOfIssues.includes('cabbageRootFly'.toLowerCase()) ||
                            listOfIssues.includes('cabbageSeedWeevil'.toLowerCase()) ||
                            listOfIssues.includes('cabbageStemFleaBeetle'.toLowerCase()) ||
                            listOfIssues.includes('mealyCabbageAphid'.toLowerCase()) ||
                            listOfIssues.includes('pollenBeetle'.toLowerCase()) ||
                            listOfIssues.includes('slugs'.toLowerCase()) ||
                            listOfIssues.includes('turnipSawfly'.toLowerCase()) ||
                            listOfIssues.includes('tuyvVectors'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>
                                                Decision support (incl. thresholds)
                                            </h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#decisionSupportDesc" aria-expanded="false" aria-controls="decisionSupportDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="decisionSupportDesc">
                                        <p>IPM decisions should be made based on the results of monitoring and forecasting combined with threshold information where available. Treatment thresholds are the population level or density that must be reached before intervention becomes economically beneficial. Thresholds enable growers to make decisions based on the level at which pests will impact economic crop yield. They are essential in guiding pest control decisions and preventing the unnecessary use of pesticides.</p>
                                        <p>Pest forecasts are available from the <a href="https://platform.ipmdecisions.net/" target="_blank" rel="noreferrer">IPM Decisions platform</a>. Forecasts are only active during the growing season.</p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'cabbageRootFly'.toLowerCase(),
                                        'cabbageSeedWeevil'.toLowerCase(),
                                        'cabbageStemFleaBeetle'.toLowerCase(),
                                        'mealyCabbageAphid'.toLowerCase(),
                                        'pollenBeetle'.toLowerCase(),
                                        'slugs'.toLowerCase(),
                                        'turnipSawfly'.toLowerCase(),
                                        'tuyvVectors'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.decisionSupport}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('cabbageStemFleaBeetle'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <h3>Defoliation (incl. mowing and grazing)</h3>
                                        </div>
                                        <div data-bs-toggle="collapse" data-bs-target="#defoliationDesc" aria-expanded="false" aria-controls="defoliationDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="defoliationDesc">
                                        <p>Defoliation is a method that has been used to combat CSFB larvae in oilseed rape, and can reduce larval numbers to up 75%. Crops can be defoliated using a flail or by grazing with sheep. This approach is best suited to early drilled crops which will have the highest larval numbers but also be best able to recover from the defoliation. Do not defoliate after stem elongation.</p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'cabbageStemFleaBeetle'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.defoliation}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}

                        {(
                            listOfIssues.includes('slugs'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Rolling soil post-planting</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#rollingSoilDesc" aria-expanded="false" aria-controls="rollingSoilDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="rollingSoilDesc">
                                        <p>Rolling soil post-planting can improve the seedbed quality, resulting in more rapid germination of crops and reducing access to soil-borne pests, such as slugs, to seeds.  Rolling may also kill some pests or reduce their mobility.  </p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'slugs'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.rollingSoil}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('cabbageStemFleaBeetle'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Seed rate</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#seedRateDesc" aria-expanded="false" aria-controls="seedRateDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="seedRateDesc">
                                        <p>Increasing seed rates can compensate for the loss of plants to pests such as cabbage stem flea beetle.</p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'cabbageStemFleaBeetle'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.seedRate}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('cabbageStemFleaBeetle'.toLowerCase()) ||
                            listOfIssues.includes('slugs'.toLowerCase()) ||
                            listOfIssues.includes('turnipSawfly'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Seedbed quality</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#seedbedQualityDesc" aria-expanded="false" aria-controls="seedbedQualityDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="seedbedQualityDesc">
                                        <p>Poor seedbeds can have two effects on pest damage. Firstly, poor seed/soil contact can cause delays in germination, which can render oilseed rape plants more susceptible to pests such as slugs and CSFB. Secondly, rough, cobbly seedbeds allow slugs to access seeds underground. Firm, fine seedbeds avoid both problems and encourage rapid germination and crop establishment, thus decreasing susceptibility to pest attack. Dry conditions can result in oilseed rape emerging slowly and unevenly, making the crop highly vulnerable to CSFB damage.</p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'cabbageStemFleaBeetle'.toLowerCase(),
                                        'slugs'.toLowerCase(),
                                        'turnipSawfly'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.seedbedQuality}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('cabbageRootFly'.toLowerCase()) ||
                            listOfIssues.includes('cabbageStemFleaBeetle'.toLowerCase()) ||
                            listOfIssues.includes('mealyCabbageAphid'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Sowing date</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#sowingDateDesc" aria-expanded="false" aria-controls="sowingDateDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="sowingDateDesc">
                                        <p>Early sowing or planting, from mid-July – mid August, can result in rapid plant establishment, which in turn can increase the tolerance of the crop to some types of pest damage. For example, the early sowing of winter oilseed rape can increase the tolerance of the cabbage stem flea beetle as the crop is well established and a larger size by the time the pest migration occurs. However these crops can be at greater risk from larval damage. Sowing oilseed rape early can also limit damage from pollen beetle in the spring as plants are often beyond the susceptible green/yellow bud stage before migration of the pest begins.</p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'cabbageRootFly'.toLowerCase(),
                                        'cabbageStemFleaBeetle'.toLowerCase(),
                                        'mealyCabbageAphid'.toLowerCase(),
                                        'pollenBeetle'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.sowingDate}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('brassicaPodMidge'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Spatial separation</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#spatialSeparationDesc" aria-expanded="false" aria-controls="spatialSeparationDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="spatialSeparationDesc">
                                        <p>Some pest species are poor fliers so do not travel long distances between host crops in a single season.  Wide spatial separation between host crops in successive years can make it difficult for the pest to find them. </p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'brassicaPodMidge'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.spatialSeparation}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('cabbageStemFleaBeetle'.toLowerCase()) ||
                            listOfIssues.includes('pollenBeetle'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Trap crops</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#trapCropsDesc" aria-expanded="false" aria-controls="trapCropsDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="trapCropsDesc">
                                        <p>Trap cropping is a method of reducing pest damage by attracting pests away from a susceptible crop and toward a trap crop. The trap crop is usually a plant stand (sown or otherwise) that is more attractive to the pest than the susceptible crop. Volunteer oilseed rape can be utilised as a trap crop. Delaying its destruction until after the cabbage stem flea beetle (CSFB) migration is complete, has been shown to reduce pest damage in newly sown oilseed rape crops in the adjacent field.</p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'cabbageStemFleaBeetle'.toLowerCase(),
                                        'pollenBeetle'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.trapCrops}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('tuyvVectors'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>Varietal choice</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#varietalChoiceDesc" aria-expanded="false" aria-controls="varietalChoiceDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="varietalChoiceDesc">
                                        <p>Varieties are now available that are resistant to turnip yellows virus (TuYV).</p>
                                        <p>For specific guidance click <a href="https://ahdb.org.uk/knowledge-library/recommended-lists-for-cereals-and-oilseeds-rl" target="_blank" rel="noreferrer">here</a></p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'tuyvVectors'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.varietalChoice}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                        {(
                            listOfIssues.includes('tuyvVectors'.toLowerCase())
                        ) && (
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }}><h3>In field non-cropped areas</h3></div>
                                        <div data-bs-toggle="collapse" data-bs-target="#nonCroppedAreasDesc" aria-expanded="false" aria-controls="nonCroppedAreasDesc">
                                            <QuestionCircle className='collapsible' color="grey" size={30} />
                                        </div>
                                    </div>
                                    <div className="collapse" id="nonCroppedAreasDesc">
                                        <p>Beetle banks consist of stands of wildflowers and grasses and are designed to act as reservoirs of beneficial insects such as ground beetles and parasitoids, which help to provide natural biological control of insect pests. Diverse crop margins and strips act in a similar way to beetle banks to increase natural enemies. More diverse strips should harbour greater biodiversity and greater numbers of beneficials. Ladybirds, hoverflies and lacewings are natural enemies of aphids in oilseed rape.</p>
                                    </div>
                                    <OilseedsPestList listOfIssues={listOfIssues} toDisplay={[
                                        'tuyvVectors'.toLowerCase()
                                    ]} />
                                    <JsonForms validationMode={'NoValidation'}
                                        schema={schema.properties.pest}
                                        data={data}
                                        uischema={uiSchema.uiSchemas.pest.nonCroppedAreas}
                                        renderers={renderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) => formOnChange({ data, errors })}
                                        readonly={status === 'completed'}
                                    />
                                    <hr />
                                </>
                            )}
                    </>
                )}
            </div>
            <div className="row justify-content-between my-5">
                <MeasuresButtons
                    farmData={location.state.farmData}
                    surveyData={location.state.surveyData}
                    index={location.state.index}
                    crops={location.state.surveyData.landParcels.map(parcel => parcel.crops).flat().filter(crop => !crop.isOther)}
                    measureType={"pests"}
                    finalUrl={"/add-pests-resistance"}
                    readonly={status === 'completed'}>
                </MeasuresButtons>
            </div>
        </>
    );
};

export default AddOilseedsPestMeasures;