import getListOfIssues from "../../../../helpers/getListOfIssues";
import SummaryIssuesTables from "../summaryIssuesTables";
import weedsIssuesSummaryMeasures from "./weedsIssuesSummaryMeasures";

const WeedsSummary = (props) => {
    const surveyData = props.surveyData;
    const listOfAllCropsIssues = (surveyData.weeds !== null && surveyData.weeds['allCrops'] !== null) ? getListOfIssues(surveyData.weeds['allCrops'], true) : [];
    const listOfCombinableCropsIssues = (surveyData.weeds !== null && surveyData.weeds['combinableCrops'] !== null) ? getListOfIssues(surveyData.weeds['combinableCrops'], true) : [];
    const listOfWideRowCropsIssues = (surveyData.weeds !== null && surveyData.weeds['wideRowCrops'] !== null) ? getListOfIssues(surveyData.weeds['wideRowCrops'], true) : [];
    const allWeedsJoined = listOfAllCropsIssues.concat(listOfCombinableCropsIssues, listOfWideRowCropsIssues);
    let allWeedsData = { notUse: [], inUse: [], futureUse: [] };
    if (allWeedsJoined.length !== 0) {
        allWeedsData = weedsIssuesSummaryMeasures(surveyData.weeds.controlMeasures, allWeedsJoined);
    }
    return (
        <>
            <SummaryIssuesTables
                listOfWeedsControls={surveyData?.weeds?.controlMeasures}
                listOfWeeds={allWeedsJoined}
                weedsData={allWeedsData}
                crop={'weed'}>
            </SummaryIssuesTables>
        </>
    );
};

export default WeedsSummary;