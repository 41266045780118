const AppleDiseaseList = ({ listOfIssues, toDisplay }) => {
    const values = listOfIssues.filter(value => toDisplay.includes(value));
    return (
        <>
            <ul className="list-group list-group-horizontal list-group-flush">
                <li className="list-group-item">
                    Useful for:
                </li>
                {values.includes('applescab'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://applegrowing.niab.com/Apple-Scab/" target="_blank" rel="noreferrer">Apple scab</a>
                    </li>
                    : <></>
                }
                {values.includes('canker'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://applegrowing.niab.com/apple-canker/" target="_blank" rel="noreferrer">Canker</a>
                    </li>
                    : <></>
                }
                {values.includes('powderymildew'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://applegrowing.niab.com/Apple-Powdery-Mildew/" target="_blank" rel="noreferrer">Powdery Mildew</a>
                    </li>
                    : <></>
                }
                {values.includes('replantdisease'.toLowerCase()) ?
                    <li className="list-group-item list-group-item-primary">
                        <a href="https://applegrowing.niab.com/apple-replant-disease/" target="_blank" rel="noreferrer">Replant disease</a>
                    </li>
                    : <></>
                }
            </ul>
        </>);
};

export default AppleDiseaseList;