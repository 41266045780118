import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
import { EyeFill, PencilFill, Trash3Fill } from 'react-bootstrap-icons';

const FarmRow = ({ farm, removeFarm }) => {

    const onDeleteSubmitClick = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div >
                        <h1 className="text-uppercase">Confirm delete farm</h1>
                        <p>Are you sure want to delete farm:
                            <span className="text-uppercase font-weight-bold"> {farm.name}</span>
                            ?
                        </p>
                        <button type="button" className="btn btn-danger m-2"
                            onClick={() => {
                                removeFarm(farm)
                                onClose();
                            }}>
                            Yes, delete all the data...
                        </button>
                        <button type="button" className="btn btn-primary  m-2" onClick={onClose}>No</button>
                    </div>
                );
            }
        });
    }

    return (
        <tr>
            <td className="text-uppercase align-middle fs-4">{farm.name}</td>
            <td>
                <span>View IPM Plans</span>
                <Link to={{ pathname: `/view-farm/${farm.id}` }} className="ms-2 me-5 text-decoration-none">
                    <EyeFill color="green" size={45} />
                </Link>
            </td>
            <td>
                <span>Edit Farm</span>
                <Link to={{ pathname: `/edit-farm/${farm.id}` }} className="ms-2 me-5 text-decoration-none">
                    <PencilFill color="royalblue" size={45} />
                </Link >
            </td>
            <td>
                <span>Delete Farm</span>
                <Trash3Fill type="button" className="ms-2 me-5" color="red" size={45} onClick={() => onDeleteSubmitClick()} />
            </td>
        </tr>
    );
};

export default FarmRow;
