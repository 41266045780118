import { ColumnsCurrent, ColumnsFuture, ColumnsNotSelected, TableStyle } from './tableStyle';
import SummaryTable from './summaryTable';
import { ExclamationTriangle } from 'react-bootstrap-icons';

function SummaryOtherIssuesTables(props) {
    const CurrentColumns = ColumnsCurrent;
    const FutureColumns = ColumnsFuture;
    const NotSelectedColumns = ColumnsNotSelected;

    return (
        <>
            {
                props.listOfPests !== undefined && (
                    <div>
                        <h4 className="fw-bolder text-decoration-underline">Pests</h4>
                        {props.listOfPests.length === 0 || props.pestData === undefined ? (
                            <>
                                <h5>No issues selected</h5>
                            </>
                        ) : (
                            <>
                                    <>
                                        <TableStyle>
                                            <SummaryTable columns={FutureColumns} data={props.pestData} tableId={`${props.crop.toLowerCase()}-pests-no-selected`}></SummaryTable>
                                        </TableStyle>
                                    </>
                            </>
                        )}
                    </div>
                )
            }
            {
                props.listOfDiseases !== undefined && (
                    <div>
                        <h4 className="fw-bolder text-decoration-underline">Diseases</h4>
                        {props.listOfDiseases.length === 0 ? (
                            <>
                                <h5>No issues selected</h5>
                            </>
                        ) : (
                            <>
                                <>
                                    <TableStyle>
                                        <SummaryTable columns={NotSelectedColumns} data={props.DiseaseData} tableId={`${props.crop.toLowerCase()}-Diseases-no-selected`}></SummaryTable>
                                    </TableStyle>
                                </>
                            </>
                        )}
                    </div>
                )
            }
        </>
    )

}

export default SummaryOtherIssuesTables;