import { Col, Row } from "react-bootstrap";
import BenchMarkingTable from "./BenchMarkingTable";

const BenchMarkingSection = ({ currentYearData, previousYearData, dynamicId }) => {
    return (
        <Row className="collapse show pt-2" id={ dynamicId }>
            <Col>
                <BenchMarkingTable tableTitle={"This year we got "} tableData={currentYearData} />
            </Col>
            <Col>
                <BenchMarkingTable tableTitle={"Last year we got "} tableData={previousYearData} />
            </Col>
        </Row>
    )
}

export default BenchMarkingSection;