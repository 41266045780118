import React, { useEffect } from 'react';
import { useState } from 'react';
import SurveyRow from './surveyRow';

const SurveyList = ({ surveyList, farm }) => {

    const [surveys, setSurveys] = useState(surveyList);
    const removeSurvey = (survey) => {
        setSurveys([...surveys.filter(s => s.id !== survey.id)]);
    };

    const addSurvey = (survey) => {
        setSurveys([...surveys, survey]);
    };

    useEffect(() => {
    }, [surveys])

    return (
        <>
            {surveys.length === 0 ? (
                <h1>You don't have any IPM Plans yet</h1>
            ) : (
                <div style={{ overflowX: 'auto' }}>
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>IPM Plan Year</th>
                            </tr>
                        </thead>
                        <tbody>
                            {surveys.map((survey, index) => {
                                const previousSurveyIndex = index - 1;
                                let lastYearSurvey = previousSurveyIndex >= 0 ? surveys[previousSurveyIndex] : null;
                                if (lastYearSurvey !== null && survey.year - 1 !== lastYearSurvey.year) lastYearSurvey = null;
                                return (
                                    <SurveyRow
                                        key={survey.id}
                                        survey={survey}
                                        lastYearSurvey={lastYearSurvey}
                                        removeSurvey={removeSurvey}
                                        addSurvey={addSurvey}
                                        farm={farm}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div >
            )}
        </>
    );
};

export default SurveyList;
