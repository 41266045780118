import { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import jsPDF from "jspdf";
import 'jspdf-autotable'
import authService from "../../../api-authorization/AuthorizeService";
import CropOptions from "../../../../helpers/cropOptions";
import AddOatsDiseaseMeasures from "../../measures/oats/addOatsDiseaseMeasures";

const SummaryReport = (props) => {
    const [reportName, setReportName] = useState("");
    const [email, setEmail] = useState("");
    const [buttonText, setButtonText] = useState("Download PDF report");
    const [isEmailChecked, setIsEmailChecked] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!reportName) {
            toast.error("Please input a report name", {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            }); return
        };
        if (isEmailChecked && !email) {
            toast.info("Please input a valid email", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return
        };
        

        let pdfReport = generateReportPDF();
        let toastText = "Report created";
        if (isEmailChecked) {
            toast.info("Sending email...", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            const formData = new FormData();
            formData.append("recipientEmail", email);
            formData.append("file", new Blob([pdfReport], { type: "application/pdf" }), `${reportName}.pdf`);
            let response = await sendReport(formData);
            if (!response.ok) {
                toast.error("Error sending email", {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }
            toastText = "Report created and sent by email";
        }
        toast.success(toastText, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
        return;
    }
    const handleEmailCheckboxChange = async (e) => {
        if (e.target.checked) {
            setButtonText("Download and send PDF report by email");
            setIsEmailChecked(true);
            return;
        }
        setButtonText("Download PDF report");
        setIsEmailChecked(false);
    }

    async function sendReport(formData) {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch("/api/survey/send-report", {
                method: "POST",
                headers: !token ? {} :
                    {
                        'Accept': "application/json",
                        'Authorization': `Bearer ${token}`
                    },
                body: formData,
            });
            return await response;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const checkIssuesSelected = (crop, issue) => {
        let flag = false;
        
            if (document.getElementById(`${crop.toLowerCase()}-${issue}-current-selected`) != null ||
                document.getElementById(`${crop.toLowerCase()}-${issue}-future-selected`) != null ||
                document.getElementById(`${crop.toLowerCase()}-${issue}-no-selected`) != null){
                flag = true;
            }
        return flag;
    }


    const generateReportPDF = () => {
        // A4 size 210x297
        const date = new Date();
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        const doc = new jsPDF();
        var posY = 30;

        function CreateNewPage() {
            doc.addPage();
            posY = 10;
            AddHeader(doc, 10);
        }

        function AddNoControlSelected() {
            doc.text("No control measures selected", 12, posY)

            doc.setFontSize(16);
            doc.setFont('times', "bold");
            posY += 10;
        }

        function AddNoNotes() {
            doc.text("No notes", 12, posY)

            doc.setFontSize(16);
            doc.setFont('times', "bold");
            posY += 10;
        }



        // Title
        doc.setFontSize(30);
        doc.setFont('times', "bold");
        doc.text(`IPM TOOL`, 105, posY, null, null, "center");
        posY += 10;

        // Plan Information
        doc.setFontSize(16);
        doc.text(`IPM Plan report name: ${reportName}`, 105, posY, null, null, "center");
        posY += 5;
        doc.line(15, posY, 200, posY);
        posY += 10;
        doc.setFontSize(12);
        
        doc.text(`Farm Name: ${props.farmData.name}`, 20, posY)
        posY += 10;
        doc.text(`Summary Year: ${props.surveyData.year}`, 20, posY)
        posY += 10;
        
        doc.text(`Plan completed by: ${props.userEmail}`, 20, posY);
        posY += 10;
        if (props.basisNumber) {
            doc.text(`Basis registration number: ${props.basisNumber}`, 20, posY);
        }
        else {
            doc.text(`Basis registration number: _______________`, 20, posY);
        }
        posY += 10;
        doc.text(`Report generated on: ${day}/${month}/${year}`, 20, posY);
        posY += 5;
        doc.line(15, posY, 200, posY);
        posY += 10;

        // Disclaimer
        doc.setFontSize(10);
        var text = "The IPM Tool provides access to guidance on integrated pest management (IPM) in outdoor crops and enables users to record current, and planned, IPM actions.The IPM Tool Host has no economic responsibility whatsoever for losses, damages or inconveniences arising out of the use of or inability to use this service.";
        doc.text(text, 105, posY, { maxWidth: 180 , align: "center"});
        posY += 15;

        // Logos footer
        doc.addImage("/images/logos/sruc.png", "JPEG", 25, 250, 25, 15);
        doc.addImage("/images/logos/adas.png", "JPEG", 10, 250, 15, 15);
        doc.addImage("/images/logos/ahdb.jpg", "JPEG", 45, 250, 25, 15);
        doc.addImage("/images/logos/nfu.jpg", "JPEG", 70, 250, 25, 15);
        doc.addImage("/images/logos/defra.jpg", "JPEG", 98, 250, 25, 15);
        doc.addImage("/images/logos/pgro.jpg", "JPEG", 125, 250, 15, 15);
        doc.addImage("/images/logos/bbro.jpg", "JPEG", 145, 250, 25, 15);
        doc.addImage("/images/logos/vi_ipm.png", "JPEG", 175, 250, 25, 15);

        jsPDF.autoTableSetDefaults({
            // theme: 'plain'
        });
        CreateNewPage();
        props.listOfCrops.forEach(crop => {
            
            posY = 10;
            AddHeader(doc,posY);
            posY += 20;

            doc.setFontSize(20);
            doc.setFont('times', "bold");
            let selectedCrop = CropOptions.find((option) => option.cropType.toLowerCase() === crop.toLowerCase())
            if (selectedCrop === undefined)
                selectedCrop = {title:crop.charAt(0).toUpperCase() + crop.slice(1), cropType:crop.toLowerCase(), isOther:true}
            doc.text(`${selectedCrop.title}`, 105, posY, null, null, "center");

            const issues = ['pests', 'diseases', 'weeds'];
            issues.forEach(issue => {
                if (issue === 'weeds' && (crop !== 'apple' && crop !== 'improvedgrassland')) {
                    return;
                }
                if (checkIssuesSelected(crop, issue)) {
                    posY += 10;
                    doc.setFontSize(16);
                    doc.text(`${selectedCrop.title.toUpperCase()} - ${issue.toUpperCase()}`, 16, posY);
                    posY += 5;
                    doc.autoTable({ html: `#${crop.toLowerCase()}-${issue}-no-selected`, startY: posY });
                    posY = doc.lastAutoTable.finalY + 10;

                    
                        doc.text(`Use in current cropping season`, 12, posY);
                        posY += 5;
                        if (document.getElementById(`${crop.toLowerCase()}-${issue}-current-selected`) != null) {
                            doc.autoTable({ html: `#${crop.toLowerCase()}-${issue}-current-selected`, startY: posY });
                            posY = doc.lastAutoTable.finalY + 10;
                        } else {
                            posY += 5;
                            doc.setFontSize(10);
                            doc.setFont('helvetica', 'normal');

                            AddNoControlSelected();
                        }
                    
                    
                    
                        doc.text(`Intend to use in future seasons`, 12, posY);
                        posY += 5;
                        if (document.getElementById(`${crop.toLowerCase()}-${issue}-future-selected`) != null) {
                            doc.autoTable({ html: `#${crop.toLowerCase()}-${issue}-future-selected`, startY: posY });
                            posY = doc.lastAutoTable.finalY + 10;
                        } else {
                            posY += 5;
                            doc.setFontSize(10);

                            AddNoControlSelected();
                        }
                    
                } else {
                    posY += 10;
                    doc.setFontSize(16);
                    doc.text(`${selectedCrop.title.toUpperCase()} - ${issue.toUpperCase()}`, 16, posY);

                    posY += 10;
                    doc.setFontSize(16);
                    doc.setFont('helvetica', 'normal');
                    doc.text("No issues selected", 12, posY)
                }
                posY += 5;
                doc.text(`Notes`, 12, posY);
                posY += 5;
                if (document.getElementById(`${crop.toLowerCase()}-${issue}-notes`) != null) {
                    doc.autoTable({ html: `#${crop.toLowerCase()}-${issue}-notes`, startY: posY });
                } else {
                    posY += 5;
                    doc.setFontSize(10);
                    AddNoNotes();
                }

                CreateNewPage();

            })
            
            
            
            function shouldAddNewPage(issue, crop) {
                return !(issue === 'weeds' && (crop === 'apple' || crop === 'improvedgrassland')) &&
                    !(issue === 'diseases' && (crop !== 'apple' && crop !== 'improvedgrassland'));
            }
        });

        posY = 10;
        
        posY += 20;
        doc.setFontSize(20);
        doc.text("Weeds", 12, posY)
        
        const weedTables = [{ TableId: "weed-weeds-no-selected", TableName: "" }, { TableId: "weed-weeds-current-selected", TableName: "Use in current cropping season" }, { TableId: "weed-weeds-future-selected", TableName: "Intend to use in future seasons" }, { TableId: "weed-weeds-notes", TableName: "Control measure notes"}];

        weedTables.forEach(weedTable => {
            AddWeedTableName(weedTable);
            posY += 10;
            if (document.getElementById(weedTable.TableId) != null) {
                doc.autoTable({ html: `#${weedTable.TableId}`, startY: posY, styles: { PageBreak: 'avoid' } });
                posY = doc.lastAutoTable.finalY + 10;
            } else if (weedTable.TableId !== "weed-weeds-no-selected"){
                AddNoControlSelected();
            }
        })

        function AddWeedTableName(weedTable) {
            posY += 10;
            doc.setFontSize(16)
            doc.text(weedTable.TableName, 12, posY)
        }

        doc.save(`${reportName}.pdf`);
        return doc.output('blob');
    }

    const AddHeader = (doc, posY) => {
        doc.setFontSize(15);
        doc.setFont('times', "bold");
        doc.text(`IPM Tool`, 20, posY);
        doc.text(`IPM Plan report name: ${reportName}`, 200, posY, null, null, "right");
    }

    return (
        <>
            <h2 className="font-weight-bold mt-3">
                <u>Get a report of your IPM Plan</u>
            </h2>
            <Form onSubmit={handleSubmit}>
                <Row className="align-items-center">
                    <Col xs="auto">
                        <FloatingLabel controlId="reportNameField"
                            label="Report Name"
                            className="mb-3">
                            <Form.Control
                                className="mb-2"
                                placeholder="My Report"
                                value={reportName}
                                onChange={(e) => setReportName(e.target.value)} />
                        </FloatingLabel>
                    </Col>
                    <Col xs="auto">
                        <Form.Check
                            type="checkbox"
                            id="autoSizingCheck"
                            className="mb-2"
                            label="Send by email"
                            onChange={handleEmailCheckboxChange}
                        />
                    </Col>
                    <Col xs="auto" style={{ display: isEmailChecked ? "block" : "none" }}>
                        <InputGroup className="mb-2">
                            <InputGroup.Text>@</InputGroup.Text>
                            <Form.Control id="userEmailTextInput"
                                placeholder="Email recipient"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                required={isEmailChecked}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                        </InputGroup>
                    </Col>
                    <Col xs="auto">
                        <Button type="submit" className="mb-2">
                            {buttonText}
                        </Button>
                    </Col>
                </Row>
            </Form >
        </>
    );
};

export default SummaryReport;